import React, { useState, useEffect } from 'react';
import { Button, Popover, OverlayTrigger, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faClipboard, faUserCircle, faTrophy, faChevronDown, faChevronUp, faArrowAltCircleRight, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@material-ui/core';
import '../../../home/about-us/about.css'
import { FormatMoney } from '../../../../utilities/formats';
import { pazySalvo, generarFactura } from '../../../../services/datos.services';
import { FormattedDate, FormattedMessage, } from 'react-intl';
import { ToastContainer, toast } from 'react-toastify';


export const ItemCreditsUser = (props) => {
    let [tinvoice, setTinvoice] = useState('')
    let [tsafe, setTsafe] = useState('')
    let [tdocuments, setTdocuments] = useState('')
    let [vtdocuments, setVTdocuments] = useState('')
    let [vtdetail, setVTdetail] = useState('')
    let [textError, setTextError] = useState('')
    let [textSuccessPeace,setTextSuccessPeace] = useState('')
    let [textSuccessInvoice, setTextSuccessInvoice] = useState('')
    const { data, setShow = () => { }, setId = () => { }, cuotas, pagos, saldo = () => { }, isAdmin = false} = props;

    useEffect(()=>{
        let server = localStorage.getItem('server');
        if(server === '"eeuu"'){
            setTextSuccessPeace(<FormattedMessage id='peacesuccess' defaultMessage={"Paz"} />)
            setTextSuccessInvoice(<FormattedMessage id='pinvoicesuccess' defaultMessage={"Factura"} />)
            setTextError(<FormattedMessage id='serverError' defaultMessage={"Paz y Salvo"} />)
            setTsafe(<FormattedMessage id='peace' defaultMessage={"Paz y Salvo"} />)
            setTinvoice(<FormattedMessage id='invoice' defaultMessage={"Factura"} />)
            setTdocuments(<FormattedMessage id='documents' defaultMessage={"Documentos"} />)
            setVTdocuments(<FormattedMessage id='viewdocuments' defaultMessage={"Ver Documentos"} />)
            setVTdetail(<FormattedMessage id='viewdetail' defaultMessage={"Ver Detalles"} />)
        }else{
            setTextSuccessInvoice("¡Factura enviada al correo electrónico, es posible que llegue a la bandeja de Spam.!")
            setTextSuccessPeace("¡Te llegará un correo con tu Paz y Salvo, es posible que llegue a la bandeja de Spam.!")
            setTextError("Ha ocurrido un error inesperado, intentalo mas tarde")
            setTinvoice("Factura")
            setTsafe("Paz y salvo")
            setTdocuments("Documentos")
            setVTdocuments("Ver Documentos")
            setVTdetail("Ver Detalles")
        }
    },[])

    const solicitarPazySalvo = () => {
        const id = parseInt(data.id);
        if (data.saldo > 0) {
            // alert("¡Para poder generar tu Paz y Salvo deber tener tu saldo en 0!")
            const error = () => toast.error("¡Para poder generar tu Paz y Salvo deber tener tu saldo en 0!");
            error();
        } else {
            pazySalvo(id, (data, flag) => {
                if (flag) {
                    const success = () => toast.success(textSuccessPeace);
                    success();
                }else{
                    const error = () => toast.error(textError);
                    error();
                }
            })
        }
    }

    const solicitarFactura = () => {
        const id = parseInt(data.id);
            generarFactura(id, (response, flag) => {
                if (flag) {
                    const success = () => toast.success(textSuccessInvoice);
                    success();
                }else{

                    const error = () => toast.error(textError);
                    error();
                }             
            })
        }

    const popover = (
        <Popover id="popover-documents" style={{ maxWidth: "160px" }}>
            <Popover.Title as="h3">{tdocuments} {data.id}</Popover.Title>
            <Popover.Content>
                {/* <Button onClick={() => solicitarFactura()} style={{ width: "140px" }} variant="light">
                    <FontAwesomeIcon style={{ marginRight: '8px', color:'#02dce8' }} icon={faTicketAlt} />
                    {tinvoice}
                </Button> */}
                <Button
                    onClick={() => solicitarPazySalvo()}
                    style={{ width: "140px" }}
                    variant="light"
                >
                    <FontAwesomeIcon style={{ marginRight: '8px', color:'#02dce8' }} icon={faClipboard} />
                    {tsafe}
                </Button>
            </Popover.Content>
        </Popover>
    );

    return (

        <>
            <ToastContainer style={{ marginTop: 50, fontSize: 16 }} autoClose={10000} />
            <tr style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px gray' }}>
                <td className='text-body'>{data.id}</td>
                <td className='text-body'>{FormatMoney(data.valor_inicial)}</td>
                <td className='text-body'>{data.fecha}</td>
                <td className='text-body'>{data.fecha_vencimiento}</td>
                <td className='text-body'>{FormatMoney(data.saldo)}</td>
                <td><Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center' }} onClick={() => { pagos(data); cuotas(data); saldo(data); setId(data.id); setShow(true) }} ><label className='text-title-black' style={{marginTop:6}}>{vtdetail}</label></Button></td>
                {!isAdmin && <td>
                    <OverlayTrigger rootClose trigger="click" placement="left" overlay={popover}>
                        <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center' }}><label className='text-title-black' style={{marginTop:6}}>{vtdocuments}</label></Button>
                    </OverlayTrigger>
                </td>}
            </tr>
        </>

    )
}

export const ItemDetail = (props) => {

    const { dataUser, showDetalleCartera, setShowModalEditar } = props

    const [showOptions, setShowOptions] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowOptions(flag);
        setOpen(flag);
    }

    return (
        <>
            <Card style={{ marginBottom: 2, maxWidth: '70rem' }}>
                <Card.Header>
                    <Row onClick={() => handleOpen(!showOptions)}>
                        <Col xs lg="3">
                            <Button style={{ minWidth: 110 }} variant="outline-dark">
                                {dataUser.nomCliente}
                                <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={dataUser.perfil === 'admin' ? faTrophy : faUserCircle} />
                            </Button>
                        </Col>

                        <Col>
                            <Row>
                                <Col><label style={{ fontSize: 16 }}>id de venta: {dataUser.id}</label></Col>
                                <Col><label style={{ fontSize: 16 }}>Saldo: $ {dataUser.debCobrar}</label></Col>
                                <Col className="text-md-right" lg="1">
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>

                {showOptions && <Card.Body>
                    <Row>
                        <Col >
                            <label style={{ fontSize: 16 }}>Valor Inicial: {dataUser.valInicial}</label>
                        </Col>
                        <Col>
                            <label style={{ fontSize: 16 }}>Valor Final: {dataUser.valFinal}</label>
                        </Col>
                        <Col style={{ justifyContent: 'center' }}>
                            <label>
                                <Button variant="light" onClick={() => showDetalleCartera(true)} >
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px', marginRight: 8 }} icon={faArrowAltCircleRight} />
                                </Button>
                            </label>
                        </Col>

                        <Col style={{ justifyContent: 'center' }}>
                            <label>
                                <Button variant="light" onClick={() => setShowModalEditar(true)} >
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px', marginRight: 8 }} icon={faUserEdit} />
                                    Editar
                                </Button>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th><label >Cuotas</label></th>
                                        <th><label>Vencidos</label></th>
                                        <th><label >Creada</label></th>
                                        <th><label >Finaliza</label></th>
                                        <th><label >Ultimo pago</label></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{dataUser.noPagos}{' '}/{' '}{dataUser.totalCuotas}</td>
                                        <td>{dataUser.noPagos}</td>
                                        <td>{dataUser.creado}</td>
                                        <td>{dataUser.finPagos}</td>
                                        <td>{dataUser.ultimoPago}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>}

            </Card>


        </>
    )
}

export const ItemDetailCuota = (props) => {

    const { cuota } = props

    return (
        <>
            <tr>
                <td className='text-title-black'>{cuota.id}</td>
                <td className='text-title-black'>{FormatMoney(cuota.total)}</td>
                <td className='text-title-black'>{cuota.fecha}</td>
                <td className='text-title-black'>{FormatMoney(cuota.saldo)}</td>
                <td className='text-title-black'>{cuota.estado}</td>
            </tr>
        </>
    )
}

export const ItemDetailPago = (props) => {

    const { pago } = props

    return (
        <>
            <tr>
                <td className='text-title-black'>{pago.pago_id}</td>
                <td className='text-title-black'>{pago.fecha}</td>
                <td className='text-title-black'>{(pago.valor).toFixed(2)}</td>
            </tr>
        </>
    )
}