import { PureComponent } from 'react'
import {FormattedMessage} from 'react-intl'
export default class Register extends PureComponent {
    constructor() {
        super();
        this.inputsToVerify = [];
        this.state = {
            allOk: false
        }
    }
    handleChange = (name, value) => {
        this.setState({
            [name]: {
                name,
                value,
                error: '',
                type: this.state[name].type,
                required: this.state[name].required
            }
        });
    }

    handleRelease = (name) => {
        this.verifyField(name);
    }
    verifyField = (name) => {

        const value = this.state[name].value;
        const type = this.state[name].type;
        const required = this.state[name].required;
        let errorMessage = "";
        if (required) {
            if (typeof (value) === 'string') {
                if (value.trim() === 'seleccione')  {
                    errorMessage =<label className='text-title-red'>Seleccione una opción</label>;
                }
                else{
                if (value.trim() === "") {
                    errorMessage = <label className='text-title-red'>Campo obligatorio</label>;
                }}
                switch (type) {
                    case "email":
                        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                        errorMessage = emailValid ? "" : 
                        <label className='text-title-red'>Correo inválido</label>;
                        break;
                    case 'phone':
                            if (value.length > 10 || value.length < 7) {
                                errorMessage =  <label className='text-title-red'>Teléfono inválido</label>;
                            }
                            break;
                    case 'mobile':
                        if (value.length > 10 || value.length < 10) {
                            errorMessage =  <label className='text-title-red'>Celular inválido</label>;
                        }
                        break;
                    case 'password':
                        if (value.length < 6) {
                            errorMessage = <label className='text-title-red'>Contraseña demasiado corta</label>
                        }
                        break;
                    case 'passwordRepeat': {
                        if (value !== this.state.password.value) {
                            errorMessage = <label className='text-title-red'>Las contraseñas no coinciden</label>;
                        }
                        break;
                    }
                    case 'emailRepeat': {
                        if (value !== this.state.email.value) {
                            errorMessage =  <label className='text-title-red'>Los correos no coinciden</label>;
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
        }
        if (errorMessage !== "") {
            this.setState({
                [name]: {
                    name,
                    value: this.state[name].value,
                    error: errorMessage,
                    type: this.state[name].type,
                    required: this.state[name].required
                }
            })
            return true;
        } else {
            return false;
        }
    }
    verifyForm = () => {
        let allOK = true;
        this.inputsToVerify.forEach(key => {
            let error = this.verifyField(key);
            if (error) {
                allOK = false;
            }
        });
        this.setState({ allOK });
        return allOK;

    }
} 