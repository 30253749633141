import React, {useEffect, useState} from 'react';
import './about.css'
import billetes from '../../../assets/billetes.png'
import paso1 from '../../../assets/paso1.png'
import paso2 from '../../../assets/paso2.png'
import paso3 from '../../../assets/paso3.png'

export const AboutUs = () => {
    
    return (//falta generar la condicion ternaria isMobile ? 'mobile' : 'desktop' ....
        <div className='containerAbout' style={{height:'100%'}}  >
            <div className='como-f' >
                <div className='titulo'  >
                    
                    <text className='text-title' style={{fontWeight:'bold', fontSize:'3.5ch'}}>¿Cómo solicitar tu Crédito?</text>
                </div>
                <div className='secciones_Solicitar_Credito'>
                    <img src={billetes} alt='billetes' />
                    <img src={paso1} alt='paso1' />
                    <img src={paso2} alt='paso2' />
                    <img src={paso3} alt='paso3' />
                </div>
            </div>
        </div>
    )
}