import React, { useEffect, useState } from 'react';
import { EditarCliente } from '../editarCliente/editarCliente';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faUserEdit, faUserPlus, faUserShield, faUserTie, faGrinBeam, faTired } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import { MDBDataTable } from 'mdbreact';
import { getHijos } from '../../../services/datos.services'
import { CrearCliente } from '../editarCliente/components/crearCliente';
import { inactivarUser } from '../../../services/datos.services';
import {  toast } from 'react-toastify';
import GridLoader from 'react-spinners/GridLoader';

export const UserController = (props) => {
    const [active, setActive] = useState(true);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [loading,setLoading] = useState(false)
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [userOption, setUserOption] = useState(false);
    const [debts, setDebts] = useState({});
    const [dataUser, setDataUser] = useState({});
    const {  defaulterList } = props;

    useEffect(() => {
        setLoading(true)
        const uid = localStorage.getItem('uid');
        const userName = localStorage.getItem('nameUser');
        const permiso = localStorage.getItem('Permission');

        getHijos(parseInt(uid), '', (data, flag) => {

            let cols = {}
            let rows = []
            setLoading(false)
            if (flag) {
                
                if (data.caja_ids.length > 0) {

                    data.caja_ids.forEach(element => {

                        rows.push({
                            perfil:
                                <div>
                                    {element.permiso === 'administrador' && <FontAwesomeIcon icon={faUserShield} style={{ marginRight: 10 }} />}
                                    {element.permiso === 'vendedor' && <FontAwesomeIcon icon={faUserTie} style={{ marginRight: 10 }} />}
                                    {element.permiso}
                                </div>,
                            nombre: element.nombre1 + ' ' + element.nombre2 + ' ' + element.apellido1 + ' ' + element.apellido2,
                            maximo_caja: FormatMoney(element.dineroLimiteEnCaja),
                            maximo_credito: FormatMoney(element.dineroLimiteEnVenta),
                            maximo_renovacion: FormatMoney(element.dineroLimiteEnRenovacion),
                            maximo_gasto: FormatMoney(element.dineroLimiteEnGasto),

                            acciones:
                                <div>
                                    <Button
                                        disabled={
                                            userName === element.nombre1 + ' ' + element.nombre2 + ' ' + element.apellido1 + ' ' + element.apellido2 &&
                                                permiso === element.permiso ? false : false}
                                        className="show-btn"
                                        size="sm"
                                        variant="light"
                                        style={{ borderRadius: 50,borderColor:'transparent' }}
                                        onClick={() => {
                                            setShowModalEditar(true);
                                            setDataUser(
                                                {
                                                    nombre1: element.nombre1,
                                                    nombre2: element.nombre2,
                                                    apellido1: element.apellido1,
                                                    apellido2: element.apellido2,
                                                    dineroLimiteEnCaja: element.dineroLimiteEnCaja,
                                                    dineroLimiteEnGasto: element.dineroLimiteEnGasto,
                                                    dineroLimiteEnRenovacion: element.dineroLimiteEnRenovacion,
                                                    dineroLimiteEnVenta: element.dineroLimiteEnVenta,
                                                    permiso: element.permiso,
                                                    id: element.id
                                                }
                                            )
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                                            icon={faUserEdit}
                                        />
                                    </Button>
                                    <button className="show-btn"
                        disabled={!active ? true : false}
                        size="sm"
                        variant="light"
                        style={{ borderRadius: 50,borderColor:'transparent' }}
                        onClick={() => { setActive(!active); inactivar(dataUser.estado) }}
                    >
                        {active && <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={faGrinBeam}
                        />}
                        {!active && <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={faGrinBeam}
                        />}
                    </button>
                                </div>
                                
                        })
                    });
                    cols = {
                        columns: [
                            {
                                label: <label className='text-title-blue'>Perfil</label>,
                                field: 'perfil',
                            },
                            {
                                label: <label className='text-title-blue'>Nombre</label>,
                                field: 'nombre',
                            },
                            {
                                label:<label className='text-title-blue'>Máximo en caja</label> ,
                                field: 'maximo_caja',
                                sort: 'asc'
                            },
                            {
                                label: <label className='text-title-blue'>Máximo en creditos</label> ,
                                field: 'maximo_credito',
                            },
                            {
                                label:<label className='text-title-blue'>Máximo en renovaciones</label> ,
                                field: 'maximo_renovacion',
                            },
                            {
                                label:<label className='text-title-blue'>Máximo gastos</label> ,
                                field: 'maximo_gasto',
                            },
                            {
                                label: <label className='text-title-blue'>Acciones</label> ,
                                field: 'acciones',
                            },
                        ],
                        rows: rows
                    }
                    setDebts(cols)

                }
            }

        });

    }, []);
    
    const inactivar = (uid) => {

        if (window.confirm('¿Desea inactivar al usuario?')) {

            inactivarUser(uid, (data, flag) => {
                if (flag) {
                    defaulterList();
                    const success = () => toast.success("¡El usuario ha sido inactivado!");
                    success();
                } else {
                    const success = () => toast.success("¡El usuario sigue disponible!");
                    success();
                }
            })
            
        } else {
            setActive(true)
        }
    }

    return (
        <>
            <div style={{ background: 'transparent', color: '#02dce8', paddingTop: 70 }}>
                <div className="container" style={{ textAlign: 'center' }}>
                    <label className='text-total' style={{ fontSize: 26 }}>
                        <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faUserCog} />
                        Control de Usuarios
                    </label>
                </div>
            </div>
            <div className='container text-center text-md-left font-small lighten-3' style={{ marginTop: 10 }}>

                <Row>
                    <Col md={4}>
                        <div style={{ marginLeft: '10%' }}>
                            <Button  variant="light" onClick={() => { setShowCreateUser(true); setUserOption(true) }}  >
                              <label className='text-total'>  <FontAwesomeIcon style={{ height: '30px', width: '30px', marginRight: 8 }} icon={faUserPlus} />
                                    Nuevo Usuario </label>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,width: '95%', left:'1%', borderRadius:50, boxShadow:'6px 10px 10px gray' }}>
                    <Container>
                        <MDBDataTable
                            className='text-title-black'
                            responsive
                            striped
                            bordered
                            small
                            paging={false}
                            data={debts}
                            searchLabel="Buscar"
                        />
                    </Container>
                </div>
                {showModalEditar && <EditarCliente dataUser={dataUser} userOption={userOption} setUserOption={(flag) => setUserOption(flag)} setShowModalEditar={(flag) => setShowModalEditar(flag)} />}
                {showCreateUser && <CrearCliente userOption={userOption} setUserOption={(flag) => setUserOption(flag)} setShowCreateUser={(flag) => setShowCreateUser(flag)} />}
            </div>
            <GridLoader  size={50} loading={loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
        </>
    )
}
