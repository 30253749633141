import React, { useEffect, useState } from 'react';
import '../../../shared/components/sidenav-style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { BoxDetail } from '../box-detail/box-detail';
import { Token } from '../token/token';

export const PrincipalCaja = () => {

    const [viewBoxDetail, setViewBoxDetail] = useState(true);
    const [show, setShow] = useState(false)
    const [showModalToken, setShowModalToken] = useState(false);

    useEffect(() => {
        if (show === true) {
            setViewBoxDetail(true);
        }
    }, [show])

    return (
        <>
            <style>
                {`
                .modal-90w { max-width: 75% !important; }
                ` }
            </style>
            <div style={{ background: 'transparent', color: 'white', paddingTop: 70 }}>
                <div className="container">
                    <label className='text-total' style={{ fontSize: 26 }}>
                        <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faCashRegister} />
                        Manejo de caja
                    </label>
                </div>
            </div>
            <div className="mt-4">
                {showModalToken && <Token setShowModalToken={(flag) => setShowModalToken(flag)} />}
                {viewBoxDetail && <BoxDetail setShow={(flag) => setShow(flag)} setShowModalToken={(flag) => setShowModalToken(flag)} />}

            </div>
        </>
    )
}