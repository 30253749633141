import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faMobile,faAppleAlt} from "@fortawesome/free-solid-svg-icons";
import { TermsAndConditions } from './terms-and-conditions';
import { Modal } from 'react-bootstrap';
import logo from '../../assets/logo-vengalepresto.png' 
import './../../pages/home/about-us/about.css'
import {FormattedMessage} from 'react-intl'
import apk from '../../utilities/apk/VengaLePresto.apk'
import { faAndroid } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
    
    let [modalPdf, setModalPdf] = useState(false);
    let [selectPdf, setSelectPdf] = useState('');
    let [colombia, setColombia] = useState(false);
    let [usa, setUsa] = useState(false);
    let [quienest, setQuienesT] = useState('');
    let [funcionaT, setFuncionaT] = useState('');
    let [frequentT, setFrequentT] = useState('');
    let [home, setHome] = useState('');
    let [linkSeguridad, setLinkSeguridad] = useState('');
    let [linkPoliticas, setLinkPoliticas] = useState('');
    let [linkTerminos, setLinkTerminos] = useState('');
    

     
    useEffect(() =>{
        let servidor = localStorage.getItem('server');
        if(servidor === '"eeuu"'){
            setUsa(true);
            setHome(<FormattedMessage id='home' defaultMessage={"Inicio"} />)
            setFrequentT(<FormattedMessage id='frequent' defaultMessage={"Preguntas frecuentes"} />)
            setFuncionaT(<FormattedMessage id='howork' defaultMessage={"¿Cómo funciona?"} />)
            setQuienesT(<FormattedMessage id='whoesT' defaultMessage={"¿Quiénes somos?"} />)
            setLinkSeguridad(<FormattedMessage id='footerSecurity' defaultMessage={"Politicas de seguridad"} />)
            setLinkPoliticas(<FormattedMessage id='footerTreatment' defaultMessage={"Políticas de privacidad"} />)
            setLinkTerminos(<FormattedMessage id='footerTerms' defaultMessage={"Términos y condiciones"} />)
            }
        if(servidor === '"colombia"' || servidor === '"colombiapruebas"'){
            setHome("Inicio")
            setFrequentT("Preguntas frecuentes")
            setFuncionaT("¿Cómo funciona?")
            setQuienesT("¿Quienes somos?")
            setColombia(true);
            setLinkPoliticas("Politicas de privacidad")
            setLinkTerminos('Términos y condiciones')
            }  

    }, []);

    return (
        <>
            <footer style={{
                background: 'black', fontSize: '16px',
                position: 'absolute',
                bottom: '10',
                display: 'block',
                width: '100%',
                marginTop: ''
            }}   >

                <div style={{ color: "white", }} className="text-center text-md-left  ">

                    <div className="row" style={{ width: '90%',marginLeft:'2%' ,marginRight:'2%', alignItems:'center', alignSelf:'center', borderBottom:'5px solid #02dce8' }}>

                        <div style={{ marginLeft: '60px' }} className=" mt-3 mx-auto t-4 mb-1">

                            
                            <p style={{marginTop:30}} className='text-title'>
                            <p> <h3 style={{color:'#02dce8',fontSize:20}} className='text-title'>Contáctanos</h3></p>
                               <p> Chatea con nosotros: 320 330 6827</p>
                               <p> Info@vengalepresto.com</p>
                               <p> Lunes a Viernes 7am a 6pm</p>
                               <p>Dirección: Carrera 23 # 62-39 | Oficina 602A</p>
                            </p>
                        </div>
                        
                        <div style={{ marginLeft: '60px' }} className=" mt-3 mx-auto t-4 mb-1">
                            <p style={{marginTop:0}} className='text-title'>
                                <p><h3 style={{color:'#02dce8',fontSize:20}} className='text-title'>Vengalepresto</h3></p>
                                <p><a  style={{ color: "white" }} href="/#misionAndVision">{quienest}</a></p> 
                                <p><a  style={{ color: "white" }} href="/#requirements">{funcionaT}</a></p>
                                <p><a  style={{ color: "white" }} href="/#preguntasFrecuentes">{frequentT}</a></p>
                            </p>
                        </div>
                        <div style={{ marginLeft: '60px' }} className=" mt-3 mx-auto t-4 mb-1">
                            <p style={{marginTop:-35}} className='text-title'>
                                <p><h3 style={{color:'#02dce8', fontSize:20}} className='text-title'>Términos legales</h3></p>
                                <p><a className='text-title' style={{ color: "white" }} target='_blank' href='policies'>{linkPoliticas}</a></p>
                                <p><a className='text-title' style={{ color: "white" }} target='_blank' href='terms-and-conditions'>{linkTerminos}</a></p>   
                            </p>
                        </div>
                        
                        <div style={{ marginLeft: '60px' }} className=" mt-3 mx-auto t-4 mb-1">
                            <p style={{marginTop:-45}} className='text-title'>
                                <p><h3 style={{color:'#02dce8', fontSize:20}} className='text-title'>Síguenos</h3></p>
                                <p>
                                    <a target='_blank' href="https://www.facebook.com/profile.php?id=100086922326030">  <FontAwesomeIcon style={{ height: '35px', width: '35px', color: '#0081ff' }} className="mr-2 align-self-center" icon={faFacebookF} /></a>
                                    <a target='_blank' href="https://instagram.com/vengalepresto?igshid=NTc4MTIwNjQ2YQ=="> <FontAwesomeIcon style={{ height: '35px', width: '35px', color: '#F400A1' }} className="mr-2 align-self-center" icon={faInstagram} /></a>
                                    <a target='_blank' href="https://wa.me/573203306827"> <FontAwesomeIcon style={{ height: '35px', width: '35px', color: '#00d650' }} className="mr-2 align-self-center" icon={faWhatsapp} /></a> 
                                    <a href={apk} target="_blank" rel="noopener noreferrer" download="VengaLePresto.apk" ><FontAwesomeIcon color={'white'}  style={{ height: '35px', width: '35px', color: '#02dce8' }} className="mr-2 align-self-center" icon={faAndroid} /> </a>
                                </p>
                                <p>
                                    <img alt='logo' style={{width:200}} src={logo}></img>
                                </p>    
                            </p>
                        </div>
                    </div>
                    <div style={{ background: '#000', color: '#02dce8', borderTopColor:'#02dce8', fontSize:20 }} className="footer-copyright text-center py-3 text-title">© 2022 Copyright: Vengalepresto
                       
                    </div>
                </div>
            </footer>
            {modalPdf &&
                <Modal
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                    show={true}
                    onHide={() => setModalPdf(false)}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <div style={{ height: 700 }}>
                            <TermsAndConditions selectPdf={selectPdf} />
                        </div>

                    </Modal.Body>
                </Modal>}
        </>
    );
}