import React, { useState } from 'react';
import '../../home/about-us/about.css'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import favicon from '../../../assets/favicon.ico'
import { faFacebook, faViacoin } from '@fortawesome/free-brands-svg-icons';
import StepLabel from '@material-ui/core/StepLabel';
import { RegisterReference } from '../user-register/register-reference';
import { RegisterLaboral } from '../user-register/register-laboral';
import { RegisterPersonal } from '../user-register/register-personal';
import { RegisterFinanciera } from '../user-register/register-financiera';
import { Container } from '@material-ui/core';
import { Modal, Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export const AfterRegister = (props) => {
    const { visible, data, dataForm, tipoDocumentos, step, upDateList } = props;
    const classes = useStyles();
    let[colombia, setFlagColombia] = useState(false)
    let[usa, setFlagusa] = useState(false)
    let [browsingT, setBrowsingT] = useState("");
    let [loanT, setLoanT] = useState("");
    const [activeStep, setActiveStep] = React.useState(step);
    const [disabled, setDisable] = useState(true)
    const steps = getSteps();
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };


    function getSteps() {
        return [<label className='text-table'>Personal</label>, <label className='text-table'>Laboral</label> , <label className='text-table'>Financiero</label>, <label className='text-table'>Referencias</label>];
    }

    const validate = (flag) => {
        setDisable(flag)
        return disabled
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <div className="container" style={{ marginLeft: '-1%' }}>
                        <RegisterPersonal upDateList={upDateList} tipoDocumentos={tipoDocumentos} data={data} dataForm={dataForm} next={() => handleNext()} back={() => handleBack()} validate={(flag) => { validate(flag) }} showBoton={visible} />
                    </div>)
            case 1:
                return (
                    <div className="container" style={{ marginLeft: '-1%' }}>
                        <RegisterLaboral upDateList={upDateList} data={data} dataForm={dataForm} next={() => handleNext()} back={() => handleBack()} validate={(flag) => { validate(flag) }} showBoton={visible} />
                    </div>)

            case 2:
                return (
                    <div className="container" style={{ marginLeft: '-1%' }}>
                        <RegisterFinanciera upDateList={upDateList} data={data} dataForm={dataForm} next={() => handleNext()} back={() => handleBack()} validate={(flag) => { validate(flag) }} showBoton={visible} />
                    </div>)
            case 3:
                return (
                    <div className="container" style={{ marginLeft: '-1%' }}>
                        <RegisterReference upDateList={upDateList} data={data} dataForm={dataForm} next={() => handleNext()} back={() => handleBack()} validate={(flag) => { validate(flag) }} showBoton={visible} />
                    </div>)
            default:
                return
        }
    }

    useEffect(()=>{
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            setFlagusa(true)
            setBrowsingT(<FormattedMessage id='browsing' defaultMessage={"Seguir navegando"}/>)
            setLoanT(<FormattedMessage id='requestCredit' defaultMessage={"Solicitar préstamo"}/>)
        }else{
            setBrowsingT("Seguir navegando")
            setLoanT("Solicitar crédito")
            setFlagColombia(true)
        }
    },[])

    return (
        <>

            <div className={classes.root}>

                <Stepper style={{ background: '#F8F8F8' }} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step  key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <Modal show={show} onHide={handleClose} backdrop="static" centered>
                            <Modal.Body>
                                {colombia &&<label className="container text-title-black" style={{ fontSize: 22 }}>
                                    ¡¡ Has completado los datos con éxito, ahora puedes seguir con la solicitud de tu crédito !!
                            </label>}
                            {usa &&<label className="container" style={{ fontSize: 22 }}>
                                <FormattedMessage id='yescredit' defaultMessage={"Yes"}/>
                                    
                            </label>}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="row container">
                                    <Col sm>
                                        <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '10vw' }} onClick={() => { window.location = "/"; localStorage.setItem('falta', JSON.stringify(false)); }}><label className='text-title-black' style={{marginTop:6}}>{browsingT}</label></Button>
                                    </Col>
                                    <Col sm>
                                        <Link
                                            to="/summary-credit"
                                        >
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '10vw' }}  ><label className='text-title-black' style={{marginTop:6}}> {loanT}</label>
                                             </Button>
                                        </Link>
                                    </Col>
                                </div>
                            </Modal.Footer>

                        </Modal>
                    ) : (
                        <div>
                            <Container className={classes.instructions}>{getStepContent(activeStep)}</Container>
                            {/* botones next y back valuesSimulator*/}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

