import React from 'react';
import { FormatMoney } from '../../../../utilities/formats';


export const ListCredit = (props) => {
    const { request } = props
    return (
        <>
            <tr>
                <td> {request.solicitud_tipo} </td>
                <td>{request.fecha_solicitud}</td>
                <td>{request.id}</td>
                <td>{request.partner_name}</td>
                <td>{FormatMoney(request.solicitud_valoraprobado)}</td>
                <td>{request.estado}</td>
                <td>{}</td>
            </tr>
        </>
    )
}