
import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { ListDetailCuotaMoroso, ListDetailPagosMoroso } from './list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';


export const MorososDetails = (props) => {

    const { setShowModalMorosos, pagos, detalles, valorInicial ,codigo ,cliente} = props;               
    
    const handleClose = () => {
        setShowModalMorosos(false)
    }

    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 85% !important; }
                `}
            </style>
            <Modal dialogClassName="modal-90w" centered show={true} onHide={handleClose} >

                <Modal.Header style={{ background: 'transparent', color: '#02dce8', borderBottomWidth:3, borderBottomColor:'#02dce8' }} closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faFileInvoiceDollar} />
                    <label className='text-total'>Detalle de cuotas</label>
                </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col lg='4'><label className='text-title-black'> Usuario: {cliente}</label> </Col>
                        <Col lg='2'><label className='text-title-black'> ID: {codigo}</label></Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListDetailCuotaMoroso data={detalles} valorInicial={valorInicial}/>
                        </Col>
                        <Col>
                            <ListDetailPagosMoroso data={pagos} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >
        </>

    );
}
