import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { TabMovimientos } from './tab-movimientos';
import { ListGastoIngresoRetiro, ListSolicitudesCreditosRenovaciones, ListPagosAndNoPagos } from './components/list';
import {  FormatDateYYMMDD, FormatMoney } from '../../../utilities/formats';
import { getDetaillBox, sendTransaction } from '../../../services/datos.services';


export const ModalDetalleCaja = (props) => {
    const { handleClose, name, id, box } = props;
    
    const [moves, setMoves] = useState(false);
    const [optionSelect, setOptionSelect] = useState('gastos');
    const [detail, setDetail] = useState({});
    const [list, setList] = useState([]);
    const [option, setOption] = useState("");
    const [gastoIngresoRetiro, setGastoIngresoRetiro] = useState(true);
    const [ventaCreditoRenovacion, setVentaCreditoRenovacion] = useState(false);
    const [pagoNoPago, setPagoNoPago] = useState(false)

    const callBack = (data, flag) => {

        let detailList = {}
        if (flag && data) {

            data.caja_detalles.forEach(element => {

                if (element.tipo === 'gasto') {
                    if (detailList['gasto']) {
                        detailList.gasto.push(element)
                    } else {
                        detailList['gasto'] = []
                        detailList.gasto.push(element)
                    }
                }
                if (element.tipo === 'ingreso') {
                    if (detailList['ingreso']) {
                        detailList.ingreso.push(element)
                    } else {
                        detailList['ingreso'] = []
                        detailList.ingreso.push(element)
                    }
                }
                if (element.tipo === 'retiro') {
                    if (detailList['retiro']) {
                        detailList.retiro.push(element)
                    } else {
                        detailList['retiro'] = []
                        detailList.retiro.push(element)
                    }
                }
                if (element.tipo === 'solicitud') {
                    if (detailList['solicitud']) {
                        detailList.solicitud.push(element)
                    } else {
                        detailList['solicitud'] = []
                        detailList.solicitud.push(element)
                    }
                }
                if (element.tipo === 'venta') {
                    if (detailList['venta']) {
                        detailList.venta.push(element)
                    } else {
                        detailList['venta'] = []
                        detailList.venta.push(element)
                    }
                }
                if (element.tipo === 'renovacion') {
                    if (detailList['renovacion']) {
                        detailList.renovacion.push(element)
                    } else {
                        detailList['renovacion'] = []
                        detailList.renovacion.push(element)
                    }
                }
                if (element.tipo === 'pago') {
                    if (detailList['pago']) {
                        detailList.pago.push(element)
                    } else {
                        detailList['pago'] = []
                        detailList.pago.push(element)
                    }
                }
                if (element.tipo === 'nopago') {
                    if (detailList['nopago']) {
                        detailList.nopago.push(element)
                    } else {
                        detailList['nopago'] = []
                        detailList.nopago.push(element)
                    }
                }
            });
        }
        setDetail(detailList);
    }

    useEffect(() => {
        getDetaillBox(id, callBack);
    }, []);

    useEffect(() => {

        switch (optionSelect) {
            case 'gastos':
                setMoves(true);
                setList(detail.gasto ? detail.gasto : []);
                setOption("gasto");
                setGastoIngresoRetiro(true);
                setVentaCreditoRenovacion(false);
                setPagoNoPago(false);
                break;
            case 'ingresos':
                setMoves(true);
                setList(detail.ingreso ? detail.ingreso : []);
                setOption("ingreso");
                setGastoIngresoRetiro(true);
                setVentaCreditoRenovacion(false);
                setPagoNoPago(false);
                break;
            case 'retiros':
                setMoves(true);
                setList(detail.retiro ? detail.retiro : []);
                setOption("retiro");
                setGastoIngresoRetiro(true);
                setVentaCreditoRenovacion(false);
                setPagoNoPago(false);
                break;
            case 'venta':
                setMoves(true);
                setList(detail.venta ? detail.venta : []);
                setGastoIngresoRetiro(false);
                setVentaCreditoRenovacion(true);
                setPagoNoPago(false);
                break;
            case 'renovaciones':
                setMoves(true);
                setList(detail.renovacion ? detail.renovacion : []);
                setGastoIngresoRetiro(false);
                setVentaCreditoRenovacion(true);
                setPagoNoPago(false);
                break;
            case 'solicitudes':
                setMoves(true);
                setList(detail.solicitud ? detail.solicitud : []);
                setGastoIngresoRetiro(false);
                setVentaCreditoRenovacion(true);
                setPagoNoPago(false);
                break;
            case 'pagos':
                setMoves(true);
                setList(detail.pago ? detail.pago : []);
                setGastoIngresoRetiro(false);
                setVentaCreditoRenovacion(false);
                setPagoNoPago(true);
                break;
            case 'nopagos':
                setMoves(true);
                setList(detail.nopago ? detail.nopago : []);
                setGastoIngresoRetiro(false);
                setVentaCreditoRenovacion(false);
                setPagoNoPago(true);
                break;
            default:
                break;
        }

    }, [optionSelect, detail])

    const updateValue = (item, value) => {
        let detalleLista = list;

        if (item.tipo === 'nopago' && value > 0) {

            const transaction =
                [
                    {
                        id: parseInt(item.id),
                        detalle: {
                            'caja_id': parseInt(box.caja_id),
                            'tipo': 'pago',
                            'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                            'valor': parseInt(value),
                        }
                    }
                ]

            sendTransaction(transaction, (data, flag) => {

                if (flag) {

                    for (let index = 0; index < detalleLista.length; index++) {
                        if (detalleLista[index].id === item.id) {
                            detalleLista[index].valor = parseInt(value)
                        }
                    }
                    setList(detalleLista);
                }
            });
        } else if (item.tipo === 'pago' && value === 0) {

            const transaction =
                [
                    {
                        id: parseInt(item.id),
                        detalle: {
                            'caja_id': parseInt(box.caja_id),
                            'tipo': 'nopago',
                            'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                            'valor': parseInt(value),
                        }
                    }
                ]

            sendTransaction(transaction, (data, flag) => {

                if (flag) {

                    for (let index = 0; index < detalleLista.length; index++) {
                        if (detalleLista[index].id === item.id) {
                            detalleLista[index].valor = parseInt(value)
                        }
                    }
                    setList(detalleLista);
                }
            });
        } else {
            const transaction =
                [
                    {
                        id: parseInt(item.id),
                        detalle: {
                            'tipo': '' + item.tipo,
                            'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                            'valor': parseInt(value),
                        }
                    }
                ]

            sendTransaction(transaction, (data, flag) => {

                if (flag) {

                    for (let index = 0; index < detalleLista.length; index++) {
                        if (detalleLista[index].id === item.id) {
                            detalleLista[index].valor = parseInt(value)
                        }
                    }
                    setList(detalleLista);
                }
            });
        }
    }

    return (
        <Modal
            show={true}
            onHide={() => handleClose()}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header style={{ background: 'transparent', color: '#02dce8', borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    <label>Detalle {name}</label>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-md-center" >
                    <Col lg="auto">
                        
                            <label className='text-title-blue'> Valor Inicial: </label>
                            <label className='text-title-black'>
                            {FormatMoney(box.valorInicial)}
                        </label>
                    </Col>
                    <Col lg="auto">

                        
                        <label className='text-title-blue'> Valor Final:</label>
                        <label className='text-title-black'>
                            {FormatMoney(box.valorFinal)}
                        </label>
                    </Col>
                    <Col lg="auto">
                        <label style={{ fontSize: 16 }}>
                            <label className='text-title-blue'>Debido Cobrar:  </label>
                            <label className='text-title-black'>  {FormatMoney(box.valorInicial)} </label>
                        </label>
                    </Col>
                    <Col lg="auto">
                        
                            <label className='text-title-blue'>Total movimientos:</label>
                            <label className='text-title-black'>
                            {FormatMoney(box.movimientos)}
                        </label>
                    </Col>
                    <Col lg="auto">
                        
                            <label className='text-title-blue'>% Cumplimiento: {box.caja_porcentajeMovimientos}</label>
                        <label className='text-title-black'>
                        </label>
                    </Col>
                </Row>
                <hr />
                <TabMovimientos data={detail} optionSelected={(option) => setOptionSelect(option)} />
                <div style={{ marginTop: 30 }}>
                    {gastoIngresoRetiro
                        && <ListGastoIngresoRetiro
                            boxId={box.caja_id}
                            option={option}
                            updateValue={(item, value) => updateValue(item, value)}
                            title={optionSelect} details={list ? list : detail.gasto}
                        />
                    }
                    {ventaCreditoRenovacion && <ListSolicitudesCreditosRenovaciones id={box.uid} title={optionSelect} details={list} />}
                    {pagoNoPago
                        && <ListPagosAndNoPagos
                            id={box.uid}
                            title={optionSelect}
                            details={list}
                            updateValue={(item, value) => updateValue(item, value)}
                        />
                    }
                </div>

            </Modal.Body>
        </Modal>

    );


}