export const OdooConfig = async (country) => {

  if (country === 'colombia') {
    const configco = {
      host: 'https://vengalepresto.vengalepresto.com',
      port: '',
      database: 'vengalepresto',
      encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
    }
    return configco;
  }
  if (country === 'eeuu') {
    const configus = {
      host: 'https://novaus.VENGALEPRESTO.us',
      port: '',
      database: 'novaus',
      encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
    }
    return configus;
  }
  if (country === 'colombiapruebas') {
    const configcolpruebas = {
      host: 'https://vengaleprestopruebas.vengalepresto.com/',
      port: '',
      database: 'vengaleprestopruebas',
      encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
    }
    return configcolpruebas;
  }
  if (country === 'alfonso') {
    const configcolpruebas = {
      host: '192.168.1.19',
      port: '8171',
      database: 'novaus',
      encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
    }
    return configcolpruebas;
  }
}

export const version = '1.3'
export const clientSession = { user:'cristian', password:'1111' } 
export const country='colombia'; 