import React, { PureComponent } from "react";
import '../../home/about-us/about.css'
import '../../../shared/components/sidenav-style.css' 
import { UpdatePassword } from "../user-register/user-updatePassword";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from "react-intl";
import {faAddressCard, faUsers,  faUserEdit} from '@fortawesome/free-solid-svg-icons';
import { UpdateEmail } from "../user-register/user-updateEmail";

export class MenuSecurity extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            flagColombia:false,
            flagusa: false,
            management:"",
            cmail:"",
            cpassword:"",
            statesForm: {},
            views: [],
            step: 0,
            menu: false
        }
    }

    selectView = (index) => {
        const views = [];
        for (let index = 0; index < 5; index++) {
            views.push(false)
        }
        views[index] = true;
        this.setState({ views })
    }

    states = () => {
        let falta = JSON.parse(localStorage.getItem('falta'));
        
        if (falta) {
            this.setState({ statesForm: this.props.estados });
            this.selectView(4)

            if (this.props.estados.estadoPersonal === false) {
                this.setState({ step: 0 });
            } else if (this.props.estados.estadoLaboral === false) {
                this.setState({ step: 1 })
            } else if (this.props.estados.estadoFinanciero === false) {
                this.setState({ step: 2 })
            } else if (this.props.estados.estadoReferencias === false) {
                this.setState({ step: 3 })
            }
        } else {            
            this.selectView(0);
            this.setState({ menu: true });
        }

    }

    componentDidMount = () => {
        this.states();
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                cmail: <FormattedMessage id='changeMail' defaultMessage={"Cambiar Correo"} />,
                cpassword: <FormattedMessage id='changePassword' defaultMessage={"Cambiar Contraseña"} />,
                management: <FormattedMessage id='securitymanagement' defaultMessage={"Gestión de seguridad"} />,
                flagusa: true
            })
        }else{
            this.setState({
                management: "Gestión de seguridad",
                cpassword:"Cambiar contraseña",
                cmail:"Cambiar Correo",
                flagColombia: true 
            })
        }
    }

    render() {
        return (

            <div style={{ paddingTop: '4.4%' }}>

                {this.state.menu && <div className="sidenav" style={{height:'100%'}} >
                    <header >
                        <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faUserEdit} />
                        <label className="text-title-black"> {this.state.management}</label>
                    </header>
                    <ul>
                        <li>
                            <label className="text-body" onClick={() => this.selectView(0)} >
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faAddressCard} />
                                 {this.state.cmail}
                            </label>
                        </li>
                        
                        
                        <li>
                            <label className="text-body" onClick={() => this.selectView(2)} >
                                <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faUsers} />
                                {this.state.cpassword}
                            </label>
                        </li>
                        
                    </ul>

                </div>}
                <div className="main">
                    {this.state.views[0] && <UpdateEmail tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} dataForm={this.props.dataForm} />}
                    
                    {this.state.views[2] && <UpdatePassword upDateList={this.props.upDateList} step={this.state.step} tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} visible={true} dataForm={this.props.dataForm} />}
                </div>
            </div>
        );
    }
};