import React, { useState, useEffect } from 'react';
import { Requirements } from './requirements/requirements';
import { AboutUs } from './about-us/about-us';
import './about-us/about.css'
import {DebtCalculator} from '../debt-calculator/deb-calculator';
import { StepByStep } from './step-by-step/step-by-step';
import './styles.css';
import loguito from '../../assets/logo-vengalepresto.png' 
import { Col, Row, Button } from 'react-bootstrap';
import {FormattedMessage} from 'react-intl'
import { MisionAndVision } from './misionAndVision';
import { FrequentQuestions } from './frequentQuestions/frequentQuestions';

const HomeComponent = (props) => {

  const {simulator, estados}=props
  const [userSession, setUserSession] = useState('')

  const [titleHome, setTitleHome] = useState('')
  const [solutions, setSolutions] = useState('')
  useEffect(() => {
    const server = localStorage.getItem('server');
    if(server==='"eeuu"'){
      setSolutions(<FormattedMessage id='solutions'defaultMessage={"VENGALEPRESTO SOLUCIONES"}/>)
      setTitleHome(<FormattedMessage id='info'  defaultMessage={"Es una plataforma 100% en línea que te brinda de manera rápida y segura micro-préstamos  para que puedas solucionar situaciones imprevistas de corto plazo."} />)   
    }else{
      setSolutions("VENGALEPRESTO SOLUCIONES S.A.S")
      setTitleHome('Es una plataforma 100% en línea que te brinda de manera rápida y segura micro-créditos  para que puedas solucionar situaciones imprevistas de corto plazo.')
      
    }
    const session = localStorage.getItem('Permission');
    if (session) {
      setUserSession(session)
    }
  }, []);


  return (
    <>
      <style>
        {`
                        
          .estilo-card {
            box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
            border-radius: 1rem;   
               
          }
                 
        `}
      </style>
      <div className="section1 ">
        <Row className="justify-content-lg-center">

          <div className='container pt-4' style={{marginBottom: 10 }} >
             <Row>
                <Col  xs={12}md={12} sm={6} lg={6}>
                  <div style={{ marginTop:'10%' }}>
                    <label className='text-home' style={{marginTop:'10%'}}>¿Vecino, está pelao? ¡Venga le presto!</label>
                    <img style={{width:300, height:50, marginBottom:'5%', marginTop:'10%'}} src={loguito} alt='venga'/>
                  </div> 
                 
                </Col>
                
                <Col  xs={12}md={12} sm={6} lg={6}>
                <div style={{marginTop:'15%'}}>
                <Button style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:100, alignSelf:'center', color:'white', marginTop:'10%'}}  className="btn" ><a style={{textDecoration:'none'}}  href='/#como-funciona' className='text-title link'>Calcula tu crédito</a></Button>
                      <Button style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:100, alignSelf:'center', color:'white', marginTop:'10%'}}  className="btn "><a style={{textDecoration:'none'}}  target='_blank' href='https://wa.me/573203306827' className='text-title'>WhatsApp</a></Button>
                  
                    
                  </div>
                  
                  
                  <div style={{flexDirection:'row', marginTop:'10px'}}>
                      
                  </div>
                  <div style={{flexDirection:'row',marginTop:'10px', borderTopColor:'#02dce8' , marginTop:'10%'}}>
                    <p className='text-table' style={{ color: '#02dce8', fontWeight:'bold', fontSize:'2.5ch' }}> Te prestamos para que soluciones un imprevisto,<br/> un choque en tu carro, pagar tu universidad<br/> o comprar el soat para tu vehiculo... </p>
                      
                  </div>
                      
                </Col>
             </Row>
            
            
            
          </div>

          
        </Row>
      </div>
{/* {!userSession &&

              <StepByStep />
     
            
          } */}
      <div  className="col-sm" id="como-funciona" >
       
          <DebtCalculator simulator={simulator} estados={estados} />

      </div>

      <div style={{ marginTop:20, backgroundColor:'gray' }} className='container' id="requirements" >
        <div className='row'>
          <AboutUs />
          
          
        </div>
      </div>
      <div style={{ padding: '2rem 0' }} className='container' id="misionAndVision" >
        
        <MisionAndVision />
          
      </div>
      <div  className="section2" id="quienes-somos" style={{backgroundColor:'gray'}}>
        
         <Requirements />
      </div>
      <div style={{ padding: '2rem 0' }} className='container' id="preguntasFrecuentes" >
        
          <FrequentQuestions />
          
      </div>
      
    </>
  )
}
export default HomeComponent;