import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListMoroso } from './components/list';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { getDefaulter } from '../../../services/datos.services';
import { FormatMoney } from '../../../utilities/formats';
import GridLoader from 'react-spinners/GridLoader';

export const Morosos = () => {

    const [morosos, setMorosos] = useState([]);
    const [list, setList] = useState([]);
    const [loading,setLoading] = useState(false)
    const [debValue, setDebValue] = useState(0);

    const [search, setSearch] = useState("");

   

    const defaulterList = () => {
        const hoy = new Date(Date.now());
        const uid = localStorage.getItem('uid');

        getDefaulter(parseInt(uid), (data, flag) => {
            setLoading(false)


            if (flag) {
                if (data.length > 0) {

                    let morosos = [];
                    let value = 0;

                    data.forEach(element => {
                        value = value + element.saldo;
                        const fechaVencimiento = new Date(element.fecha_vencimiento);
                        let diasMora = Math.floor((hoy - fechaVencimiento) / (1000 * 60 * 60 * 24));

                        let defaulter = {
                            estado_usuario: element.estado_usuario,
                            usuario_id: element.usuario_id,
                            id: element.partner_id,
                            celular: element.celular,
                            direccion: element.direccion,
                            detalles: element.detalles,
                            pago: element.pagos,
                            codigo: element.id,
                            vendedor: element.nombre_usuario,
                            nombre1: element.nombre1,
                            nombre2: element.nombre2,
                            apellido1: element.apellido1,
                            apellido2: element.apellido2,
                            valor_inicial: element.valor_inicial,
                            saldo: element.saldo,
                            saldo_vencido: element.saldo_vencido,
                            fin_cobro: element.fecha_vencimiento,
                            diasMora: diasMora,
                            nombre: `${element.nombre1.toLowerCase()} ${element.nombre2.toLowerCase()} ${element.apellido1.toLowerCase()} ${element.apellido2.toLowerCase()}`
                        }
                        morosos.push(defaulter);
                    });
                    setDebValue(value);
                    setMorosos(morosos.sort((a, b) => b.diasMora - a.diasMora));
                } else {
                    setMorosos([]);
                }
            }

        });
    }

    useEffect(() => {
        setLoading(true)
        defaulterList();
    }, []);

    useEffect(() => {
        if (morosos) {
            filterData();
        }

    }, [search, morosos])

    const filterData = () => {
        let list = []
        if (search !== '') {

            morosos.forEach(element => {
                if (element.nombre.includes(search.toLowerCase()) || element.vendedor.includes(search.toLowerCase())) {
                    list.push(element)
                }
                return setList(list)
            })
        } else {
            morosos.forEach(element => {
                list.push(element)
            });
            return setList(list)
        }
    }

    return (
        <>
            <div style={{ background: 'transparent', color: '##ffdc08', paddingTop: 70 }}>
                <div className="container" style={{ textAlign: 'center' }}>
                    <label className='text-total' style={{ fontSize: 26 }}><FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faExclamationTriangle} />
                        Morosos
                    </label>
                </div>
            </div>

            <Container className="mt-4">
                <Row style={{ marginBottom: 10 }}>
                    <Col md={2}><label className='text-title-blue'>Total clientes: {list.length}</label> </Col>
                    <Col md={2}><label className='text-title-blue'>Saldo: {FormatMoney(debValue)}</label> </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                        <input
                            style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                            className="form-control text-title-black"
                            type="text"
                            placeholder="         Buscar Usuario"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <Table style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px gray' }} responsive striped bordered hover size="sm">
                    <thead className='text-title-blue'>
                        <tr>
                            <th><label >Estado</label></th>
                            <th><label >Código</label></th>
                            <th><label>Vendedor</label></th>
                            <th><label >Cliente</label></th>
                            <th><label >Valor inicial</label></th>
                            <th><label >Saldo</label></th>
                            <th><label >Valor vencido</label></th>
                            <th><label >Fecha Vencimiento</label></th>
                            <th><label >Acciones</label></th>
                        </tr>
                    </thead>
                    <tbody className='text-title-black'>
                        {list.map((moroso, i) => (
                            <ListMoroso moroso={moroso} key={i} defaulterList={defaulterList} />
                        )
                        )}
                    </tbody>
                </Table>
            </Container>
            <GridLoader  size={50} loading={loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
        </>
    )
}

