import React from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { InputForm,  InputMoney } from '../../../../shared/components/inputs';
import Register from '../../../../shared/components/register';
import  '../../../home/about-us/about.css'
import { ToastContainer, toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { uploadEvidence } from '../../../../services/datos.services';

const optionsPay = [
    { title: 'Seleccione ', value: 'seleccione' },
    { title: 'Mañana', value: 'mañana' },
    { title: 'Tarde', value: 'tarde' },
];
export class UploadEvidence extends Register {
    constructor(props) {
        super();
        this.state = {
            showBoton: "",
            latitud: "",
            longitud: "",
            pagoPse: false,
            errorCampos:"",
            flagColombia: false,
            flagusa: false,
            evidence:"",
            tUpload:"",
            tSuccess:"",
            tError:"",
            tschedule:"",
            valor: {
                name: 'valor',
                value: "",
                error: '',
                type: "off",
                required: true,
            },
            description: {
                name: 'description',
                value: "",
                error: '',
                type: "off",
                required: true,
            },
        }
        this.allOK = true;
        this.inputsToVerify = [
            'description',
            'valor',
        ];
    }

    componentDidMount = () => {
        
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                tSuccess: <FormattedMessage id='evidenceS' defaultMessage={"Evidencia subida"} />,
                tError:<FormattedMessage id='serverError' defaultMessage={"Aun faltan campos por llenar"} />,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                tUpload: <FormattedMessage id='uploadevidence' defaultMessage={"Subir evidencia"} />,
                tschedule: <FormattedMessage id='evidence' defaultMessage={"Evidencia de pago"} />,
                flagusa: true
            })
        }else{
            this.setState({
                errorCampos:"Atención, aún faltan campos por llenar",
                tSuccess:"Evidencia de pago subida correctamente, a tu correo se te notificará si es válida o no.",
                tError:"Error en el servidor",
                tUpload: "Subir evidencia",
                tschedule: "Evidencia de pago",
                flagColombia: true 
            })
        }
    }

    attachEvidence = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0])
        reader.onload = (e) => {
           e.preventDefault();
           this.setState({evidence: e.target.result})
        }

        
       
    }

    upload = () => {
        if (this.verifyForm() && this.state.evidence!==""){
            let json = this.state.evidence
         json = json.replace('data:image/jpeg;base64,', '')
         json = json.replace('data:image/png;base64,', '')
         json = json.replace('data:image/jpg;base64,', '')
         json = json.replace('', '')
         let consignacion = json;
        const data ={
            descripcion:this.state.description.value,
            amount:parseInt(this.state.valor.value),
            consignacion
        }
        console.log("datos que se van",data)
        uploadEvidence(data,(response,flag)=>{
            console.log("Respuesta del server",response,flag)
            if(flag){
                if(response.valida){
                    const success =()=> toast.success(this.state.tSuccess)
                    success()
                    setTimeout(() => {
                        window.location = "/simulador";
                        }, 9000);
                    }
            }else{
                const error =()=> toast.error(this.state.tError)
                    error()
                    setTimeout(() => {
                        window.location = "/simulador";
                        }, 3000);
            }
        })

        }else{
            const error =()=> toast.error(this.state.errorCampos)
                    error()
        }
        
    }

    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '5% 0% 0% 5%',padding: '1% 0% 2% 0%'}}>
                <Card style={{ width: '75%', borderRadius:50, boxShadow:'6px 10px 10px black' }}>
                        <Card.Body>
                            <h3 className='text-total'>{this.state.tschedule}</h3>
                            <hr />
                            <Form>
                                <Row>
                                    <Col sm >
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='valuepay' defaultMessage={"Valor a pagar"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Valor a pagar</label>}
                                        <InputMoney
                                            field={this.state.valor}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='description' defaultMessage={"Valor a pagar"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Descripción de pago</label>}
                                        <InputForm
                                            field={this.state.description}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                           
                                        />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col sm>
                                    <h6>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='evidence' defaultMessage={"¿Hace cuánto vive en esta dirección?"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Foto evidencia</label>}
                                        <input className='file-upload-input text-title-black'
                                                        type="file"
                                                        multiple
                                                        accept="image/*"
                                                        onChange={e => { this.attachEvidence(e); }}
                                                    >
                                        </input>
                                        </h6>
                                    </Col>
                                </Row>
                            </Form>
                            <Col className="container">
                                <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center' }} className="btn btn-warning" onClick={this.upload}><label className='text-title-black' style={{marginTop:6}}>{this.state.tUpload}</label></Button>

                            </Col>
                            
                        </Card.Body>
                    </Card>
                 
                </div>

            </>
        );
    }
}


