import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Container, Button, Pagination } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import { FormatDateYYMMDD, FormatMoney } from '../../../../utilities/formats';
import NumberFormat from 'react-number-format';
import { sendTransaction } from '../../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';

export const ListBill = (props) => {

    const { title, data } = props
    const [showModalEditar, setShowModalEditar] = useState(true);
    const [debts, setDebts] = useState({});
    const [position, setPosition] = useState('');
    const [count, setCount] = useState(1);

    let valor;

    // const filteredData = data.filter(data => {
    //     return data.cliente.toLowerCase().includes(search.toLowerCase())
    // });

    const add = () => {
        if (count >= 1) {
            setCount(count + 1);
        }
    }
    const subtract = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    }

    const setTransaction = (item, title) => {

        if (title === 'pagos') {
            const transaction =
                [
                    {
                        id: -1,
                        detalle: {

                            'tipo': 'pago',
                            'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                            'valor': parseInt((valor.split(' ')[1]).replace(',', '').replace(',', '')),
                            pago_factura_venta_id: item.id
                        }
                    }
                ]

            sendTransaction(transaction, (data, flag) => {
                if (flag) {
                    setPosition('');
                    const success = () => toast.success("¡Transacción creada!");
                    success();
                }
            });
        } else {
            const transaction =
                [
                    {
                        id: -1,
                        detalle: {
                            'tipo': 'nopago',
                            'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                            'valor': 0,
                            nopago_factura_venta_id: item.id
                        }
                    }
                ]

            sendTransaction(transaction, (data, flag) => {
                if (flag) {
                    setPosition('');
                    const success = () => toast.success("¡Transacción creada!");
                    success();
                }
            });
        }

    }

    useEffect(() => {
        let cols = {};
        let rows = [];
        let name;

        if (title === 'pagos') {
            data.forEach((element, index) => {
                name = element.nombre1 + ' ' + element.nombre2 + ' ' + element.apellido1 + ' ' + element.apellido2;
                rows.push({
                    codigo: element.id,
                    cliente: name,
                    valor_inicial: FormatMoney(parseInt(element.saldo)),
                    ultimo_pago: element.fecha_vencimiento,
                    acciones:
                        <div>
                            <Button
                                // className="show-btn"
                                size="sm"
                                variant="light"
                                style={{ borderRadius: 50, width: '35px', height: '35px' }}
                                onClick={() => { setPosition(index) }}
                            >
                                <FontAwesomeIcon
                                    style={{ height: '20px', width: '20px', marginTop: 2 }}
                                    icon={faUserEdit}
                                />
                            </Button>
                        </div>,
                    estado:
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div>
                                <NumberFormat
                                    className="form-control mt-1"
                                    style={{ width: '10rem' }}
                                    thousandSeparator={true}
                                    prefix='$ '
                                    maxLength={11}
                                    value={position === index ? valor : ''}
                                    placeholder={'Nuevo valor'}
                                    onChange={(event) => valor = event.target.value}
                                    disabled={position === index ? !showModalEditar : showModalEditar}
                                />
                            </div>
                            <div >
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ borderRadius: 50, width: '35px', height: '35px', marginRight: 5, marginLeft: 5 }}
                                    onClick={() => setTransaction(element, title)}
                                    disabled={position === index ? !showModalEditar : showModalEditar}
                                >
                                    <FontAwesomeIcon
                                        style={{ height: '20px', width: '20px', marginTop: 2, color: '#7ABF11' }}
                                        icon={faCheck}
                                    />
                                </Button>
                                <Button
                                    size="sm"
                                    variant="light"
                                    style={{ borderRadius: 50, width: '35px', height: '35px', marginRight: 5, marginLeft: 5 }}
                                    onClick={() => { setPosition('') }}
                                    disabled={position === index ? !showModalEditar : showModalEditar}
                                >
                                    <FontAwesomeIcon
                                        style={{ height: '20px', width: '20px', marginTop: 2, color: '#F25757' }}
                                        icon={faTimes}
                                    />
                                </Button>
                            </div>
                        </div>
                })
            });
            cols = {
                columns: [
                    {
                        label: 'Código',
                        field: 'codigo',
                    },
                    {
                        label: 'Cliente',
                        field: 'cliente',
                        sort: 'asc'
                    },
                    {
                        label: 'Valor Inicial',
                        field: 'valor_inicial',
                    },
                    {
                        label: 'Ultimo pago',
                        field: 'ultimo_pago',
                    },
                    {
                        label: 'Editar',
                        field: 'acciones',
                    },
                    {
                        label: 'Nuevo valor',
                        field: 'estado',
                    },
                ],
                rows: rows
            }
            setDebts(cols)
        } else {
            data.forEach((element, index) => {
                name = element.nombre1 + ' ' + element.nombre2 + ' ' + element.apellido1 + ' ' + element.apellido2;
                rows.push({
                    codigo: element.id,
                    cliente: name,
                    valor_inicial: FormatMoney(parseInt(element.saldo)),
                    ultimo_pago: element.fecha_vencimiento,
                    acciones:
                        <div>
                            <Button
                                size="sm"
                                variant="light"
                                style={{ borderRadius: 50, width: '35px', height: '35px', marginRight: 5, marginLeft: 5 }}
                                onClick={() => setTransaction(element, title)}
                            >
                                <FontAwesomeIcon
                                    style={{ height: '20px', width: '20px', marginTop: 2, color: '#F25757' }}
                                    icon={faTimes}
                                />
                            </Button>
                        </div>,
                })
            });
            cols = {
                columns: [
                    {
                        label: 'Código',
                        field: 'codigo',
                    },
                    {
                        label: 'Cliente',
                        field: 'cliente',
                        sort: 'asc'
                    },
                    {
                        label: 'Valor Inicial',
                        field: 'valor_inicial',
                    },
                    {
                        label: 'Ultimo pago',
                        field: 'ultimo_pago',
                    },
                    {
                        label: 'No pago',
                        field: 'acciones',
                    },
                ],
                rows: rows
            }
            setDebts(cols)
        }
    }, [position]);

    return (
        <>
            <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
            <Container className="mt-4">
                <MDBDataTable
                    responsive
                    striped
                    bordered
                    small
                    paging={false}
                    data={debts}
                    searchLabel="Buscar"
                />
                <div className="d-flex justify-content-center">
                    <Pagination>
                        <Pagination.Prev onClick={subtract}>Anterior</Pagination.Prev >
                        <Pagination.Item>{count}</Pagination.Item>
                        <Pagination.Next onClick={add}>Siguiente</Pagination.Next>
                    </Pagination>
                </div>
            </Container>
        </>
    )
}