import React, { useState } from 'react';
import { ItemDetail, ItemDetailCuotaCartera, ItemDetailPagoCartera } from './item'
import { Col, Row, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";
import { FormatMoney } from '../../../../utilities/formats';
import { ItemCreditsUser } from '../../../client/credits-history/components/item';
import { ModalCuotas } from '../../../client/credits-history/components/modalCuotas';

let activos = [];
export const ListSons = (props) => {
    const { item, showClient } = props;
    return (
        <>
            <tr onClick={() => showClient(true, item.id, item.nombre)} style={{ cursor: 'pointer' }}>
                <td>{item.nombre}</td>
                <td>{item.debidoCobrarCantidad}</td>
                <td>{FormatMoney(item.valorCartera)}</td>
                <td>{FormatMoney(item.debidoCobrarValor)}</td>
                <td>{FormatMoney(item.saldoVencidoCartera)}</td>
                <td>{FormatMoney(item.saldoCartera)}</td>
            </tr>
        </>
    )
}

export const ListDetail = (props) => {
    activos = [];
    const { data, showDetalleCartera, setShowModalEditar } = props;
    const [activo, setActivo] = useState(true)
    const [cartera, setCartera] = useState(true)
    const [search, setSearch] = useState('')

    if (activo) {
        data.forEach(element => {
            if (element.estado === "activo") {
                activos.push(element)
            }
        });
    } else activos = [];

    if (!activo) {
        data.forEach(element => {
            if (element.estado !== "activo") {
                activos.push(element)
            }
        });
    }

    const changeActivo = (activo) => {
        setActivo(activo);
    }

    const changeCartera = (cartera) => {
        setCartera(cartera);
    }

    const filteredData = activos.filter(dataUser => {
        return dataUser.nomCliente.toLowerCase().includes(search.toLowerCase())
    })
    return (
        <>
            <div className='container  text-center text-md-left font-small lighten-3 '>
                <div className=' mb-3'>

                    <hr />
                    <Row style={{ fontSize: 20, textAlign: 'center' }}>
                        <Col lg="3">
                            <label>
                                <span>Usuarios activos </span>
                                <Switch height={18} onChange={changeActivo} checked={activo} />
                            </label>
                        </Col>
                        <Col lg="3" >
                            <label>
                                <span>Cartera </span>
                                <Switch height={18} onChange={changeCartera} checked={cartera} />
                            </label>
                        </Col>
                        <Col md={{ span: 3, offset: 3 }}>
                            <input style={{ height: 30, width: 150 }} type="text" placeholder="Buscar" onChange={e => setSearch(e.target.value)} />
                        </Col>
                    </Row>
                </div>
                {filteredData.map((dataUser, i) => (
                   
                    <ItemDetail dataUser={dataUser} showDetalleCartera={(flag) => showDetalleCartera(flag)} setShowModalEditar={(flag) => setShowModalEditar(flag)} key={i}

                    />
                )
                )}
            </div>
        </>
    )
}

export const ListDetailPagosCartera = (props) => {

    const { data } = props

    const [showPagos, setShowPagos] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowPagos(flag);
        setOpen(flag);
    }

    return (
        <>
            <Card>
                <Card.Header onClick={() => handleOpen(!open)}>
                    <Row>
                        <Col>
                            Pagos : 9
                        </Col>
                        <Col>
                            total pagado: $ 200.000
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {showPagos && <Card.Body>
                    <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th><label >Pago</label></th>
                                <th><label>Fecha</label></th>
                                <th><label >Valor Pagado</label></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((pagoCartera, i) => (
                                <ItemDetailPagoCartera pagoCartera={pagoCartera} key={i} />
                            )
                            )}
                        </tbody>
                    </Table>
                </Card.Body>}
            </Card>
        </>
    )
}

export const ListCreditsUserAdmin = (props) => {
    const { data, idCredit, isAdmin = false } = props;
    
    const [pagos, setPagos] = useState([]);
    const [show, setShow] = useState(false);
    const [cuotas, setCuotas] = useState([]);
    const [saldo, setSaldo] = useState({ valor_inicial: 0, saldo: 0 });

    return (
        <>
            <div>
                <Table style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px gray' }} striped bordered hover responsive="sm" size="sm">
                    <thead className='text-title-blue'>
                        <tr>
                            <th>ID</th>
                            <th>Valor</th>
                            <th>Desembolso</th>
                            <th>Pago</th>
                            <th>Saldo</th>
                            <th></th>
                            {!isAdmin && <th></th>}
                        </tr>
                    </thead>
                    <tbody >
                        {data.map((obj, i) => (

                            <ItemCreditsUser isAdmin={isAdmin} pagos={(data) => setPagos(data.pagos)} cuotas={(data) => setCuotas(data.detalles)} setShow={(flag) => setShow(flag)} key={i} data={obj} />

                        ))}

                    </tbody>
                </Table>
            </div>
            <ModalCuotas cuota={cuotas} pago={pagos} saldo={data} id={data.id} show={show} saldo={saldo} close={()=>setShow(false)}/>
        </>
    )
}