import React, { PureComponent } from 'react';
import { ComboBoxForm, RadioForm } from '../../shared/components/inputs';
import { Row, Col, Badge, Container } from 'react-bootstrap';
import { Resume } from './components/resume';
import { FormatMoney } from '../../utilities/formats';
import './components/slider.css'
import '../home/about-us/about.css'
import { Slider } from '@material-ui/core';
import { Desencrypt } from '../../utilities/cipher';
import { getParametros } from '../../services/datos.services';
import Roundy from "roundy";
import {FormattedMessage} from 'react-intl'
import { ToastContainer, toast } from 'react-toastify';

 export class DebtCalculator extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            calculatucredito: '',
            flagusa:false,
            semanal: false,
            quincenal: false,
            summary:"",
            mensual: false,
            flagColombia:false,
            howmuch:"",
            efinancial:"",
            ePersonal: "",
            eReferences:"",
            eLaboral:"",
            count: 0,
            pays: 0,
            totalDays: 0,
            seal: 25,
            investigation: 25,
            show: false,
            tmust: '',
            showSimulator: true,
            closeModal: false,
            optionsCiclo: [{title: "Semanal", value: 'semanal'},
                           {title: "Quincenal", value: 'quincenal'},
                           {title: "Mensual", value: 'mensual'}],
            daysName: { nombre: '', id: '' },
            value: 0,
            days: 1,
            tax: 0,
            administration: 0,
            subTotal: 0,
            tecnology: 0,
            iva: 0,
            total: 0,
            valueChange: 0,
            normalButton: false,
            afterButton: false,
            nombreUsuario: '',
            apellido1: '',
            apellido2: '',
            iva_id: '',
            cuota_id: '',
            titleCalculo:'',
            interes_id: '',
            debtsValues: {
                MaxDiasPrestamo: 0,
                MinDiasPrestamo: 0,
                MaxValorPrestamo: 0,
                MinValorPrestamo: 1,
                monthlyInterest: 0,
                administration: 0,
                tecnology: 0,
                iva: 0,
            },
            ciclo: {
                name: 'ciclo',
                value: "",
                error: '',
                type: "off",
                required: false
            },
        }
        this.daysName = {}
        this.value = 0
    }
    

    sendRequest = () => {
        const session = localStorage.getItem('Permission');
        if (session) {
            const datos = {
                user: this.state.nombreUsuario + " " + this.state.apellido1 + " " + this.state.apellido2,
                payment_term_id: this.state.daysName.id
            }
            localStorage.setItem('valuesSimulator', JSON.stringify(datos))

            if (this.props.estados.estadoPersonal === false) {
                localStorage.setItem('falta', JSON.stringify(true));
                window.location = "/user-profile";
                const warning = () => toast.warn(this.state.ePersonal)
                warning() 
            } else if (this.props.estados.estadoLaboral === false) {
                localStorage.setItem('falta', JSON.stringify(true));
                window.location = "/user-profile";
                const warning = () => toast.warn(this.state.eLaboral)
                warning() 
            } else if (this.props.estados.estadoReferencias === false) {
                localStorage.setItem('falta', JSON.stringify(true));
                window.location = "/user-profile";
                const warning = () => toast.warn(this.state.eReferences)
                warning() 
            } else if (this.props.estados.estadoFinanciero === false) {
                localStorage.setItem('falta', JSON.stringify(true));
                window.location = "/user-profile";
                const warning = () => toast.warn(this.state.efinancial)
                warning() 
            } else {

                if (window.confirm('¿Desea realizar la solicitud?')) {
                    localStorage.setItem('falta', JSON.stringify(false));
                    this.setState({ show: true });
                } else {
                    this.setState({ show: false });
                }
            }
        } else {
            const warning = () => toast.warn(this.state.tmust)
            warning() 
        }
    };

    initialiceData = async () => {
        const servidor = localStorage.getItem('server');

        if(servidor==='"eeuu"'){
            this.setState({
                flagusa:true,
                eReferences:<FormattedMessage id='eReferences' defaultMessage={"Debe completar los datos de su referencia."} />, 
                efinancial: <FormattedMessage id='eFinancial' defaultMessage={"Debe completar los datos financieros."} />, 
                eLaboral: <FormattedMessage id='eLaboral' defaultMessage={"Debe completar los datos laborales."} />, 
                ePersonal: <FormattedMessage id='ePersonal' defaultMessage={"Debe completar los datos personales."} />, 
                tmust: <FormattedMessage id='must' defaultMessage={"Debe iniciar sesión"} />, 
                summary:<FormattedMessage id='summary' defaultMessage={"Resumen"} />,
                howmuch:<FormattedMessage id='howmatch' defaultMessage={"¿Cuánto Necesitas?"} /> ,
            })
        }
        if(servidor==='"colombia"' || servidor==='"colombiapruebas"'){
            this.setState({
                eReferences:"Debe completar los datos de sus referencias.", 
                efinancial:"Debe completar los datos financieros.", 
                eLaboral: "Debe completar los datos laborales.", 
                ePersonal:"Debe completar los datos personales.", 
                tmust: "Debe Iniciar Sesión",
                summary:"Resumen",
                howmuch:"¿Cuánto necesitas?",
                flagColombia:true,
            })
        }

        const session = localStorage.getItem('Permission');

        if (!session) {

            getParametros((data, flag) => {
                //console.log("getParametros data.terminosprestamo.length",data.terminosprestamo.length)
                //console.log("getParametros data del jjee",data.terminosprestamo)
                if (flag) {
                
                    let payments = []
                    this.setState({ count: data.terminosprestamo.length - 1 });
                    let interes, administracion, tecnologia, iva, investigacion, sello = 0;
                    const ids = {
                        venta_interes_id: data['interes'],
                        iva_id: data['iva']
                    };
                    if (data.terminosprestamo.length > 0) {
                        data.terminosprestamo.forEach(terminos => {
                            data.payment_term_ids.forEach(element => {
                                if (parseInt(terminos.id) === parseInt(element.id)) {
                                    payments.push(element);
                                }
                            });
                        });
                    }
                    localStorage.setItem('idsSolicitud', JSON.stringify(ids))
                    data.porcentaje_ids.forEach(porcentaje => {
                        if (data['interes'] === parseInt(porcentaje.id)) {
                            interes = porcentaje.valor;
                        } else if (data['administracion'] === parseInt(porcentaje.id)) {
                            administracion = porcentaje.valor;
                        } else if (data['tecnologia'] === parseInt(porcentaje.id)) {
                            console.log("porcentajes",tecnologia)
                            tecnologia = porcentaje.valor;
                        } else if (data['iva'] === parseInt(porcentaje.id)) {
                            iva = porcentaje.valor;
                        }
                        if(porcentaje.nombre==="Impuesto de sello de documentos"){
                            this.setState({
                                seal: porcentaje.valor
                            })
                        }
                        if(porcentaje.nombre==="Cargo por investigación de crédito"){
                            this.setState({
                                investigation: porcentaje.valor
                            })
                        }
                    });
                    //console.log("data del get parametros",data)
                    this.setState({
                        value: data.MinValorPrestamo,
                        iva_id: data['iva'],
                        cuota_admin_id: data['MaxDiasPrestamo'],
                        interes_id: data['interes'],
                        daysName: data.terminosprestamo.length > 0 ? payments[0] : { nombre: '', id: '' },
                        days: 0,
                        debtsValues: {
                            payment_term_ids: payments,
                            MaxValorPrestamo: data.MaxValorPrestamo,
                            MinValorPrestamo: data.MinValorPrestamo,
                            monthlyInterest: interes,
                            administration: administracion,
                            tecnology: tecnologia,
                            iva: iva,
                        },
                    });
                    let debts = {
                        daysName: data.terminosprestamo.length > 0 ? payments[0] : { nombre: '', id: '' },
                        value: data.MinValorPrestamo,
                        interes: interes,
                        administration: administracion,
                        tecnology: tecnologia,
                        iva: iva,
                    }
                    this.daysName = payments[0];
                    this.value = data.MinValorPrestamo;
                    this.initialDebts(debts)
                }

            })
        } else {
            const data = this.props.simulator;
           if (data.terminosprestamo) {
                let payments = [];
                
                this.setState({ count: data.terminosprestamo.length-1 });
                let interes, administracion, tecnologia, iva = 0;
                const ids = {
                    venta_interes_id: data['interes'],
                    iva_id: data['iva']
                };
                if (data.terminosprestamo.length > 0) {
                    data.terminosprestamo.forEach(terminos => {
                        data.payment_term_ids.forEach(element => {
                            if (parseInt(terminos.id) === parseInt(element.id)) {
                                payments.push(element);
                            }
                        });
                    });
                }
                localStorage.setItem('idsSolicitud', JSON.stringify(ids))
                data.porcentaje_ids.forEach(porcentaje => {
                    if (data['interes'] === parseInt(porcentaje.id)) {
                        interes = porcentaje.valor;
                    } else if (data['administracion'] === parseInt(porcentaje.id)) {
                        administracion = porcentaje.valor;
                    } else if (data['tecnologia'] === parseInt(porcentaje.id)) {
                        tecnologia = porcentaje.valor;
                    } else if (data['iva'] === parseInt(porcentaje.id)) {
                        iva = porcentaje.valor;
                    }
                });
                
                this.setState(
                    {
                        value: data.MinValorPrestamo,
                        iva_id: data['iva'],
                        cuota_admin_id: data['MaxDiasPrestamo'],
                        interes_id: data['interes'],
                        daysName: data.terminosprestamo.length > 0 ? payments[0] : { nombre: '', id: '' },
                        days: 0,
                        debtsValues: {

                            payment_term_ids: payments,
                            MaxValorPrestamo: data.MaxValorPrestamo,
                            MinValorPrestamo: data.MinValorPrestamo,
                            monthlyInterest: interes,
                            administration: administracion,
                            tecnology: tecnologia,
                            iva: iva,
                        },

                    })
                let debts = {
                    daysName: data.terminosprestamo.length > 0 ? payments[0] : { nombre: '', id: '' },
                    value: data.MinValorPrestamo,
                    interes: interes,
                    administration: administracion,
                    tecnology: tecnologia,
                    iva: iva,
                }
                this.daysName = payments[0];
                this.value = data.MinValorPrestamo;
                this.initialDebts(debts)
            }
        }
        const data = await Desencrypt();
        if (data) {
            const nameUser = localStorage.getItem('nameUser')
            this.setState({
                nombreUsuario: nameUser,
            });
            this.setState({ normalButton: true })
        } else {
            this.setState({ normalButton: false })
        }
    }

    componentDidMount() {
        this.initialiceData();

    }

    handleShow = () => {
        this.setState({ show: true })
    };
    handleClose = () => {
        this.setState({ show: false })
    };
    calculeDebts = () => {    
        console.log("que llego a los diad",this.daysName)
        console.log("que llego a los dias problema",this.daysName.nombre.split(" ")[0])
        let day = this.daysName.nombre.split(" ")[0];
        let dayS = this.daysName.nombre.split(" ");
        let cuotas=0;
        let micuota = 0;
        if(this.state.flagusa){
            if(this.state.semanal){
                micuota=7
            }else if(this.state.quincenal){
                    micuota= 15
                }else if(this.state.mensual){
                        micuota=30
                    }
            cuotas = day / micuota 
            this.setState({
                tiempo:cuotas
            })
            
            const baseInteresDia = (1 + (this.state.debtsValues.monthlyInterest / 100));
            const exponenteInteresDia = ((1 / 35))
            const exponenteAdministracion = ((1 / 60))
            const baseadministration = (1 + (this.state.debtsValues.administration / 100));   
            const administrationDay = (Math.pow(baseadministration, exponenteAdministracion) - 1)
            const administration =  ((this.value * administrationDay) * parseInt(day));
            const interesDia = (Math.pow(baseInteresDia, exponenteInteresDia) - 1)
            const interest = ((this.value * interesDia) * parseInt(day));
            const subTotal = (parseFloat(this.value) + parseFloat(interest));
            let total = parseFloat(subTotal) + parseFloat(administration);
            this.setState(
                {

                    subTotal: subTotal,
                    tax: interest,
                    administration: administration,
                    total: total,
                });
        }if(this.state.flagColombia){
            if(this.state.semanal){
                micuota=7
            }else if(this.state.quincenal){
                    micuota= 15
                }else if(this.state.mensual){
                        micuota=30
                    }
            cuotas = day / micuota 
            this.setState({
                tiempo:cuotas
            })
            const baseInteresDia = (1 + (this.state.debtsValues.monthlyInterest / 100));
            const exponenteInteresDia = ((1 / 360));
            const exponenteAdministracion = ((1 / 35));
            const interesDia = (Math.pow(baseInteresDia, exponenteInteresDia) - 1)
            const interest = ((this.value * interesDia) * parseInt(day)).toFixed(0);
            const tecnology = ((this.value  * this.state.debtsValues.tecnology / 100)).toFixed(0);
            const iva = Math.round(((tecnology * this.state.debtsValues.iva) / 100));
            const baseadministration = (1 + (this.state.debtsValues.administration / 100));     
            const administrationDay = (Math.pow(baseadministration, exponenteAdministracion) - 1)
            const administration =  ((this.value * administrationDay) * parseInt(day)).toFixed(0);
            const subTotal = (parseFloat(this.value) + parseFloat(interest));
            let total = (parseFloat(subTotal) + parseFloat(iva) + (parseFloat(tecnology))).toFixed(0);
            total = (parseFloat(total) + parseFloat(administration)).toFixed(0);
            this.setState(
                {
                    subTotal: subTotal,
                    tax: interest,
                    administration: administration,
                    total: total,
                    tecnology: tecnology,
                    iva: iva
                });

        }      

    };

    initialDebts = (data) => {
        let day = this.daysName.nombre.split(" ")[0];
        let dayS = this.daysName.nombre.split(" ");
        let cuotas=0;
        let micuota = 0;
        if(this.state.flagusa){
            if(this.state.semanal){
                micuota=7
            }else if(this.state.quincenal){
                    micuota= 15
                }else if(this.state.mensual){
                        micuota=30
                    }
            cuotas = day / micuota 
            this.setState({
                tiempo:cuotas
            })
            
            const baseInteresDia = (1 + (this.state.debtsValues.monthlyInterest / 100));
            const exponenteInteresDia = ((1 / 30))
            const exponenteAdministracion = ((1 / 30))
            const baseadministration = (1 + (this.state.debtsValues.administration / 100));   
            const administrationDay = (Math.pow(baseadministration, exponenteAdministracion) - 1)
            const administration =  ((this.value * administrationDay) * parseInt(day)).toFixed(0);
            const interesDia = (Math.pow(baseInteresDia, exponenteInteresDia) - 1)
            const interest = ((this.value * interesDia) * parseInt(day)).toFixed(0);
            const subTotal = (parseFloat(this.value) + parseFloat(this.state.tax));
            let total = parseFloat(subTotal) + parseFloat(administration);
            this.setState(
                {

                    subTotal: subTotal,
                    tax: interest,
                    administration: administration,
                    total: total,
                });
        }if(this.state.flagColombia){
            const baseInteresDia = (1 + (this.state.debtsValues.monthlyInterest / 100));
            const exponenteInteresDia = ((1 / 360))
            const exponenteAdministracion = ((1 / 25))
            const interesDia = (Math.pow(baseInteresDia, exponenteInteresDia) - 1)
            const interest = ((this.value * interesDia) * parseInt(day)).toFixed(0);
            const baseTecnology = (1 + (this.state.debtsValues.tecnology / 100));
            const tecnologyDay = (Math.pow(baseTecnology, exponenteAdministracion) - 1)
            const tecnology = ((this.value * tecnologyDay) * parseInt(day)).toFixed(0);
            const iva = Math.round(((tecnology * this.state.debtsValues.iva) / 100));
            const baseadministration = (1 + (this.state.debtsValues.administration / 100));     
            const administrationDay = (Math.pow(baseadministration, exponenteAdministracion) - 1)
            const administration =  ((this.value * administrationDay) * parseInt(day)).toFixed(0);
            const subTotal = (parseFloat(this.value) + parseFloat(this.state.tax));
            let total = (parseFloat(subTotal) + parseFloat(iva) + (parseFloat(tecnology))).toFixed(0);
            total = (parseFloat(total) + parseFloat(administration)).toFixed(0);
            this.setState(
                {
                    subTotal: subTotal,
                    tax: interest,
                    administration: administration,
                    total: total,
                    tecnology: tecnology,
                    iva: iva
                });

        }  
    
    };
        

    handleChange = (value) => {
        this.setState({
            value: value
        });
        this.value = value
        this.calculeDebts();
    };
    handleChange2 = (name, value) => {
        this.setState({
            [name]: {
                name,
                value,
                error: '',
                type: this.state[name].type,
                required: this.state[name].required
            }
        });
          if(value==="quincenal"){ this.setState({quincenal:true})
        } if(value==="semanal"){ this.setState({semanal:true})
        } if(value==="mensual"){ this.setState({mensual:true})}
    }

    handleChangeDays = (index) => {
        console.log("los dias,",index)
        if (this.state.debtsValues.payment_term_ids.length > 0) {
            this.setState({
                days: index,
                daysName: this.state.debtsValues.payment_term_ids[index]
            });
            this.daysName = this.state.debtsValues.payment_term_ids[index];
            this.calculeDebts();
        }

    };

    render() {
        return (
            <>
                <ToastContainer style={{ marginTop: 50, fontSize: 16 }} autoClose={1500} />
                    <style>
                        {`
                            .estilo-card
                            {
                                box-shadow: 0px 2px 4px 1px rgba(255,165,0,0.17);
                                border-radius: 1rem;        
                            }                 
                        `}
                    </style>
                    <div className='container pt-4 estilo-card' style={{
                         minWidth: '100%',
                        backgroundColor: '#F2F2F2',
                        opacity: '0.9', paddingBottom: 10
                    }}>
                        {this.state.flagColombia &&<h5 className='text-total'><strong>Calcula tu crédito</strong></h5>}
                        {this.state.flagusa &&<h4><FormattedMessage
                        id='calculateloan'
                        defaultMessage={"Calcula tu préstamo"}
                        /></h4>}
                        <hr />
                        {!this.state.show && <Row>
                            <Col xs={12} md={6}sm={6} lg={6}>
                                <Row  style={{ paddingTop: '8%' }}>
                                    <Col>
                                        <h5 className='text-total'>{this.state.howmuch}</h5>
                                        <Row style={{marginTop:20}}>
                                            <Col>
                                                <h5 className='text-table'>Ciclos de pago </h5>
                                                <RadioForm
                                                    title="Ciclos de pago"
                                                    field={this.state.ciclo}
                                                    options={this.state.optionsCiclo !== '' ? this.state.optionsCiclo : []}
                                                    type='horizontal'
                                                    handleChange={this.handleChange2}
                                                />  
                                            </Col>
                                        </Row> 
                                        <Row style={{marginTop:20}}>
                                            <Col >
                                                <h5 >
                                                    <Badge className='text-title-black' pill variant="light"> {FormatMoney(this.state.debtsValues.MinValorPrestamo)}</Badge>
                                                </h5>
                                            </Col>
                                            <Col>
                                                <h5 >
                                                    <Badge className='text-title-black' pill variant="light"> {FormatMoney(this.state.debtsValues.MaxValorPrestamo)}</Badge>
                                                </h5>
                                            </Col>
                                        </Row>
                                        
                                        <Col  style={{ marginTop: 10 }}>
                                            
                                            <input 
                                            type='range'
                                            min={this.state.debtsValues.MinValorPrestamo}
                                            max={this.state.debtsValues.MaxValorPrestamo}
                                            step={10000}
                                            thumbColor={'green'}
                                            onChange={(e) => this.handleChange(e.target.valueAsNumber)}
                                            />
                                            <div style={{ position: 'relative', top: '45%', left: '34%', width: '30%' }}>
                                                <h5 className='text-title-black'><strong htmlFor="points">{FormatMoney(parseInt(this.state.value))}</strong></h5>
                                            </div>
                                        </Col>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col style={{ marginTop: 40 }}>
                                        <h5 className='text-total'> Plazo 
                                        </h5>

                                        <Col style={{ marginTop: 40 }}> 
                                            <input
                                                
                                                type='range'
                                                min={0}
                                                max={this.state.count}
                                                defaultValue={this.state.days}
                                                onChange={(e) => {  this.handleChangeDays(e.target.value) }}  
                                            />
                                            
                                            <div style={{ position: 'relative', top: '45%', left: '34%', width: '30%' }}>
                                                <h5 className='text-title-black'><strong htmlFor="points">{this.state.daysName.nombre}</strong></h5>
                                            </div>
                                        </Col>
                                    </Col>
                                    
                                </Row>
                                
                                
                            </Col>
                            <Col xs={12} md={6}sm={6} lg={6} style={{ padding: '10px', borderLeft: '1px solid hsla(200, 10%, 50%,100)', boxShadow:'0px 10px 10px 0px', borderRadius:'20px' }}>
                                <Row>
                                    <div className="container ">
                                        <Resume
                                            value={this.state.value}
                                            days={this.state.daysName.nombre}
                                            tax={this.state.tax}
                                            administration={this.state.administration}
                                            tecnology={this.state.tecnology}
                                            porcentajes={this.state.debtsValues}
                                            iva={this.state.iva}
                                            cuotas={this.state.tiempo}
                                            totalDays= {this.state.totalDays}
                                            subTotal={this.state.subTotal}
                                            total={this.state.total}
                                            show={this.state.show}
                                            investigacion= {this.state.investigation} 
                                            sellos = {this.state.seal}
                                            sendRequest={() => this.sendRequest()}
                                        />
                                    </div>
                                </Row>
                            </Col>
                        </Row>}
                        
                        {this.state.show &&
                            <Container style={{ padding: '0% 20% 0% 20%' }}>
                                <div className="container ">
                                    <h4>{}</h4>
                                    <Resume
                                        nameUser={this.props.estados.nameUser}
                                        close={() => this.setState({ show: false })}
                                        show={this.state.show}
                                        value={this.state.value}
                                        totalDays= {this.state.totalDays}
                                        cuotas={this.state.tiempo}
                                        days={this.state.daysName.nombre?this.state.daysName.nombre:this.state.days}
                                        tax={this.state.tax}
                                        administration={this.state.administration}
                                        tecnology={this.state.tecnology}
                                        porcentajes={this.state.debtsValues}
                                        iva={this.state.iva}
                                        investigacion= {this.state.investigation} 
                                        sellos = {this.state.seal}
                                        subTotal={this.state.subTotal}
                                        total={this.state.total}
                                        sendRequest={() => this.sendRequest()}
                                    />
                                </div>
                            </Container>
                        }
                    </div>
            </>
        )
    }
}


