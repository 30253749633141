import React from 'react';
import { Row, Col, Badge, Button } from 'react-bootstrap';

export const TabMovimientos = (props) => {
    const { optionSelected, data } = props;

    return (

        <Row className="border-bottom">
            <Col xs lg="auto">
                <div className="border-right">                    
                    <Button onClick={() => optionSelected('gastos')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Gastos</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.gasto ? data.gasto.length : 0}</label></Badge>
                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('ingresos')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Ingresos</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.ingreso ? data.ingreso.length : 0}</label></Badge>
                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('retiros')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Retiros</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.retiro ? data.retiro.length : 0}</label></Badge>
                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('venta')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Créditos</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.venta ? data.venta.length : 0}</label></Badge>
                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('solicitudes')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Solicitudes</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.solicitud ? data.solicitud.length : 0}</label></Badge>
                </div>
            </Col>
            <Col lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('renovaciones')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Renovaciones</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.renovacion ? data.renovacion.length : 0}</label></Badge>
                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('pagos')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>Pagos</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.pago ? data.pago.length : 0}</label></Badge>

                </div>
            </Col>
            <Col xs lg="auto">
                <div className="border-right">
                    <Button onClick={() => optionSelected('nopagos')} style={{ backgroundColor: 'transparent', borderColor: 'white', borderRadius:70 }}><label className='text-title-blue'>No Pagos</label></Button> <Badge style={{ fontSize: 14, marginLeft: '8px' }}><label className='text-title-black'>{data && data.nopago ? data.nopago.length : 0}</label></Badge>
                </div>
            </Col>
        </Row>
    )
}