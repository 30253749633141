import React from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { InputForm, InputNumber, RadioForm, ComboBoxForm, DateForm } from '../../../shared/components/inputs';
import Register from '../../../shared/components/register';
import { subirClientes } from '../../../services/datos.services';
import './../../../pages/home/about-us/about.css'
import { ToastContainer, toast } from 'react-toastify';
import { Combobox } from 'react-widgets';
import {  FormattedMessage } from 'react-intl';
export class RegisterPersonal extends Register {
    constructor(props) {
        super();
        this.typedocuments = props.tipoDocumentos;
        console.log("",props.tipoDocumentos)
        this.data = props.data;
        this.state = {

            tformP:"",
            showBoton: "",
            confirmT:"",
            backT:"",
            nextT:"",
            tFamiliar: "",
            tAlquilada: "",
            succPersonal:"",
            fechaNacimiento: {
                name: 'fechaNacimiento',
                value: props.dataForm.xbirthday ? props.dataForm.xbirthday : '',
                error: '',
                type: "date",
                required: true
            },
            selectedFrontal: {
                name: 'selectedFrontal',
                value: props.dataForm.selectedFrontal ? props.dataForm.selectedFrontal : '',
                error: '',
                type: "off",
                required: true
            },
            selectedReverso: {
                name: 'selectedFrontal',
                value: props.dataForm.selectedReverso ? props.dataForm.selectedReverso : '',
                error: '',
                type: "off",
                required: true
            },
            fechaExpedicion: {
                name: 'fechaExpedicion',
                value: props.dataForm.fecha_expedicion_documento ? props.dataForm.fecha_expedicion_documento : '',
                error: '',
                type: "date",
                required: true
            },
            nacionalidad: {
                name: 'nacionalidad',
                value: props.dataForm.nacionalidad_id ? props.dataForm.nacionalidad_id : '',
                error: '',
                type: "off",
                required: true
            },
            ciudad: {
                name: 'ciudad',
                value: props.dataForm.municipio_id ? props.dataForm.municipio_id : '',
                error: '',
                type: "off",
                required: true
            },
            departamento: {
                name: 'departamento',
                value: props.dataForm.departamento_id ? props.dataForm.departamento_id : '',
                error: '',
                type: "off",
                required: true
            },
            numeroDocumento: {
                name: 'numeroDocumento',
                value: props.dataForm.identificacion ? props.dataForm.identificacion : '',
                error: '',
                type: "off",
                required: true
            },
            otherDocument: {
                name: 'otherDocument',
                value: props.dataForm.documento ? props.dataForm.documento : '',
                error: '',
                type: "off",
                required: true
            },
            celular: {
                name: 'celular',
                value: props.dataForm.celular ? props.dataForm.celular : '',
                error: '',
                type: "mobile",
                required: true
            },
            nombre: {
                name: 'nombre',
                value: props.dataForm.nombre1 ? props.dataForm.nombre1 : '',
                error: '',
                type: "off",
                required: true
            },
            segundoNombre: {
                name: 'segundoNombre',
                value: props.dataForm.nombre2 ? props.dataForm.nombre2 : '',
                error: '',
                type: "off",
                required: false
            },
            apellido1: {
                name: 'apellido1',
                value: props.dataForm.apellido1 ? props.dataForm.apellido1 : '',
                error: '',
                type: "off",
                required: true
            },
            apellido2: {
                name: 'apellido2',
                value: props.dataForm.apellido2 ? props.dataForm.apellido2 : '',
                error: '',
                type: "off",
                required: false
            },
            direccion: {
                name: 'direccion',
                value: props.dataForm.street ? props.dataForm.street : '',
                error: '',
                type: "off",
                required: true
            },
            postal: {
                name: 'postal',
                value: props.dataForm.codigo_postal ? props.dataForm.codigo_postal : '',
                error: '',
                type: "off",
                required: true
            },
            placaVehiculo: {
                name: 'placaVehiculo',
                value: props.dataForm.placa_vehiculo ? props.dataForm.placa_vehiculo : '',
                error: '',
                type: "off",
                required: false
            },
            radioEstadoEstudio: {
                name: 'radioEstadoEstudio',
                value: props.dataForm.estado_estudios ? props.dataForm.estado_estudios : '',
                error: '',
                type: "off",
                required: true
            },
            radioSexo: {
                name: 'radioSexo',
                value: props.dataForm.genero ? props.dataForm.genero : '',
                error: '',
                type: "off",
                required: true
            },
            radioCelular: {
                name: 'radioCelular',
                value: props.dataForm.plan_celular ? props.dataForm.plan_celular : '',
                error: '',
                type: "off",
                required: true
            },
            comboEstadoCivil: {
                name: 'comboEstadoCivil',
                value: props.dataForm.estado_civil ? props.dataForm.estado_civil : '',
                error: '',
                type: "off",
                required: true
            },
            comboPermanencia: {
                name: 'comboPermanencia',
                value: props.dataForm.tipo_de_permanencia ? props.dataForm.tipo_de_permanencia : '',
                error: '',
                type: "off",
                required: true
            },
            comboVive: {
                name: 'comboVive',
                value: props.dataForm.con_quien_vive ? props.dataForm.con_quien_vive : '',
                error: '',
                type: "off",
                required: true
            },
            comboHijos: {
                name: 'comboHijos',
                value: props.dataForm.cuantos_hijos_tiene ? props.dataForm.cuantos_hijos_tiene : '',
                error: '',
                type: "off",
                required: true
            },
            comboDependen: {
                name: 'comboDependen',
                value: props.dataForm.persona_dependen_economicamente ? props.dataForm.persona_dependen_economicamente : '',
                error: '',
                type: "off",
                required: true
            },
            comboEstrato: {
                name: 'comboEstrato',
                value: props.dataForm.estrato ? props.dataForm.estrato : '',
                error: '',
                type: "off",
                required: true
            },

            comboSiVehiculo: {
                name: 'comboSiVehiculo',
                value: props.dataForm.tiene_vehiculo ? props.dataForm.tiene_vehiculo : '',
                error: '',
                type: "off",
                required: true,
            },
            comboNivelEstudio: {
                name: 'comboNivelEstudio',
                value: props.dataForm.nivel_estudios ? props.dataForm.nivel_estudios : '',
                error: '',
                type: "off",
                required: true
            },
            comboTipoDocumento: {
                name: 'comboTipoDocumento',
                value: props.dataForm.tipodocumento ? props.dataForm.tipodocumento : '',
                error: '',
                type: "off",
                required: true
            },
            tieneDocumento: {
                name: 'tieneDocumento',
                value: props.dataForm.tiene_documento ? props.dataForm.tiene_documento : '',
                error: '',
                type: "off",
                required: true
            },
            tipoCelular: {
                name: 'tipoCelular',
                value: props.dataForm.tipo_celular ? props.dataForm.tipo_celular : '',
                error: '',
                type: "off",
                required: true
            },
            email: {
                name: 'email',
                value: props.dataForm.email ? props.dataForm.email : '',
                error: '',
                type: "email",
                required: true
            },
            tipoVivienda: {
                name: 'tipoVivienda',
                value: props.dataForm.tipo_de_vivienda ? props.dataForm.tipo_de_vivienda : '',
                error: '',
                type: "off",
                required: true
            },
            vehiculoRequire: false,
            optionsVivienda: [],
            optionsConQuienVive: [],
            optionNacionalidad: [],
            optionsDepartamento: [],
            optionsMunicipio: [],
            optionsDependen: [],
            optionsCantHijos: [],
            optionsEstadoCivil: [],
            optionsEstrato: [],
            optionsGenero: [],
            optionsNivelEstudio: [],
            optionsPlanCelular: [],
            optionsTipoDocumento: [],
            optionsTipoDocumentoUsa: [{ title: "United states passport", value: "44" },
            { title: "Driver's license", value: "45" },
            { title: "foreign passport", value: "46" },
            { title: "other", value: "47" },],
            optionsTipoPermanencia: [],
            optionsTieneDocumento: [{ title: " ", value: "seleccione" },
                                    { title: "Ssn", value: "ssn" },
                                    { title: "Itin", value: "itin" },
                                    { title: "None", value: "ninguno" }
                                    ],
            optionsTipoCelular:[{ title: " ", value: "seleccione" },
                                { title: "House", value: "casa" },
                                { title: "Mobile", value: "movil" },],
            optionsEstadoEstudio: [],
            optionsSiVehiculo: [],
            stateDoc: true,
            vardepto: "",
            errorCampos: "",
            flagusa:false,
            flagColombia:false,
            flagTieneDocumento:false,
        }

        

        this.allOK = true;
        this.inputsToVerify=[

        ]
        
    }

    inicialiceData = () => {
        const pais_server =  localStorage.getItem('server');
        if(pais_server==='"eeuu"'){
            this.inputsToVerify = [
                'nombre',
                'apellido1',
                'numeroDocumento',
                'comboTipoDocumento',
                'celular',
                'tieneDocumento',
                'tipoCelular',
                'direccion',
                'postal',
                'departamento',
                'selectedFrontal',
                'selectedReverso',
                'tipoVivienda',
                'ciudad',
                'comboPermanencia',
                'fechaNacimiento',
                'email'
            ]
            this.setState({
                tformP:<FormattedMessage id='personal' defaultMessage={"Información personal"} />,
                succPersonal:<FormattedMessage id='personalOK' defaultMessage={"Formulario personal cargado correctamente"} />,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                nextT:<FormattedMessage id='next' defaultMessage={"Siguiente"}/>,
                backT:<FormattedMessage id='back' defaultMessage={"Atras"}/>,
                confirmT:<FormattedMessage id='confirm' defaultMessage={"Confirmar"}/>,
                flagusa:true,
            });
        } if(pais_server==='"colombia"' || pais_server==='"colombiapruebas"'){
            this.inputsToVerify = [
                'nombre',
                'segundoNombre',
                'apellido1',
                'apellido2',
                'numeroDocumento',
                'fechaExpedicion',
                'comboTipoDocumento',
                'radioSexo',
                'celular',
                'comboNivelEstudio',
                'comboEstrato',
                'comboDependen',
                'comboHijos',
                'comboVive',
                'comboPermanencia',
                'comboEstadoCivil',
                'comboSiVehiculo',
                'radioEstadoEstudio',
                'tipoVivienda',
                'radioCelular',
                'placaVehiculo',
                'direccion',
                'nacionalidad',
                'departamento',
                'ciudad',
                'fechaNacimiento',
                'email'
            ]
            this.setState({
                tAlquilada: "Alquilada",
                tFamiliar: "Familiar",
                tformP: "Información Personal",
                succPersonal: "Formulario personal cargado OK!",
                errorCampos: "Atención, aún faltan campos por llenar",
                flagColombia:true,
                nextT:"Siguiente",
                confirmT: "Confirmar",
                backT:"Atrás",    
            });
        }

        const listOptionHouses=[];
        const listOptionsConQuienVive = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsNacionalidad = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsDepartamento = [{ title: " ", value: "seleccione" }];
        const listOptionsMunicipio = [{ title: " ", value: "seleccione" }];
        const listOptionsDependen = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsCantHijos = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsEstadoCivil = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsEstrato = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsGenero = [];
        const listOptionsNivelEstudio = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsPlanCelular = [];
        const listOptionsTipoDocumento = [];
        const listOptionsTipoPermanencia = [{ title: " ", value: "seleccione" }];
        //const listOptionsTipoPermanencia =[];
        const listOptionsTieneDocumento = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsEstadoEstudio = [];
        const listOptionsSiVehiculo = [{ title: "seleccione una opción", value: "seleccione" }];

        if (!this.data) {
            return;
        }

        
        if (this.data.nacionalidad_ids) {
            if (!this.props.dataForm.nacionalidad_id) {
                this.setState({
                    nacionalidad: {
                        name: 'nacionalidad',
                        value: listOptionsNacionalidad[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                });
            }
            this.data.nacionalidad_ids.forEach(element => {
                listOptionsNacionalidad.push({ title: element.nombre, value: element.id })
            });

        }

        if (this.data.tipo_de_vivienda_ids) {
            if (!this.props.dataForm.tipo_de_vivienda) {
                
            }
            this.data.tipo_de_vivienda_ids.forEach(element => {
                listOptionHouses.push({ title: element[1], value: element[0] })
                //listOptionHouses.push({ title: <FormattedMessage id='family' defaultMessage={"Familiar"}/>, value: "familiar"},
                                    //  { title: <FormattedMessage id='rented' defaultMessage={"Alquilada"}/>, value: "alquilada"  })
            });
            listOptionHouses.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        }

        if (this.data.con_quien_vive_ids) {
            if (!this.props.dataForm.con_quien_vive) {
                this.setState({
                    comboVive: {
                        name: 'comboVive',
                        value: listOptionsConQuienVive[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.con_quien_vive_ids.forEach(element => {
                listOptionsConQuienVive.push({ title: element[1], value: element[0] })
            });
            listOptionsConQuienVive.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        }
        if (this.data.cuantos_hijos_tiene_ids) {
            if (!this.props.dataForm.cuantos_hijos_tiene) {
                this.setState({
                    comboHijos: {
                        name: 'comboHijos',
                        value: listOptionsCantHijos[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.cuantos_hijos_tiene_ids.forEach(element => {
                listOptionsCantHijos.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.persona_dependen_economicamente_ids) {
            if (!this.props.dataForm.persona_dependen_economicamente) {
                this.setState({
                    comboDependen: {
                        name: 'comboDependen',
                        value: listOptionsDependen[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.persona_dependen_economicamente_ids.forEach(element => {
                listOptionsDependen.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.estado_civil_ids) {
            if (!this.props.dataForm.estado_civil) {
                this.setState({
                    comboEstadoCivil: {
                        name: 'comboEstadoCivil',
                        value: listOptionsEstadoCivil[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.estado_civil_ids.forEach(element => {
                listOptionsEstadoCivil.push({ title: element[1], value: element[0] })
            });
            listOptionsEstadoCivil.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        }
        if (this.data.estrato_ids) {
            if (!this.props.dataForm.estrato) {
                this.setState({
                    comboEstrato: {
                        name: 'comboEstrato',
                        value: listOptionsEstrato[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.estrato_ids.forEach(element => {
                listOptionsEstrato.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.genero_ids) {
            this.data.genero_ids.forEach(element => {
                listOptionsGenero.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.nivel_estudios_ids) {
            if (!this.props.dataForm.nivel_estudios) {
                this.setState({
                    comboNivelEstudio: {
                        name: 'comboNivelEstudio',
                        value: listOptionsNivelEstudio[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.nivel_estudios_ids.forEach(element => {
                listOptionsNivelEstudio.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.plan_celular_ids) {
            this.data.plan_celular_ids.forEach(element => {
                listOptionsPlanCelular.push({ title: element[1], value: element[0] })
            });
        }

        if (this.typedocuments.length > 0) {
            this.typedocuments.forEach(element => {
                if (parseInt(element[0]) === 1) {
                    listOptionsTipoDocumento.push({ title: 'Seleccione el tipo de identificación', value: 'seleccione' })
                } else {
                    listOptionsTipoDocumento.push({ title: element[1], value: element[0] })
                }
            });
        }
        if (this.data.tipo_de_permanencia_ids) {
            if (!this.props.dataForm.tipo_de_permanencia) {
                this.setState({
                    comboPermanencia: {
                        name: 'comboPermanencia',
                        value: listOptionsTipoPermanencia[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            } 
            this.data.tipo_de_permanencia_ids.forEach(element => {
            
                listOptionsTipoPermanencia.push({ title: element[1], value: element[0] })
                /* listOptionsTipoPermanencia.push(  {title:"From 0 to 1 years" ,value:"0a1"},
                                                  {title:"From 1 to 3 years" ,value:"1a3"},
                                                  {title:"More than 3 years" ,value:"3mas"} ) */
               
            }); 
        }
        if (this.data.estado_estudios_ids) {
            this.data.estado_estudios_ids.forEach(element => {
                listOptionsEstadoEstudio.push({ title: element[1], value: element[0] })
            });
            
        }
        if (this.data.departamento_ids) {
            if (!this.props.dataForm.departamento_id) {
                this.setState({
                    ciudad: {
                        name: 'ciudad',
                        value: listOptionsMunicipio[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                    departamento: {
                        name: 'departamento',
                        value: listOptionsDepartamento[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            
            this.data.departamento_ids.forEach(element => {
                listOptionsDepartamento.push({
                    title: element.nombre, value: element.id
                })
            });
            listOptionsDepartamento.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        }

        if (this.data.municipio_ids) {
            if (this.props.dataForm.departamento_id) {   
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.props.dataForm.departamento_id) === parseInt(element.departamento_id)) { 
                        listOptionsMunicipio.push({
                            title: element.nombre, value: element.id
                        });

                        listOptionsMunicipio.sort((a,b) =>{
                            return (a.title < b.title) ? -1 : 1
                        })       
                    }
                });
            } else {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.data.departamento_ids[0].id) === parseInt(element.departamento_id)) { 
                        listOptionsMunicipio.push({ title: element.nombre, value: element.id, });      
                    }
                });
                
                this.setState({
                    ciudad: {
                        name: 'ciudad',
                        value: listOptionsMunicipio[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        }

        if (this.data.tiene_vehiculo_ids) {
            if (!this.props.dataForm.tiene_vehiculo) {
                this.setState({
                    comboSiVehiculo: {
                        name: 'comboSiVehiculo',
                        value: listOptionsSiVehiculo[0].value,
                        error: '',
                        type: "off",
                        required: true,
                    },
                })
            }
            this.data.tiene_vehiculo_ids.forEach(element => {
                listOptionsSiVehiculo.push({ title: element[1], value: element[0] })
            });
        }
        this.setState({
            
            optionsVivienda: listOptionHouses,
            optionsConQuienVive: listOptionsConQuienVive,
            optionsCantHijos: listOptionsCantHijos,
            optionsDependen: listOptionsDependen,
            optionsEstadoCivil: listOptionsEstadoCivil,
            optionsEstrato: listOptionsEstrato,
            optionsGenero: listOptionsGenero,
            optionsNivelEstudio: listOptionsNivelEstudio,
            optionsPlanCelular: listOptionsPlanCelular,
            optionsTipoDocumento: listOptionsTipoDocumento,
            optionsTipoPermanencia: listOptionsTipoPermanencia,
            optionsEstadoEstudio: listOptionsEstadoEstudio,
            optionNacionalidad: listOptionsNacionalidad,
            optionsDepartamento: listOptionsDepartamento,
            optionsMunicipio: listOptionsMunicipio,
            optionsSiVehiculo: listOptionsSiVehiculo
        })
        
    }

    changeDocument = (value) =>{
        if(value==="itin" || value === "ssn"){
           this.setState({
            flagTieneDocumento: true
           })
        }else{
            this.setState({
                flagTieneDocumento: false
            })
           } 
        }
    
    selectDepartamento = (index) => {
        const optionsMunicipio = [{ title: " ", value: "seleccione" }];
        this.data.municipio_ids.forEach(element => {
            if (parseInt(index) === parseInt(element.departamento_id)) {
                
                optionsMunicipio.push({ title: element.nombre, value: element.id,   
                    });
                
                optionsMunicipio.sort((a,b) =>{
                    return (a.title < b.title) ? -1 : 1
                })
            }
        });
        this.setState({
            optionsMunicipio,
            ciudad: {
                name: 'ciudad',
                value: optionsMunicipio[0].value,
                error: '',
                type: "off",
                required: true
            },
        })
    }

    componentDidMount = (props) => {
        if (this.data) {
            this.inicialiceData();
        }
    }
    
    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    frontalChangedHandler = async (event) => {
        const file = event.target.files[0];
        const base64 = await this.convertBase64(file);
        this.setState({ selectedFrontal: base64 })
    }
    reversoChangedHandler = async (event) => {
        const file = event.target.files[0];
        const base64 = await this.convertBase64(file);
        this.setState({ selectedReverso: base64 })
    }

    uploadHandler = () => {
        
    }

    registrar = () => {
                let list = this.props.dataForm;
                if (this.verifyForm()) {
                    if(this.state.flagColombia){
                        const uid = localStorage.getItem('uid')
                        const personal = {
                            id: parseInt(uid),
                            cliente: {
                                nombre1: this.state.nombre.value,
                                nombre2: this.state.segundoNombre.value,
                                apellido1: this.state.apellido1.value,
                                apellido2: this.state.apellido2.value,
                                identificacion: this.state.numeroDocumento.value,
                                fecha_expedicion_documento: this.state.fechaExpedicion.value,
                                tipodocumento: this.state.comboTipoDocumento.value,
                                celular: this.state.celular.value,
                                nacionalidad_id: this.state.nacionalidad.value,
                                municipio_id: this.state.ciudad.value,
                                departamento_id: this.state.departamento.value,
                                street: this.state.direccion.value,
                                placa_vehiculo: this.state.placaVehiculo.value,
                                tipo_de_vivienda: this.state.tipoVivienda.value,
                                estado_estudios: this.state.radioEstadoEstudio.value,
                                estado_civil: this.state.comboEstadoCivil.value,
                                tipo_de_permanencia: this.state.comboPermanencia.value,
                                con_quien_vive: this.state.comboVive.value,
                                cuantos_hijos_tiene: this.state.comboHijos.value,
                                persona_dependen_economicamente: this.state.comboDependen.value,
                                estrato: this.state.comboEstrato.value,
                                tiene_vehiculo: this.state.comboSiVehiculo.value,
                                nivel_estudios: this.state.comboNivelEstudio.value,
                                xbirthday: this.state.fechaNacimiento.value,
                                genero: this.state.radioSexo.value,
                                plan_celular: this.state.radioCelular.value,
                                email: this.state.email.value,
                                imagencedulafrontal: this.state.selectedFrontal,
                                imagencedulaposterior: this.state.selectedReverso,
                                estadopersonal: true,
                            }
                        }
                        list.nombre1 = this.state.nombre.value;
                        list.nombre2 = this.state.segundoNombre.value;
                        list.apellido1 = this.state.apellido1.value;
                        list.apellido2 = this.state.apellido2.value;
                        list.identificacion = this.state.numeroDocumento.value;
                        list.fecha_expedicion_documento = this.state.fechaExpedicion.value;
                        list.tipodocumento = this.state.comboTipoDocumento.value;
                        list.celular = this.state.celular.value;
                        list.nacionalidad_id = this.state.nacionalidad.value;
                        list.municipio_id = this.state.ciudad.value;
                        list.departamento_id = this.state.departamento.value;
                        list.street = this.state.direccion.value;
                        list.placa_vehiculo = this.state.placaVehiculo.value;
                        list.tipo_de_vivienda = this.state.tipoVivienda.value;
                        list.estado_estudios = this.state.radioEstadoEstudio.value;
                        list.estado_civil = this.state.comboEstadoCivil.value;
                        list.tipo_de_permanencia = this.state.comboPermanencia.value;
                        list.con_quien_vive = this.state.comboVive.value;
                        list.cuantos_hijos_tiene = this.state.comboHijos.value;
                        list.persona_dependen_economicamente = this.state.comboDependen.value;
                        list.estrato = this.state.comboEstrato.value;
                        list.tiene_vehiculo = this.state.comboSiVehiculo.value;
                        list.nivel_estudios = this.state.comboNivelEstudio.value;
                        list.xbirthday = this.state.fechaNacimiento.value;
                        list.genero = this.state.radioSexo.value;
                        list.plan_celular = this.state.radioCelular.value;
                        list.email = this.state.email.value;
                        list.imagencedulafrontal = this.state.selectedFrontal;
                        list.imagencedulaposterior = this.state.selectedReverso; 
                               
                        subirClientes(personal, (data, flag) => {
                            if (flag) {
                                if (this.props.next) {
                                    this.props.next();
                                    this.props.upDateList(list)
                                } else {
                                    const success = () => toast.success(this.state.succPersonal);
                                    success();                  
                                }
                            }
                        })
                    }
                    if(this.state.flagusa){
                        const uid = localStorage.getItem('uid')
                        const personal = {
                            id: parseInt(uid),
                            cliente: {
                                nombre1: this.state.nombre.value,
                                nombre2: this.state.segundoNombre.value,
                                apellido1: this.state.apellido1.value,
                                apellido2: this.state.apellido2.value,
                                identificacion: this.state.numeroDocumento.value,
                                tipodocumento: this.state.comboTipoDocumento.value,
                                tiene_documento : this.state.tieneDocumento.value,
                                tipo_celular : this.state.tipoCelular.value,
                                celular: this.state.celular.value,
                                //nacionalidad_id: this.state.nacionalidad.value,
                                municipio_id: this.state.ciudad.value,
                                departamento_id: this.state.departamento.value,
                                street: this.state.direccion.value,
                                codigo_postal: parseInt(this.state.postal.value),
                    //          placa_vehiculo: this.state.placaVehiculo.value,
                                tipo_de_vivienda: this.state.tipoVivienda.value,
                    //          estado_estudios: this.state.radioEstadoEstudio.value,
                //              estado_civil: this.state.comboEstadoCivil.value,
                                tipo_de_permanencia: this.state.comboPermanencia.value,
                //              con_quien_vive: this.state.comboVive.value,
                //              cuantos_hijos_tiene: this.state.comboHijos.value,
                //              persona_dependen_economicamente: this.state.comboDependen.value,
                //              estrato: this.state.comboEstrato.value,
                //              tiene_vehiculo: this.state.comboSiVehiculo.value,
                //              nivel_estudios: this.state.comboNivelEstudio.value,
                                xbirthday: this.state.fechaNacimiento.value,
                //               plan_celular: this.state.radioCelular.value,
                                email: this.state.email.value,
                                imagencedulafrontal: this.state.selectedFrontal,
                                imagencedulaposterior: this.state.selectedReverso,
                                estadopersonal: true,
                            }
                        }
                        list.nombre1 = this.state.nombre.value;
                        list.nombre2 = this.state.segundoNombre.value;
                        list.apellido1 = this.state.apellido1.value;
                        list.apellido2 = this.state.apellido2.value;
                        list.identificacion = this.state.numeroDocumento.value;
                        list.fecha_expedicion_documento = this.state.fechaExpedicion.value;
                        list.tipodocumento = this.state.comboTipoDocumento.value;
                        list.tiene_documento = this.state.tieneDocumento.value;
                        list.tipo_celular = this.state.tipoCelular;
                        list.celular = this.state.celular.value;
                        list.nacionalidad_id = this.state.nacionalidad.value;
                        list.municipio_id = this.state.ciudad.value;
                        list.departamento_id = this.state.departamento.value;
                        list.street = this.state.direccion.value;
                        list.codigo_postal= this.state.postal.value;
                //       list.placa_vehiculo = this.state.placaVehiculo.value;
                        list.tipo_de_vivienda = this.state.tipoVivienda.value;
                //      list.estado_estudios = this.state.radioEstadoEstudio.value;
                //      list.estado_civil = this.state.comboEstadoCivil.value;
                        list.tipo_de_permanencia = this.state.comboPermanencia.value;
                //      list.con_quien_vive = this.state.comboVive.value;
                //       list.cuantos_hijos_tiene = this.state.comboHijos.value;
                //       list.persona_dependen_economicamente = this.state.comboDependen.value;
                //      list.estrato = this.state.comboEstrato.value;
                //      list.tiene_vehiculo = this.state.comboSiVehiculo.value;
                //      list.nivel_estudios = this.state.comboNivelEstudio.value;
                        list.xbirthday = this.state.fechaNacimiento.value;
                //        list.genero = this.state.radioSexo.value;
                //      list.plan_celular = this.state.radioCelular.value;
                        list.email = this.state.email.value;
                        list.imagencedulafrontal = this.state.selectedFrontal;
                        list.imagencedulaposterior = this.state.selectedReverso;
                        subirClientes(personal, (data, flag) => {
                            if (flag) {
                                if (this.props.next) {
                                    this.props.next();
                                    this.props.upDateList(list)
                                } else {
                                    const success = () => toast.success(this.state.succPersonal);
                                    success();                 
                                }
                            }
                        })           
                    }
                    
                } else {
                    const error = () => toast.error(this.state.errorCampos);
                    error();
                }
  
}

    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '2% 0 0 8%', paddingBottom: "2%" }}>
                    <Card style={{ maxWidth: '70rem' }}>
                        <Card.Body>
                            <h3 className='text-title-black'>{this.state.tformP}</h3>
                            <hr />
                            <Form>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='firstName' defaultMessage={"Primer Nombre"} /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer nombre</label>}
                                    <InputForm
                                        field={this.state.nombre}
                                        handleChange={this.handleChange}
                                        onBlur={this.handleRelease}
                                    />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
{this.state.flagusa &&<label style={{ fontSize: '16px' }}><FormattedMessage  id='secondName' defaultMessage={"Segundo Nombre"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}>Segundo nombre</label>}
                                        <InputForm
                                            field={this.state.segundoNombre}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='firstSurName' defaultMessage={"Primer Apellido"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Primer Apellido</label>}
                                        <InputForm
                                            field={this.state.apellido1}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                    {this.state.flagusa &&<label style={{ fontSize: '16px' }}><FormattedMessage id='secondSurName' defaultMessage={"Segundo Apellido"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}>Segundo Apellido</label>}
                                        <InputForm
                                            field={this.state.apellido2}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />

                                </Row>
                                <Row>
                                    <Col sm>
                                    {this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='dateB'
                                      defaultMessage={"Primer Nombre"}
                                    /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Fecha de nacimiento</label>}
                                        <DateForm
                                            field={this.state.fechaNacimiento}
                                            handleChange={this.handleChange}
                                            type="date"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm>
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Género</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioSexo}
                                            options={this.state.optionsGenero !== '' ? this.state.optionsGenero : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.radioSexo.value}

                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    {this.state.flagColombia &&<Col sm>
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de documento</label>
                                        <ComboBoxForm
                                            disabled={true}
                                            field={this.state.comboTipoDocumento}
                                            options={this.state.optionsTipoDocumento !== '' ? this.state.optionsTipoDocumento : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboTipoDocumento.value}
                                        />
                                    </Col>}
             {this.state.flagusa && <Col sm>
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='typedocument' defaultMessage={"Tipo de documento"}/></label>
                                        <ComboBoxForm
                                            field={this.state.comboTipoDocumento}
                                            options={this.state.optionsTipoDocumentoUsa !== '' ? this.state.optionsTipoDocumentoUsa : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboTipoDocumento.value}
                                        />
                                    </Col>}
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='documentN' defaultMessage={"Número de identificación"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Número de documento</label>}
                                        <InputForm
                                            field={this.state.numeroDocumento}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            disabled={this.state.flagColombia}                    
                                        />
                                    </Col>
                                </Row>
                                <Row>
    {this.state.flagusa &&          <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='havessn' defaultMessage={"¿Tiene  (SSN) o (ITIN)?"}/></label>
                                        <ComboBoxForm   
                                            field={this.state.tieneDocumento}
                                            options={this.state.optionsTieneDocumento !== '' ? this.state.optionsTieneDocumento : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.changeDocument(value) }}
                                            onBlur={this.handleRelease}
                                            initial={this.state.tieneDocumento.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagTieneDocumento &&   <Col sm>
    {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='documentN'
                                      defaultMessage={"Número del documento"}
                                    /></label>}
                                        <InputForm
                                            title="Número"
                                            field={this.state.otherDocument}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            disabled={this.state.flagColombia}
                                            placeholder="Ingrese el número del documento"
                                            
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm>
    
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Fecha de expedición</label>
                                        <DateForm
                                            title="Fecha de expedición"
                                            field={this.state.fechaExpedicion}
                                            handleChange={this.handleChange}
                                            type="date"
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='phone' defaultMessage={"Telefono"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Celular</label>}
                                        <InputNumber
                                            field={this.state.celular}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de servicio de celular</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioCelular}
                                            options={this.state.optionsPlanCelular !== '' ? this.state.optionsPlanCelular : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <Col sm>
                                    {this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='email'
                                      defaultMessage={"Correo Electrónico"}
                                    /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo Electrónico</label>}
                                        <InputForm
                                            field={this.state.email}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            disabled={true}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
        {this.state.flagColombia && <Col sm>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Nacionalidad</label>
                                        <ComboBoxForm
                                            type='horizontal'
                                            field={this.state.nacionalidad}
                                            handleChange={this.handleChange}
                                            options={this.state.optionNacionalidad !== '' ? this.state.optionNacionalidad : []}
                                            onBlur={this.handleRelease}
                                            initial={this.state.nacionalidad.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                    {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='state'
                                      defaultMessage={"Estado"}
                                    /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Departamento</label>}
                                        <ComboBoxForm
                                            type='horizontal'
                                            field={this.state.departamento}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.selectDepartamento(value) }}
                                            options={this.state.optionsDepartamento !== '' ? this.state.optionsDepartamento : []}
                                            onBlur={this.handleRelease}
                                            initial={this.state.departamento.value}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                    {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='city'
                                      defaultMessage={"Ciudad"}
                                    /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Municipio</label>}
                                        <ComboBoxForm
                                            field={this.state.ciudad}
                                            handleChange={this.handleChange}
                                            options={this.state.optionsMunicipio !== '' ? this.state.optionsMunicipio : []}
                                            onBlur={this.handleRelease}
                                            initial={this.state.ciudad.value}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='address'
                                      defaultMessage={"Dirección"}
                                    /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Dirección</label>}
                                        <InputForm
                                            field={this.state.direccion}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
         {this.state.flagusa && <Col sm>
           <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='zip'
                                      defaultMessage={"Código Postal"}
                                    /></label>
                                        <InputNumber
                                            title="Código Postal"
                                            field={this.state.postal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder={"Código Postal"}
                                        />
                                    </Col>}
                                     <Col sm>
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de vivienda</label>}
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.tipoVivienda}
                                            options={this.state.optionsVivienda !== '' ? this.state.optionsVivienda : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.tipoVivienda.value}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia &&<Col sm>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Estrato</label>
                                        <ComboBoxForm
                                            title="Estrato"
                                            field={this.state.comboEstrato}
                                            options={this.state.optionsEstrato !== '' ? this.state.optionsEstrato : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboEstrato.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='lived' defaultMessage={"¿Hace cuánto vive en esta dirección?"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tiempo de permanencia</label>}
                                        <ComboBoxForm
                                            field={this.state.comboPermanencia}
                                            options={this.state.optionsTipoPermanencia !== '' ? this.state.optionsTipoPermanencia : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboPermanencia.value}
                                        />
                                    </Col>
        {this.state.flagusa &&      <Col sm>
            {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='phoneservice'
                                      defaultMessage={"Tipo de servicio celular"}
                                    /></label>}
                                        <ComboBoxForm
                                            field={this.state.tipoCelular}
                                            options={this.state.optionsTipoCelular !== '' ? this.state.optionsTipoCelular : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.tipoCelular.value}
                                        />
                                    </Col>}
        {this.state.flagColombia && <Col sm >
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Estado Civil</label>
                                        <hr className="clearfix w-100 d-md-none" />
                                        <ComboBoxForm
                                            field={this.state.comboEstadoCivil}
                                            options={this.state.optionsEstadoCivil !== '' ? this.state.optionsEstadoCivil : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboEstadoCivil.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />

        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>¿Con quien vive?</label>
                                        <ComboBoxForm
                                            field={this.state.comboVive}
                                            options={this.state.optionsConQuienVive !== '' ? this.state.optionsConQuienVive : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboVive.value}
                                        />
                                    </Col>}
                                </Row>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia &&  <Col sm>

<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Número de hijos</label>
                                        <ComboBoxForm
                                            field={this.state.comboHijos}
                                            options={this.state.optionsCantHijos !== '' ? this.state.optionsCantHijos : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboHijos.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia &&   <Col sm>
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>¿Personas a cargo?</label>
                                        <ComboBoxForm
                                            field={this.state.comboDependen}
                                            options={this.state.optionsDependen !== '' ? this.state.optionsDependen : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboDependen.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
         {this.state.flagColombia && <Col sm>
            
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Nivel de estudios</label>
                                        <ComboBoxForm                                       
                                            field={this.state.comboNivelEstudio}
                                            options={this.state.optionsNivelEstudio !== '' ? this.state.optionsNivelEstudio : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboNivelEstudio.value}
                                        />
                                        <hr className="clearfix w-100 d-md-none" />
                                    </Col>}
         {this.state.flagColombia && <Col sm>
                <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Estado de estudios</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioEstadoEstudio}
                                            options={this.state.optionsEstadoEstudio !== '' ? this.state.optionsEstadoEstudio : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>¿Tiene vehiculo?</label>
                                        <ComboBoxForm
                                            field={this.state.comboSiVehiculo}
                                            options={this.state.optionsSiVehiculo !== '' ? this.state.optionsSiVehiculo : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.comboSiVehiculo.value}
                                        />
                                    </Col>}

                                    {this.state.comboSiVehiculo.value === 'carro' || this.state.comboSiVehiculo.value === 'moto' ?
                                        <Col sm>
                                            <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    Placa</label>}
                                            <InputForm
                                                title="Placa del vehiculo"
                                                field={this.state.placaVehiculo}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                                placeholder="Ingrese la placa de su vehiculo"
                                            />
                                        </Col>
                                        : <></>}
                                </Row>
                                <Row>
                                    <Col><h6>
{this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='photoF' defaultMessage={"¿Hace cuánto vive en esta dirección?"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Foto frontal documento</label>}
                                            <input
                                                style={{ marginTop: 20 }}
                                                type="file"
                                                name="myImage"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                onChange={this.frontalChangedHandler}
                                            />
                                        </h6>
                                        
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col>
<h6>{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='photoR' defaultMessage={"¿Hace cuánto vive en esta dirección?"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Foto Reverso documento</label>}
                                            <input
                                                style={{ marginTop: 20 }}
                                                type="file"
                                                name="myImage"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                onChange={this.reversoChangedHandler}
                                            />
                                        </h6>
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>


                                {!this.props.showBoton &&
                                    <div className="container mt-4">
                                        <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                            onClick={this.registrar}>
                                            <label className='text-title-black' style={{marginTop:6}}>{this.state.confirmT}</label>         
                                        </Button>
                                    </div>
                                }
                            </Form>
                            {this.props.showBoton &&
                                <div className="container mt-4">
                                    <hr />
                                    <Row className="justify-content-md-center">
                                        <Col  sm >
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={() => this.props.back ? this.props.back() : <></>}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.backT}</label>   
                                            </Button>
                                        </Col>
                                        <Col sm >
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={this.registrar}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.nextT}</label>   
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                            }
                        </Card.Body>
                    </Card>
                </div>
            </>
        );
    }
}