import React from 'react'
import { InputForm } from '../../../shared/components/inputs';
import '../../home/about-us/about.css'
import Register from '../../../shared/components/register';
import { Button, Col, Card, Row } from 'react-bootstrap';
import { Desencrypt } from '../../../utilities/cipher';
import { cambiaCorreo } from '../../../services/datos.services';
import { FormattedMessage } from 'react-intl';
import { ToastContainer,toast } from 'react-toastify';



export class UpdateEmail extends Register {

    constructor(props) {
        super(props);
        this.state = {
            flagColombia: false,
            flagusa: false,
            disabled: true,
            valid: false,
            currentEmail: "",
            errorCampos: "",
            tCurrentMail:"",
            repeatMail:"",
            succMail:"",
            validateEmail: '',
            tconfirm: "",
            typePass: true,
            txtActMail: '',
            typeRepeatPass: true,
            email: {
                name: "email",
                value: "",
                error: '',
                type: "email",
                required: true
            },
            emailRepeat: {
                name: 'emailRepeat',
                value: '',
                error: '',
                type: 'emailRepeat',
                required: true
            }
        };
        this.allOK = true;
        this.inputsToVerify = [
            'email',
            'emailRepeat',
        ]
    }
    handleChangeValidate = (value) => {
        this.setState({
            validateEmail: value,
        })
        if (value !== '') {
            if (this.state.currentEmail === value) {
                this.setState({ valid: false, disabled: false })
            } else {
                this.setState({ valid: true, disabled: true })
            }
        } else {
            this.setState({ valid: false, disabled: true })
        }
    }
    inicialiceData = async () => {
        const session = Desencrypt(await localStorage.getItem("Session"));
        this.setState({
            currentEmail: session.user,
        })
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                flagusa: true,
                succMail:<FormattedMessage id='mailUpdate' defaultMessage={"Correo actualizado correctamente"} />, 
                repeatMail:<FormattedMessage id='mailya' defaultMessage={"Correo ya existente"} />, 
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                tconfirm:<FormattedMessage id='confirm' defaultMessage={"Correo actual"} />,
                tCurrentMail:<FormattedMessage id='currentMail' defaultMessage={"Correo actual"} />,
                txtActMail:<FormattedMessage id='updateMail' defaultMessage={"Actualización de correo"} />
            })
        }else{
            this.setState({
                succMail: "Correo actualizado correctamente, te llegará un mail para habilitar nuevamente tu cuenta",
                tconfirm: "Confirmar",
                repeatMail: "Correo ya existente en el sistema, comunícate con VENGALEPRESTO",
                flagColombia: true,
                txtActMail: "Actualización de correo",
                tCurrentMail: "Correo actual",
                errorCampos: "Atención, aún faltan campos por llenar"
            })
        }
    }
    componentDidMount = () => {
        this.inicialiceData();
    }
    registrar = () => {
        if (this.verifyForm()) {
            const uid = localStorage.getItem('uid');
            this.id_user=uid;
            const personal = {
                id: uid,
                correo: this.state.email.value
            }
            cambiaCorreo(personal, (flag, response) => {
                if (flag) {
                    const success = () => toast.success(this.state.succMail);
                    success();
                    setTimeout(() => {
                        localStorage.clear();
                        window.location = "*";
                        }, 3000);
                    
                }else{
                    
                    const error = () => toast.error(this.state.repeatMail);
                    error();
                }
            })
        } else {
            const error = () => toast.error(this.state.errorCampos);
            error();
        }
    }
    

    render() {
        return (
            <>
            <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={3500}/>
            <div style={{ margin: '5% 0 0 5%', padding: "1% 0 2% 0" }}>
                <Card style={{ width: '75%', borderRadius:50, boxShadow:'6px 10px 10px black' }}>
                    <Card.Body>
                        <Card.Title><h3 className='text-total'>{this.state.txtActMail}</h3></Card.Title>
                        <hr />
                        <div className="form-group">
                            <label className='text-title-black'
                                style={{ fontSize: '16px' }}>{this.state.tCurrentMail}<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                style={{ fontSize: '14px' }}
                                className="form-control text-title-black"
                                value={this.state.validateEmail}
                                onChange={(e) => this.handleChangeValidate(e.target.value)}
                                maxLength={50}
                            >
                            </input>
                            {this.state.valid && this.state.flagColombia && <label className='text-title-red' style={{ color: "red", fontSize: '14px' }}>El correo actual no es  correcto</label>}
                        </div>
                        <Row>
                            <Col className="col-12">
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo nuevo</label>}
                                <InputForm
                                    field={this.state.email}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    maxLength={50}
                                    minLength={6}
                                    disabled={this.state.disabled}
                                    type={'text'}
                                />
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col className="col-12">
{this.state.flagusa &&<label className='text-body' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='confirmMail' defaultMessage={"Confirmar correo"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Confirmar correo</label>}
                                <InputForm
                                    field={this.state.emailRepeat}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    maxLength={50}
                                    minLength={6}
                                    disabled={this.state.disabled}
                                    type={'text'}
                                />
                            </Col>
                            
                        </Row>
                        <Col className="container">
                            <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={this.registrar} className="btn btn-warning"  > <label className='text-title-black' style={{marginTop:6}}>{this.state.tconfirm}</label></Button>
                        </Col>

                    </Card.Body>
                </Card>
            </div>
            </>
        );
    }

}