import React from 'react'
import Register from '../../../shared/components/register';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import './../../../pages/home/about-us/about.css'
import { InputNumber, RadioForm, ComboBoxForm, InputMoney } from '../../../shared/components/inputs';
import { subirClientes } from '../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';
import {  FormattedMessage } from 'react-intl';

export class RegisterFinanciera extends Register {
    constructor(props) {
        super();
        this.data = props.data
        this.state = {
            showBoton: true,
            errorCampos: "",
            serverError: "",
            succFin:"",
            nameActivos: {
                name: 'nameActivos',
                value: props.dataForm.activos ? props.dataForm.activos : '',
                error: '',
                type: "off",
                required: true
            },
            nameIngresos: {
                name: 'nameIngresos',
                value: props.dataForm.ingresos ? props.dataForm.ingresos : '',
                error: '',
                type: "off",
                required: true
            },
            nameEgresos: {
                name: 'nameEgresos',
                value: props.dataForm.egresos ? props.dataForm.egresos : '',
                error: '',
                type: "off",
                required: true
            },
            namePasivos: {
                name: 'namePasivos',
                value: props.dataForm.pasivos ? props.dataForm.pasivos : '',
                error: '',
                type: "off",
                required: true
            },
            nameNumeroCuenta: {
                name: 'nameNumeroCuenta',
                value: props.dataForm.numero_cuenta ? props.dataForm.numero_cuenta : '',
                error: '',
                type: "off",
                required: true
            },
            nameNumeroRuta: {
                name: 'nameNumeroRuta',
                value: props.dataForm.numero_ruta ? props.dataForm.numero_ruta : '',
                error: '',
                type: "off",
                required: true
            },

            radioTipoCuenta: {
                name: 'radioTipoCuenta',
                value: props.dataForm.tipo_cuenta ? props.dataForm.tipo_cuenta : '',
                error: '',
                type: "off",
                required: true
            },
            radioTiempoCuenta: {
                name: 'radioTiempoCuenta',
                value: props.dataForm.tiempo_cuenta ? props.dataForm.tiempo_cuenta : '',
                error: '',
                type: "off",
                required: true
            },
            radioTarjetaCredito: {
                name: 'radioTarjetaCredito',
                value: props.dataForm.tarjeta_credito ? props.dataForm.tarjeta_credito : '',
                error: '',
                type: "off",
                required: true
            },
            radioMovilGo: {
                name: 'radioMovilGo',
                value: props.dataForm.punto_autorizado ? props.dataForm.punto_autorizado : 'cuenta_bancaria',                
                error: '',
                type: "off",
                required: true
            },
            radioUsa: {
                name: 'radioUsa',
                value: props.dataForm.punto_autorizado ? props.dataForm.punto_autorizado : '',                
                error: '',
                type: "off",
                required: true
            },
            comboBanco: {
                name: 'comboBanco',
                value: props.dataForm.banco_id === 'False' ? this.data.banco_ids[0].id : props.dataForm.banco_id,
                error: '',
                type: "off",
                required: true
            },
            optionsBanco: [],
            confirmT:"",
            backT:"",
            nextT:"",
            tiempoCuenta:[],
            optionsTipoCuenta: [],
            optionsTajetaCredito: [],
            optionsPunto:[],
            optionsUsa:[{title: <FormattedMessage id='cash' defaultMessage={"Efectivo"}/>, value: "efectivo"},
                        {title: <FormattedMessage id='transfer' defaultMessage={"Transferencia"}/>, value: "transferencia"},
                        {title: <FormattedMessage id='check' defaultMessage={"Cheque"}/>, value: "cheque"},],
            flagusa:false,
            flagColombia:false,
            titleFinanciera:""
        }
        this.optionsTajetaCredito = [];
        this.optionsPunto = [] ;
        this.allOK = true;
        if(localStorage.getItem('server') === '"colombia"' || localStorage.getItem('server') === '"colombiapruebas"' ){
        this.inputsToVerify = [
            'nameActivos',
            'nameIngresos',
            'nameEgresos',
            'nameNumeroCuenta',
            'namePasivos',
            'radioTipoCuenta',
            'radioTarjetaCredito',
            'radioMovilGo',
            'comboBanco',
            ]
        }
        if(localStorage.getItem('server') === '"eeuu"' || localStorage.getItem('server') === '"eeuupruebas"' ){
            this.inputsToVerify = [   
            'nameNumeroCuenta',
            'nameNumeroRuta',
            'radioTipoCuenta',
            'comboBanco', 
            ]
            
        }
    }

    inicialiceData = () => {
        const pais_server =  localStorage.getItem('server');
        if(pais_server==='"eeuu"'){
            this.setState({
                flagusa:true,
                succFin: <FormattedMessage id='financialOK' defaultMessage={"Financiero ok"} />,
                serverError:<FormattedMessage id='serverError' defaultMessage={"Error en el servidor."} />,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                nextT:<FormattedMessage id='next' defaultMessage={"Siguiente"}/>,
                backT:<FormattedMessage id='back' defaultMessage={"Atras"}/>,
                confirmT:<FormattedMessage id='confirm' defaultMessage={"Confirmar"}/>,
                titleFinanciera:<FormattedMessage id='financialInformation' defaultMessage={"Información Financiera"}/>,
            });
        }else{
            this.setState({
                flagColombia:true,
                succFin: "Formulario financiero cargado correctamente",
                serverError:"Error al subir los datos",
                nextT:"Siguiente",
                confirmT: "Confirmar",
                backT:"Atrás",
                errorCampos: "Atención, aún faltan campos por llenar",
                titleFinanciera:"Información Financiera"
            });
        }
        const optionsBanks = [{ title: " ", value: "efectivo", value: "seleccione" }];
        const accountType = [];
        const creditCard = [];
        const opcionesCuenta = [];
        const movilGo = [];
        if (!this.data) {
            return;
        }

        if (this.data.banco_ids) {
            if (this.props.dataForm.banco_id === 'False') {
                this.setState({
                    comboBanco: {
                        name: 'comboBanco',
                        value: optionsBanks[0].value,
                        error: '',
                        type: "off",
                        required: true
                    }
                })
            }
            this.data.banco_ids.forEach(element => {
                optionsBanks.push({ title: element.nombre, value: element.id })
            });
        }
        if (this.data.tipo_cuenta_ids) {

            this.data.tipo_cuenta_ids.forEach(element => {
                accountType.push({title:  element[1], value: element[0]  })
               // accountType.push({ title: <FormattedMessage id='saving' defaultMessage={"Ahorro"}/>, value: 'ahorro' },
                 //                {  title: <FormattedMessage id='currentaccount' defaultMessage={"Corriente"}/>, value: 'corriente' })
            });
        }
        if (this.data.tarjeta_credito_ids) {
            this.data.tarjeta_credito_ids.forEach(element => {
                creditCard.push({ title: element[1], value: element[0] })             
            });
        };

        //this.data.antiguedad_ids.forEach(element=>{
            opcionesCuenta.push({ title: <FormattedMessage id='ind1' defaultMessage={"Antiguedad"}/>, value: '1' },
            {  title: <FormattedMessage id='ind2' defaultMessage={"Antiguedad"}/>, value: '2' },
            {  title: <FormattedMessage id='ind3' defaultMessage={"Antiguedad"}/>, value: '3' },
            {  title: <FormattedMessage id='ind4' defaultMessage={"Antiguedad"}/>, value: '4' })
       // })


       if(this.data.desembolso_ids){
           this.data.desembolso_ids.forEach(element => {
               movilGo.push({title: element[1], value: element[0]})
           })
       }
        this.setState({
            optionsBanco: optionsBanks,
            optionsTipoCuenta: accountType,
            optionsTajetaCredito: creditCard,
            optionsPunto: movilGo,
            tiempoCuenta: opcionesCuenta

        });
        
    };

    componentDidMount = () => {
        this.inicialiceData();
    };
    registrar = () => {
        let list = this.props.dataForm;
        if (this.verifyForm()) {
                if(this.state.flagColombia){
                const uid = localStorage.getItem('uid')
                const personal = {
                    id: parseInt(uid),
                    cliente: {
                        banco_id: this.state.comboBanco.value,
                        numero_cuenta: this.state.nameNumeroCuenta.value,
                       pasivos: parseInt(this.state.namePasivos.value),
                        egresos: parseInt(this.state.nameEgresos.value),
                        tipo_cuenta: this.state.radioTipoCuenta.value,
                        activos: parseInt(this.state.nameActivos.value),
                        ingresos: parseInt(this.state.nameIngresos.value),
                        tarjeta_credito: this.state.radioTarjetaCredito.value,
                        desembolso_ids: this.state.radioMovilGo.value,
                        estadofinanciero: true,
                    }
                }
                list.banco_id = this.state.comboBanco.value;
                list.numero_cuenta = this.state.nameNumeroCuenta.value;
                list.pasivos = parseInt(this.state.namePasivos.value);
                list.egresos = parseInt(this.state.nameEgresos.value);
                list.tipo_cuenta = this.state.radioTipoCuenta.value;
                list.activos = parseInt(this.state.nameActivos.value);
                list.ingresos = parseInt(this.state.nameIngresos.value);
                list.tarjeta_credito = this.state.radioTarjetaCredito.value;
                list.desembolso_ids = this.state.radioMovilGo.value;

                

                subirClientes(personal, (data, flag) => {

                    if (flag) {
                        if (this.props.next) {
                            this.props.next();
                            this.props.upDateList(list);
                        } else {
                            const success = () => toast.success(this.state.succFin);
                                    success(); 
                        }
                    } else {
                        const error = () => toast.error(this.state.errorCampos);
                        error();
                    }
                })

            }
            if(this.state.flagusa){
                const uid = localStorage.getItem('uid')
                const personal = {
                    id: parseInt(uid),
                    cliente: {
                        banco_id: this.state.comboBanco.value,
                        numero_cuenta: this.state.nameNumeroCuenta.value,
                        numero_ruta: this.state.nameNumeroRuta.value,
                        tipo_cuenta: this.state.radioTipoCuenta.value,
                        tiempo_cuenta: this.state.radioTiempoCuenta.value,
                        desembolso_ids: this.state.radioUsa.value,
                        estadofinanciero: true,
                    }
                }
                list.banco_id = this.state.comboBanco.value;
                list.numero_cuenta = this.state.nameNumeroCuenta.value;
                list.numero_ruta = this.state.nameNumeroRuta.value;
           //     list.pasivos = parseInt(this.state.namePasivos.value);
           //     list.egresos = parseInt(this.state.nameEgresos.value);
                  list.tipo_cuenta = this.state.radioTipoCuenta.value;
                  list.tiempo_cuenta = this.state.radioTiempoCuenta.value;
           //     list.activos = parseInt(this.state.nameActivos.value);
           //     list.ingresos = parseInt(this.state.nameIngresos.value);
           //     list.tarjeta_credito = this.state.radioTarjetaCredito.value;
                list.desembolso_ids = this.state.radioUsa.value;
                console.log("lo que se va",personal)
                subirClientes(personal, (data, flag) => {
                    console.log("llegó",data,flag)

                    if (flag) {
                        if (this.props.next) {
                            this.props.next();
                            this.props.upDateList(list);
                        } else {
                            const success = () => toast.success(this.state.succFin);
                                    success(); 
                        }
                    } else {
                        const error = () => toast.error(this.state.serverError);
                        error();
                    }
                })

            }
            
        } else {
            const error = () => toast.error(this.state.errorCampos);
            error();
        }
    }
    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '2% 0 0 8%', paddingBottom: "2%" }}>
                    <Card style={{ maxWidth: '70rem' }}>
                        <Card.Body>
                            <Form>
                                <h3 className='text-title-black'>{this.state.titleFinanciera}</h3>
                                <hr />
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span> <FormattedMessage id='bank' defaultMessage={"Banco"}  /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Banco</label>}
                                        <ComboBoxForm
                                            field={this.state.comboBanco}
                                            options={this.state.optionsBanco !== '' ? this.state.optionsBanco : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboBanco.value}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />

                                </Row>
                                <Row>

                                    <Col sm>
                                        {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span> <FormattedMessage id='accountNumber' defaultMessage={"Número de cuenta"}/></label>}
                                        {this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Número de cuenta</label>}
                                        <InputNumber
                                            field={this.state.nameNumeroCuenta}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
             {this.state.flagusa &&<Col sm>
                <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='accountRoute'
                                      defaultMessage={"Número de ruta"}
                                    /></label>
                                        <InputNumber
                                            field={this.state.nameNumeroRuta}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de cuenta</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioTipoCuenta}
                                            options={this.state.optionsTipoCuenta !== '' ? this.state.optionsTipoCuenta : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>
{this.state.flagColombia &&           <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>¿Tiene tarjeta de crédito?</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioTarjetaCredito}
                                            options={this.state.optionsTajetaCredito !== '' ? this.state.optionsTajetaCredito : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
                                </Row>
                                <Row>
{this.state.flagusa &&            <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='timeAccount' defaultMessage={"Tiempo de cuenta"}/></label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioTiempoCuenta}
                                            options={this.state.tiempoCuenta !== '' ? this.state.tiempoCuenta : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm >
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Total Activos</label>
                                        <InputMoney
                                            field={this.state.nameActivos}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm >
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Total Pasivos</label>
                                        <InputMoney
                                            field={this.state.namePasivos}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm >
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Total Ingresos</label>
                                        <InputMoney
                                            field={this.state.nameIngresos}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagColombia &&         <Col sm >
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Total Egresos</label>
                                        <InputMoney
                                            field={this.state.nameEgresos}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
{this.state.flagColombia &&<Col sm>
                                <h3 className='text-title-black'>Desembolso</h3>
                                
                                <hr />
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioMovilGo}
                                            options={this.state.optionsPunto !== '' ? this.state.optionsPunto : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
{this.state.flagusa &&     <Col sm>
                                <h3 className='text-title-black'><FormattedMessage id='disbursement' defaultMessage={"Desembolso"}/></h3>
                                
                                <hr />
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioUsa}
                                            options={this.state.optionsUsa !== '' ? this.state.optionsUsa : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                {!this.props.showBoton &&
                                    <div className="container mt-4">
                                        <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                            onClick={this.registrar}>
                                            <label className='text-title-black' style={{marginTop:6}}>{this.state.confirmT}</label>         
                                        </Button>
                                    </div>
                                }
                            </Form>
                            {this.props.showBoton &&
                                <div className="container mt-4">
                                    <hr />
                                    <Row className="justify-content-md-center">
                                        <Col xs sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={() => this.props.back ? this.props.back() : <></>}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.backT}</label>   
                                            </Button>
                                        </Col>
                                        <Col xs sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={this.registrar}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.nextT}</label>   
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </>
        )
    }
}