import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { recuperarContrasena } from '../../../services/datos.services';
import { InputForm } from '../../../shared/components/inputs';
import Register from '../register';
import { ToastContainer, toast } from 'react-toastify';

export class ForgetPassword extends Register {
    constructor() {
        super();
        this.state = {
            correo: "",
            tRecover:"",
            tReturn: "",
            tSend: "",
            success: "",
            tError:"",
            flagColombia: false,
            flagusa: false,
            email: {
                name: 'email',
                value: '',
                error: '',
                type: "email",
                required: true
            },
        }

        this.allOK = true;
        this.inputsToVerify = [
            'email'
        ]
    }

    recuperar = () => {

        
        if (this.verifyForm()) {


            recuperarContrasena(this.state.email.value, (data, flag) => {
                console.log(flag,data) 
                if (flag) {
                    const success = () => toast.success(this.state.success)
                    success();
                    setTimeout(() => {
                        window.location = "/simulador";
                    }, 3000);
                }else{
                    const error = () => toast.error(this.state.tError)
                    error();
                    setTimeout(() => {
                        window.location = "/simulador";
                    }, 3000);

                }
            });
        }
    }

    componentDidMount = () => {
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                flagusa: true,
                tError:<FormattedMessage id='serverError' defaultMessage={"Aun faltan campos por llenar"} />,
                success: <FormattedMessage id='moments' defaultMessage={"Exitoso correo"}/>, 
                tSend: <FormattedMessage id='process' defaultMessage={"Recuperar cuenta"}/>,
                tReturn: <FormattedMessage id='returnaccount' defaultMessage={"Recuperar cuenta"}/>,
                tRecover: <FormattedMessage id='recoveraccount' defaultMessage={"Recuperar cuenta"}/>
            })
        }else{
            this.setState({
                flagColombia: true,
                success: "¡ En instantes te llegara un mensaje al correo para recuperar tu cuenta de VENGALEPRESTO !",
                tRecover: "Recuperar cuenta",
                tReturn: "Volviendo a la cuenta de VENGALEPRESTO",
                tError:"Estamos teniendo problemas con el servidor. Inténtalo más tarde.",
                tSend: "Se enviará un correo a tu email para continuar el proceso",
            
            })

        }
    }

    render() {
        return (
            <>
            <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={3500}/>
                <div style={{ background: '#363636', color: 'white', paddingTop: 70 }}>
                    <div className="container" style={{ textAlign: 'left', paddingLeft: 200 }}>
                        <label style={{ fontSize: 26 }}>
                            {this.state.tReturn}
                        </label>
                    </div>
                </div>
                <div className='container' style={{ paddingTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <hr />
                    <Form>
                        <Col>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='email' defaultMessage={"Correo"} /></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo electrónico</label>}
                            <InputForm                  
                                field={this.state.email}
                                handleChange={this.handleChange}
                                onBlur={this.handleRelease}
                            />
                        </Col>
                        <div className="container mt-2">
                            <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center', color:'black' }}
                                onClick={this.recuperar}
                                className="btn btn-warning"
                            >
                                <label className='text-title-black' style={{marginTop:6}}>{this.state.tRecover}</label>
                            </Button>
                        </div>
                        <label className='text-title-black'>{this.state.tSend}</label>
                    </Form>
                </div>
            </>
        );
    }
}