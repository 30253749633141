import React, { PureComponent } from 'react'
import { Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router';
import { Resume } from './resume';

export class SummaryCredit extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            nombre: '',
            tsummary:"",
            flagColombia: false,
            flagusa: false,
            value: '',
            days: '',
            tax: '',
            administration: '',
            tecnology: '',
            porcentajes: '',
            iva: '',
            cuotas:0,
            investigacion: 0,
            sellos: 0,
            subTotal: '',
            total: ''
        }
    }
    componentDidMount = () => {
        let server = localStorage.getItem("server")
        if(server==='"eeuu"'){
            this.setState({
                flagusa:true,
                tsummary: <FormattedMessage id='summary' defaultMessage={"Resumen"}/>

            })
        }else{
            this.setState({
                tsummary: "Resumen",
                flagColombia: true  
            })
        }
        
        const summary = JSON.parse(localStorage.getItem('valueSummary'));
        const name =  localStorage.getItem('nameUser');
        
        this.setState({
            show: true,
            nombre: name,
            value: summary.value,
            days: summary._days,
            tax: summary.tax,
            administration: summary.administration,
            tecnology: summary.tecnology,
            iva: summary.iva,
            cuotas:summary.cuotas,
            investigacion: summary.investigacion,
            sellos: summary.sellos,
            porcentajes: summary.porcentajes,
            subTotal: summary.subTotal,
            total: summary.total
        })
    }
    render() {
        return (

            <Container style={{ padding: '10% 20% 0% 20%' }}>
                <div className="container ">
                    <h4 className='text-total'>{this.state.tsummary}</h4>
                    <Resume
                        nameUser={this.state.nombre}
                        show={true}
                        value={this.state.value}
                        days={this.state.days}
                        tax={this.state.tax}
                        cuotas={this.state.cuotas}
                        administration={this.state.administration}
                        tecnology={this.state.tecnology}
                        porcentajes={this.state.porcentajes}
                        iva={this.state.iva}
                        investigacion ={this.state.investigacion}
                        sellos ={this.state.sellos}
                        subTotal={this.state.subTotal}
                        total={this.state.total}
                        sendRequest={() => this.sendRequest()}
                    />
                </div>
            </Container>
        )
    }
}