export const NumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * 
 * @param {*} value Un valor numerico que desea tranformar
 * @returns El valor introducido en formato $###
 */
export const FormatMoney = (value) => {
    if (typeof (value) === 'string') {
        return Capitalize(value);
    }
    return '$ ' + NumberWithCommas(value);
};
export const FormatMoney2 = (value) => {
    if (typeof (value) === 'string') {
        return Capitalize(value);
    }
    return 'us ' + NumberWithCommas(value);
};

export const Capitalize = (value) => {
    if (value) {
        return (value.charAt(0).toUpperCase() + value.slice(1));
    }
   return "undefined"
}

export const FormatDateComplete = function (una_fecha) {
    const fecha = una_fecha === null ? new Date() : new Date(una_fecha + " UTC");
    const miFecha = fecha.getFullYear() + "-" + (((fecha.getMonth() + 1).toString().length === 1) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1)) + "-" + (((fecha.getDate()).toString().length === 1) ? "0" + (fecha.getDate()) : (fecha.getDate())) + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds() + "";
    return miFecha;
};
export const FormatShortDate = function (fecha) {
    const miFecha = fecha.getFullYear().toString()+"-"+(fecha.getMonth()+1).toString()+"-"+fecha.getDate().toString()
    return miFecha;
};
export const FormatDateYYMMDD = function (una_fecha) {
    const fecha = una_fecha === null ? new Date() : new Date(una_fecha + " UTC");
    const miFecha = fecha.getFullYear() + "-" + (((fecha.getMonth() + 1).toString().length === 1) ? "0" + (fecha.getMonth() + 1) : (fecha.getMonth() + 1)) + "-" + (((fecha.getDate()).toString().length === 1) ? "0" + (fecha.getDate()) : (fecha.getDate()));
    return miFecha;
};
export const OdooDateToReact = (dateOdoo) => {
    const dateSplit = dateOdoo.split(" ")[0].split("-");
    const hourSplit = dateOdoo.split(" ")[1].split(":");
    return new Date(dateSplit[0], dateSplit[1], dateSplit[2], hourSplit[0], hourSplit[1], hourSplit[2]);
}