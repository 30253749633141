
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


export const IntroModal = () => {

    const [show, setShow] = useState(true);
    let [initial, setInitial] = useState(false)
    let [colombia, setColombia] = useState(false)
    let [usa, setUsa] = useState("")

    const handleClose = () => {
        setShow(false);
        sessionStorage.setItem('initialModal',false)
    };

    useEffect(()=>{
        let server= localStorage.getItem("server");
        if(server === '"eeuu"'){
            setUsa(true)
            setInitial(<FormattedMessage id='info1' defaultMessage={"info1"}/>)
        }else{
            setInitial("“Crédito rápido y fácil, 100% en línea.”")
            setColombia(true)
        }

    },[])

    return (
        <>
            <Modal borderRadius={10} centered show={show} onHide={handleClose}>
                <Modal.Header style={{ background: 'white' }} closeButton>
                    <div className="container">
                        <h4 className='text-title-black'>VENGALEPRESTO</h4>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mb-4 text-title-black">
                        <h4 className='text-title-black'>{initial}</h4>
                    </div>
                
                    <div className="container">
                        {colombia &&<Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={handleClose}>
                           <label className='text-title-black' style={{marginTop:6}}>Ingresar</label> 
                        </Button>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

