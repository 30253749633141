import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
// import Checkbox from "./components/checkbox";
// import { ComboBoxForm, DateForm, InputForm, InputNumber } from '../../../shared/components/inputs';
import Register from '../../../../shared/components/register';
import { getParametros, subirClientes } from '../../../../services/datos.services';
import { ComboBoxForm, DateForm, InputForm, InputNumber } from '../../../../shared/components/inputs';
import Checkbox from './checkbox';
import { toast, ToastContainer } from 'react-toastify';


const OPTIONS = ["Gasto", "Ingreso", "Retiro", "Borrar Credito", "Modificar Pago", "Fotos Requeridas", "Solicitud Token"];

export class CrearCliente extends Register {
    constructor(props) {
        super();

        this.state = {

            checkBoxAlert: false,
            perfilAdmin: false,
            perfilVendedor: false,

            checkboxes: OPTIONS.reduce(
                (options, option) => ({
                    ...options,
                    [option]: false
                }), {}
            ),

            nombre1: {
                name: 'nombre1',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            nombre2: {
                name: 'nombre2',
                value: "",
                error: '',
                type: "off",
                required: false
            },
            apellido1: {
                name: 'apellido1',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            apellido2: {
                name: 'apellido2',
                value: "",
                error: '',
                type: "off",
                required: false
            },
            phone: {
                name: 'phone',
                value: "",
                error: '',
                type: "mobile",
                required: true
            },
            email: {
                name: 'email',
                value: "",
                error: '',
                type: "email",
                required: true
            },
            password: {
                name: 'password',
                value: '',
                error: '',
                type: 'password',
                required: true
            },
            passwordRepeat: {
                name: 'passwordRepeat',
                value: '',
                error: '',
                type: 'passwordRepeat',
                required: true
            },
            numeroDocumento: {
                name: 'numeroDocumento',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            tipoDocumento: {
                name: 'tipoDocumento',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            fechaExpedicion: {
                name: 'fechaExpedicion',
                value: '',
                error: '',
                type: "date",
                required: true
            },
            dineroMaxCaja: {
                name: 'dineroMaxCaja',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            dineroMaxCredito: {
                name: 'dineroMaxCredito',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            dineroMaxGasto: {
                name: 'dineroMaxGasto',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            dineroMaxRenovacion: {
                name: 'dineroMaxRenovacion',
                value: "",
                error: '',
                type: "off",
                required: true
            },
        }
        this.allOK = true;
        this.inputsToVerify = [

            'nombre1',
            'apellido1',
            'dineroMaxCaja',
            'dineroMaxCredito',
            'dineroMaxGasto',
            'dineroMaxRenovacion',
            'phone',
            'email',
            'password',
            'passwordRepeat',
            'numeroDocumento',
            'tipoDocumento',
            'fechaExpedicion',

        ]
    }

    initialiceData = () => {
        const list = []
        getParametros((data) => {
            if (data.tipodocumento_ids !== undefined) {
                data.tipodocumento_ids.forEach(element => {
                    if (parseInt(element[0]) === 1) {
                        list.push({ title: 'Seleccione una opción', value: "seleccione" })
                    } else {
                        list.push({ title: element[1], value: element[0] })
                    }
                });
                this.setState({ optionsTipoDocumento: list })
            }
        })
    }

    componentDidMount = () => {
        this.initialiceData();
    }

    registrar = () => {

        const uid = localStorage.getItem('uid')
        if (this.state.perfilAdmin || this.state.perfilVendedor) {
            this.setState({ checkBoxAlert: false });
        } else {
            this.setState({ checkBoxAlert: true });
        }

        if (this.verifyForm()) {
            if (this.state.perfilAdmin || this.state.perfilVendedor) {
                this.setState({ checkBoxAlert: false });
                const personal = {
                    id: parseInt(uid),
                    cliente: {
                        team_members_ids: [[0, 0, {
                            nombre1: this.state.nombre1.value,
                            nombre2: this.state.nombre2.value,
                            apellido1: this.state.apellido1.value,
                            apellido2: this.state.apellido2.value,
                            dineroLimiteEnCaja: parseInt(this.state.dineroMaxCaja.value),
                            dineroLimiteEnVenta: parseInt(this.state.dineroMaxCredito.value),
                            dineroLimiteEnRenovacion: parseInt(this.state.dineroMaxRenovacion.value),
                            dineroLimiteEnGasto: parseInt(this.state.dineroMaxGasto.value),
                            in_group_28: this.state.perfilAdmin,
                            in_group_29: this.state.perfilVendedor,

                            tipodocumento: this.state.tipoDocumento.value,
                            identificacion: "" + this.state.numeroDocumento.value,
                            fecha_expedicion_documento: this.state.fechaExpedicion.value,
                            login: this.state.email.value.toLowerCase(),
                            celular: this.state.phone.value,
                            clave: this.state.password.value,
                            email: this.state.email.value.toLowerCase(),
                        }]]
                    }
                }

                subirClientes(personal, (data, flag) => {
                    
                    if (flag) {
                        if(data[0].errores){
                            
                            const error = () => toast.error("Ocurrio un error creando usuario, podría ya encontrarse registrado");
                            error();
                        }else{
                            const success = () => toast.success("¡El usuario ha sido creado!");
                            success();
                            /* setTimeout(() => {
                            window.location = "/control-usuario";
                            }, 6000); */
                        }
                        
                    } else {
                        const success = () => toast.success("¡El usuario no se creo!");
                        success();
                    }
                })

                Object.keys(this.state.checkboxes)
                    .filter(checkbox => this.state.checkboxes[checkbox])
                    .forEach(checkbox => {
                        // console.log(checkbox, "está seleccionado.");
                    });

            } else {
                this.setState({ checkBoxAlert: true });
            }
        }

    }

    handleClose = () => {
        this.props.setShowCreateUser(false)
        // this.props.setUserOption(false)
        this.cleanFields();
    }

    handleCheckboxChange = changeEvent => {
        const { name } = changeEvent.target;

        this.setState(prevState => ({
            checkboxes: {
                ...prevState.checkboxes,
                [name]: !prevState.checkboxes[name]
            }
        }));
    };

    handleFormSubmit = formSubmitEvent => {
        formSubmitEvent.preventDefault();

        Object.keys(this.state.checkboxes)
            .filter(checkbox => this.state.checkboxes[checkbox])
            .forEach(checkbox => {
                // console.log(checkbox, "is selected.");
            });
    };

    createCheckbox = option => (
        <Checkbox
            label={option}
            isSelected={this.state.checkboxes[option]}
            onCheckboxChange={this.handleCheckboxChange}
            key={option}
        />
    );
    createCheckboxes = () => OPTIONS.map(this.createCheckbox);

    selectAdmin = () => {
        this.setState({
            perfilAdmin: true,
            perfilVendedor: false,
        });

    }

    selectVendedor = () => {
        this.setState({
            perfilAdmin: false,
            perfilVendedor: true
        });
    }

    cleanFields = () => {
        this.setState({
            perfilAdmin: false,
            perfilVendedor: false,
            nombre1: '',
            nombre2: '',
            apellido1: '',
            apellido2: '',
            dineroLimiteEnCaja: '',
            dineroLimiteEnVenta: '',
            dineroLimiteEnRenovacion: '',
            dineroLimiteEnGasto: '',
        })
    }
    render() {
        return (
            <>
                <ToastContainer style={{ marginTop: 50, fontSize: 16 }} /* autoClose={10000} */ />
                <Modal
                    show={true}
                    onHide={this.handleClose}
                >
                    <Modal.Header style={{borderBottomWidth:3,borderBottomColor:'#02dce8'}} closeButton>
                        <Modal.Title>
                            <h2 className='text-total'>Nuevo Usuario</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ margin: '10px' }} >

                            <Form>
                                <Row>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer Nombre</label>
                                        <InputForm
                                            title="Primer Nombre"
                                            field={this.state.nombre1}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su nombre completo"
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Segundo Nombre</label>
                                        <InputForm
                                            title="Segundo Nombre"
                                            field={this.state.nombre2}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su nombre completo"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer Apellido</label>
                                        <InputForm
                                            title="Primer Apellido"
                                            field={this.state.apellido1}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese sus apellidos"
                                        />
                                    </Col>
                                    <Col>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Segundo Apellido</label>
                                        <InputForm
                                            title="Segundo apellido"
                                            field={this.state.apellido2}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese sus apellidos"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de Documento</label>
                                        <ComboBoxForm
                                            title="Tipo de documento"
                                            field={this.state.tipoDocumento}
                                            options={this.state.optionsTipoDocumento !== ''
                                                ? this.state.optionsTipoDocumento
                                                : []
                                            }
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Número de Documento</label>
                                        <InputNumber
                                            title="Número de documento"
                                            field={this.state.numeroDocumento}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese el numero del documento"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Fecha de Expedición</label>
                                        <DateForm
                                            title="Fecha de expedición"
                                            field={this.state.fechaExpedicion}
                                            handleChange={this.handleChange}
                                            type="date"
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Celular</label>
                                        <InputNumber
                                            title="Número de Celular"
                                            field={this.state.phone}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su número de celular"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo Electrónico</label>
                                        <InputForm
                                            title="Correo"
                                            field={this.state.email}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su correo"
                                            type="email"
                                        />
                                    </Col>
                                </Row>
                                <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Contraseña</label>
                                <InputForm
                                    title="Contraseña"
                                    field={this.state.password}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    placeholder="Contraseña minimo 6 y máximo 8 dígitos"
                                    type="password"
                                    minLength={6}
                                    maxLength={8}
                                />
                                <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Confirmar Contraseña</label>
                                <InputForm
                                    title="Confirmar Contraseña"
                                    field={this.state.passwordRepeat}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    placeholder="Confirmar contraseña"
                                    type="password"
                                    minLength={6}
                                    maxLength={8}
                                />
                                <Row>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Dinero Máximo en Caja</label>
                                        <InputNumber
                                            title="Dinero Maximo caja"
                                            field={this.state.dineroMaxCaja}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Dinero máximo"
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Dinero Máximo en Crédito</label>
                                        <InputNumber
                                            title="Dinero máximo crédito"
                                            field={this.state.dineroMaxCredito}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Dinero máximo"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <label className='text-title-black' style={{ fontSize: '13px' }}><span style={{ color: "red" }}>*</span>Dinero Máximo en Renovación</label>
                                        <InputNumber
                                            title="Dinero Maximo renovación"
                                            field={this.state.dineroMaxRenovacion}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Dinero máximo"
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Dinero Máximo en Gasto</label>
                                        <InputNumber
                                            title="Dinero máximo gasto"
                                            field={this.state.dineroMaxGasto}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Dinero máximo"
                                        />
                                    </Col>
                                </Row>

                                Perfil<label style={{ color: ' red' }}>*</label>
                                <br />

                                <div>
                                    <input
                                        name="perfil"
                                        className="mr-2"
                                        type="radio"
                                        value="administrador"
                                        checked={this.state.perfilAdmin}
                                        onChange={this.selectAdmin}
                                    /><label className='text-body'>Administrador</label> 
                                </div>
                                <div className="mb-4">
                                    <input
                                        name="perfil"
                                        className="mr-2"
                                        type="radio"
                                        value="vendedor"
                                        checked={this.state.perfilVendedor}
                                        onChange={this.selectVendedor}
                                    /> <label className='text-body'>Vendedor</label> 
                                    <br />
                                    {this.state.checkBoxAlert && <label style={{ color: ' red', fontSize: 14 }}> Debe seleccionar un perfil </label>}
                                </div>

                                {/* <Row>
                                    <Col>
                                        {this.createCheckboxes()}
                                    </Col>
                                </Row> */}

                            </Form>

                            <Row>
                                <Col>
                                    <Button  style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={this.handleClose} > <label className='text-title-black'>Cancelar</label> </Button>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                    <Button  style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={this.registrar} ><label className='text-title-black'>Guardar</label></Button>
                                </Col>

                            </Row>

                        </div>
                    </Modal.Body>
                </Modal >
            </>
        )
    }
}


