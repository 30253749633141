import React from 'react';
import './styles.css';
import { useState, useEffect } from 'react';
import {FormattedMessage} from 'react-intl'


export const StepByStep = () => {
   
   
    const [credito, setCredito] = useState('')
    const [moneda, setMoneda] = useState('')
    let [stept, setStept] = useState('')
    let [funcionat, setFuncionat] = useState('')
    const [flagColombia,setFlagColombia] = useState(false)
    const [flagUsa,setFlagUsa] = useState(false)
    
    let valMinimo = localStorage.getItem('valorMinimo');
    let ValMaximo = localStorage.getItem('valorMaximo');

    useEffect(() => {
        const server = localStorage.getItem('server');
        if(server==='"eeuu"'){
            setStept(<FormattedMessage id='step1Title' defaultMessage={"Paso"} />)
            setFuncionat(<FormattedMessage id='howork' defaultMessage={"¿Cómo funciona?"} />)
            setFlagUsa(true)    
        }else{
            setStept("Paso")
            setFuncionat("¿Cómo funciona?")
            setFlagColombia(true)
        }
      }, []);
    
    return (
        <div className='container' style={{ paddingTop: "40px" }}  >
            <div className='como-funciona' >
                <div className='titulo'  >
                    <h2>{funcionat}</h2>
                    {flagColombia &&<h5 style={{ color: 'gray' }}>Realiza los siguientes pasos para acceder a tu crédito</h5>}
                    {flagUsa &&<h5 style={{ color: 'gray' }}><FormattedMessage
                id='take'
                defaultMessage={"Realiza los siguientes pasos para acceder a tu préstamo"}
                /></h5>}
                </div>
                <div className='como-funciona-pasos'>
                    <div className='pasos-item pasos-1'>
                         <h3>{stept} 1</h3>
        {flagColombia &&<p>
                        Al ingresar a la página web <span style={{textTransform:'capitalize'}}>VENGALEPRESTO</span>  diligencia los formularios aportando la información personal la cual debe ser verídica para la aprobación del crédito. 
                        </p>}
            {flagUsa &&<p>
                <FormattedMessage
                id='step1'
                defaultMessage={"Al ingresar a la página web VENGALEPRESTO diligencia los formularios aportando la información personal la cual debe ser verídica para la aprobación del préstamo."}
                />
                        </p>}
                    </div>
                    <div className='pasos-item pasos-2'>
                    <h3>{stept} 2</h3>
                        {flagColombia &&<p>
                        Inicia sesión e ingresa a la sección “TU crédito”, en donde podrás solicitar el crédito por el valor que necesitas, recuerda que este crédito podrá ser entre ${valMinimo} pesos a ${ValMaximo} pesos. 
                        </p>}
                        {flagUsa &&<p>
                            <FormattedMessage
                id='step2'
                defaultMessage={"Inicia sesión e ingresa a la sección “tu préstamo”, en donde podrás solicitar el préstamo por el valor que necesitas, recuerda que este crédito podrá ser entre $100 dólares a $1000 dólares."}
                />
                        </p>}
                    </div>
                    <div className='pasos-item pasos-3'>
                    <h3>{stept} 3</h3>
                        {flagColombia &&<p>
                            <span style={{textTransform:'capitalize'}}>Si</span> tu crédito es aprobado, tendrás tu dinero en máximo 24 horas.
                        </p>}
                        {flagUsa &&<p>
                            <FormattedMessage
                id='step3'
                defaultMessage={"Si tu préstamo es aprobado, tendrás tu dinero en máximo 24 horas."}
                />
                            
                        </p>}
                    </div>
                </div>
            </div>
        </div>
    )
}