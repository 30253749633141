import React, { useState, useEffect } from 'react'
import { Container, Pagination, Table } from 'react-bootstrap';
import Switch from "react-switch";
import { List } from './listWallet';

const CarteraShow = (props) => {

  const { client, updateList } = props;  

  // console.log('en cartera show',client)

  const listSearch = [];
  let listShow = [];
  const [switchCommand, setSwitchCommand] = useState(true);
  const [list, setList] = useState([]);
  const [finalList, setFinalList] = useState([]);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {

    setList(client);
    filterData();

  }, [search, switchCommand, client])

  const active = (active) => {
    
    if (active) {      
      updateList(false)
    }if(!active){
      updateList(true);
    }

  }

  const filterData = () => {

    list.forEach(element => {
      if (element.nombre.toLowerCase().includes(search.toLowerCase())) {
        listSearch.push(element);
      }
    })
    return setFinalList(listSearch)
  }


  const add = () => {

    if (count >= 1) {
      setCount(count + 1);
    }
  }
  const subtract = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }
  return (

    <>
      <Container className="mt-4">
        <div className="d-flex justify-content-between" style={{ marginBottom: 10 }}>
          <div className="d-flex">
            <p className="mr-2 text-title-blue"><b>Activos</b></p>
            <Switch onColor='#02dce8' height={18} onChange={() => { setSwitchCommand(!switchCommand); active(!switchCommand) }} checked={switchCommand} />
          </div>

          <input
            style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
            type="text"
            placeholder="  Buscar Usuario"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Table  style={{ width: '98%', borderRadius:50, boxShadow:'6px 10px 10px gray' }} responsive striped bordered hover size="sm">
          <thead>
            <tr >
              <th colSpan="6" className="WidthSubsection"><label  className='text-title-blue'>Sección Créditos</label></th>
              <th colSpan="3"><label className='text-title-blue'>Sección Cuotas</label></th>
              <th colSpan="2"><label className='text-title-blue'>Sección Fechas</label></th>
            </tr>
          </thead>
          <thead className='text-title-blue'>

            <tr>
              <th><label >Estado</label></th>
              <th><label >Nombre Cliente</label></th>
              <th><label >Id Cliente</label></th>
              <th><label>Valor</label></th>
              <th><label>Saldo</label></th>
              <th><label >Saldo Vencido</label></th>
              <th><label >Vencidas</label></th>
              <th><label >Pagadas</label></th>
              <th><label >Restantes</label></th>
              <th><label >Creación</label></th>
              <th><label >Terminación</label></th>
              <th><label >Acciones</label></th>
            </tr>
          </thead>
          <tbody className='text-title-black'>
            {search.trim() == '' ? (list.map((Information, i) => (
              <List
                stateSwich={active}
                updateList={updateList}
                data={Information} key={i} />
            ))
            ) : (finalList.map((data, i) => (
              <List
                stateSwich={active}
                updateList={updateList}
                data={data} key={i} />
            ))
            )}

          </tbody>
        </Table>
      </Container>
    </>

  );
}

export default CarteraShow;