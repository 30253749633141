import React, { PureComponent } from "react";
import '../../../shared/components/sidenav-style.css' 
import { UpdatePassword } from "../user-register/user-updatePassword";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from "react-intl";
import './../../home/about-us/about.css';
import {faAddressCard, faUsers,  faUserEdit} from '@fortawesome/free-solid-svg-icons';
import { UpdateEmail } from "../user-register/user-updateEmail";
import { Pagos } from "./components/pagos";
import { UploadEvidence } from "./components/uploadEvidence";

export class MenuPayments extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            flagColombia:false,
            flagusa: false,
            management:"",
            cUpload:"",
            cAgenda:"",
            statesForm: {},
            views: [],
            step: 0,
            menu: false
        }
    }

    selectView = (index) => {
        const views = [];
        for (let index = 0; index < 5; index++) {
            views.push(false)
        }
        views[index] = true;
        this.setState({ views })
    }

    states = () => {
        let falta = JSON.parse(localStorage.getItem('falta'));
        
        if (falta) {
            this.setState({ statesForm: this.props.estados });
            this.selectView(4)

            if (this.props.estados.estadoPersonal === false) {
                this.setState({ step: 0 });
            } else if (this.props.estados.estadoLaboral === false) {
                this.setState({ step: 1 })
            } else if (this.props.estados.estadoFinanciero === false) {
                this.setState({ step: 2 })
            } else if (this.props.estados.estadoReferencias === false) {
                this.setState({ step: 3 })
            }
        } else {            
            this.selectView(0);
            this.setState({ menu: true });
        }

    }

    componentDidMount = () => {
        this.states();
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                cAgenda: <FormattedMessage id='scheduleP' defaultMessage={"Agendar Cobro"} />,
                cUpload: <FormattedMessage id='evidence' defaultMessage={"Evidencia de pago"} />,
                management: <FormattedMessage id='payments' defaultMessage={"Gestión de pagos"} />,
                flagusa: true
            })
        }else{
            this.setState({
                management: "Gestión de pagos",
                cAgenda:"Agendar Cobro",
                cUpload:"Subir Evidencia",
                flagColombia: true 
            })
        }
    }

    render() {
        return (

            <div style={{ paddingTop: '4.4%' }}>

                {this.state.menu && <div className="sidenav" style={{backgroundColor:'#31DBED', 
                                                                    height:'100%'}}>
                    <header className="text-title-black" style={{backgroundColor:'#31DBED',
                                    
                                    textAlign:'center', 
                                    marginLeft:'10%',
                                    lineHeight:1.3,
                                    marginTop:12,
                                    marginBottom:10}}>
                        <FontAwesomeIcon style={{ marginRight: '8px'}} icon={faUserEdit} />
                        {this.state.management}
                    </header>
                    <ul>
                        <li>
                            <label className="text-body" onClick={() => this.selectView(0)} style ={{color:'white', 
                                                                               textAlign:'start', 
                                                                               borderBottomColor:'white'}} >
                                <FontAwesomeIcon style={{ marginRight: '8px', color:'black' }} icon={faAddressCard} />
                                {this.state.cAgenda}
                            </label>
                        </li>
                        
                        
                        <li>
                            <label className="text-body" onClick={() => this.selectView(2)} style ={{color:'white', 
                                                                               textAlign:'start', 
                                                                               borderBottomColor:'white'}}>
                                <FontAwesomeIcon style={{ marginRight: '10px', color:'black' }} icon={faUsers} />
                                {this.state.cUpload}
                            </label>
                        </li>
                        
                    </ul>

                </div>}
                <div className="main">
                    {this.state.views[0] && <Pagos tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} dataForm={this.props.dataForm} />}
                    
                    {this.state.views[2] && <UploadEvidence upDateList={this.props.upDateList} step={this.state.step} tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} visible={true} dataForm={this.props.dataForm} />}
                </div>
            </div>
        );
    }
};