import React from 'react';
import { ItemDetail } from './item'

export const ListDetail = (props) => {
    const { stateLock, setShowModalToken, setShowModalEditar, movimientos, hijos = [], verHijos, setShowModalMovimientos, startDate } = props;
    return (
        <>
            <div>
                {hijos.map((dataUser, i) => (
                    <ItemDetail
                        startDate={startDate}
                        verHijos={verHijos}
                        setShowModalEditar={setShowModalEditar}
                        setShowModalToken={(flag) => setShowModalToken(flag)}
                        setShowModalMovimientos={(flag) => setShowModalMovimientos(flag)}
                        stateLock={stateLock}
                        movimientos={movimientos}
                        dataUser={dataUser}
                        key={i}
                    />
                )
                )}
            </div>
        </>
    )

}