import React from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { InputForm, ComboBoxForm, DateFormMin, InputMoney } from '../../../../shared/components/inputs';
import Register from '../../../../shared/components/register';
import { FormatDateComplete } from '../../../../utilities/formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { requestPay } from '../../../../services/datos.services';
import { PagoPse } from './pagoPse';
import { ToastContainer, toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

const optionsPay = [
    { title: 'Seleccione ', value: 'seleccione' },
    { title: 'Mañana', value: 'mañana' },
    { title: 'Tarde', value: 'tarde' },
];
export class Pagos extends Register {
    constructor(props) {
        super();
        this.state = {
            showBoton: "",
            latitud: "",
            longitud: "",
            textError: "",
            pagoPse: false,
            flagColombia: false,
            flagusa: false,
            tschedule:"",
            horario: {
                name: 'horario',
                value: "",
                error: '',
                type: "off",
                required: false,
            },
            ubicacion: {
                name: 'ubicacion',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            fechaPago: {
                name: 'fechaPago',
                value: "",
                error: '',
                type: "date",
                required: true
            },
            valor: {
                name: 'valor',
                value: "",
                error: '',
                type: "off",
                required: true,
            },
        }
        this.allOK = true;
        this.inputsToVerify = [
            'ubicacion',
            'fechaPago',
            'horario',
            'valor',
        ];
    }

    componentDidMount = () => {
        this.getLocation();
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                textError: <FormattedMessage id='Einvoice' defaultMessage={"Agendar"} />,
                tschedule: <FormattedMessage id='schedule' defaultMessage={"Agendar"} />,
                flagusa: true
            })
        }else{
            this.setState({
                textError: "NO Tiene factura pendiente por pagar",
                tschedule: "Agendar Cobro",
                flagColombia: true 
            })
        }
    }

    getLocation = () => {

        navigator.geolocation.getCurrentPosition((position) => {

            fetch('http://45.56.122.125/nominatim/reverse?format=json&lat=' +
                position.coords.latitude + '&lon=' +
                position.coords.longitude + '&zoom=18&addressdetails')
                .then((response) => response.json()).then((responseJson) => {
                    // console.log(responseJson)
                    this.setState({
                        ubicacion: {
                            value: responseJson.display_name,
                            name: 'ubicacion',
                            error: '',
                            type: "off",
                            required: true
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });

            this.setState({
                latitud: position.coords.latitude,
                longitud: position.coords.longitude
            })
        });
    };

    solicitarPago = () => {

        if (this.verifyForm()) {
            // this.props.validate(false)
            const uid = localStorage.getItem('partner_id');

            const pay = {
                partner_id: parseInt(uid),
                descripcion: this.state.ubicacion.value + ' ' + this.state.horario.value,
                fecha_pago: FormatDateComplete(this.state.fechaPago.value),
                latitud: this.state.latitud,
                longitud: this.state.longitud,
                valortotal: parseInt(this.state.valor.value),
                fecha_solicitud:FormatDateComplete(this.state.fechaPago.value),
                // horario: this.state.horario.value,
            }
            console.log("esto se va",pay)
            requestPay(pay, (data, flag) => {
                console.log("respuesta",data)
                if (flag) {            
                    if (data.transaccion) {
                        const success = () => toast.success('La solicitud de cobro se realizó con éxito');
                        success();
                        setTimeout(() => {
                            window.location = "/simulador";
                        }, 6000);
                    } else {
                        //let mesage = data.errores['error'];
                        const error = () => toast.error(this.state.textError);
                        error();
                        /* setTimeout(() => {
                            window.location = "/simulador";
                        }, 12000); */
                    }

                }else{
                    const error = () => toast.error("Estamos teniendo problemas en el servidor, inténtalo más tarde");
                        error();
                        /* setTimeout(() => {
                            window.location = "/simulador";
                        }, 12000); */

                }
            });

        }
    }

    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '5% 0% 0% 5%',padding: '1% 0% 2% 0%'}}>
                    <Card style={{ width: '75%', borderRadius:50, boxShadow:'6px 10px 10px black' }}>
                        <Card.Body >
                            <h3 className='text-total' style={{ textAlign:'center'}}>{this.state.tschedule}</h3>
                            <hr />
                            <Form>
                                <Row>
                                    <Col className="col-10">
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='gps' defaultMessage={"Ubicación"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Ubicación</label>}
                                        <InputForm
                                            field={this.state.ubicacion}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <Col className="col-sm-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button style={{ backgroundColor: "white", borderColor: "white", height: '30px', width: '30px', marginLeft: -30, marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={this.getLocation}>
                                            <FontAwesomeIcon style={{ height: '25px', width: '25px', color: "black" }} icon={faMapMarkedAlt} />
                                        </Button>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='paymentdate' defaultMessage={"Fecha de pago"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Fecha de pago</label>}
                                        <DateFormMin
                                            field={this.state.fechaPago}
                                            handleChange={this.handleChange}
                                            type="date"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm >
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='selectTime' defaultMessage={"Horario"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Horario</label>}
                                        <ComboBoxForm
                                            field={this.state.horario}
                                            options={optionsPay}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.horario.value}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm >
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='valuepay' defaultMessage={"Valor a pagar"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Valor a pagar</label>}
                                        <InputMoney
                                            field={this.state.valor}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                            <Col className="container">
                                <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center' }}  className="btn btn-warning" onClick={this.solicitarPago}><label className='text-title-black' style={{marginTop:6}}>{this.state.tschedule}</label></Button>

                            </Col>
                            
                        </Card.Body>
                    </Card>
                    
                    {this.state.pagoPse && <PagoPse showPse={(flag) => this.setState({ pagoPse: flag })} />}
                </div>

            </>
        );
    }
}


