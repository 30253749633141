import React from 'react';
import { Card, Button, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faUserEdit, faUser } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../utilities/formats';


export const ItemMorosos = (props) => {

    const { dataMoroso, setShowModalMorosos, setShowModalEditar } = props

    const editClient = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Editar cliente
        </Tooltip>
    );
    const detalle = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Ver detalle
        </Tooltip>
    );

    return (
        <>
            <style>
                {`
                    .show-btn{
                    height: 20px;
                    width: 20px;
                    margin: 0px 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    }
                    .show-btn:hover {
                    height: 35px;
                    width: 35px;
                    }
            
                `}
            </style>
            <Card style={{ marginBottom: 2 }} >
                <Card.Header style={{ padding: '2%' }}>
                    <Row >
                        {/* <Col className="text-md-right mr-2"> */}
                        {dataMoroso.riesgo === 'bajo' && <FontAwesomeIcon style={{ height: '20px', width: '20px', color: '#53A677' }} icon={faUser} />}
                        {dataMoroso.riesgo === 'medio' && <FontAwesomeIcon style={{ height: '20px', width: '20px', color: '#FFC700' }} icon={faUser} />}
                        {dataMoroso.riesgo === 'alto' && <FontAwesomeIcon style={{ height: '20px', width: '20px', color: '#D9042B' }} icon={faUser} />}
                        {/* </Col> */}
                    </Row>
                    <Row>

                        <Col>
                            <Row >
                                <Col ><label style={{ fontSize: 16 }}>Id Credito: {dataMoroso.id}</label></Col>
                                <Col >
                                    <label style={{ fontSize: 16 }}>Valor Inicial: {dataMoroso.valInicial}</label>
                                </Col>
                                <Col >
                                    <label style={{ fontSize: 16 }}>Vencidas: {dataMoroso.noPagos}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col> <label style={{ fontSize: 16 }}>Cliente: {dataMoroso.cliente}</label> </Col>
                                <Col >
                                    <label style={{ fontSize: 16 }}> Valor vencido: {dataMoroso.valVencido} </label>
                                </Col>
                                <Col >
                                    <label style={{ fontSize: 16 }}>Ultimo pago: {dataMoroso.ultimoPago}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col><label style={{ fontSize: 16 }}>Vendedor: {dataMoroso.vendedor}</label></Col>
                                <Col>
                                    <label style={{ fontSize: 16 }}>Cuotas pagadas: {dataMoroso.pagos}{' '}/{' '}{dataMoroso.totalCuotas}</label>
                                </Col>

                                <Col >
                                    <label style={{ fontSize: 16 }}>Fin de cobro kkkkkkk: {dataMoroso.finPagos}</label>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="1">
                            <Row>
                                <Col className="text-md-right">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 100 }}
                                        overlay={editClient}
                                    >
                                        <Button
                                            className="show-btn"
                                            size="sm"
                                            variant="light"
                                            style={{ borderRadius: 50, width: '40px', height: '40px' }}
                                            onClick={() => setShowModalEditar(true)}
                                        >
                                            <FontAwesomeIcon
                                                style={{ height: '25px', width: '25px' }}
                                                icon={faUserEdit}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                                <Col className="text-md-right">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 100 }}
                                        overlay={detalle}
                                    >
                                        <Button
                                            className="show-btn"
                                            size="sm"
                                            variant="light"
                                            style={{ borderRadius: 50, width: '40px', height: '40px' }}
                                            onClick={() => setShowModalMorosos(true)}
                                        >
                                            <FontAwesomeIcon
                                                style={{ height: '25px', width: '25px', marginTop: 2, marginLeft: -2 }}
                                                icon={faArrowAltCircleRight}
                                            />
                                        </Button>

                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Card.Header>
            </Card >
        </>
    )
}

export const ItemDetailCuotaMoroso = (props) => {

    const { cuotaMoroso } = props

    return (
        <>
            <tr>
                <td>{cuotaMoroso.id}</td>
                <td>{FormatMoney(cuotaMoroso.total)}</td>
                <td>{FormatMoney(cuotaMoroso.saldo)}</td>
                <td>{cuotaMoroso.fecha}</td>
                <td>{cuotaMoroso.estado}</td>
            </tr>
            <tr style={{columnSpan:5}}>
            </tr>
        </>
    )
}

export const ItemDetailPagoMoroso = (props) => {

    const { pagoMoroso } = props

    return (
        <>
            <tr>
                <td>{pagoMoroso.pago_id}</td>
                <td>{pagoMoroso.fecha}</td>
                <td>{FormatMoney(pagoMoroso.valor)}</td>
            </tr>
        </>
    )
}
