
import React from 'react';

const Error = ({warning}) => {
    return ( 
        <div style= {{paddingLeft:15,marginTop:25,width: 260 , color: '#f9f4f4'  , background:'#fa1e1e', fontSize:'14px'}}> 
            <p> <b className='tet-title-black'> {warning} </b></p>
        </div>
        
     );
}
 
export default Error;
