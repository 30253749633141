import React from 'react'


export const PoliciesSecurity = () => {

    return (
        <div style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center',marginTop:'3%'}}>
         
            <div style={{  width: '100%', height: 800, padding:15 }}>
                
            </div>
        </div>
    )
}