import React, { useState } from 'react';
import { Col, Row, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../../utilities/formats';

const DetallesCarterasPagos = (props) => {

    const { pagos } = props;

    const [showCuotas, setShowCuotas] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowCuotas(flag);
        setOpen(flag);
    }

    const balance = (saldos) => {

        let saldo = 0;

        saldos.forEach(element => {
            if (element.valor) {
                saldo += element.valor;
            }
        });

        return FormatMoney(saldo);
    }

    return (
        <Card style={{ width: '1' }}>
            <Card.Header onClick={() => handleOpen(!open)}>
                <Row>
                    <Col>
                        <label className='text-title-black'>Pagos:</label>   <label className='text-body'>{pagos.length}</label> 
                    </Col>
                    <Col>
                        <label className='text-title-black'>Total Pagados:</label>  <label className='text-body'>{balance(pagos)}</label>
                    </Col>
                    <Col className="text-md-right" lg="1">
                        <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                    </Col>
                </Row>
            </Card.Header>

            {open && <Card.Body>
                <Table style={{ width: '95%', borderRadius:30, boxShadow:'6px 10px 10px gray' }}  responsive striped bordered hover size="sm">
                    <thead className='text-title-blue'>
                        <tr>
                            <th><label >Pago</label></th>
                            <th><label>Fecha</label></th>
                            <th><label >Valor Pagado</label></th>
                        </tr>
                    </thead>
                    <tbody className='text-title-black'>
                        {pagos.length > 0 && pagos.map((pago, i) => (
                            <tr key={i}>
                                <td>{++i}</td>
                                <td>{pago.fecha}</td>
                                <td>{FormatMoney(pago.valor)}</td>
                            </tr>
                        )
                        )}

                    </tbody>
                </Table>
            </Card.Body>}
        </Card>
    );
}

export default DetallesCarterasPagos;