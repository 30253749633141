import React, { PureComponent } from "react";
import { RegisterLaboral } from "../user-register/register-laboral";
import { RegisterFinanciera } from "../user-register/register-financiera";
import { RegisterPersonal } from "../user-register/register-personal";
import '../../../shared/components/sidenav-style.css'
import '../../../pages/home/about-us/about.css'
import { RegisterReference } from "../user-register/register-reference"; 
import { AfterRegister } from "../after-register/after-register";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  FormattedMessage } from 'react-intl';
import {faAddressCard, faUsers, faPiggyBank,faBriefcase, faUserEdit} from '@fortawesome/free-solid-svg-icons';

export class UserProfile extends PureComponent {
    constructor(props) {
        super();
        this.state = {
            flagColombia: false,
            flagusa:false,
            statesForm: {},
            views: [],
            step: 0,
            gestion: "",
            personal:"",
            ingresos: "",
            financiera: "",
            referencias:"",
            menu: false
        }
    }

    selectView = (index) => {
        const views = [];
        for (let index = 0; index < 5; index++) {
            views.push(false)
        }
        views[index] = true;
        this.setState({ views })
    }

    states = () => {
        let falta = JSON.parse(localStorage.getItem('falta'));
        
        if (falta) {
            this.setState({ statesForm: this.props.estados });
            this.selectView(4)

            if (this.props.estados.estadoPersonal === false) {
                this.setState({ step: 0 });
            } else if (this.props.estados.estadoLaboral === false) {
                this.setState({ step: 1 })
            } else if (this.props.estados.estadoFinanciero === false) {
                this.setState({ step: 2 })
            } else if (this.props.estados.estadoReferencias === false) {
                this.setState({ step: 3 })
            }
        } else {            
            this.selectView(0);
            this.setState({ menu: true });
        }

    }

    componentDidMount = () => {
        const pais_server =  localStorage.getItem('server');
        if(pais_server === '"eeuu"'){
           this.setState({
            flagusa: true,
            gestion:<FormattedMessage id='informationManagement' defaultMessage={"Gestión de información"}/>,
            personal:<FormattedMessage id='personal' defaultMessage={"Gestión Personal"}/>,
            ingresos:<FormattedMessage id='incomes' defaultMessage={"Gestión Ingresos"}/>,
            financiera:<FormattedMessage id='financial' defaultMessage={"Gestión Financiera"}/>,
            referencias: <FormattedMessage id='references' defaultMessage={"Gestión Referencias"}/>,
           })
        }else{
            this.setState({
                flagColombia: true,
                gestion: "Mi información",
                personal: "personal",
                ingresos: "laboral",
                financiera: "Financiera",
                referencias: "Referencias"
               })
           } 
        this.states();
    }

    render() {
        return (

            <div style={{ paddingTop: '5rem' }}>

                {this.state.menu && <div className="sidenav" style={{height:'100%'}}>
                    <header className="text-title-black">
                        <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faUserEdit} />
                        {this.state.gestion}
                    </header>
                    <ul>
                        <li>
                            <label className="text-body" onClick={() => this.selectView(0)} >
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faAddressCard} />
                                {this.state.personal}
                            </label>
                        </li>
                        <li>
{this.state.flagColombia &&<label className="text-body" onClick={() => this.selectView(1)} >
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faBriefcase} />
                                 Laboral
                            </label>}
{this.state.flagusa &&      <label className="text-body" onClick={() => this.selectView(1)} >
                                <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faBriefcase} />
                                 {this.state.ingresos}
                            </label>}
                        </li>
                        <li>
                            <label className="text-body" onClick={() => this.selectView(3)} >
                                <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faPiggyBank} />
                                {this.state.financiera}
                            </label>
                        </li>
                        <li>
                            <label className="text-body" onClick={() => this.selectView(2)} >
                                <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faUsers} />
                                {this.state.referencias}
                            </label>
                        </li>
                        
                    </ul>

                </div>}
                <div className="main">
                    {this.state.views[0] && <RegisterPersonal tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} dataForm={this.props.dataForm} />}
                    {this.state.views[1] && <RegisterLaboral data={this.props.data} dataForm={this.props.dataForm} />}
                    {this.state.views[2] && <RegisterReference data={this.props.data} dataForm={this.props.dataForm} />}
                    {this.state.views[3] && <RegisterFinanciera data={this.props.data} dataForm={this.props.dataForm} />}
                    {this.state.views[4] && <AfterRegister upDateList={this.props.upDateList} step={this.state.step} tipoDocumentos={this.props.tipoDocumentos} data={this.props.data} visible={true} dataForm={this.props.dataForm} />}
                </div>
            </div>
        );
    }
};