import React, {useState, useEffect} from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import '../../../home/about-us/about.css'
import { ListDetailCuota, ListDetailPagos } from './list';
import { FormattedMessage } from 'react-intl';

export const ModalCuotas = (props) => {
    let[detailoan, setDetailLoan] = useState('')
    const { show, close, id, cuota, pago, saldo } = props

    
    useEffect(()=>{
        let server = localStorage.getItem('server');
        if(server === '"eeuu"'){
            setDetailLoan(<FormattedMessage id='detailoan' defaultMessage={"Detallie de Crédito"} />)
            
        }else{
            setDetailLoan("Detalle de crédito")
           
        }
    },[])
    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 85% !important; }
                `}
            </style>
            <Modal 
                dialogClassName="modal-90w" show={show} onHide={close} centered>
                <Modal.Header style={{borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                    <Modal.Title className='text-total'>{detailoan} {id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <ListDetailCuota cuota={cuota} saldo={saldo}/>                        
                        </Col>
                        <Col>
                            <ListDetailPagos pago={pago} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}