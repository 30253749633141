import React, { useEffect, useState } from 'react';
import { ItemMorosos, ItemDetailCuotaMoroso, ItemDetailPagoMoroso } from './item';
import { Col, Row, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown,faUserAlt, faUser, faUserEdit, faInfo, faTired, faGrinBeam } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../utilities/formats';
import { MorososDetails } from './morososDetails';
import { UpdateCliente } from '../../updateCliente/updateCliente';
import { inactivarUser } from '../../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';

export const ListMorosos = (props) => {

    const { data, setShowModalMorosos, setShowModalEditar } = props;


    return (
        <>
            <div className='container  text-center text-md-left font-small lighten-3 pt-4'>

                {data.map((dataMoroso, i) => (
                    <ItemMorosos
                        setShowModalMorosos={(flag) => setShowModalMorosos(flag)}
                        setShowModalEditar={(flag) => setShowModalEditar(flag)}
                        dataMoroso={dataMoroso}
                        key={i}
                    />
                )
                )}
            </div>
        </>
    )
}

export const ListMoroso = (props) => {

    const { moroso, defaulterList } = props
    
    const [active, setActive] = useState(true);
    const [showModalMorosos, setShowModalMorosos] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [dataUser, setDataUser] = useState({});
    const [detalles, setDetalles] = useState([]);
    const [pagos, setPagos] = useState([]);
    const cliente = useState('');
    const [codigo, setCodigo] = useState('');
    const [valorInicial, setValorInicial] = useState('');

    const inactivar = (uid) => {

        if (window.confirm('¿Desea inactivar al usuario?')) {

            inactivarUser(uid, (data, flag) => {
                if (flag) {
                    defaulterList();
                    const success = () => toast.success("¡El usuario ha sido inactivado!");
                    success();
                } else {
                    const success = () => toast.success("¡El usuario sigue disponible!");
                    success();
                }
            })
            
        } else {
            setActive(true)
        }
    }


    return (
        <>
            <ToastContainer style={{ marginTop: 50, fontSize: 16 }} autoClose={10000} />
            <tr>
                <td>
                    {moroso.diasMora < 1 && <FontAwesomeIcon style={{ height: '20px', width: '20px', color: '#53A677', background:'transparent' }} icon={faUserAlt} />}
                    {moroso.diasMora >= 1 && moroso.diasMora <= 14 && <FontAwesomeIcon style={{ height: '20px', width: '20px', color: '#FFC700' }} icon={faUserAlt} />}
                    {moroso.diasMora >= 15 && <FontAwesomeIcon style={{ height: '20px', width: '20px', border:'#D9042B', color: '#D9042B' }} icon={faUserAlt}  />}
                </td>
                <td>{moroso.codigo}</td>
                <td>{moroso.vendedor}</td>
                <td>{moroso.nombre1 + ' ' + moroso.nombre2 + ' ' + moroso.apellido1 + ' ' + moroso.apellido2}</td>
                <td>{FormatMoney(moroso.valor_inicial)}</td>
                <td>{FormatMoney( moroso.saldo)}</td>
                <td>{FormatMoney(moroso.saldo)}</td>
                <td>{moroso.fin_cobro}</td>
                <td>
                    <button className="show-btn"
                        size="sm"
                        variant="light"
                        style={{ borderRadius: 50,borderColor:'transparent' }}
                        onClick={() => {
                            setShowModalEditar(true);
                            setDataUser({ nombre1: moroso.nombre1, nombre2: moroso.nombre2, apellido1: moroso.apellido1, apellido2: moroso.apellido2, celular: moroso.celular, direccion: moroso.direccion, usuario_id: moroso.usuario_id })
                        }}
                    >
                        <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={faUserEdit}
                        />
                    </button>
                    <button className="show-btn"
                    
                        size="sm"
                        variant="light"
                        style={{ borderRadius: 50,borderColor:'transparent' }}
                        onClick={() => { setValorInicial(moroso.valor_inicial); setPagos(moroso.pago); setDetalles(moroso.detalles); setCodigo(moroso.codigo); setShowModalMorosos(true) }}
                    >
                        <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={faInfo}
                        />
                    </button>
                    <button className="show-btn"
                        disabled={!active ? true : false}
                        size="sm"
                        variant="light"
                        style={{ borderRadius: 50,borderColor:'transparent' }}
                        onClick={() => { setActive(!active); inactivar(moroso.usuario_id) }}
                    >
                        {active && <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={moroso.estado_usuario?faGrinBeam:faTired}
                        />}
                        {!active && <FontAwesomeIcon
                            style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={moroso.estado_usuario?faGrinBeam:faTired}
                        />}
                    </button>
                </td>
            </tr>
            {showModalMorosos && <MorososDetails valorInicial={valorInicial} pagos={pagos} detalles={detalles} codigo={codigo} cliente={cliente} setShowModalMorosos={(flag) => setShowModalMorosos(flag)} />}
            {showModalEditar && <UpdateCliente dataUser={dataUser} setShowModalEditar={(flag) => setShowModalEditar(flag)} />}
        </>
    )
}

export const ListDetailCuotaMoroso = (props) => {

    const { data, valorInicial } = props

    const [showCuotas, setShowCuotas] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowCuotas(flag);
        setOpen(flag);
    }


    return (
        <>

            <Card>
                <Card.Header onClick={() => handleOpen(!open)}>
                    <Row>
                        <Col>
                          <label className='text-title-black'>Valor Credito:</label>  <label className='text-body'>{FormatMoney(valorInicial)}</label> 
                        </Col>
                        <Col>
                        <label className='text-title-black'>Debe:</label> <label className='text-body'>{FormatMoney(data[0].saldo)}</label>
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {showCuotas && <Card.Body>
                    <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                        <thead className='text-title-blue'>
                            <tr>
                                <th><label >Cuota</label></th>
                                <th><label >Valor del crédito</label></th>
                                <th><label>Saldo</label></th>
                                <th><label >Fecha de pago</label></th>
                                <th><label >Estado</label></th>
                            </tr>
                        </thead>
                        <tbody className='text-title-black'>
                            {data.map((cuotaMoroso, i) => (
                                <ItemDetailCuotaMoroso cuotaMoroso={cuotaMoroso} key={i} />
                            )
                            )}
                        </tbody>
                    </Table>
                </Card.Body>}
            </Card>
        </>
    )
}

export const ListDetailPagosMoroso = (props) => {

    const { data } = props

    const [showPagos, setShowPagos] = useState(false);
    const [open, setOpen] = useState(false);
    const [totalPagado, setTotalPagado] = useState(0);

    const handleOpen = (flag) => {
        setShowPagos(flag);
        setOpen(flag);
    }

    useEffect(() => {
        let pago = 0;
        data.forEach(moroso => {
            pago = (pago + moroso.valor);
        });
        setTotalPagado(pago);
    }, [data])


    return (
        <>
            <Card>
                <Card.Header onClick={() => handleOpen(!open)}>
                    <Row>
                        <Col>
                        <label className='text-title-black'>Pagos :</label> <label className='text-body'>{data.length}</label>
                             
                        </Col>
                        <Col>
                        <label className='text-title-black'>Total pagado:</label> <label className='text-body'>{FormatMoney(totalPagado)}</label>
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {showPagos && <Card.Body>
                    <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                        <thead className='text-title-blue'>
                            <tr>
                                <th><label >Pago</label></th>
                                <th><label>Fecha</label></th>
                                <th><label >Valor Pagado</label></th>
                            </tr>
                        </thead>
                        <tbody className='text-title-black'>
                            {data.map((pagoMoroso, i) => (
                                <ItemDetailPagoMoroso pagoMoroso={pagoMoroso} key={i} />
                            )
                            )}
                        </tbody>
                    </Table>
                </Card.Body>}
            </Card>
        </>
    )
}
