
import React, { useContext, useEffect, useState } from 'react';
import { Nav, Navbar, Button, NavDropdown, Image } from 'react-bootstrap';
import { LoginShow } from '../../pages/login/loginShow';
import { Encrypt, Desencrypt } from '../../utilities/cipher';
import { Capitalize } from '../../utilities/formats';
import '../../shared/components/sidenav-style.css'
import logo from './../../assets/venga.png' 
//import logo from '../../assets/logo-vengalepresto.png'
import '../../pages/home/about-us/about.css'
import { useToasts } from 'react-toast-notifications';
import imgSpanish from '../../assets/spain.png'
import imgEnglish from '../../assets/usa.png'
import { getDataFormulario } from '../../services/datos.services';
import { IntroModal } from '../../pages/introModal/introModal';
import { NavLink } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import {langContext} from '../../context/langContext'
import {  FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-bootstrap';

const Header = () => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [viewModalLogin, setViewModalLogin] = useState(false);
    const [viewInfoL, setViewInfoL] = useState(false);
    const [viewInfoR, setViewInfoR] = useState(false);
    const [userLog, setUserLog] = useState(false);
    const [welcomeInfo, setWelcomeInfo] = useState('');
    const [userName, setUserName] = useState('preparent');
    const [disabled, setDisable] = useState(true);
    let [colombia, setColombia] = useState(false)
    let [usa, setUsa] = useState(false)
    let [textRegister,setTextRegister] = useState('')
    let [textLogin,setTextLogin] = useState('')
    let [textLogout,setTextLogout] = useState('')
    let [textSecurity,setTextSecurity] = useState('')
    let [tperfil,setTperfil] = useState('')
    let [tpay,setTpay] = useState('')
    let [tclose,setTclose] = useState('')
    let [thistory,setThistory] = useState('')
    let [infoerrorlogin,setInfoErrorLogin] = useState('')
    let [infoclosedsession,setInfoClosedSession] = useState('')
    const [vendedor, setVendedor] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [cliente, setCliente] = useState(false);
    const [viewModalFirst, setViewModalFirst] = useState(false);
    const FB_PIXEL_ID = "313798497539699";
    const language = useContext(langContext)

    const { addToast } = useToasts();
    const facebookPixel = async () => {
            import("react-facebook-pixel")
              .then((x) => x.default)
              .then((ReactPixel) => {
                ReactPixel.fbq();
                ReactPixel.init(FB_PIXEL_ID);
                ReactPixel.pageView();
              });
          
          return null;

    }
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollHeight(position);
      };
    
      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
      }, [scrollHeight]);

    const initialiceData = async () => {
        let server = localStorage.getItem("server");
        if(server=== '"eeuu"'){
            setUsa(true)
            setTclose(<FormattedMessage id='close' defaultMessage={"Sesión Cerrada"}/>)
            setInfoErrorLogin(<FormattedMessage id='error2' defaultMessage={"Correo o contraseña no válida"}/>)
            setThistory(<FormattedMessage id='creditHistory' defaultMessage={"Historial de Crédito"} />)
            setTpay(<FormattedMessage id='payments' defaultMessage={"Pagos"} />)
            setTperfil(<FormattedMessage id='profileSettings' defaultMessage={"Configuración de Perfil"}  />)
            setTextSecurity(<FormattedMessage id='security' defaultMessage={"Seguridad"}/>)
            setTextRegister(<FormattedMessage id='checkin' defaultMessage={"Registrarse"}/>)
            setTextLogin(<FormattedMessage id='login' defaultMessage={"Iniciar Sesión"} />)
            setTextLogout(<FormattedMessage id='logout' defaultMessage={"Cerrar Sesión"}/>)
        }else{
            setColombia(true);
            setInfoErrorLogin("Usuario y/0 contraseña nó válidos.")
            setTclose("Sesión Cerrada")
            setThistory("Histórico de Crédito")
            setTpay("Gestión de Pagos")
            setTperfil("Actualización de datos (Mi información)")
            setTextSecurity("Actualización de usuario")
            setTextRegister("Registrarse")
            setTextLogin("Iniciar Sesión")
            setTextLogout("Cerrar Sesión")
        }

        let initialModal = sessionStorage.getItem('initialModal');

        const data = await Desencrypt();
        if (data) {
            setUserName(data.user);
            setUserLog(true);
            setWelcomeInfo(" " + Capitalize(data.user) + " " )
        } else {
            initialModal ? setViewModalFirst(false) : setViewModalFirst(true);
            setUserName('');
            setWelcomeInfo("Menu")
        }
        if (localStorage.getItem('Permission') === "vendedor") {
            setVendedor(true);
            setAdmin(false);
            setCliente(false);
        }
        if (localStorage.getItem('Permission') === "administrador") {
            setVendedor(false)
            setAdmin(true);
            setCliente(false);
        }
        if (localStorage.getItem('Permission') === "cliente") {
            setVendedor(false)
            setAdmin(false)
            setCliente(true)
        }
    }

    useEffect(() => {
       
        facebookPixel();
        initialiceData();


        const uid = localStorage.getItem('uid');
        if (uid) {

            getDataFormulario(parseInt(uid), (data, flag) => {
                if (flag) {
                    localStorage.setItem('partner_id', data.res_partner_id[0].id);
                    localStorage.setItem('nameUser', data.res_partner_id[0].nombre1 + ' ' + data.res_partner_id[0].nombre2 +
                        ' ' + data.res_partner_id[0].apellido1 + ' ' + data.res_partner_id[0].apellido2);
                }
            })
        }
    }, [])

    const validate = (flag) => {
        setDisable(flag)
        return disabled
    }
    const pageview = () => {
        window.fbq('track', 'PageView')
      }

    const login = (data, flag) => {
        if (flag) {
            if (data === "vendedor") {
                addToast('No se puede ingresar como Vendedor', { appearance: 'warning' })
            } else if (data.permiso === "cliente") {
                let messagge = '¡ Welcome to VENGALEPRESTO ' + data.user.user.toUpperCase() + ' !';
                addToast(messagge, { appearance: 'success' })
                Encrypt(data.user);
                setUserName(data.user.user);
                setUserLog(true);
                setViewModalLogin(false);
                setViewModalFirst(false)
                setTimeout(() => {
                    window.location.href = "/simulador";
                }, 750);
            } else {
                
                let messagge = '¡ WELCOME TO VENGALEPRESTO' + data.user.user.toUpperCase() + ' !';
                addToast(messagge, { appearance: 'success' })
                Encrypt(data.user);
                setUserName(data.user.user);
                setUserLog(true);
                setViewModalLogin(false);
                setViewModalFirst(false)
                setTimeout(() => {
                    window.location.href = "/principal-caja";
                }, 750);
            }
        } else {
            addToast(infoerrorlogin, { appearance: 'error' })
        }
    }

    const closeSession = () => {
        localStorage.clear();
       
        window.location = "*";
        setUserLog(false);
        addToast(tclose, { appearance: 'warning' });
        setVendedor(false);
        setAdmin(false);
        setCliente(false);
        
    }

    return (
        
        <>
        <script id="facebook-pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${FB_PIXEL_ID});
        fbq('track', 'PageView');
      `}
    
      
    </script>
       
            <style>
                {`
                    .nav {           
                        z-index: 1000;
                        padding: 0px 20vw 0px 15vw;
                        position: absolute;
                        top: 0;
                        width: 100%;
                        
                    }
                    .modal-90w { max-width: 72% !important; }
                `}
            </style>
            <Col md={12} xs={12} sm={12} lg={12}>
                <Navbar
                    className="nav"
                    collapseOnSelect
                    expand="lg"
                    variant="light">
                    <Navbar.Brand >
                    
                        <Image
                            src={logo}
                            style={{ width: 70, height: 70, marginBottom: '0%', marginTop: '20%' }}
                            onClick={() => window.location = "/"}
                        />
                    
                        
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:'white'}}/>  
                    
                    
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {cliente &&
                            <Nav className="mr-auto">
                            <a className='nav-link azul_login' style={{ fontSize: 20, marginLeft: '500px'}} href="/simulador">Solicitar Crédito</a>

                            </Nav>
                        }
                        <Nav className="ml-auto" id="responsive-navbar-nav" >
                            {userLog && cliente === true &&
                                <b >
                                    <NavDropdown  title={<label className='nav-link azul_login'>{welcomeInfo}</label>} style={{ fontSize: 20, paddingLeft: '5px',color:'white'}} id="basic-nav-dropdown">
                                        <div style={{ paddingLeft: 5 }}>
                                            <NavLink className='nav-link text-title-black' to="/credits-history"> 
                                        
                                            {thistory}
                                            </NavLink>
                                            <NavLink className='nav-link text-title-black' to="/pays">
                                            {tpay}
                                            </NavLink>
                                            <NavLink className='nav-link text-title-black' to="/user-profile">
                                            {tperfil}
                                            </NavLink>
                                            <NavLink className='nav-link text-title-black' to="/menu-security">
                                            {textSecurity}
                                            </NavLink>
                                        </div>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            href="/" onClick={() => { closeSession() }}>
                                        
                                        <label className='text-title-black'>{textLogout} </label> 
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </b>
                            }
                            {/* 
                                Admin 
                            */}
                            {userLog && admin === true &&
                                <b>
                                    <NavDropdown title={welcomeInfo} style={{ fontSize: 20, paddingLeft: '5px' }} id="basic-nav-dropdown">
                                        <div style={{ paddingLeft: 5 }}>
                                            <NavLink className='nav-link text-title-black' to="/principal-caja">  Manejo De Cajas</NavLink>
                                            <NavLink className='nav-link text-title-black' to="/morosos">  Morosos</NavLink>
                                            <NavLink className='nav-link text-title-black' to="/cartera">  Cartera</NavLink>
                                            <NavLink className='nav-link text-title-black' to="/solicitudes">  Solicitudes</NavLink>
                                            <NavLink className='nav-link text-title-black' to="/reportes">  Reportes</NavLink>
                                            <NavLink className='nav-link text-title-black' to="/control-usuario"> Control de Usuarios</NavLink>
                                        </div>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            href="/" onClick={() => { closeSession() }}>
                                            <label className='text-title-black'>{textLogout} </label> 
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </b>
                            }
                            {userLog && vendedor === true &&
                                <b>
                                    <NavDropdown title={welcomeInfo} style={{ fontSize: 20, paddingLeft: '50px' }} id="basic-nav-dropdown">
                                        <NavDropdown.Item
                                            href="/" onClick={() => { closeSession() }}>
                                        <label className='text-title-black'>{textLogout} </label> 
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </b>
                            }
                            
                                {!userLog && userName === '' &&
                                    
                                        <Button 
                                            style={{
                                                backgroundColor: 'rgba(138,221,45,0)',
                                                color: 'black',
                                                border: 'none',
                                                fontSize: 20
                                            }}
                                            className='nav-link azul'
                                            onClick={() => { setViewModalLogin(true); setViewInfoL(true); setViewInfoR(false) }}
                                        >
                                            <text className='text-session'>{textLogin}</text>
                                        </Button>  
                                
                                    
                                }
                                {!userLog && userName === '' &&
                                    
                                        <Button
                                            style={{
                                                fontFamily:'Monserrat',
                                                backgroundColor: 'rgba(138,221,45,0)',
                                                color: 'black',
                                                border: 'none',
                                                fontSize: 20,
                                                marginLeft:'5%'
                                            }}
                                            className='nav-link azul'
                                            onClick={() => { setViewModalLogin(true); setViewInfoR(true); setViewInfoL(false) }}
                                        >
                                            <text className='text-session' style={{fontSize:'2.3ch'}}>{textRegister}</text>
                                        </Button>  
                                    
                                }
                            
                            
                            {usa &&<Button
                                    style={{
                                        backgroundColor: '000',
                                        color: 'black',
                                        border: 'none',
                                        fontSize: 20
                                    }}
                                    className='nav-link'
                                    onClick={() => {
                                        language.changeLanguage("en-US")
                                    }} 
                                ><img width="40" height="30" src={imgEnglish}></img>
                                </Button>}
                                {usa &&<Button
                                    style={{
                                        backgroundColor: 'rgba(138,221,45,0)',
                                        color: 'black',
                                        border: 'none',
                                        fontSize: 20
                                    }}
                                    className='nav-link'
                                    onClick={() => {
                                        language.changeLanguage("es-ES")
                                    }} 
                                ><img  width="40" height="30" src={imgSpanish}></img>
                                </Button>}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Col>
            
            {viewModalLogin &&
                <LoginShow
                    viewModalLogin={(flag) => setViewModalLogin(flag)}
                    onLogin={(resp, flag) => { login(resp, flag) }}
                    viewInfoL={viewInfoL}
                    viewInfoR={viewInfoR}
                    validate={validate}
                />
            }
            {viewModalFirst &&
                <IntroModal />
            }
        </>
    )
}

export default Header;