import React from 'react';
import { Row,Col } from 'react-bootstrap';
import { ItemReport } from './itemReport';


export const ListReport = (props) => {
    const { sons, startDate,endDate } = props
    return (
        <>
        {sons.map((item, i) => (
                                <ItemReport startDate={startDate} endDate={endDate} item={item} key={i} />
                            )
                            )}
        </>
    )
}