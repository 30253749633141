import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Toast } from 'react-bootstrap';
import Format from './images/format.jpg';
import Coins from './images/Coins.jpg';
import Place from './images/Place.jpg'; 
import { useLocation } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { activarUsuario } from '../../../services/datos.services';
import { FormattedMessage } from 'react-intl';

const LandWeb = () => {
    const [succCuenta, setSuccCuenta] = useState("Tu cuenta está siendo activada, en un momento podrás acceder a VENGALEPRESTO.")
    let [errCuenta, setErrCuenta] = useState("Server Error")


    const location = useLocation();

    const activarUsuarioMetodo = () => {
        let server = localStorage.getItem("server");
        console.log("el servidor",server)
        if(server=== '"eeuu"'){
            console.log("Hola, estoy aqui")
            
             setSuccCuenta(<FormattedMessage id='succCuenta' defaultMessage={"SuccCuenta"}/>) 
            setErrCuenta(<FormattedMessage id='serverError' defaultMessage={"Server Error"}/>)
        }else{
            setSuccCuenta("¡ Tu cuenta esta siendo activada, en un momento podras acceder a VENGALEPRESTO !")
        }

        let valor = location.search.split('a=b%27')[1];
        let llave = location.search.split('b=b%27')[1];

        valor = valor.split('%27')[0];
        llave = llave.split('%27')[0];

        let cadena = {            
            llave: llave,
            valor: valor.split('&')[0],
        }
        activarUsuario(cadena,(data,flag)=>{
            if(flag){   
                const success = () => toast.success(succCuenta);
                success();             
                setTimeout(() => {
                    //localStorage.clear();
                    window.location = "/";
                    }, 10000);
            }else{
                const error = () =>  toast.error(errCuenta);
                error();
                
            }           
        })
    }
    useEffect(() => {
        activarUsuarioMetodo()
    }, [])

    return (
        <>
        <ToastContainer style={{marginTop:50, fontSize:16}}  autoClose={10000}/>
         <Container>
            <Row>
                <Col className="col-12">
                    <div className="Welcome">
                        <div className="Text">
                            {/* <h1><b>Bienvenido a VENGALEPRESTO</b></h1>
                            <h2><b>Siéntete como en casa</b></h2> */}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5 Num">
                <Col className="col-12 col-sm-4">
                    <img width="80%" height="95%" src={Place} />
                    {/* <p>Podrás solcitar el crédito que necesites en cualquier lugar</p> */}
                </Col>
                <Col className="col-12 col-sm-4">
                    <img width="80%" height="95%" src={Format} />
                  {/*   <p>Tienes la posibilidad de acceder a un crédito sin necesidad de tanto papeleo y documentación como en las entidades bancarias.</p> */}
                </Col>
                <Col className="col-12 col-sm-4">
                    <img width="80%" height="95%" src={Coins} />
                   {/*  <p>Te ayudamos con eficiencia y rapidez para tus soluciones inmediatas</p> */}
                </Col>
            </Row>
            <Row>
                <Col className="col-12 ero ">
                    <div className="ExCol mt-5 pt-5 mb-5">
                      {/*   <h3><b>Ayudando a nuestros clientes con soluciones inmediatas</b></h3> */}
                        <hr />
                        <p>
                           {/*  Al momento de utilizar nuestro servicio deseamos que recuerdes lo siguiente: */}
                        </p>
                        <p>
                           {/*  *Debes ser mayor de edad, tener línea telefónica propia, poseer un E-mail personal y poseer una cuenta bancaria a tu nombre para poder acceder a un crédito. */}
                        </p>

                        <p>
                          {/*   *No te pedimos cuenta crediticia, sin embargo, se realiza un análisis de tu estado financiero a través de diferetes aliados para determinar tu aprobación de crédito. */}
                        </p>

                        <p>
                           {/*  *Si el crédito no es aprobado, puedes realizar la solicitud nuevamente a partir del prmer día del calendario del mes siguiente al de la solicitud inicial. */}
                        </p>


                    </div>
                </Col>
            </Row>
        </Container>
        </>
        
    );
}

export default LandWeb;