import React, { useState } from 'react';
import { ListDetail } from './list'
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WalletUserDetails } from './wallet-user-details';
import { UpdateCliente } from '../../updateCliente/updateCliente';

export const DetalleCartera = (props) => {
    const { data } = props;


    const [showDetalleCartera, setShowDetalleCartera] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);

    return (
        <>

            <div className='text-center text-md font-small'>
            <h5>CARTERA HIJOS</h5>
                <div className="row">
                    <div className=" col-lg ">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30, fontSize: 16 }}>
                            <ListDetail
                                showDetalleCartera={(flag) => setShowDetalleCartera(flag)}
                                setShowModalEditar={(flag) => setShowModalEditar(flag)}
                                data={data} />
                        </div>
                    </div>
                </div>
            </div>
            {showDetalleCartera && <WalletUserDetails data={data} showDetalleCartera={(flag) => setShowDetalleCartera(flag)} />}
            {showModalEditar && <UpdateCliente setShowModalEditar={(flag) => setShowModalEditar(flag)} />}
        </>
    )
}

