import { data } from 'jquery';
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { getCreditsAdmin } from '../../../../services/datos.services';
import CarteraShow from '../newComponents/carteraShow';
import {  FormatMoney } from '../../../../utilities/formats';
import GridLoader from 'react-spinners/GridLoader';

export const WalletUserDetails = (props) => {

    const { showClient } = props;
    

    const handleClose = () => {
        showClient(false)
    }

    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 95% !important; }
                `}
            </style>


            <Modal dialogClassName="modal-90w" centered show={true} onHide={handleClose} >

                <Modal.Header closeButton>
                    <Modal.Title> Detalle de cuotas </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <CarteraShow />
                </Modal.Body>
            </Modal >
        </>

    );
}

export const WalletClient = (props) => {

    const { showClient, uid, nameSheller } = props;
    const [client, setClient] = useState([]);
    const [loading,setLoading] = useState(false)
    const [inactivos, setInactivos] = useState(false);
    const [saldoT, setSaldoT] = useState(1);
    const handleClose = () => {
        showClient(false)
    }

    const updateList = (isActive) => {
        setLoading(true)

        getCreditsAdmin(uid, (data, flag) => {
            let saldo = 0;
            setLoading(false)
            if (data.length > 0) {
                let listClient = [];
               

                data.forEach((element, i) => {
                    
                    let cliente = {                        
                        nombre: `${element.nombre1 ? element.nombre1 : ''} ${element.nombre2 ? element.nombre2 : ''} ${element.apellido1 ? element.apellido1 : ''} ${element.apellido2 ? element.apellido2 : ''}`,
                        id: element.id,
                        usuario_id: element.usuario_id,
                        nombre1: element.nombre1 ? element.nombre1 : '',
                        nombre2: element.nombre2 ? element.nombre2 : '',
                        apellido1: element.apellido1 ? element.apellido1 : '',
                        apellido2: element.apellido2 ? element.apellido2 : '',
                        direccion: element.direccion?element.direccion:'',
                        celular: element.celular?element.celular:'',
                        estado: element.estado ? element.estado : '',
                        estado_usuario: element.estado_usuario,
                        valor_inicial: element.valor_inicial ? element.valor_inicial : 0,
                        saldo: element.saldo ? element.saldo : 0,
                        saldo_vencido: element.saldo_vencido ? element.saldo_vencido : 0,
                        fecha_vencimiento: element.fecha_vencimiento ? element.fecha_vencimiento : '--/--/--',
                        fecha: element.fecha ? element.fecha : '--/--/--',
                        cuotas_pagas: element.cuotas_pagas ? element.cuotas_pagas : 0,
                        cuotas_vencidaspagas: element.cuotas_vencidaspagas ? element.cuotas_vencidaspagas : 0,
                        detalle: element.detalles,
                        pago: element.pagos
                    }
                    listClient.push(cliente)
                });
                setClient(listClient);

           
            listClient.forEach((element, i) => {
                saldo += element.saldo;

                });
                setSaldoT(saldo);
                
            }else{
                setClient([]);
                    setSaldoT(saldo);
            }
        },isActive)

    }

    useEffect(() => {
        setLoading(true)
        updateList(false)
    }, []);

    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 90% !important; }
                `}
            </style>

            <Modal dialogClassName="modal-90w" centered show={true} onHide={handleClose} >
                <Modal.Header style={{borderBottomWidth:3,borderBottomColor:'#02dce8'}} closeButton>
                    <Modal.Title className='text-total'> Listado de clientes de: {nameSheller}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <Row className="justify-content-md-center">
                        <Col md={2}><label className='text-title-black'>Total creditos: {client.length}</label> </Col>
                        <Col md={2}><label className='text-title-black'>Total saldo: {FormatMoney(saldoT)}</label> </Col>
                    </Row>
                    <GridLoader  size={50} loading={loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
                    <CarteraShow updateList={updateList} client={client} />
                </Modal.Body>
            </Modal >
            
        </>

    );
}