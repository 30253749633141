import React, { useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import HomeComponent from './pages/home/home';
import { Requirements } from './pages/home/requirements/requirements';
import { FrequentQuestions } from './pages/home/frequentQuestions/frequentQuestions';
import { AboutUs } from './pages/home/about-us/about-us';
import { StepByStep } from './pages/home/step-by-step/step-by-step';
import { UserProfile } from './pages/client/user-profile/user-profile';
import { MenuSecurity } from './pages/client/user-profile/menu-security';
import { MenuPayments } from './pages/client/movements/menu-payments';
import { CreditsHistory } from './pages/client/credits-history/credits-history';
import { AfterRegister } from './pages/client/after-register/after-register';
import { Desencrypt } from './utilities/cipher';
import { LoginShow } from './pages/login/loginShow';
import { Pagos } from './pages/client/movements/components/pagos';
import { PoliciesSecurity } from './shared/components/policiesSecurity';
import { UpdateCliente } from './pages/admin/updateCliente/updateCliente';
import { Token } from './pages/admin/token/token';
import { PrincipalCaja } from './pages/admin/principalCaja/principalCaja';
import { Morosos } from './pages/admin/morosos/morosos';
import { Cartera } from './pages/admin/cartera/cartera';
import { DetalleCartera } from './pages/admin/cartera/components/wallet-detail';
import { UserController } from './pages/admin/user-controller/user-controller';
import { getDataFormulario, getParametros } from './services/datos.services';
// Recuperar contraseña
import { ForgetPassword } from './shared/components/forget-password/forget.password';
import { DebtCalculator } from './pages/debt-calculator/deb-calculator';
import { Politicas } from './shared/components/politicas';
import { Terminos } from './shared/components/terminos';
import ResetPassword from './shared/components/forget-password/resetPassword/resetPassword';
import LandWeb from './shared/components/landWeb/landWeb';
import CarteraShow from './pages/admin/cartera/newComponents/carteraShow';
import PassedData from './shared/components/RecoverDataForm/PassedData';
import { Solicitudes } from './pages/admin/solicitudes/solicitudes';
import {Informs} from './pages/admin/reportes/reportes'
import { SummaryCredit } from './pages/debt-calculator/components/summary-credit';
import { MisionAndVision } from './pages/home/misionAndVision';
const Routes = (props) => {
    const { formData } = props;
    
    localStorage.setItem('conceptos', JSON.stringify(formData.concepto_ids));

    const [dataForm, setDataForm] = useState({});
    const [simulator, setSimulator] = useState({});
    const [states, setStates] = useState({});
    const [tipodocumento, setTipodocumento] = useState({});
    const data = Desencrypt();


    useEffect(() => {
        if (formData.permiso) {
            const session = localStorage.getItem('Permission');

            if (session && formData.permiso !== "administrador") {
                const uid = localStorage.getItem('uid');

                getDataFormulario(parseInt(uid), (data, flag) => {                               
                
                    if (data) {
                        setDataForm(data.res_partner_id[0]);
                        let estados = {
                            nameUser: data.res_partner_id[0].nombre1 + ' ' + data.res_partner_id[0].nombre2 + ' ' + data.res_partner_id[0].apellido1 + ' ' + data.res_partner_id[0].apellido2,
                            estadoPersonal: data.res_partner_id[0].estadopersonal,
                            estadoLaboral: data.res_partner_id[0].estadofinanciero,
                            estadoFinanciero: data.res_partner_id[0].estadolaboral,
                            estadoReferencias: data.res_partner_id[0].estadoreferencias
                        }
                        setStates(estados)
                    }
                })
                getParametros((data, flag) => {              
                    if (data) {       
                        if (flag) {
                            setSimulator({
                                MaxValorPrestamo: data.MaxValorPrestamo,
                                MaxValorRenovacion: data.MaxValorRenovacion,
                                MinValorPrestamo: data.MinValorPrestamo,
                                MinValorRenovacion: data.MinValorRenovacion,
                                administracion: data.administracion,
                                interes: data.interes,
                                iva: data.iva,
                                payment_term_ids: data.payment_term_ids,
                                porcentaje_ids: data.porcentaje_ids,
                                tecnologia: data.tecnologia,
                                terminosprestamo: data.terminosprestamo
                            })
                            setTipodocumento(data['tipodocumento_ids'])
                        }
                    }
                })
            }
        }
    }, [formData]);

    const upDateList = (list) => { 
        setDataForm(list);
    }
    
    if (data) {
        return (
            <Switch>
                <Route exact path='/' component={()=><HomeComponent simulator={simulator} estados={states} />} />
                {/* Rutas de cliente */}
                <Route exact sensitive path='/#quienes-somos' component={AboutUs} />
                <Route exact sensitive path='/#requirements' component={Requirements} />
                <Route exact sensitive path='/#como-funciona' component={StepByStep} />
                <Route exact sensitive path='/#misionAndVision' component={MisionAndVision} />
                <Route exact sensitive path='/#preguntasFrecuentes' component={FrequentQuestions} />
                
                <Route exact sensitive path='/simulador' component={() =>
                    <div style={{ paddingTop: '7%', display:'flex', justifyContent:'center', alignItems:'center' }} > < DebtCalculator simulator={simulator} estados={states} /> </div>} />
                <Route exact sensitive path='/user-profile' component={() =>
                <UserProfile tipoDocumentos={tipodocumento} upDateList={(list) => upDateList(list)} estados={states} data={formData} dataForm={dataForm} />} />
                 <Route exact sensitive path='/menu-security' component={() =>
                <MenuSecurity tipoDocumentos={tipodocumento} upDateList={(list) => upDateList(list)} estados={states} data={formData} dataForm={dataForm} />} />
                <Route exact sensitive path='/pays' component={() =>
                <MenuPayments tipoDocumentos={tipodocumento} upDateList={(list) => upDateList(list)} estados={states} data={formData} dataForm={dataForm} />} />
                <Route exact sensitive path='/pagos' component={Pagos} />
                <Route exact sensitive path='/credits-history' component={()=><CreditsHistory saldoTotal={formData.saldo}/>} />
                <Route exact sensitive path='/after-register' component={() => <AfterRegister />} />
                <Route exact sensitive path='/loginShow' component={LoginShow} />
                <Route exact sensitive path='/summary-credit' component={SummaryCredit} />
                
                {/*Rutas del Admin*/}
                <Route exact sensitive path='/updateClient' component={UpdateCliente} />
                <Route exact sensitive path='/token' component={Token} />
                <Route exact sensitive path='/principal-caja' component={PrincipalCaja} />
                <Route exact sensitive path='/morosos' component={Morosos} />
                <Route exact sensitive path='/cartera' component={Cartera} />
                <Route exact sensitive path='/solicitudes' component={Solicitudes} />
                <Route exact sensitive path='/reportes' component={Informs} />

                {/* No olvidar que esta parte es nueva */}

                <Route exact sensitive path='/newCartera' component={CarteraShow}/>
                <Route exact sensitive path='/detalle-cartera' component={DetalleCartera} />
                <Route exact sensitive path='/control-usuario' component={UserController} />
                {/*Enlaces de acceso externo */}
                <Route exact path="/policies" component={Politicas} />
                <Route exact path="/policiesSecurity" component={PoliciesSecurity} />
                <Route exact path="/terms-and-conditions" component={Terminos} />

                {/*Contraseña olvidada*/}
                <Route exact sensitive path='/resetPassword/' component={ResetPassword} />
                {/*Firmar contrato*/}
                <Route exact sensitive path='/firmarContrato/' component={PassedData} />
            </Switch>
        )
    } else {
        return (
            <Switch>                
                <Route exact sensitive path='/forgetPassword' component={ForgetPassword} />
                <Route exact sensitive path='/resetPassword/' component={ResetPassword} />
                <Route exact sensitive path='/landWeb/' component={LandWeb} />
                <Route exact sensitive path='/firmarContrato/' component={PassedData} />
                <Route exact path="/policies" component={Politicas} />
                <Route exact path="/policiesSecurity" component={PoliciesSecurity} />
                <Route exact path="/terms-and-conditions" component={Terminos} />
                <Route exact path="*" component={HomeComponent} />
            </Switch>
        )
    }
}

export default withRouter(Routes);