import React from 'react'
import Register from '../../../shared/components/register';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import './../../../pages/home/about-us/about.css'
import { InputForm, RadioForm, ComboBoxForm, InputNumber } from '../../../shared/components/inputs';
import { subirClientes } from '../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';
import {  FormattedMessage } from 'react-intl';

// opcion radio buttons.
export class RegisterLaboral extends Register {
    constructor(props) {
        super();
        this.data = props.data;
        console.log("propiedades ids",props.dataForm.cargo_id,props.dataForm.eps_id)
        this.state = {
            succLaboral:"",
            showBoton: true,
            comboCotizaSeguridadSocial: {
                name: 'comboCotizaSeguridadSocial',
                value: props.dataForm.seguridadSocial ? props.dataForm.seguridadSocial : '',
                error: '',
                type: "off",
                required: true
            },
            nameEmpresa: {
                name: 'nameEmpresa',
                value: props.dataForm.nombre_empresa ? props.dataForm.nombre_empresa : '',
                error: '',
                type: "off",
                required: true
            },
            nameComercio: {
                name: 'nameComercio',
                value: props.dataForm.nombre_comercio ? props.dataForm.nombre_comercio : " ",
                error: '',
                type: "off",
                required: true
            },
            descripcionIngresos: {
                name: 'descripcionIngresos',
                value: props.dataForm.descripcion_ingreso ? props.dataForm.descripcion_ingreso : '',
                error: '',
                type: "off",
                required: true
            },
            nameDireccionEmpresa: {
                name: 'nameDireccionEmpresa',
                value: props.dataForm.direccion_empresa ? props.dataForm.direccion_empresa : '',
                error: '',
                type: "off",
                required: true
            },
            nameDireccionComercio: {
                name: 'nameDireccionComercio',
                value: props.dataForm.direccion_comercio ? props.dataForm.direccion_comercio : '',
                error: '',
                type: "off",
                required: true
            },
            postalEmpresa: {
                name: 'postalEmpresa',
                value: props.dataForm.codigo_postal_empresa ? props.dataForm.codigo_postal_empresa : '',
                error: '',
                type: "off",
                required: true
            },
            nameTelefonoEmpresa: {
                name: 'nameTelefonoEmpresa',
                value: props.dataForm.telefono_empresa ? props.dataForm.telefono_empresa : '',
                error: '',
                type: "phone",
                required: true
            },
            comboEps: {
                name: 'comboEps',
                value: props.dataForm.eps_id ? props.dataForm.eps_id : 0,
                error: '',
                type: "off",
                required: true
            },
            comboCargo: {
                name: 'comboCargo',
                value: props.dataForm.cargo_id ? props.dataForm.cargo_id : 0,
                error: '',
                type: "off",
                required: true
            },
            comboComercio: {
                name:'comboComercio',
                value: props.dataForm.nombre_comercio ? 'si' : 'no',
                error: '',
                type: "off",
                required: true
            },
            comboFuente: {
                name: 'comboFuente',
                value: props.dataForm.fuenteingreso_id ? props.dataForm.fuenteingreso_id : '',
                error: '',
                type: "off",
                required: true
            },
            comboTipoContrato: {
                name: 'comboTipoContrato',
                value: props.dataForm.tipo_contrato_id === 'False' ? 0 : props.dataForm.tipo_contrato_id,
                error: '',
                type: "off",
                required: true
            },
            nameAntiguedad: {
                name: 'nameAntiguedad',
                value: props.dataForm.antiguedad_id ? props.dataForm.antiguedad_id : '',
                error: '',
                type: "off",
                required: true
            },
            formaPago: {
                name: 'formaPago',
                value: '',
                error: '',
                type: "off",
                required: false
            },
            tiempoIngreso: {
                name: 'tiempoIngreso',
                value: props.dataForm.tiempo_ingreso  ?  props.dataForm.tiempo_ingreso : '',
                error: '',
                type: "off",
                required: true
            },

            nameIngresos: {
                name: 'nameIngresos',
                value: props.dataForm.ingresos ? props.dataForm.ingresos : '',
                error: '',
                type: "off",
                required: true
            },
            radioTipoEmpresa: {
                name: 'radioTipoEmpresa',
                value: props.dataForm.tipo_empresa ? props.dataForm.tipo_empresa : '',
                error: '',
                type: "off",
                required: true
            },
            radioPeriodoNomina: {
                name: 'radioPeriodoNomina',
                value: props.dataForm.periodo_nomina ? props.dataForm.periodo_nomina : '',
                error: '',
                type: "off",
                required: true
            },
            ciudad: {
                name: 'ciudad',
                value: props.dataForm.municipio_id ? props.dataForm.municipio_id : '',
                error: '',
                type: "off",
                required: true
            },
            departamento: {
                name: 'departamento',
                value: props.dataForm.departamento_id ? props.dataForm.departamento_id : '',
                error: '',
                type: "off",
                required: true
            },
            optionsComercio:[{title:"Seleccione una opción",value:"seleccione"},{title:"SI",value:"si"},{title:"NO",value:"no"}],
            isIndependent:false,
            flagColombia:false,
            flagusa:false,
            flagDependiente: false,
            flagDescripcion: false,
            optionsTipoEmpresa: [],
            optionsPeriodoNomina: [],
            optionsCargo: [],
            optionsFuente: [],
            optionsContrato: [],
            optionsSeguridadSocial: [],
            optionsDepartamento: [],
            optionsMunicipio: [],
            optionsEps: [],
            optionsAntiguedad: [],
            optionsformaPago: [],
            confirmT:"",
            backT:"",
            nextT:"",
            errorCampos:"",
            haveComercio:false,
            
        }

        this.tittle="";
        this.allOK = true;
        this.inputsToVerify = [
           ]
        
    }
    componentDidMount = () => {
        this.inicialiceData();
    }

    inicialiceData = () => {
        const pais_server =  localStorage.getItem('server');
        if(pais_server==='"eeuu"'){
            this.inputsToVerify = [
                'nameIngresos',
                'comboFuente',
                'radioPeriodoNomina',
                'tiempoIngreso',
            ]
            this.setState({
                nextT:<FormattedMessage id='next' defaultMessage={"Siguiente"}/>,
                succLaboral:<FormattedMessage id='laboralOK' defaultMessage={"Form laboral"}/>,
                tittle:<FormattedMessage id='source' defaultMessage={"Fuente de ingresos"}/>,
                confirmT:<FormattedMessage id='confirm' defaultMessage={"Confirmar"}/>,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                backT:<FormattedMessage id='back' defaultMessage={"Atras"}/>,
                flagusa:true,
            });
        } if(pais_server==='"colombia"' ||  pais_server==='"colombiapruebas"'){
            this.inputsToVerify=[
                'comboCotizaSeguridadSocial',
                'comboEps',

            ]
            this.setState({
                nextT: "Siguiente",
                backT: "Atrás",
                confirmT: "Confirmar",
                succLaboral: "Formulario laboral cargado OK!",
                tittle:"Datos de la Empresa",
                flagColombia:true,
                errorCampos: "Atención, aún faltan campos por llenar"
            });
        }

        const listCompanyType = [];
        const listPayrollPeriod = [];
        const listChargeOption = [{ title: "seleccione una opción", value: "seleccione" }];
        const listFuenteOption = [{ title: " ", value: "seleccione" }];
        const listContractOption = [{ title: "seleccione una opción", value: "seleccione" }];
        const listSocialSecurity = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsDepartamento = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsMunicipio = [{ title: "seleccione una opción", value: "seleccione" }];
        const listOptionsEps = [{ title: "seleccione una opción", value: "seleccione" }];
        const listAntiguedad = [{ title: " ", value: "seleccione" }];
        const listFormaPago = [{ title: "None", value: "seleccione" }];

        if (!this.data) {
            return;
        }
        if (this.data.antiguedad_ids) {
            if (!this.props.dataForm.antiguedad_id) {
                this.setState({
                    nameAntiguedad: {
                        name: 'nameAntiguedad',
                        value: listAntiguedad[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            if(this.props.dataForm.nombre_comercio){
                this.setState({haveComercio:true})
            }else{
                this.setState({haveComercio:false})
            }
            if (!this.props.dataForm.tiempo_ingreso) {
                this.setState({
                    tiempoIngreso: {
                        name: 'tiempoIngreso',
                        value: listAntiguedad[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        
            this.data.antiguedad_ids.forEach(element => {
                listAntiguedad.push({
                    title: element.nombre, value: element.id
                });
            });
        }

        if (this.data.tipo_empresa_ids) {
            this.data.tipo_empresa_ids.forEach(element => {
                listCompanyType.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.periodo_nomina_ids) {
            this.data.periodo_nomina_ids.forEach(element => {
                listPayrollPeriod.push({ title: element[1], value: element[0] })
                /* listPayrollPeriod.push({title: <FormattedMessage id='weekly' defaultMessage={"Semanal"}/>, value: 'semanal'},
                {title: <FormattedMessage id='biweekly' defaultMessage={"Quincenal"}/>, value: 'quincenal'},
                {title: <FormattedMessage id='monthly' defaultMessage={"Mensual"}/>, value: 'mensual'}) */
            });
        }
        if(this.props.data.cargo_ids){
            if(!this.props.dataForm.cargo_id){
                this.setState({
                    comboCargo: {
                        name: 'comboCargo',
                        value: listChargeOption[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
                
            }
        }    
                this.setState({
                    
                    comboFuente: {
                        name: 'comboFuente',
                        value: listFuenteOption[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                    formaPago: {
                        name: 'formaPago',
                        value: listFormaPago[0].value,
                        error: '',
                        type: "off",
                        required: true
                    }
                })
            
            this.data.cargo_ids.forEach(element => {
                listChargeOption.push({ title: element.nombre, value: element.id })
            });
            listChargeOption.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
            this.data.fuenteingreso_ids.forEach(element => {
                listFuenteOption.push({ title: element.nombre, value: element.id })
            });
            listFuenteOption.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        
        if (this.data.tipo_contrato_ids) {
            if (this.props.dataForm.tipo_contrato_id === 'False') {
                this.setState({
                    comboTipoContrato: {
                        name: 'comboTipoContrato',
                        value: listContractOption[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.tipo_contrato_ids.forEach(element => {
                listContractOption.push({ title: element.nombre, value: element.id })
            });
            listContractOption.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        }
        if (this.data.seguridadSocial_ids) {
            if (!this.props.dataForm.seguridadSocial) {

                this.setState({
                    comboCotizaSeguridadSocial: {
                        name: 'comboCotizaSeguridadSocial',
                        value: listSocialSecurity[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }else{
                if(this.props.dataForm.seguridadSocial === "independiente"){
                    this.setState({isIndependent:true})
                }else{
                    this.setState({isIndependent:false})
                }
            }
            listFormaPago.push({title: <FormattedMessage id='check' defaultMessage={"Cheque"}/>, value: "1"},{title: <FormattedMessage id='transfer' defaultMessage={"Transferencia"}/>, value: "2"})
            this.data.seguridadSocial_ids.forEach(element => {
                listSocialSecurity.push({ title: element[1], value: element[0] })
            });
        }
        if (this.data.eps_ids) {
            if (!this.props.dataForm.eps_id) {
                this.setState({
                    comboEps: {
                        name: 'comboEps',
                        value: listOptionsEps[0].value,
                        error: '',
                        type: "off",
                        required: true
                    }
                })
            }
            this.data.eps_ids.forEach(element => {
                listOptionsEps.push({ title: element.nombre, value: element.id, })
            });
        }
        if (this.data.departamento_ids) {
            if (!this.props.dataForm.departamento_id) {
                this.setState({
                    ciudad: {
                        name: 'ciudad',
                        value: listOptionsMunicipio[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                    departamento: {
                        name: 'departamento',
                        value: listOptionsDepartamento[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.departamento_ids.forEach(element => {
                listOptionsDepartamento.push({
                    title: element.nombre, value: element.id
                })
                listOptionsDepartamento.sort((a,b) =>{
                    return (a.title < b.title) ? -1 : 1
                })
            });
        }
        if (this.data.municipio_ids) {
            if (this.props.dataForm.departamento_id) {   
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.props.dataForm.departamento_id) === parseInt(element.departamento_id)) { 
                        listOptionsMunicipio.push({
                            title: element.nombre, value: element.id
                        });

                        listOptionsMunicipio.sort((a,b) =>{
                            return (a.title < b.title) ? -1 : 1
                        })       
                    }
                });
            } else {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.data.departamento_ids[0].id) === parseInt(element.departamento_id)) { 
                        listOptionsMunicipio.push({ title: element.nombre, value: element.id, });      
                    }
                });
                
                this.setState({
                    ciudad: {
                        name: 'ciudad',
                        value: listOptionsMunicipio[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        }

        this.setState({
            optionsTipoEmpresa: listCompanyType,
            optionsPeriodoNomina: listPayrollPeriod,
            optionsCargo: listChargeOption,
            optionsFuente: listFuenteOption,
            optionsContrato: listContractOption,
            optionsSeguridadSocial: listSocialSecurity,
            optionsDepartamento: listOptionsDepartamento,
            optionsMunicipio: listOptionsMunicipio,
            optionsEps: listOptionsEps,
            optionsAntiguedad: listAntiguedad,
            optionsformaPago: listFormaPago,

        })
    }

    changeIncomes = (value) =>{
        if(value === "7"){
            this.setState({flagDescripcion: false,flagDependiente: true})  
        }else if(value==="6" || value==="5" ){
            this.setState({flagDescripcion: true,flagDependiente: false})    
        }else{
            this.setState({flagDependiente: false, flagDescripcion: false})      
        }
    }
    changeTypeContract = (value) =>{
        console.log("El tipo de contrato",value)
        if(value === "independiente"){
            this.inputsToVerify=[
                'comboCotizaSeguridadSocial',
                'descripcionIngresos',
                'comboEps',
                'comboComercio'
            ]
            this.setState({isIndependent: true})  
        }else{
            this.inputsToVerify = [
                'comboCotizaSeguridadSocial',
                'comboEps',
                'comboCargo',
                'comboTipoContrato',
                'nameAntiguedad',
                'nameEmpresa',
                'nameDireccionEmpresa',
                'nameTelefonoEmpresa',
                'radioTipoEmpresa',
                'radioPeriodoNomina',      
            ]
            this.setState({isIndependent: false})      
        }
        }
        changeComercio = (value) =>{
            console.log("combo comercio",value)
            
            if(value==="si"){
                this.inputsToVerify=[
                    'comboCotizaSeguridadSocial',
                    'descripcionIngresos',
                    'comboEps',
                    'comboComercio',
                    'nameDireccionComercio',
                    'nameComercio'
    
                ]
                
                this.setState({haveComercio:true})
            }else{
                this.inputsToVerify=[
                    'comboCotizaSeguridadSocial',
                    'descripcionIngresos',
                    'comboEps',
                    'comboComercio',
    
                ]
                this.setState({haveComercio:false})
            }
        }

    selectDepartamento = (index) => {
        const optionsMunicipio = [{ title: "seleccione una opción", value: "seleccione" }];
        this.data.municipio_ids.forEach(element => {
            if (parseInt(index) === parseInt(element.departamento_id)) {
                optionsMunicipio.push({ title: element.nombre, value: element.id, });
            }
            optionsMunicipio.sort((a,b) =>{
                return (a.title < b.title) ? -1 : 1
            })
        });
        this.setState({
            optionsMunicipio,
            ciudad: {
                name: 'ciudad',
                value: optionsMunicipio[0].value,
                error: '',
                type: "off",
                required: true
            },
        })
    }



    registrar = () => {
        let list = this.props.dataForm;
        if (this.verifyForm()) {
                if(this.state.flagColombia){
                const uid = localStorage.getItem('uid')
                let personal = {}
                if(!this.state.isIndependent){
                    personal = {
                        id: parseInt(uid),
                        cliente: {
                            seguridadSocial: this.state.comboCotizaSeguridadSocial.value,
                            periodo_nomina: this.state.radioPeriodoNomina.value,
                            tipo_empresa: this.state.radioTipoEmpresa.value,
                            antiguedad_id: this.state.nameAntiguedad.value,
                            tipo_contrato_id: this.state.comboTipoContrato.value,
                            cargo_id: this.state.comboCargo.value,
                            //municipio_id_em: this.state.ciudad.value,
                            //departamento_id_em: this.state.departamento.value,
                            codigo_postal_empresa: this.state.postalEmpresa.value,
                            ingresos: parseInt(this.state.nameIngresos.value),
                            eps_id: this.state.comboEps.value,
                            telefono_empresa: this.state.nameTelefonoEmpresa.value,
                            direccion_empresa: this.state.nameDireccionEmpresa.value,
                            nombre_comercio:this.state.nameComercio.value,
                            direccion_comercio:this.state.nameDireccionComercio.value,
                            nombre_empresa: this.state.nameEmpresa.value,
                            estadolaboral: true,
                        }
                    }
                    list.seguridadSocial = this.state.comboCotizaSeguridadSocial.value;
                    list.periodo_nomina = this.state.radioPeriodoNomina.value;
                    list.tipo_empresa = this.state.radioTipoEmpresa.value;
                    list.antiguedad_id = this.state.nameAntiguedad.value;
                    list.ingresos = parseInt(this.state.nameIngresos.value);
                    list.tipo_contrato_id = this.state.comboTipoContrato.value;
                    list.cargo_id = this.state.comboCargo.value;
                    list.departamento_id_em = this.state.departamento.value;
                    list.ciudad_id_em = this.state.ciudad.value;
                    list.codigo_postal_empresa= this.state.postalEmpresa.value;
                    list.eps_id = this.state.comboEps.value;
                    list.telefono_empresa = this.state.nameTelefonoEmpresa.value;
                    list.direccion_empresa = this.state.nameDireccionEmpresa.value;
                    list.nombre_empresa = this.state.nameEmpresa.value;
                    
                }else{
                    personal = {
                        id: parseInt(uid),
                        cliente: {
                            seguridadSocial: this.state.comboCotizaSeguridadSocial.value,
                            descripcion_ingreso: this.state.descripcionIngresos.value,
                            nombre_comercio:this.state.nameComercio.value,
                            direccion_comercio:this.state.nameDireccionComercio.value,
                            eps_id: this.state.comboEps.value,
                           
                            estadolaboral: true,
                        }
                    }
                    list.descripcionIngresos= this.state.descripcionIngresos.value;
                    list.seguridadSocial = this.state.comboCotizaSeguridadSocial.value;
                    list.eps_id = this.state.comboEps.value;

                }
                console.log("informacion que se va",personal)
                subirClientes(personal, (data, flag) => {
                    console.log("respuesta",flag,data)
                    if (flag) {
                        if (this.props.next) {
                            this.props.next();
                            this.props.upDateList(list);
                        } else {
                            const success = () => toast.success("¡Formulario laboral cargado OK!");
                            success();
                        }
                    }else{
                        const error = () =>toast.error("Estamos teniendo problemas con el servidor");
                        error()
                    }
                })           
            } 
            
        } else {
            const error = () => toast.error(this.state.errorCampos);
            error();
        }
    }

    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '2% 0 0 8%', paddingBottom: "2%" }}>
                    <Card style={{ maxWidth: '70rem' }}>
                        <Card.Body>
                            <Form>
                                <h3 className='text-title-black'>{this.state.tittle}</h3>
                                <hr />
                                <Row>
{this.state.flagusa &&              <Col sm>
    {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>
                                    <FormattedMessage
                                      id='source'
                                      defaultMessage={"Fuente de ingresos"}
                                    /></label>}
                                        <ComboBoxForm
                                            field={this.state.comboFuente}
                                            options={this.state.optionsFuente !== '' ? this.state.optionsFuente : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.changeIncomes(value)}}
                                            onBlur={this.handleRelease}
                                            placeholder="Seleccione"
                                            required={true}
                                            initial={this.state.comboFuente.value}
                                        />
                                    </Col>}
                                    <Col sm >
<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de cotizante</label>
                                        <ComboBoxForm
                                            field={this.state.comboCotizaSeguridadSocial}
                                            options={this.state.optionsSeguridadSocial !== '' ? this.state.optionsSeguridadSocial : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.changeTypeContract(value)}}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboCotizaSeguridadSocial.value}
                                        />
                                    </Col>
                                    {this.state.isIndependent &&<Col sm>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Describa el tipo de actividad económica que desarrolla</label>
                                        <InputForm
                                            field={this.state.descripcionIngresos}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease} 
                                        />
                                    </Col>}

                                    <hr className="clearfix w-100 d-md-none" />
    {!this.state.isIndependent&&<Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de contrato</label>
                                        <ComboBoxForm
                                            field={this.state.comboTipoContrato}
                                            options={this.state.optionsContrato !== '' ? this.state.optionsContrato : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboTipoContrato.value}
                                        />
                                    </Col>}
                                </Row>
                                <hr />
                                {!this.state.isIndependent && <h3>Dependiente</h3>}
                                <hr />
                                <Row>
                                {!this.state.isIndependent &&<Col sm>
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Nombre de la empresa</label>
                                        <InputForm
                                            field={this.state.nameEmpresa}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    {!this.state.isIndependent &&<Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Dirección de la empresa</label>
                                        <InputForm
                                            field={this.state.nameDireccionEmpresa}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}

                                    <hr className="clearfix w-100 d-md-none" />  
                                    
                                </Row>
                                <Row>
{this.state.isIndependent &&<Col >
                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>¿Tiene establecimiento de comercio?</label>
                    <ComboBoxForm
                                            field={this.state.comboComercio}
                                            options={this.state.optionsComercio !== '' ? this.state.optionsComercio : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.changeComercio(value)}}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboComercio.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    {!this.state.isIndependent &&<Col sm >
                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Teléfono de la empresa</label>
                                        <InputNumber
                                            field={this.state.nameTelefonoEmpresa}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>

    {!this.state.isIndependent &&        <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Cargo</label>
                                        <ComboBoxForm
                                            field={this.state.comboCargo}
                                            options={this.state.optionsCargo !== '' ? this.state.optionsCargo : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboCargo.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
    {!this.state.isIndependent &&  <Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Periodo de Nómina</label>
                                            <RadioForm
                                                type='horizontal'
                                                field={this.state.radioPeriodoNomina}
                                                options={this.state.optionsPeriodoNomina !== '' ? this.state.optionsPeriodoNomina : []}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                                required={true}
                                            />
                                        </Col>}

                                </Row>
                                <Row>
                                {this.state.haveComercio &&<Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Nombre establecimiento</label>
                                        <InputForm
                                            field={this.state.nameComercio}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.haveComercio &&<Col sm>
                                        <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Dirección establecimiento</label>
                                        <InputForm
                                            field={this.state.nameDireccionComercio}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
{!this.state.isIndependent &&  <Col sm>

                                    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tipo de empresa</label>
                                        <RadioForm
                                            type='horizontal'
                                            field={this.state.radioTipoEmpresa}
                                            options={this.state.optionsTipoEmpresa !== '' ? this.state.optionsTipoEmpresa : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
    {!this.state.isIndependent &&        <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Tiempo de antigüedad</label>
                                        <ComboBoxForm
                                            field={this.state.nameAntiguedad}
                                            options={this.state.optionsAntiguedad !== '' ? this.state.optionsAntiguedad : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.nameAntiguedad.value}
                                        />
                                    </Col>} 
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>

                                    <Col >
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>EPS</label>
                                        <ComboBoxForm
                                            field={this.state.comboEps}
                                            options={this.state.optionsEps !== '' ? this.state.optionsEps : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.comboEps.value}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagusa &&              <Col sm>
    {this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span> <FormattedMessage id='timeincome'  defaultMessage={"¿Hace cuánto comenzaste a recibir estos ingresos?"} /></label>}
                                        <ComboBoxForm
                                            field={this.state.tiempoIngreso}
                                            options={this.state.optionsAntiguedad !== '' ? this.state.optionsAntiguedad : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.tiempoIngreso.value}
                                        />
                                    </Col>}
                                </Row>
                                <hr />
{this.state.flagusa &&      <h3><FormattedMessage id='waypay' defaultMessage={"Forma de pago"}/></h3>}
                                <hr />
                                <Row>
                                
                                <hr className="clearfix w-100 d-md-none" />
                                {this.state.flagusa &&<Col sm>
                                        <RadioForm
                                            field={this.state.formaPago}
                                            options={this.state.optionsformaPago !== '' ? this.state.optionsformaPago : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.formaPago.value}
                                            type= {"horizontal"}
                                        />
                                    </Col>}
                                </Row>
                                {!this.props.showBoton &&
                                    <div className="container">
                                        <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                            onClick={this.registrar}
                                        >
                                            <label className='text-title-black' style={{marginTop:6}}>{this.state.confirmT}</label>
                                    </Button>
                                    </div>
                                }
                            </Form>
                            {this.props.showBoton &&
                                <div className="container mt-4">
                                    <hr />
                                    <Row className="justify-content-md-center">
                                        <Col sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={() => this.props.back ? this.props.back() : <></>}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.backT}</label>   
                                            </Button>
                                        </Col>
                                        <Col sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={this.registrar}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.nextT}</label>   
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </>
        )
    }
}