import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";
import { ListDetail } from "./components/list";
import { Button, Col, Row } from "react-bootstrap";
import { UpdateCliente } from "../updateCliente/updateCliente";
import { getHijos, openBoxAll } from "../../../services/datos.services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GridLoader from 'react-spinners/GridLoader';
import { FormatDateComplete, FormatDateYYMMDD } from "../../../utilities/formats";

export const BoxDetail = (props) => {
  const { setShowModalToken } = props;

  const [lock, setLock] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const [loading,setLoading] = useState(false)
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [hijos, setHijos] = useState([]);
  const [ids, setIds] = useState([]);
  const [idUSer, setIdUSer] = useState(0);
  const [showLock, setShowLock] = useState(false);

  const handleLock = (flag) => {
    const id = parseInt(localStorage.getItem("uid"));

    if (window.confirm("¿Desea realizar apertura masiva de cajas?")) {

      openBoxAll(id, (data, flag) => {
        if (flag) {
          setDisabled(true);
          setLock(!lock)
          traerHijos(null, callBack);
        }
      });
      setLock(flag);
    }
  };

  const callBack = (data, flag) => {
    setHijos(data["caja_ids"]);
    let id = parseInt(localStorage.getItem("uid"));

    if (data["caja_ids"].length !== 0) {
      for (let index = 0; index < data["caja_ids"].length; index++) {

  


        let dateNow = FormatDateYYMMDD(new Date(Date.now()));

        let dateStart = FormatDateYYMMDD(startDate);

        if (dateNow === dateStart) {
          setShowLock(false)
          setLock(true);

          if (data["caja_ids"][index].caja_estado === "abierto") {

            setLock(true);
            setDisabled(true);

          } else {
            setLock(true);
            setDisabled(false);
          }

        } else {
          setShowLock(true)
          if (data["caja_ids"][index].caja_estado === "abierto") {
            setLock(true);
          }
        }
        //   }
      }
    } else {
      setLock(false);

    }


  }
  const traerHijos = (id, call) => {
    if (!id) {
      id = parseInt(localStorage.getItem("uid"));
    }
    const sons = ids;
    sons.push(id);
    setIds(sons);
    getHijos(id, FormatDateComplete(startDate), call);
  };

  const filteredData = hijos.filter((data) => {

    if (data.nombre1 && data.caja_estado) {
      return data.nombre1.toLowerCase().includes(search.toLowerCase());
    }

  });

  useEffect(() => {
    setLoading(true)
    setIdUSer(parseInt(localStorage.getItem("uid")));
    traerHijos(null, callBack);
    setLoading(false)

    let dateNow = FormatDateYYMMDD(new Date(Date.now()));
    let dateStart = FormatDateYYMMDD(startDate);

    if (dateNow === dateStart) {
      setLock(false)
      setDisabled(false);
    } else {
      setLock(true)
      setDisabled(true);
    }
  }, [startDate]);

  useEffect(() => {

  }, [lock])
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="center-block col-md-8 col-lg-12 mr-auto mb-1">
            <div
              style={{ paddingRight: "2%", paddingLeft: "5%", fontSize: 16, marginBottom:10 }}
            >
              <Row>
                {!showLock && <Button
                  className="show-btn"
                  variant="light"
                  style={{ borderRadius: 50, width: "60px", height: "60px" }}
                  onClick={() => handleLock(!lock)}
                  disabled={disabled}
                >
                  <FontAwesomeIcon
                    style={{ height: "25px", width: "25px" }}
                    icon={!lock ? faLock : faLockOpen}
                  />
                </Button>}

                <Col>
                  <DatePicker
                    style={{ fontSize: '16px' ,backgroundColor:'#e3e3e3', borderRadius:50 }} 
                    className="form-control text-title-black"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    maxDate={new Date()}
                  />
                </Col>

                <Col md={{ span: 3, offset: 3 }}>
                  <input
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                    className="form-control text-title-black"
                    type="text"
                    placeholder="Buscar Usuario"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Col>
              </Row>
            </div>

            <ListDetail
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              ids={ids}
              verHijos={traerHijos}
              setShowModalToken={(flag) => setShowModalToken(flag)}
              setShowModalEditar={(flag) => setShowModalEditar(flag)}
              hijos={filteredData}
            />
            {showModalEditar && (
              <UpdateCliente
                setShowModalEditar={(flag) => setShowModalEditar(flag)}
              />
            )}
          </div>
        </div>
      </div>
      <GridLoader  size={50} loading={loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
    </>
  );
};


