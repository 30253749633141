import React, { useState } from 'react';
import { Col, Row, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../../utilities/formats';

const DetallesCarteraM = (props) => {

    const { cuotas } = props;
    const [showCuotas, setShowCuotas] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowCuotas(flag);
        setOpen(flag);
    }

    return (
        <>

            <Card style={{ width: '1' }} onClick={() => handleOpen(!open)}>
                <Card.Header>
                    <Row>
                        <Col>
                          <label className='text-title-black'>Valor Credito:</label> <label className='text-body'>{FormatMoney(cuotas[0].total)}</label>  
                        </Col>
                        <Col>
                        <label className='text-title-black'>Debe:</label> <label className='text-body'>{FormatMoney(cuotas[0].saldo)}</label>
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {open && <Card.Body>

                    <Table style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px gray' }}  responsive striped bordered hover size="sm">
                        <thead className='text-title-blue'>
                            <tr>
                                <th><label >Cuota</label></th>
                                <th><label>Valor del Créditos</label></th>
                                <th><label >Saldo</label></th>
                                <th><label >Fecha de Pago</label></th>
                                <th><label >Estado</label></th>
                            </tr>
                        </thead>
                        <tbody className='text-title-black'>
                            {cuotas.length > 0 && cuotas.map((cuota, i) => (
                                <tr key={i}>
                                    <td>{++i}</td>
                                    <td>{FormatMoney(cuota.total)}</td>
                                    <td>{FormatMoney(cuota.saldo)}</td>
                                    <td>{cuota.fecha}</td>
                                    <td>{cuota.estado}</td>
                                </tr>
                            )
                            )}

                        </tbody>
                    </Table>
                </Card.Body>}

            </Card>
        </>
    );
}

export default DetallesCarteraM;