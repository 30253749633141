import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faChevronDown, faChevronUp, faUserShield, faUserTie, faUser } from '@fortawesome/free-solid-svg-icons';
import { ListDetail } from './list'
import { ModalDetalleCaja } from '../../../../shared/components/tab-movimientos/modal-detalle-caja';
import { FormatDateYYMMDD, FormatMoney } from '../../../../utilities/formats';
import { closeBox, openBox } from '../../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';


export const ItemDetail = (props) => {

    const { dataUser, setShowModalToken, setShowModalEditar, movimientos, verHijos } = props

    const [showOptions, setShowOptions] = useState(false)
    const [lock, setLock] = useState(true);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [hijos, setHijos] = useState([]);
    const [box, setBox] = useState({});
    const [disabled, setDisabled] = useState(true);
    let dateNow = FormatDateYYMMDD(new Date(Date.now()));

    useEffect(() => {

        if (dataUser) {
            if (dateNow === dataUser.caja_fecha) {
                if (dataUser.caja_estado === "abierto") {
                    setLock(true);
                    setDisabled(false);
                } else {
                    setLock(false);
                    setDisabled(false);
                }
            } else {
                if (dataUser.caja_estado === "abierto") {
                    setLock(true);
                    setDisabled(false);
                } else {
                    setLock(false);
                    setDisabled(true);
                }
            }

            setBox({
                uid: dataUser.id,
                valorInicial: dataUser.caja_saldo_inicial,
                valorFinal: dataUser.caja_saldo_final,
                movimientos: dataUser.caja_valor_movimientos,
                caja_id: dataUser.caja_id
            });
            setShowOptions(false);
            setOpen(false);
        }
    }, [dataUser]);

    const handleLock = (id) => {
        if (window.confirm('¿Desea modificar el estado de la caja?')) {
            if (!lock) {
                openBox(id, (data, flag) => {
                    if (flag) {
                        setLock(!lock);
                    }
                })
            } else {
                closeBox(id, (data, flag) => {
                    if (flag) {
                        if (dateNow !== dataUser.caja_fecha) {
                            setDisabled(true)
                        }
                        setLock(!lock);
                    } else {
                        const error = () => toast.error("¡Atención, no se puede cerrar la caja!");
                        error();
                    }
                })
            }
        }
    };

    const handleOpen = (flag) => {
        setShowOptions(flag);
        setOpen(flag);
        getSons();
    };

    const getSons = () => {
        const callBack = (data, flag) => {
            setHijos(data["caja_ids"]);
        }
        verHijos(dataUser.id, callBack);
    };

    let nombre2 = dataUser.nombre2 === false ? '' : dataUser.nombre2;
    let apellido2 = dataUser.apellido2 === false ? '' : dataUser.apellido2;
    let nombreCompleto = dataUser.nombre1 + " " + nombre2 + " " + dataUser.apellido1 + " " + apellido2;
    let id = parseInt(localStorage.getItem('uid'));
    return (
        <>
            <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
            <style>
                {`
                    .show-btn{
                    height: 20px;
                    width: 20px;
                    margin: 1px 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    }
                    .show-btn:hover {
                    height: 35px;
                    width: 35px;
                    background: #31DBED;
                    }
            
                `}
            </style>

            <Card style={{ width: '100%', borderRadius:50, boxShadow:'6px 10px 10px gray' }}>
                <Card.Header style={{ padding: 10 }}>
                    <Row>
                        <Col>
                            <Row>
                                <Col lg={2} className=" text-md-left">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 100 }}
                                        overlay={<Tooltip style={{ margin: 0 }} id="tooltip-disabled">Detalles</Tooltip>}
                                    >
                                        <div
                                            onClick={() => setShow(true, dataUser.caja_id)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '5px 10px 5px 10px',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                border: '1px', solid: '#00cc00',
                                                background: '#EDECEB',
                                                borderRadius: '6px',
                                                boxShadow: 'inset 1px 1px 1px rgba(255,255,255,.7), inset -1px -1px 2px rgba(0,0,0,.1), 1px 1px 5px rgba(0,0,0,.1)'
                                            }}
                                        >
                                            <FontAwesomeIcon style={{ marginRight: '8px', height: '30px', width: '30px' , color:'#02dce8' }} icon={dataUser.permiso === 'administrador'
                                                ? faUserShield : dataUser.permiso === 'cliente'
                                                    ? faUser : faUserTie}
                                            />
                                            <label className='text-title-black'>{nombreCompleto}</label>
                                        </div>
                                    </OverlayTrigger>
                                    <label style={{ fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <b className='text-title-black'>Creación: </b> {dataUser.caja_fecha}
                                    </label>
                                </Col>
                                <Col>
                                    <div>
                                        <Row>
                                            <Col>
                                                <label className='text-title-blue' style={{ fontSize: 16 }}>
                                                     Valor Inicial:
                                                    
                                                </label>
                                                <label className='text-title-black' style={{ fontSize: 16 }}>
                                                {FormatMoney(dataUser.caja_saldo_inicial)}
                                                    
                                                </label>
                                                
                                            </Col>
                                            <Col>
                                                <label  className='text-title-blue'> Movimientos:</label>
                                                <label  className='text-title-black'>{FormatMoney(dataUser.caja_valor_movimientos ? dataUser.caja_valor_movimientos : 0)}</label>
                                            </Col>
                                            <Col>
                                                <label  className='text-title-blue' >Valor Final:</label>
                                                <label  className='text-title-black' >{FormatMoney(dataUser.caja_saldo_inicial + dataUser.caja_valor_movimientos)}</label>
                                            </Col>
                                            { dataUser.permiso !== 'administrador' && <Col>
                                                <label className='text-title-blue'>Debido Cobrar: </label>
                                                <label  className='text-title-black'>{FormatMoney(dataUser.caja_saldo_inicial)}</label> 
                                                
                                            </Col>}
                                            {dataUser.permiso !== 'administrador' && <Col>
                                                <label className='text-title-blue'>
                                                Cumplimiento: 
                                                   
                                                </label>
                                                <label className='text-title-black'>
                                                {dataUser.caja_porcentajeCumplimiento}
                                                   
                                                </label>
                                                
                                            </Col>}
                                            {dataUser.permiso === 'vendedor' &&<Col>
                                                <label className='text-title-blue'>Clientes:</label>
                                                <label className='text-title-black'>falta definir</label>
                                                    
                                        
                                            </Col>}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="table-responsive" style={{ padding: "0% 2% 0 2%" }}>
                                <table className="table table-hover table-sm" >
                                    <thead className='text-title-blue'>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">  Gastos </th>
                                            <th scope="col">  Ingresos</th>
                                            <th scope="col">Retiros</th>
                                            <th scope="col">Creditos</th>
                                            <th scope="col">Renovaciones</th>
                                            <th scope="col">Pagos</th>
                                            <th scope="col">No Pagos</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-title-black'>
                                        <tr>
                                            <th scope="row">Valor</th>
                                            <td>{FormatMoney(dataUser.caja_gastoValor ? dataUser.caja_gastoValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_ingresoValor ? dataUser.caja_ingresoValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_retiroValor ? dataUser.caja_retiroValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_ventaValor ? dataUser.caja_ventaValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_renovacionValor ? dataUser.caja_renovacionValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_pagoValor ? dataUser.caja_pagoValor : 0)}</td>
                                            <td>{FormatMoney(dataUser.caja_noPagoValor ? dataUser.caja_noPagoValor : 0)}</td>

                                        </tr>
                                        <tr>
                                            <th scope="row">Cantidad</th>
                                            <td>{dataUser.caja_gastoCantidad}</td>
                                            <td>{dataUser.caja_ingresoCantidad}</td>
                                            <td>{dataUser.caja_retiroCantidad}</td>
                                            <td>{dataUser.caja_ventaCantidad}</td>
                                            <td>{dataUser.caja_renovacionCantidad}</td>
                                            <td>{dataUser.caja_pagoCantidad}</td>
                                            <td>{dataUser.caja_noPagoCantidad}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                        </Col>

                        <div className="col-md-1"
                            style={{
                                maxWidth: 55, marginRight: 8,
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                border: '1px', solid: '#00cc00',
                                background: '#EDECEB',
                                borderRadius: '6px',
                                boxShadow: 'inset 1px 1px 1px rgba(255,255,255,.7), inset -1px -1px 2px rgba(0,0,0,.1), 1px 1px 5px rgba(0,0,0,.1)'
                            }}
                        >
                            <div>
                                
                                <Col style={{ marginBottom: '20px' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 50, hide: 100 }}
                                        overlay={<Tooltip style={{ margin: 0 }} id="tooltip-disabled">{lock ? 'Cerrar' : 'Abrir'}</Tooltip>}
                                    >
                                        <Button
                                            disabled={disabled}
                                            className="show-btn"
                                            size="sm"
                                            variant="light"
                                            style={{ borderRadius: 50, width: '40px', height: '40px' }}
                                            onClick={() => handleLock(dataUser.caja_id)}
                                        >
                                            <FontAwesomeIcon style={{ height: 23, width: 23,  color:'#02dce8' }} icon={lock ? faLockOpen : faLock} />
                                        </Button>

                                    </OverlayTrigger>

                                </Col>
                                {dataUser.permiso === 'administrador' && dataUser.id === id
                                    ? <></>
                                    : dataUser.permiso === 'administrador'
                                        ?
                                        <Col>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 50, hide: 100 }}
                                                overlay={<Tooltip style={{ margin: 0 }} id="tooltip-disabled">Hijos</Tooltip>}
                                            >
                                                <Button
                                                    className="show-btn"
                                                    size="sm"
                                                    variant="light"
                                                    style={{ borderRadius: 50, width: '40px', height: '40px', paddingTop: 10 }}
                                                    onClick={() => handleOpen(!showOptions)}
                                                >
                                                    <FontAwesomeIcon style={{ height: 23, width: 23 }} icon={!open ? faChevronDown : faChevronUp} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        : <></>
                                }
                            </div>
                        </div>
                    </Row>
                </Card.Header>

                {showOptions &&
                    <Card.Body style={{ padding: '15px 8px 2px 40px', backgroundColor: '#EBEFF2' }}>
                        <ListDetail
                            verHijos={verHijos}
                            setShowModalToken={(flag) => setShowModalToken(flag)}
                            setShowModalEditar={(flag) => setShowModalEditar(flag)}
                            stateLock={lock}
                            hijos={hijos}
                            movimientos={movimientos}

                        />
                    </Card.Body>}
            </Card>
            {show && <ModalDetalleCaja
                id={dataUser.caja_id}
                name={nombreCompleto}
                handleClose={() => setShow(false)}
                box={box}
            />}
        </>
    )
}
