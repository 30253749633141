import React, { useEffect, useState, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from './shared/components/footer';
import Header from './shared/components/header';
import Routes from './routes';
import { ToastProvider } from 'react-toast-notifications'
import { setData } from './services/login.service';
import { OdooConfig } from './utilities/odoo-config';
import { Desencrypt } from './utilities/cipher';
import Odoo from 'odoo-xmlrpc';
import { langContext } from './context/langContext';
import { country } from './utilities/odoo-config';


function App() {
  const idioma = useContext(langContext)
  const [formData, setFormData] = useState({});
  //const country='colombia';
  localStorage.setItem('server',JSON.stringify(country))
  const getListFormData = async (formData, flag) => {

    if (flag) {
      console.log("oeoeoeoeoeoe",formData)
      localStorage.setItem("desembolso_ids",JSON.stringify(formData.desembolso_ids))
      setFormData(formData);
    }
  }

  useEffect(() => {
    const initialiceData = async () => {
      const config = await OdooConfig(country);
      const data = await Desencrypt();

      if (data) {
        const odoo = new Odoo({
          url: config.host,
          port: config.port,
          db: config.database,
          username: data.user,
          password: data.password
        });
        await setData(odoo, getListFormData, data);
      }
    }
    initialiceData()

  }, []);
  return (
    <>
      <ToastProvider>
        <style>
          {`
            .margen {
              min-height: 78.5vh;
              min-width: 5vh;
              align: center;
        `}
        </style>
        <div style={{ background: '#f8f8f8' }}>
          <Header />
          <div className="margen">
            
              <Routes formData={formData} />
            
          </div>
          <Footer />
        </div>
      </ToastProvider>
    </>
  )
}
export default App;
