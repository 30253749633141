import React from 'react';
import './login.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { Login } from '../../services/login.service';
import Register from '../../shared/components/register';
import '../../pages/home/about-us/about.css'
import { InputForm, InputNumber, ComboBoxForm, RadioForm, DateForm } from '../../shared/components/inputs';
import { getParametros, subirClientes } from '../../services/datos.services';
import { TermsAndConditions } from '../../shared/components/terms-and-conditions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  FormattedMessage } from 'react-intl';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';


const selectConocioNova = [
    
    { title: "Redes sociales", value: 'red_social' },
    { title: "Amigos", value: 'amigos' },
    { title:"Otros", value: 'otros' }
];


export class LoginShow extends Register {


    constructor(props) {
        super(props);
        this.state = {
            politicasPrivacidad: false,
            flagOtherDocumento: false,
            error1:"",
            success1:"",
            titlelogin:'',
            titleRegister:'',
            terminosCondiciones: false,
            isLoginOpen: false,
            selectPdf: '',
            modalPdf: false,
            isRegisterOpen: false,
            userLogin: "",
            passwordLogin: "",
            segmento: "",
            txtPass:"",
            txtMail:"",
            rememberPass:"",
            checkBoxAlert: false,
            alert: false,
            flagColombia: false,
            flagusa: false,
            eye: false,
            eye2:false,
            optionsTipoDocumentoUsa: [{ title: "United states passport", value: "44" },
            { title: "Driver's license", value: "45" },
            { title: "Foreign passport", value: "46" },
            { title: "other", value: "47" },],
            optionsTieneDocumento: [{ title: " ", value: "seleccione" },
            { title: "Ssn", value: "ssn" },
            { title: "Itin", value: "itin" },
            { title: "None", value: "ninguno" }
            ],
            conocioNova: {
                name: 'conocioNova',
                value: selectConocioNova[0],
                error: '',
                type: "off",
                required: true
            },
            conocioApp: {
                name: 'conocioApp',
                value: "",
                error: '',
                type: "off",
                required: false
            },

            nombre1: {
                name: 'nombre1',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            nombre2: {
                name: 'nombre2',
                value: "",
                error: '',
                type: "off",
                required: false
            },
            apellido1: {
                name: 'apellido1',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            apellido2: {
                name: 'apellido2',
                value: "",
                error: '',
                type: "off",
                required: false
            },
            phone: {
                name: 'phone',
                value: "",
                error: '',
                type: "mobile",
                required: true
            },
            nacionalidad: {
                name: 'nacionalidad',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            email: {
                name: 'email',
                value: "",
                error: '',
                type: "email",
                required: true
            },
            password: {
                name: 'password',
                value: '',
                error: '',
                type: 'password',
                required: true
            },
            passwordRepeat: {
                name: 'passwordRepeat',
                value: '',
                error: '',
                type: 'passwordRepeat',
                required: true
            },
            numeroDocumento: {
                name: 'numeroDocumento',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            otherDocument: {
                name: 'otherDocument',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            tipoDocumento: {
                name: 'tipoDocumento',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            tieneDocumento: {
                name: 'tieneDocumento',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            fechaExpedicion: {
                name: 'fechaExpedicion',
                value: '',
                error: '',
                type: "date",
                required: true
            },
            swalProps:{}
        }
        this.allOK = true;
        
    }

    initialiceData = () => {

        const list = []
        getParametros((data) => {

            if (data.tipodocumento_ids !== undefined) {
                data.tipodocumento_ids.forEach(element => {
                    if (parseInt(element[0]) === 1) {
                        list.push({ title: 'Seleccione el tipo de identificación', value: element[0] })
                    } else {
                        list.push({ title: element[1], value: element[0] })
                    }
                });
                this.setState({
                    optionsTipoDocumento: list,
                    conocioNova: {
                        name: 'conocioNova',
                        value: 'seleccione',
                        error: '',
                        type: "off",
                        required: true
                    }
                });
            }
        })
        const pais_server =  localStorage.getItem('server');
        if(pais_server==='"eeuu"'){
            this.setState({
                success1:<FormattedMessage id='success1' defaultMessage={"success1"}/>,
                error1:<FormattedMessage id='error1' defaultMessage={"error1"}/>,
                txtPass:<FormattedMessage id='password' defaultMessage={"Contraseña"}/>,
                txtMail:<FormattedMessage id='email' defaultMessage={"Correo Electrónico"}/>,
            rememberPass:<FormattedMessage  id='oldAccount' defaultMessage={"¿Olvidaste tu contraseña?"}/>,
            titleRegister:<FormattedMessage  id='checkin' defaultMessage={"Registrarse"}/>,
            titlelogin:<FormattedMessage  id='login' defaultMessage={"Iniciar Sesión"}/>,
            });
        }else{
            this.setState({
                success1:"¡Registro Éxitoso... Te llegará un correo para activar tu cuenta en VENGALEPRESTO, es posible que llegue a la bandeja de Spam. !",
                error1:"¡ Oops... Cédula o Correo ya existe en el sistema, comunicate al teléfono 3203306827 o al correo contacto@vengalepresto.com !",
                txtPass:"Contraseña",
                txtMail:"Correo Electrónico",
                titleRegister:"Registrarse",
                titlelogin:"Iniciar Sesión",
                rememberPass:"¿Has olvidado tu contraseña?"
            });
        }

    }
    changeDocument = (value) =>{
        if(value==="itin" || value === "ssn"){
           this.setState({
            flagTieneDocumento: true
           })
        }else{
            this.setState({
                flagTieneDocumento: false
            })
           } 
        }

        changetoOther = (value) =>{
            if(value==="47"){
               this.setState({
                flagOtherDocumento: true
               })
            }else{
                this.setState({
                    flagOtherDocumento: false
                })
               } 
            }

    componentDidMount = () => {
        this.setState({ isLoginOpen: this.props.viewInfoL, isRegisterOpen: this.props.viewInfoR })
        this.initialiceData();
        const server = localStorage.getItem('server');
        if(server==='"eeuu"'){
            this.setState({
                flagusa: true,
                

            })
        }else{
            this.setState({
                flagColombia: true,

                linkTerminos: '',
            })
        }
    }
    login = async () => {
        const data = { user: (this.state.userLogin.toLowerCase()).trim(), password: this.state.passwordLogin };
        Login(data, this.props.onLogin);
    }

    enterKey = (event) => {
        if (event.keyCode === 13) {
            this.login();
        }
    }

    showLoginBox() {
        this.setState({ isLoginOpen: true, isRegisterOpen: false });
    }

    showRegisterBox() {
        this.setState({ isRegisterOpen: true, isLoginOpen: false });
    }

    handleClose = () => {
        this.props.viewModalLogin(false)
    }

    handleClick() {
        window.open("/forgetPassword");
    }
    
    registrar = () => {
        this.inputsToVerify = [
        ]
        if(this.state.flagColombia){
            this.inputsToVerify = [
                    'nombre1',
                    'nombre2',
                    'apellido1',
                    'apellido2',
                    'numeroDocumento',
                    'tipoDocumento',
                    'fechaExpedicion',
                    'email',
                    'password',
                    'passwordRepeat',
                    'phone',
                    'conocioNova',
                    'conocioApp',
            ]

        }else{
            this.inputsToVerify = [
                'nombre1',
                'nombre2',
                'apellido1',
                'apellido2',
                'numeroDocumento',
                'tipoDocumento',
                'email',
                'password',
                'passwordRepeat',
                'phone',
                'conocioNova',
                'conocioApp',
        ]
        }

        this.setState({ checkBoxAlert: false });

        if (this.verifyForm()) {
            let objeto;
            if (this.state.politicasPrivacidad === true &&
                this.state.terminosCondiciones === true) {
                this.props.validate(false);
                 if(this.state.flagColombia){
                    objeto = {
                        id: parseInt(27),
                        cliente: {
                            team_members_ids: [
                                [
                                    0, 0, {
                                        nombre1: this.state.nombre1.value,
                                        nombre2: this.state.nombre2.value,
                                        apellido1: this.state.apellido1.value,
                                        apellido2: this.state.apellido2.value,
                                        tipodocumento: this.state.tipoDocumento.value,
                                        identificacion: "" + this.state.numeroDocumento.value,
                                        fecha_expedicion_documento: this.state.fechaExpedicion.value,
                                        login: (this.state.email.value.toLowerCase()).trim(),
                                        celular: this.state.phone.value,
                                        clave: this.state.password.value,
                                        email: this.state.email.value.toLowerCase(),
                                        in_group_27: true,
                                        acepta_terminos_condiciones: true,
                                        acepta_tratamiento_datos: true
                                    }
                                ]
                            ]
                        }
                    }
                }else{
                        objeto = {
                            id: parseInt(27),
                            cliente: {
                                team_members_ids: [
                                    [
                                        0, 0, {
                                            nombre1: this.state.nombre1.value,
                                            nombre2: this.state.nombre2.value,
                                            apellido1: this.state.apellido1.value,
                                            apellido2: this.state.apellido2.value,
                                            tipodocumento: this.state.tipoDocumento.value,
                                            otroDocumento: this.state.otherDocument.value,
                                            identificacion: "" + this.state.numeroDocumento.value,
                                            login: (this.state.email.value.toLowerCase()).trim(),
                                            celular: this.state.phone.value,
                                            clave: this.state.password.value,
                                            email: this.state.email.value.toLowerCase(),
                                            in_group_27: true,
                                            acepta_terminos_condiciones: true,
                                            acepta_tratamiento_datos: true
                                        }
                                    ]
                                ]
                            }
                        }
                    }
                subirClientes(objeto, (data, flag) => {
                    console.log("error que está llegando",data,flag)
                    if (flag) {
                        if (data[0].errores) {
                            /* const error = () => toast.error(this.state.error1);
                            error(); */
                            Swal.fire({
                                title: 'VengaLePresto',
                                html: '<span style="color: black;">' + this.state.error1 + '</span>',
                                icon: 'info',
                                showCancelButton: false,
                                confirmButtonColor:'#4B4B4B',
                                confirmButtonText: '<strong>Aceptar</strong>',
                                
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    this.setState(prevState => ({
                                        numeroDocumento: {
                                          ...prevState.numeroDocumento,
                                          value: ""
                                        }
                                      }));
                                    this.setState(prevState => ({
                                        email: {
                                          ...prevState.email,
                                          value: ""
                                        }
                                    }));
                                }
                              });
                            
                        } else {
                            //const success = () => toast.success(this.state.success1);
                            //success();
                            Swal.fire({
                                title: 'VengaLePresto',
                                html: '<span style="color: black;">' + this.state.success1 + '</span>',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonColor: '#4ccfd4',
                                confirmButtonText: '<strong>Aceptar</strong>',
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = "/";
                                }
                              });
                        }
                    }
                })

            }
            else {
                this.setState({ checkBoxAlert: true })
            }
        }
        else {
            this.setState({ checkBoxAlert: true })
        }
    }

    render() {

        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <SweetAlert2 {...this.state.swalProps} />
                <style>
                    {`
                        .modal-90w { max-width: 50% !important; }
                        .modal-45w { max-width: 45% !important; }
                    `}
                </style>
                <Modal
                    centered
                    show={true}
                    onHide={this.handleClose}
                >
                    <Modal.Header  closeButton>
                        <Modal.Title>
                            {this.state.isLoginOpen &&
                                <div className="header">
                                    <h3 className='text-title-black'> {this.state.titlelogin} </h3>
                                </div>
                            }
                            {this.state.isRegisterOpen &&
                                <div className="header">
                                    <h3 className='text-title-black'>{this.state.titleRegister}</h3>
                                </div>
                            }

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="root-container">
                            <div className="box-controller" >
                                <div

                                    style={{ paddingTop: 10 }}
                                    className={"controller " + (this.state.isLoginOpen
                                        ? "selected-controller"
                                        : "")}
                                    onClick={this
                                        .showLoginBox
                                        .bind(this)} >
                                    <h5 className='text-title-black'> {this.state.titlelogin} </h5>
                                </div>
                                <div

                                    style={{ paddingTop: 10 }}
                                    className={"controller " + (this.state.isRegisterOpen
                                        ? "selected-controller"
                                        : "")}
                                    onClick={this
                                        .showRegisterBox
                                        .bind(this)} >
                                    <h5 className='text-title-black' style={{marginTop:8}}> {this.state.titleRegister} </h5>
                                </div>
                            </div>
                            {this.state.isLoginOpen &&
                                <form>
                                    <div style={{ marginTop: 20 }} className="form-group">

                                        <h5 className='text-title-black' style={{ textAlign: 'left' }} >{this.state.txtMail}</h5>
                                        <input
                                            autoFocus

                                            value={this.state.userLogin}
                                            onChange={(e) => { this.setState({ userLogin: e.target.value }) }}
                                            onKeyDown={(e) => this.enterKey(e)}
                                            type="text"
                                            className="form-control text-title-black"
                                            aria-describedby="emailHelp"
                                            placeholder="ejemplo@gmail.com" />
                                    </div>
                                    <div className="form-group">
                                        <h5 className='text-title-black' style={{ color: 'black', textAlign: 'left' }} >{this.state.txtPass}</h5>
                                        <input
                                            value={this.state.passwordLogin}
                                            onChange={(e) => { this.setState({ passwordLogin: e.target.value }) }}
                                            onKeyDown={(e) => this.enterKey(e)}
                                            type="password"
                                            className="form-control text-title-black"
                                            placeholder="Contraseña"
                                            autoComplete="off"
                                            maxLength="8"
                                            minLength="6"
                                        />
                                    </div>
                                    <div className="form-row text-center">
                                        <div className="col-12">
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                                onClick={() => { this.login() }}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.titlelogin} </label>
                                        </Button>
                                        </div>
                                    </div>
                                    <div className="container" style={{ fontSize: 14 }}>
                                        <Button className=" mt-3" variant="link"
                                            onClick={() => { this.handleClick() }}>
                                           <label className='text-title-black'> {this.state.rememberPass}</label> 
                                        </Button>
                                    </div>
                                </form>
                            }
                            {this.state.isRegisterOpen &&

                                <Form>
                                    <Row>
                                        <Col sm>
            {this.state.flagusa &&  <p>
                                        <FormattedMessage
                                        id='firstName'
                                        defaultMessage={"Primer Nombre"}
                                        />
                                    </p>}
        {this.state.flagColombia &&<p className='text-title-black'>Primer nombre</p>}
                                            <InputForm
                                                field={this.state.nombre1}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>
                                        <Col sm>
            {this.state.flagusa &&  <p className='text-title-black'> <FormattedMessage id='secondName' defaultMessage={"Segundo Nombre"} /></p>}
        {this.state.flagColombia &&<p className='text-title-black'>Segundo nombre</p>}
                                            <InputForm
                                                field={this.state.nombre2}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm>
                {this.state.flagColombia &&<p className='text-title-black'>Primer Apellido</p>}
                                            <InputForm
                                                field={this.state.apellido1}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>
                                        <Col sm>
                                        {this.state.flagusa &&<p><FormattedMessage
                                                id='secondSurName'
                                                defaultMessage={"Segundo Apellido"}
                                            />
                                        </p>}
                {this.state.flagColombia &&<p className='text-title-black'>Segundo Apellido</p>}
                                            <InputForm
                                                field={this.state.apellido2}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
        {this.state.flagColombia &&<Col sm>
                                    <p className='text-title-black'>Tipo de documento</p>
                                            <ComboBoxForm
                                                field={this.state.tipoDocumento}
                                                options={this.state.optionsTipoDocumento !== ''
                                                    ? this.state.optionsTipoDocumento
                                                    : []
                                                }
                                                handleChange={(name, value) => { this.handleChange(name, value); this.changetoOther(value)}}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>}
                {this.state.flagusa &&<Col sm>
<p><FormattedMessage id='typedocument' defaultMessage={"Tipo de documento"}/></p>
                                            <ComboBoxForm
                                                field={this.state.tipoDocumento}
                                                options={this.state.optionsTipoDocumentoUsa !== ''
                                                    ? this.state.optionsTipoDocumentoUsa
                                                    : []
                                                }
                                                handleChange={(name, value) => { this.handleChange(name, value); this.changetoOther(value)}}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>}
{this.state.flagOtherDocumento &&       <Col sm>
                                            <p><FormattedMessage
                                                id='which'
                                                defaultMessage={"¿Cuál?"}
                                                />
                                            </p>
                                            <InputForm
                                                field={this.state.otherDocument}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                            />
                                        </Col>}
                                    </Row>
                                    <Row>
                                    <Col sm>
{this.state.flagusa &&<p><FormattedMessage id='documentN' defaultMessage={"Numero de identificación"}/></p>}
                                            {this.state.flagColombia &&<p className='text-title-black'>Numero de documento</p>}
                                            <InputForm
                                                field={this.state.numeroDocumento}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease} 
                                                maxLength={10}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
{this.state.flagusa &&              <Col sm>
                                            <p><FormattedMessage
                                                id='havessn'
                                                defaultMessage={"¿Tiene  (SSN) o (ITIN)?"}
                                                />
                                            </p>
                                        <ComboBoxForm
                                            field={this.state.tieneDocumento}
                                            options={this.state.optionsTieneDocumento !== '' ? this.state.optionsTieneDocumento : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.changeDocument(value) }}
                                            onBlur={this.handleRelease}
                                            initial={this.state.tieneDocumento.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
{this.state.flagTieneDocumento &&   <Col sm>
                                            <p><FormattedMessage
                                                id='number'
                                                defaultMessage={"Número"}
                                                />
                                            </p>
                                        <InputForm
                                            field={this.state.otherDocument}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            disabled={false}
                                            
                                        />
                                    </Col>}
{this.state.flagColombia &&             <Col sm>
    <p className='text-title-black'>Fecha de expedición de documento</p>
                                            <DateForm
                                                field={this.state.fechaExpedicion}
                                                handleChange={this.handleChange}
                                                type="date"
                                            />
                                        </Col>}
                                        
                                    </Row>
                                    <Row>
                                    <Col sm>
                    {this.state.flagusa &&<p>
                                            <FormattedMessage
                                                id='phone'
                                                defaultMessage={"Teléfono"}
                                            />
                                        </p>}
                    {this.state.flagColombia &&<p className='text-title-black'>Celular</p>}
                                            <InputForm
                                                field={this.state.phone}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                                maxLength={10}
                                            />
                                        </Col>
                                        <Col sm >
                                        {this.state.flagusa &&<p><FormattedMessage
                                                id='email'
                                                defaultMessage={"Correo Electrónico"}
                                                />
                                            </p>}
                                            {this.state.flagColombia &&<p className='text-title-black'>Correo Electrónico</p>}
                                            <InputForm
                                                field={this.state.email}
                                                handleChange={this.handleChange}
                                                onBlur={this.handleRelease}
                                                type="email"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
{this.state.flagusa &&<p><FormattedMessage id='password' defaultMessage={"Contraseña"}/></p>}
{this.state.flagColombia &&<p className='text-title-black'>Contraseña</p>}
                                    <InputForm
                                        field={this.state.password}
                                        handleChange={this.handleChange}
                                        onBlur={this.handleRelease}
                                        maxLength={8}
                                        minLength={6}
                                        type={this.state.eye ? 'text' : 'password'}
                                    />
                                        </Col>
                                        
                                            <Button onClick={() => this.setState({eye: !this.state.eye})} name='hiddenOne' id='hiddenOne' variant="light" disabled={this.state.password ? '' : 'disabled'} >
                                                <FontAwesomeIcon style={{ height: '20px', width: '20px', color: 'black' }} className="mr-2 align-self-center" icon={this.state.eye ? faEye : faEyeSlash} />
                                            </Button>
                                        
                                    </Row>
                                    <Row>
                                        <Col>
{this.state.flagusa &&<p><FormattedMessage id='confirmPassword' defaultMessage={"Confirmar Contraseña"}/></p>}
{this.state.flagColombia &&<p className='text-title-black'>Confirmar contraseña</p>}
                                        <InputForm
                                            field={this.state.passwordRepeat}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            maxLength={8}
                                            minLength={6}
                                            type={this.state.eye2 ? 'text' : 'password'}
                                        />
                                        </Col>
                                        
                                            <Button onClick={() => this.setState({eye2: !this.state.eye2})} name='hiddenOne' id='hiddenOne' variant="light" disabled={this.state.password ? '' : 'disabled'} >
                                                <FontAwesomeIcon style={{ height: '20px', width: '20px', color: 'black' }} className="mr-2 align-self-center" icon={this.state.eye2 ? faEye : faEyeSlash} />
                                            </Button>
                                        
                                    </Row>                      

                                    {this.state.flagusa&&<p><FormattedMessage
                                                id='knowNova'
                                                defaultMessage={"¿Cómo conoció VENGALEPRESTO?"}
                                                />
                                            </p>}
                                            {this.state.flagColombia&&<p className='text-title-black'>¿Cómo conoció VENGALEPRESTO?
                                            </p>}

                                    <RadioForm
                                        field={this.state.conocioNova}
                                        options={selectConocioNova}
                                        handleChange={this.handleChange}
                                        onBlur={this.handleRelease}
                                        type={"horizontal"}
                                        required={true}
                                    />
                                    {this.state.conocioNova.value === 'otros' && <InputForm
                                        field={this.state.conocioApp}
                                        handleChange={this.handleChange}
                                        onBlur={this.handleRelease}
                                    />}
                                    <div>
                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            checked={this.state.politicasPrivacidad}
                                            onChange={() => this.setState({
                                                politicasPrivacidad: !this.state.politicasPrivacidad
                                            })}
                                        />
                                        <a target='_blank' href='policies'>
                                        {this.state.flagusa &&<p><FormattedMessage
                                                id='dataTreatment'
                                                defaultMessage={"ENTIENDO Y ACEPTO las Políticas de tratamiento de datos personales y privacidad"}
                                                />
                                            </p>}
    {this.state.flagColombia &&<p className='text-title-black'>ENTIENDO Y ACEPTO las Políticas de tratamiento de datos personales y privacidad</p>}
                                        </a>
                                    </div>
                                    <div>
                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            checked={this.state.terminosCondiciones}
                                            onChange={() => this.setState({
                                                terminosCondiciones: !this.state.terminosCondiciones
                                            })}
                                        />
                                        <a target='_blank' href='terms-and-conditions' >
                                        {this.state.flagusa &&<p><FormattedMessage
                                                id='termsAndConditions'
                                                defaultMessage={"ENTIENDO Y ACEPTO los Términos y condiciones créditos por internet VENGALEPRESTO S.A.S"}
                                                />
                                            </p>}
                                            {this.state.flagColombia &&<p className='text-title-black'>ENTIENDO Y ACEPTO los Términos y condiciones créditos por internet VENGALEPRESTO S.A.S
                                            </p>}
                                            
                                        </a>
                                        {this.state.checkBoxAlert &&
                                            <h6 style={{ color: ' red' }}>
                                               
                                            </h6>}
                                    </div>
{this.state.flagusa &&              <a target='_blank' href='policiesSecurity' >
                                            <p><FormattedMessage
                                                id='policySecurity'
                                                defaultMessage={"CONSULTE AQUI Nuestra politica de seguridad de la información."}
                                                />
                                            </p>
                                    </a>}
                                    <div className="form-row text-center">
                                        <div className="col-12">

                                            <Button
                                                onClick={this.registrar}
                                                style={{ alignItems:'center', borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}

                                            >
                                              <label className='text-title-black' style={{marginTop:6}}>{this.state.titleRegister} </label> 
                                            
                                                </Button>

                                        </div>
                                    </div>
                                </Form>
                            }
                        </div>
                    </Modal.Body>
                </Modal >

                {this.state.modalPdf &&
                    <Modal
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        centered
                        show={true}
                        onHide={() => this.setState({ modalPdf: false })}
                    >
                        <Modal.Header closeButton />
                        <Modal.Body>
                            <div style={{ height: 700 }}>
                                <TermsAndConditions selectPdf={this.state.selectPdf} />
                            </div>

                        </Modal.Body>
                    </Modal>}

            </>

        );
    }

}


