import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { InputForm, InputNumber } from '../../../shared/components/inputs';
import Register from '../../../shared/components/register';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subirClientes } from '../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';


export class UpdateCliente extends Register {
    constructor(props) {

        super();
        this.state = {

            nombre: {
                name: 'nombre',
                value: props.dataUser.nombre1,
                error: '',
                type: "off",
                required: true
            },
            segundoNombre: {
                name: 'segundoNombre',
                value: props.dataUser.nombre2,
                error: '',
                type: "off",
                required: false
            },
            apellido1: {
                name: 'apellido1',
                value: props.dataUser.apellido1,
                error: '',
                type: "off",
                required: true
            },
            apellido2: {
                name: 'apellido2',
                value: props.dataUser.apellido2,
                error: '',
                type: "off",
                required: false
            },
            celular: {
                name: 'celular',
                value: props.dataUser.celular,
                error: '',
                type: "mobile",
                required: true
            },
            direccion: {
                name: 'direccion',
                value: props.dataUser.direccion,
                error: '',
                type: "off",
                required: true
            },
            disabled: false,
            errorCampos:'',

        }

        this.allOK = true;
        this.inputsToVerify = [
            'nombre',
            'segundoNombre',
            'apellido1',
            'apellido2',
            'celular',
            'direccion',
        ]
    }

    registrar = () => {

        if (this.verifyForm()) {
            const objeto = {
                id: parseInt(this.props.dataUser.usuario_id),
                cliente: {
                    nombre1: this.state.nombre.value,
                    nombre2: this.state.segundoNombre.value,
                    apellido1: this.state.apellido1.value,
                    apellido2: this.state.apellido2.value,
                    celular: this.state.celular.value,
                    street: this.state.direccion.value,
                }
            }

            subirClientes(objeto, (data, flag) => {
                if (flag) {
                    this.setState({ disabled: true})
                    const success = () => toast.success("¡Datos actualizados correctamente!");
                    success();
                    setTimeout(() => {
                        this.handleClose();
                    }, 10000);

                } else {
                    const error = () => toast.error("¡Error al subir datos!");
                    error();
                }
            })
        } else {
            const error = () => toast.error("¡Atencion aun faltan campos por llenar...!");
            error();
        }


    }

    handleClose = () => {
        this.props.setShowModalEditar(false)
    }

    render() {
        return (
            <>
                <ToastContainer style={{ marginTop: 50, fontSize: 16,  }} autoClose={10000} />
                <Modal
                    centered
                    show={true}
                    onHide={this.handleClose}
                >
                    <Modal.Header style={{ background: 'white', color: '#02dce8',borderBottomWidth:3, borderBottomColor:'#02dce8' }} closeButton>
                        <Modal.Title >
                            <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faUserEdit} />
                           <label className='text-title-blue'> Editar a {this.state.nombre.value} </label>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div>{this.state.nombre.value+' '+this.state.segundoNombre.value+' '+this.state.apellido1.value+' '+this.state.apellido2.value}</div> */}
                        <div style={{ margin: '10px' }} >
                            <Form>
                                <Row>
                                    <Col sm>
                                        <InputForm
                                            title="Nombre"
                                            field={this.state.nombre}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su nombre"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                        <InputForm
                                            title="Segundo Nombre"
                                            field={this.state.segundoNombre}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese su Segundo nombre"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <Col sm>
                                        <InputForm
                                            title="Primer Apellido"
                                            field={this.state.apellido1}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese el primer apellido"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                        <InputForm
                                            title="Segundo Apellido"
                                            field={this.state.apellido2}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese el segundo apellido"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <InputNumber
                                            title="Celular"
                                            field={this.state.celular}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese el celular"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                        <InputForm
                                            title="Dirección"
                                            field={this.state.direccion}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese la dirección"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col>
                                    <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }}  onClick={this.handleClose} ><label className='text-title-black'>Cancelar</label></Button>
                                </Col>
                                <Col>
                                </Col>
                                <Col>
                                    <Button
                                        disabled={this.state.disabled}
                                        style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }}
                                        onClick={this.registrar}
                                    >
                                       <label className='text-title-black'>Actualizar</label> 
                                        </Button>
                                </Col>

                            </Row>

                        </div>
                    </Modal.Body>
                </Modal >
            </>
        )
    }
}


