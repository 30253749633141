import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faUserTie, faUser } from '@fortawesome/free-solid-svg-icons';
import { Row,Col } from 'react-bootstrap';
import { GetDetailBoxReport } from '../../../../services/datos.services';
import consolidated from '../../../../assets/btn-consolidado.png'
import generalExpenses from '../../../../assets/btn-gastos.png'
import movements from '../../../../assets/btn-movements.png'
import jsPDF from 'jspdf';
import { FormatMoney } from '../../../../utilities/formats';
import briefcase from '../../../../assets/btn-cartera.png'
import { Capitalize } from '../../../../utilities/formats';


export const ItemReport = (props) => {
    const { item , startDate,endDate} = props
    let [data,setData] = useState([])
    
    const [agrupadosFechaGeneral,setAgrupadosFechaGeneral]=useState()
    let [dataGasto,setDataGasto]= useState()
    
    const generateExpenses=async()=>{
        await updateList(async(flag)=>{
            if(flag){
              try {
                const htmlPdf = `
                <html >

                
                        <div class="containerPdf">
                       
                          <div>
                              <h3 class="text-total">Reporte movimientos por ruta Desde ${startDate} hasta  ${endDate} </h3>
                          </div> 
                          <div>    
                            <table class="table table-bordered table-sm" >
                                <tr class="text-title-blue">
                                  <th>Ruta</th>
                                  <th>Fecha servidor</th>
                                  <th>Fecha celular</th>
                                  <th>Tipo</th>
                                  <th>Descripcion</th>
                                  <th>Valor</th>
                                </tr>
                                ${dataGasto
                                .map(
                                    line => `
                                <tr>
                                  <td>${line.caja_user}</td>
                                  <td>${line.fecha}</td>
                                  <td>${line.hora_dispositivo}</td>
                                  <td>${line.tipo}</td>
                                  <td>${line.descripcion?line.descripcion:""}</td>
                                  <td>${line.valor?FormatMoney(parseInt(line.valor)):"$ 0"}</td>
                                </tr>
                                `,
                                )
                                .join('')}
                            </table></div>
                        </div>
                  </html>
                `;
    
                const doc = new jsPDF("p","px","letter")
                const margin=3;
                const scale= (doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth;
                
                doc.html(htmlPdf,{
                  x:margin,
                  y:margin,
                  html2canvas :{
                    scale:scale
                  },
                  callback: function(doc){
                    doc.save("ReporteGastoPorVendedor.pdf")
                  }
                }) 
              } catch (error) {
                console.log("Error",error)
              }
            }else{
              alert('se presento un error al actualizar datos')
            }
          })
    }
    const generateMovements = async ()=>{
        //setLoading(true)
        await updateList(async(flag)=>{
          if(flag){
            try {
              const htmlPdf = `
              <html>
              <head>
                    <style>
                      body {
                        font-family: 'Helvetica';
                        font-size: 12px;
                        justify-content: center;
                        margin-left: 50px;
                      }
                      header, footer {
                        height: 50px;
                        background-color: #fff;
                        color: #000;
                        display: flex;
                        justify-content: center;
                        padding: 0 20px;
                      }
                      table {
                        width: 100%;
                        border-collapse: collapse;
                      }
                      th, td {
                        border: 1px solid #000;
                        padding: 5px;
                      }
                      th {
                        background-color: #02DCE8;
                      }
                    </style>
                  </head>
                  <body>
                    <div class="containerPdfMovements">
                      <h1 class="text-total">Reporte movimientos por ruta Desde ${startDate} hasta  ${endDate} </h3>
                                  
                        <table class="table table-bordered table-sm" >
                            <tr class="text-title-blue">
                            <th>Ruta</th>
                            <th>Fecha servidor</th>
                            <th>Fecha celular</th>
                            <th>Tipo</th>
                            <th>Descripcion</th>
                            <th>Valor</th>
                            </tr>
                            ${data
                            .map(
                                line => `
                            <tr>
                                <td>${line.caja_user}</td>
                                <td>${line.fecha}</td>
                                <td>${line.hora_dispositivo}</td>
                                <td>${line.tipo}</td>
                                <td>${line.descripcion?line.descripcion:""}</td>
                                <td>${line.valor?FormatMoney(parseInt(line.valor)):"$ 0"}</td>
                            </tr>
                            `,
                            )
                            .join('')}
                        </table>
                      </div>
                    </body>
              </html>
              `;
  
              const doc = new jsPDF("p","mm","letter")
              const margin=3;
              const scale= (doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth;
              
              doc.html(htmlPdf,{
                x:margin,
                y:margin,
                html2canvas :{
                  scale:scale
                },
                callback: function(doc){
                  doc.save("ReporteMovimientos.pdf")
                }
              }) 
              
                
            } catch (error) {
              alert('Error', error);
            }
          }else{
            alert('se presento un error al actualizar datos')
          }
        }) 
  
      }
    const generatedConsolidated = async() =>{
        await generalReport(async(flag,data)=>{
            if(flag){
              let vendedores = Object.keys(data);
              
              try {
                let html = `
                <html>
                  
                <div class="containerPdfConsolidated">
               
                  <div>
                      <h3 class="text-total">Reporte movimientos por ruta Desde ${startDate} hasta  ${endDate} </h3>
                  </div> 
                  <div>  
                      <table class="table table-bordered table-sm" >
                        <tr class="text-title-blue">
                          <th>Ruta</th>
                          <th>Fecha</th>
                          <th>Gasto</th>
                          <th>Ingreso</th>
                          <th>Retiro</th>
                          <th>Ventas</th>
                          <th>Renovaciones</th>
                          <th># No Pago</th>
                          <th>Recaudo</th>
                          <th>Solicitud</th>
                          <th>debido Cobrar</th>
                          <th>Caja Inicial</th>
                          <th>Caja Final</th>
                        </tr> `
                            vendedores.forEach( x => {
                              console.log("x",x)
                              let Fechas=Object.keys(data[x]);
                              console.log("fechas",Fechas)
                              Fechas.forEach( y => {
                                html+=` <tr>
                                  <td>${x.toString()}</td>
                                  <td>${y.toString()}</td>
                                  <td>${data[x][y].report.sumaGasto?FormatMoney(parseInt(data[x][y].report.sumaGasto)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaIngreso?FormatMoney(parseInt(data[x][y].report.sumaIngreso)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaRetiro?FormatMoney(parseInt(data[x][y].report.sumaRetiro)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaVenta?FormatMoney(parseInt(data[x][y].report.sumaVenta)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaRenovaciones?FormatMoney(parseInt(data[x][y].report.sumaRenovaciones)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaNoPago?FormatMoney(parseInt(data[x][y].report.sumaNoPago)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaRecaudo?FormatMoney(parseInt(data[x][y].report.sumaRecaudo)):"$ 0"}</td>
                                  <td>${data[x][y].report.sumaSolicitud?FormatMoney(parseInt(data[x][y].report.sumaSolicitud)):"$ 0"}</td>
                                  <td>${"0"}</td>
                                  <td>${"0"}</td>
                                  <td>${"0"}</td>
                                </tr> `
                              }) 
                            })
                            html+=   
                      ` </table></div>
                      </div>
                </html>
                          `;
      
                          const doc = new jsPDF("p","px","letter")
                          const margin=3;
                          const scale= (doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth;
                  
                        doc.html(html,{
                    x:margin,
                    y:margin,
                    html2canvas :{
                      scale:scale
                    },
                    callback: function(doc){
                      doc.save("ReporteConsolidado.pdf")
                    }
                  }) 
                  
                    /* */
                  //setLoading(false);
      
                        
                  
                
              } catch (error) {
                //setLoading(false);
                alert("Error en el servidor")
      
              }
      
            }else{
              //setLoading(false);
              alert('se presento un error al actualizar datos')
            }
      
            })

    }
    const updateList = async(call) =>{
       
            let Movimientos=[]
            let id = parseInt(localStorage.getItem("uid"));
        setDataGasto([])
        console.log("el id que se va",id)
        await GetDetailBoxReport(startDate,endDate,parseInt(id), async(flag,data ) => {
              if (flag) {
                let dataGastos = []
                  for(let i = 0; i < data.length; i++){
                    if(item.login==data[i].caja_user){
                      if(data[i].tipo=='pago'){
                        data[i].descripcion=data[i].descripcion=null?"Pago "+data[i].descripcion :""
                      }else if(data[i].tipo=='nopago'){
                        data[i].descripcion=data[i].descripcion=null?"No pago "+data[i].descripcion :""
                      }else if(data[i].tipo.toString()=='gasto'){
                        dataGastos.push(data[i])
                      }else if(data[i].tipo.toString()=='solicitud'){
                        let obj2={
                          caja_user:data[i].caja_user,
                          fecha:data[i].fecha,
                          hora_dispositivo:data[i].hora_dispositivo,
                          tipo:data[i].tipo,
                          descripcion:" solicitud de : "+data[i].nombre,
                          valor:data[i].valor
                        }
                        data[i]=obj2
                      }
                    setDataGasto(dataGastos)
                      Movimientos.push(data[i])
                      }
                    
                  }
                  setData(Movimientos)
                  return call(true,Movimientos)
              }else{
                  alert("Server Error")
                  return call(false)
              }
            })
        
    }
    const generalReport = async (call) =>{
        let id = parseInt(localStorage.getItem("uid"));
        await GetDetailBoxReport(startDate,endDate,parseInt(id), async(flag,data ) => {
          //setLoading(false)
          if (flag) {
            
            console.log("length de la data ",data.length)
            let nuevoObjeto = {}
            let agrupadosFecha={}
            //Recorremos el arreglo 
            data.forEach( x => {
              //Si el vendedor no existe en nuevoObjeto entonces
              //lo creamos e inicializamos el arreglo de Movimientos. 
              if( !nuevoObjeto.hasOwnProperty(x.caja_user)){
                nuevoObjeto[x.caja_user] = {
                  movimientos: []
                }
                agrupadosFecha[x.caja_user] = {}
              }
              
              //Agregamos los datos de movimientos. 
                nuevoObjeto[x.caja_user].movimientos.push(x)
              
            })
  
            
  
            let claves = Object.keys(nuevoObjeto); // claves = ["cris", "ferc", "macho", "edad"]
            
  
            claves.forEach(element => {
              nuevoObjeto[element].movimientos.forEach(element2 => {
                if( !agrupadosFecha[element].hasOwnProperty(element2.fecha)){
                  agrupadosFecha[element][element2.fecha] = {
                    movimientos: [],
                    report:{
                      sumaGasto:0,
                      sumaIngreso:0,
                      sumaRetiro:0,
                      sumaVenta:0,
                      sumaRenovaciones:0,
                      sumaNoPago:0,
                      sumaRecaudo:0,
                      sumaSolicitud:0
                      
                    }
                  }
                  
                }
                
                //Agregamos los datos de movimientos. 
                agrupadosFecha[element][element2.fecha].movimientos.push(element2)
                if(element2.tipo=='gasto'){
                  agrupadosFecha[element][element2.fecha].report.sumaGasto+=parseFloat(element2.valor)
                }else if(element2.tipo=='ingreso'){
                  agrupadosFecha[element][element2.fecha].report.sumaIngreso+=parseFloat(element2.valor)
                }else if(element2.tipo=='retiro'){
                  agrupadosFecha[element][element2.fecha].report.sumaRetiro+=parseFloat(element2.valor)
                }else if(element2.tipo=='venta'){
                  agrupadosFecha[element][element2.fecha].report.sumaVenta+=parseFloat(element2.valor)
                }else if(element2.tipo=='renovacion'){
                  agrupadosFecha[element][element2.fecha].report.sumaRenovaciones+=parseFloat(element2.valor)
                }else if(element2.tipo=='nopago'){
                  agrupadosFecha[element][element2.fecha].report.sumaNoPago+=parseFloat(element2.valor)
                }else if(element2.tipo=='pago'){
                  agrupadosFecha[element][element2.fecha].report.sumaRecaudo+=parseFloat(element2.valor)
                }else if(element2.tipo=='solicitud'){
                agrupadosFecha[element][element2.fecha].report.sumaSolicitud+=parseFloat(element2.valor)
              }
              });
            });

            console.log("agrupados fecha dentro de petodo",agrupadosFecha)
            setAgrupadosFechaGeneral(agrupadosFecha)
            return call(true,agrupadosFecha)
          }else{
              alert("Error en el servidor")
              return call(false)
          }
        })
      }
    return (
        <>
            <Row className="justify-content-md-center" style={{borderBottomWidth:'2px', borderBottomColor:'red'}}>
                <Col className="col-2">
                <FontAwesomeIcon style={{ marginRight: '8px', height: '50px', width: '50px' , color:'#02dce8' }} icon={item.permiso === 'administrador'
                                                ? faUserShield : item.permiso === 'cliente'
                                                    ? faUser : faUserTie}
                                            />
                </Col>
                <Col className="col-2">
                    <label className='text-total-black' >{item.nombre1 + " "+ item.apellido1 }</label>
                </Col>
                <Col className="col-4">
                    <Row>
                        <Col >
                            <label className='text-total-black' >Id: </label>
                            <label className='text-body'> {item.id}</label>

                        </Col>
                        <Col>
                            <label className='text-total-black' >Perfil: </label>
                            <label className='text-body'> {Capitalize(item.permiso)}</label>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-4">
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={4}> <img src={generalExpenses} style={{width:'100px', height:'100px'}} onClick={()=>{generateExpenses(item.id)}}/> </Col>
                        <Col md={4}><img src={movements} style={{width:'100px', height:'100px'}} onClick={()=>{generateMovements(item.id)}}/> </Col> 
                        <Col md={4}><img src={consolidated} style={{width:'100px', height:'100px'}} onClick={()=>{generatedConsolidated(item.id)}}/></Col>
                        
                    </Row>
                </Col>
                            
            </Row>
        </>
    )
}