import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Error from './error.js'
import { cambiarContrasena } from '../../../../services/datos.services.js';
import { useEffect } from 'react';


const ResetPassword = () => {

  const [dataForm, setDataForm] = useState({
    password: '',
    confirmPassword: '',
  });
  const [hiddenOne, setHiddenOne] = useState(false);
  const [hiddenTwo, setHiddenTwo] = useState(false);
  const [error, setError] = useState(true);
  const [message, setMessage] = useState('');
  const { password, confirmPassword } = dataForm;
  

  

  const recuperarPassword = () => {

    let url = window.location.search.split("%27&b=b%27");

    let valor = url[0];
    let  llave = url[1];

    valor=valor.split("?a=b%27")[1];
    llave=llave.split("%27")[0];   

valor = valor.trim();
llave= llave.trim();

    let cadena = {
      valor: valor,
      llave: llave,
      password: password
    }

    cadena = JSON.stringify(cadena);

  

    cambiarContrasena(cadena,(flag, response)=>{
      
      if(flag){
        alert("¡ Haz cambiado tu contraseña, ya puedes ingresar de nuevo a tu cuenta VENGALEPRESTO !");
        window.location = "/";
      }else{
        alert("¡¡ Ups!!!, Ocurrio algo, intenta de nuevo más tarde !");
      }
      
    })
  }

  const handleChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    })
  }

  const sendingData = e => {

    e.preventDefault();
    //Validación
    if (password.trim() === '' || confirmPassword.trim() === '') { 
      setMessage("TODOS LOS CAMPOS DEBEN ESTAR LLENOS")
      setError(true);
    } else if (password.length < 6 ) { 
      setMessage('LA CONTRASEÑA DEBE TENER ENTRE 6 A 8 CARACTERES')
      setError(true);
      return;
    } else if (password !== confirmPassword) { 
      setMessage("AMBAS CONTRASEÑAS DEBEN COINCIDIR")
      setError(true);
      return;
    } else if (password === confirmPassword) {
      setError(false);
      recuperarPassword();
    }else{
      setMessage("Ocurrio un error inesperado, intentalo más tarde")
      setError(true);
    }
  }

  useEffect(()=>{
    let server = localStorage.getItem("server");
    if (server === '"eeuu"'){
      console.log("Si le entró al chiquito")
    }else{
      console.log(server)
      console.log("no le entró al chiquito")
    }
  })

  return (

    <div style={{ paddingTop: 70 }} className='App d-flex flex-column align-items-center'>
      <Form id="Form" onSubmit={sendingData} >
        <Form.Group >
          <div className="text-left">
            <h6 className="text-left text-title-black" >RESTABLECER CONTRASEÑA</h6>
            {error ? <Error warning={message} /> : null}
          </div>
        </Form.Group>
        <Form.Group>
          <div className="text-left text-title-black">
            <label >Nueva Contraseña</label>
          </div>
          <Row>
            <Col className="col-10">

              <input
                onChange={handleChanges}
                type={hiddenOne ? "text" : "password"}
                className="form-control text-title-black"
                autoComplete="off"
                maxLength="8"
                minLength="6"
                placeholder=""
                name="password"
              />
            </Col>

            <Col className="col-2">

              <Button onClick={() => setHiddenOne(!hiddenOne)} name='hiddenOne' id='hiddenOne' variant="light" disabled={password ? '' : 'disabled'} >
                <FontAwesomeIcon style={{ height: '25px', width: '35px', color: 'black' }} className="mr-2 align-self-center" icon={hiddenOne ? faEye : faEyeSlash} />
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <div className="text-left text-title-black">
            <label>Repetir Contraseña</label>
          </div>
          <Row>
            <Col className="col-10">

              <input
                onChange={handleChanges}
                type={hiddenTwo ? "text" : "password"}
                className="form-control text-title-black"
                autoComplete="off"
                maxLength="8"
                minLength="6"
                placeholder=""
                name="confirmPassword"
              />
            </Col>
            <Col className="col-2">
              <Button onClick={() => setHiddenTwo(!hiddenTwo)} name='hiddenTwo' id='hiddenTwo' variant="light" disabled={confirmPassword ? '' : 'disabled'}>
                <FontAwesomeIcon id="icon"  style={{ height: '25px', width: '35px', color: 'black' }} className="mr-2 align-self-center" icon={hiddenTwo ? faEye : faEyeSlash} />
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <div>
          <Button onClick={sendingData} type="submit"  style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100, alignSelf:'center' }}>
            <label className='text-title-black' style={{marginTop:6}}>Restablecer</label>
          </Button>
        </div>
      </Form>

    </div>

  );
}

export default ResetPassword;