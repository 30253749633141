import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { PureComponent } from 'react'
import { Container, Table } from 'react-bootstrap'
import { getRequest } from '../../../services/datos.services'
import { ListCredit } from './components/list-credit'
import GridLoader from 'react-spinners/GridLoader';

export class Solicitudes extends PureComponent {
    constructor() {
        super();
        this.state = {
            list: [],
            copyList:[],
            loading:false
        }
    }
    componentDidMount = () => {
        this.setState({loading:true})
        this.obtenerSolicitudes(null)
    }
    obtenerSolicitudes = () => {
       
            getRequest('', (data, flag) => {  
                
                this.setState({ copyList: data.solictud_ids, list: data.solictud_ids, loading:false })             
        })

    }
    filterData = (data, buscar) => {
        let list = [];
        if (buscar && buscar !== '') {
            data.forEach(element => {
                if (element.partner_name.toLowerCase().includes(buscar.toLowerCase())) {
                    list.push(element)                
                }
                this.state.list.sort(function(a, b) { a = new Date(a.solicitud_write_date); b = new Date(b.solicitud_write_date); return a<b ? -1 : a>b ? 1 : 0; });
                return this.setState({ list: list  })  
            })
        } else {
            let pendiente = "Pendiente";
            let aprobado = "Aprobado";
            data.forEach(element => {
                if (element.estado.toLowerCase()===pendiente.toLowerCase() || element.estado.toLowerCase()===aprobado.toLowerCase() ) {
                list.push(element)
                }
            });
            this.state.list.sort(function(a, b) { a = new Date(a.solicitud_write_date); b = new Date(b.solicitud_write_date); return a<b ? -1 : a>b ? 1 : 0; });
            return this.setState({ list: list })
        }
    }
    render() {
        return (
            <>
                <div style={{ background: 'transparent', color: '#02dce8', paddingTop: 70 }}>

                    <div className="container" style={{ textAlign: 'center' }}>

                        <label className='text-total' style={{ fontSize: 26 }}>
                            <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faListAlt} />
                        Solicitudes
                    </label>
                    </div>
                </div>
                <Container style={{ marginTop: 15 }}>
                    <div style={{ float: 'right', marginBottom: 10 }}>
                        <input
                            style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                            className="form-control text-title-black"
                            type="text"
                            placeholder="         Buscar por cliente"
                            onChange={(e) => this.filterData(this.state.copyList, e.target.value)}
                        />
                    </div>
                    <Table style={{ width: '95%', left:'1%', borderRadius:50, boxShadow:'6px 10px 10px gray' }} responsive striped bordered hover size="sm">
                        <thead className='text-title-blue'>
                            <tr>
                                <th>Tipo  de solicitud</th>
                                <th>Fecha de Solicitud</th>
                                <th>Id solicitud</th>
                                <th>Cliente </th>
                                <th>Valor</th>
                                <th>Estado</th>
                                <th>Observaciones</th>
                            </tr>
                        </thead>
                        <tbody className='text-title-black'>
                            {
                                this.state.list.map((request) => (
                                    <ListCredit request={request} />
                                ))
                            }
                        </tbody>
                    </Table>
                </Container>
                <GridLoader  size={50} loading={this.state.loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
            </>
        )
    }
}