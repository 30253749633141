import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import '../../../home/about-us/about.css'
import { ItemCreditsUser, ItemDetailCuota, ItemDetailPago } from './item';
import { Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../utilities/formats';
import { FormattedMessage } from 'react-intl';


export const ListCreditsUser = (props) => {
    const { data, setShow, idCredit, cuotas,pagos, saldo,saldoTotal } = props;
    let [textHistory, setTextHistory] = useState('')
    let [textDescription, setTxtdescription] = useState('')
    let [textloans, setTextloans] = useState('')
    let [textloan, setTextloan] = useState('')
    let [textSaldo, setTxtSaldo] = useState('')
    let [textValue, setTextValue] = useState('')
    let [datePay, setDatePay] = useState('')
    let [dateDes, setDateDes] = useState('')

    useEffect(()=>{
        let server = localStorage.getItem('server');
        if(server === '"eeuu"'){
            setDateDes(<FormattedMessage id='disbursementdate' defaultMessage={"Fecha de desembolso"} />)
            setDatePay(<FormattedMessage id='paymentdate' defaultMessage={"Fecha de pago"} />)
            setTextValue(<FormattedMessage id='loanvalue' defaultMessage={"Valor del préstamo"} />)
            setTxtSaldo(<FormattedMessage id='balance' defaultMessage={"Saldo"} />)
            setTextloan(<FormattedMessage id='loan' defaultMessage={"Préstamo"} />)
            setTextloans(<FormattedMessage id='loans' defaultMessage={"Préstamos"} />)
            setTxtdescription(<FormattedMessage id='txthistory' defaultMessage={"Historial de Crédito"} />)
            setTextHistory(<FormattedMessage id='creditHistory' defaultMessage={"Historial de préstamo"} />)
        }else{
            setDateDes("Fecha de desembolso")
            setDatePay("Fecha de pago")
            setTextValue("Valor del crédito")
            setTextValue("Valor del crédito")
            setTxtSaldo("Saldo")
            setTextloans("Créditos")
            setTextloan("Crédito")
            setTxtdescription("Aquí podrás ver el historial de créditos con nosotros.")
            setTextHistory("Historial de crédito")
        }
    },[])
  
    
    return (
        <>
            <div style={{ paddingTop: 100, alignContent:'center' }}>
                <label className='text-total'>{textHistory}</label>
                <p className='text-body'>{textDescription}</p>
                <label className='text-body'>{textloans}:  {data.length} </label>
                <p className='text-total'>{textSaldo}:   {saldoTotal?FormatMoney(parseFloat(saldoTotal)):0}</p>
                <Card style={{ width: '95%', borderRadius:25, boxShadow:'3px 2px 2px black' }}>
                    <Table  striped  hover responsive="sm" size="sm">
                        <thead >
                            <tr style={{borderWidthLeft:'2px solid red'}}>
                                <th className='text-table'>Id  {textloan}</th>
                                <th className='text-table'>{textValue}</th>
                                <th className='text-table'>{dateDes}</th>
                                <th className='text-table'>{datePay}</th>
                                <th className='text-table'>{textSaldo}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((obj, i) => (
                                <ItemCreditsUser pagos={pagos} cuotas={cuotas} saldo={saldo} setId={(id) => { idCredit(id) }} setShow={(flag) => setShow(flag)} key={i} data={obj} />
                            ))}

                        </tbody>
                    </Table>
                </Card>
            </div>
        </>
    )
}

export const ListDetailCuota = (props) => {

    const { cuota, saldo } = props
    const [showCuotas, setShowCuotas] = useState(true);
    let [tdues, setTdues] = useState('');
    let [tvalue, setTvalue] = useState('');
    let [tdate, setTdate] = useState('');
    let [tstate, setTstate] = useState('');
    let [tbalance, setTbalance] = useState('');
    const [open, setOpen] = useState(true);

    const handleOpen = (flag) => {
        setShowCuotas(flag);
        setOpen(flag);
    }

    useEffect(()=>{
        let server = localStorage.getItem('server');
        if(server === '"eeuu"'){
            setTdate(<FormattedMessage id='date' defaultMessage={"Fecha"} />)
            setTstate(<FormattedMessage id='state' defaultMessage={"Estado"} />)
            setTbalance(<FormattedMessage id='balance' defaultMessage={"Saldo"} />)
            setTvalue(<FormattedMessage id='value' defaultMessage={"Valor"} />)
            setTdues(<FormattedMessage id='dues' defaultMessage={"Cuotas"} />)
           
        }else{
            setTdate("Fecha")
            setTstate("Estado")
            setTbalance("Saldo")
            setTvalue("Valor")
            setTdues("Cuotas")
            
        }
    },[])

    return (
        <>
            <h3 className='text-table'> {tdues} </h3>          
            <Card>
                <Card.Header onClick={() => handleOpen(!open)}>
                    <Row>
                        <Col className='text-title-black'>
                            {tdues}: {cuota.length}
                        </Col>
                        <Col className='text-title-black'>
                            {tvalue}: {FormatMoney(saldo.valor_inicial)}
                        </Col>
                        <Col className='text-title-black'>
                            {tbalance}: {FormatMoney(saldo.saldo)}
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {showCuotas && <Card.Body>
                    <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th><label className='text-table'>Id</label></th>
                                <th><label className='text-table'>{tvalue}</label></th>
                                <th><label className='text-table'>{tdate}</label></th>
                                <th><label className='text-table'>{tbalance}</label></th>
                                <th><label className='text-table'>{tstate}</label></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cuota.map((cuota, i) => (
                                <ItemDetailCuota cuota={cuota} key={i} />
                            )
                            )}
                        </tbody>
                    </Table>
                </Card.Body>}
            </Card>

        </>
    )
}

export const ListDetailPagos = (props) => {

    const { pago } = props

    const [showPagos, setShowPagos] = useState(true)
    const [open, setOpen] = useState(true)
    let [tvalue, setTvalue] = useState('');
    let [tdate, setTdate] = useState('');
    let [tpayments, setTpayments] = useState("")

    const handleOpen = (flag) => {
        setShowPagos(flag);
        setOpen(flag);
    }

    useEffect(()=>{
        let server = localStorage.getItem('server');
        if(server === '"eeuu"'){
            setTpayments(<FormattedMessage id='payments' defaultMessage={"Pagos"} />)
            setTdate(<FormattedMessage id='date' defaultMessage={"Fecha"} />)
            setTvalue(<FormattedMessage id='value' defaultMessage={"Valor"} />)
           
        }else{
            setTpayments("Pagos")
            setTdate("Fecha")
            setTvalue("Valor del pago")
            
        }
    },[])


    return (
        <>
            <h3 className='text-table'> {tpayments} </h3>
            <Card>
                <Card.Header onClick={() => handleOpen(!open)}>
                    <Row>
                        <Col className='text-title-black'>
                            {tpayments} : {pago.length}
                        </Col>
                        <Col className="text-md-right" lg="1">
                            <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                        </Col>
                    </Row>
                </Card.Header>
                {showPagos && <Card.Body>
                    <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th><label className='text-table'>Id</label></th>
                                <th><label className='text-table'>{tdate}</label></th>
                                <th><label className='text-table'>{tvalue}</label></th>
                            </tr>
                        </thead>
                        <tbody>
                            {pago.map((pago, i) => (
                                <ItemDetailPago pago={pago} key={i} />
                            )
                            )}
                        </tbody>
                    </Table>
                </Card.Body>}
            </Card>
        </>
    )
}