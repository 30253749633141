import React from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { sendTransaction } from '../../../../services/datos.services';
import { ComboBoxForm, InputForm, InputMoney } from '../../../../shared/components/inputs';
import Register from '../../../../shared/components/register';
import { ToastContainer, toast } from 'react-toastify';
import { FormatDateYYMMDD, Capitalize } from '../../../../utilities/formats';


export class Movements extends Register {
    constructor(props) {
        super();

        this.state = {
            valor: 0,
            tipoTransf: '',
            selectedFile: null,
            required: false,
            concepto: {
                name: 'concepto',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            selectedPrice: {
                name: 'selectedPrice',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            descripcion: {
                name: 'descripcion',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            valor: {
                name: 'valor',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            optionConcept: []
        }
        this.allOK = true;
        this.inputsToVerify = [
            'concepto',
            'descripcion',
            'selectedPrice'
        ]
    }

    loadMovement = () => {

        if (this.verifyForm()) {

            let concepto = '';
            let tipo = '';
            let descripcion = '';
            if (this.props.tipoTransf === 'gastos') {
                tipo = 'gasto';
                concepto = 'gasto_concepto_id';
                descripcion = 'gasto_descripcion';
            } else if (this.props.tipoTransf === 'ingresos') {
                tipo = 'ingreso';
                concepto = 'ingreso_tipo_concepto_id';
                descripcion = 'ingreso_descripcion';
            } else if (this.props.tipoTransf === 'retiros') {
                tipo = 'retiro';
                concepto = 'retiro_tipo_concepto_id';
                descripcion = 'retiro_descripcion';
            }

            if (this.props.tipoTransf === 'gastos') {

                const transaction =
                    [
                        {
                            id: -1,
                            detalle: {
                                'caja_id': parseInt(this.props.boxId),
                                'tipo': tipo,
                                [descripcion]: this.state.descripcion.value,
                                [concepto]: parseInt(this.state.concepto.value),
                                'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                                'valor': parseInt(this.state.selectedPrice.value),
                            }
                        }
                    ]

                sendTransaction(transaction, (data, flag) => {
                    if (flag) {
                        this.handleClose()
                        const success = () => toast.success("¡Transacción creada!");
                        success();
                    }
                });

            } else {
                const transaction =
                    [
                        {
                            id: -1,
                            detalle: {
                                'caja_id': parseInt(this.props.boxId),
                                'tipo': tipo,
                                [descripcion]: this.state.descripcion.value,
                                [concepto]: parseInt(this.state.concepto.value),
                                'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                                'valor': parseInt(this.state.selectedPrice.value),
                            }
                        }
                    ]
                sendTransaction(transaction, (data, flag) => {
                    if (flag) {
                        this.handleClose();
                        const success = () => toast.success("¡Modificado!");
                        success();
                    }
                });

            }

        }
    }

    fileChangedHandler = event => {
        this.setState({ selectedFile: event.target.files[0] })
    }

    handleClose = () => {
        this.props.viewModalNewT(false)
    }

    componentDidMount = () => {
        this.setState({ tipoTransf: Capitalize(this.props.tipoTransf) });
        const optionsConcept = [{ title: "Seleccione un opción", value: "seleccione" }];
        const list = this.props.list
        const titulo = Capitalize(this.props.tipoTransf)

        if (list[titulo]) {
            list[titulo].forEach(element => {
                optionsConcept.push({ title: element['concepto'], value: element['id'] })
            });
        }
        this.setState({ optionConcept: optionsConcept });
    };

    render() {

        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <Modal show={true} onHide={this.handleClose}>
                    <Modal.Header style={{borderBottomWidth:3, borderBottomColor:'#02dce8'}}  closeButton>
                        <Modal.Title>
                            <h2 className='text-total'>Crear {this.state.tipoTransf}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <div style={{ margin: '5vh' }}>
                                <Form>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Concepto</label>
                                        <ComboBoxForm
                                            title="Concepto"
                                            field={this.state.concepto}
                                            options={this.state.optionConcept}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            required={true}
                                            initial={this.state.concepto.value}
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Descripción</label>
                                        <InputForm
                                            title="Descripcion"
                                            field={this.state.descripcion}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <Col>
                                        <label className='text-title-black' style={{ fontSize: '14px' }}><span style={{ color: "red" }}>*</span>Valor</label>
                                        <InputMoney
                                            title="Valor"
                                            field={this.state.selectedPrice}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col>
                                            <br />
                                            <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }}  onClick={this.loadMovement}><label className='text-title-black'>Crear {this.props.option}</label></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Container>
                    </Modal.Body>
                </Modal >
            </>
        );
    }
}