import React from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { ComboBoxForm, InputForm } from '../../../shared/components/inputs';
import Register from '../../../shared/components/register';

const optionsConcepto = [
    { title: 'Seleccione ', value: 'seleccione' },
    { title: '1', value: '1' },
    { title: '2', value: '2' }
];

export class Token extends Register {
    constructor(props) {
        super();
        this.state = {
            valor: {
                name: 'valor',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            comboConcepto: {
                name: 'comboConcepto',
                value: "",
                error: '',
                type: "off",
                required: true
            },
        }
        this.allOK = true;
        this.inputsToVerify = [
            'valor',
            'comboConcepto'
        ]
    }

    handleClose = () => {
        this.props.setShowModalToken(false)
    }

    registrar = () => {

        if (this.verifyForm()) {
            // const objeto = {
            //     valor: this.state.valor.value,
            //     concepto: this.state.comboConcepto.value,
            // }
              }
    }

    render() {
        return (
            <>
              <Modal
                centered
                show={true}
                onHide={this.handleClose}
            >

                <Modal.Header closeButton>
                 
                </Modal.Header>
                <Modal.Body>
                   
                <div style={{ paddingTop: '60px', margin: '40px' }} >
                    <h1 style={{ textAlign: "center", color: "black" }}>Token</h1>
                    <Card style={{ margin: '60px' }}>
                        <Card.Body style={{ textAlign: "center" }} >

                            <Form>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
                                        <ComboBoxForm
                                            title="Concepto"
                                            field={this.state.comboConcepto}
                                            options={optionsConcepto}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm>
                                        <InputForm
                                            title="Valor"
                                            field={this.state.valor}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            placeholder="Ingrese el valor"
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                            </Form>
                        </Card.Body>
                        <div>

                            <h3 style={{ textAlign: "center" }}>Token de vendedor</h3>

                            <br />
                            <div style={{
                                 margin: '0 auto',
                                backgroundColor: 'red',
                                height: '100px',
                                width: '30%',
                            }}>
                            </div>
                            <br />
                        </div>
                        <Row>
                            <Col>
                                
                            </Col>
                            <Col>
                                <Button style={{ backgroundColor: '#31DBED'  }} onClick={this.registrar} className="btn btn-warning">Confirmar</Button>
                            </Col>
                            <Col>
                            
                            </Col>
                            
                        </Row>
                    </Card>
                </div>
                </Modal.Body>
            </Modal >


            </>
        )
    }
}


