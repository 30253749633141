import React, {useState} from "react";
import english from '../lang/english.json'
import spanish from '../lang/spanish.json'
import {IntlProvider} from 'react-intl'

const langContext = React.createContext();

const LangProvider = ({children}) =>{
    let localeDefault;
    let messagesDefault;
    let lang = localStorage.getItem('language')
    if(lang === 'es-ES'){
        localeDefault = lang;
        messagesDefault = spanish
    }else if(lang === 'en-US'){
        localeDefault = lang;
        messagesDefault = english
    }else{
        localeDefault = 'en-US';
        messagesDefault = english
    }
    const [messages, setMessages] = useState(messagesDefault);
    const [locale, setLocale] = useState(localeDefault);

    const changeLanguage =  (language) =>{
      switch(language){
        case 'es-ES':
            setMessages(spanish)
            localStorage.setItem('language','es-ES')
            setLocale('es-ES');
            break;
        case 'en-US':
            localStorage.setItem('language','en-US')
            setMessages(english)
            setLocale('en-US');
            break;
        default:
            localStorage.setItem('language','en-US')
            setMessages(english)
            setLocale('en-US');


      }

    }

    return(
        <langContext.Provider value = {{changeLanguage}}>
            <IntlProvider locale={locale} messages={messages}>
            {children}
            </IntlProvider>
            
        </langContext.Provider>
    );

}

export {LangProvider, langContext}