import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faInfo, faTired, faGrinBeam, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import ListaDetalleCartera from './elmentosDeModales/listaDetalleCartera';
import { UpdateCliente } from '../../updateCliente/updateCliente';
import { FormatMoney } from '../../../../utilities/formats';
import { activarUser, getCreditsAdmin, inactivarUser } from '../../../../services/datos.services';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { ListCreditsUserAdmin } from '../components/list'


export const List = ({ data, updateList })  => {

    const [active, setActive] = useState(true);
    const [show, setShow] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [id, setId] = useState('');
    const [cuota, setCuota] = useState([]);
    const [pago, setPago] = useState([]);
    const [datas, setData] = useState([]);
    const [showCredits, setShowCredits] = useState(false);

    const handleCloseDetail = () => setShowDetail(false);
    const handleShowDetail = () => setShowDetail(true);
    const handleClose = () => setShowModalEditar(false);
    const handleShow = () => setShowModalEditar(true);

    const close = () => {
        setShow(false);
    }

    const showHistory = () => {
        setShow(true);
        creditsClient();
    }

    useEffect(() => {
        getCreditsAdmin(data.usuario_id, (data, flag) => {
            if (flag) {

                if (data.length > 0) {
                    setHistoryData(data);
                }
            }

        }, null, true, data.estado_usuario)

    }, []);
    const creditsClient = () => {
        getCreditsAdmin(data.usuario_id, (data, flag) => {
            if (flag) {

                if (data.length > 0) {
                    setData(data);
                }
            }

        }, null, true, data.estado_usuario)
    }
    const cuotas = (data) => {
        setCuota(data.detalles)
    }

    const pagos = (data) => {
        setPago(data.pagos)
    }

    const dataSaldo = (data) => {
        setData(data)
    }

   
    const activar = (uid, active) => {

        if (active) {

            if (window.confirm('¿Desea inactivar al usuario?')) {

                inactivarUser(uid, (data, flag) => {
                    if (flag) {

                        setActive(!active);
                        updateList(false);
                        const success = () => toast.success("¡El usuario ha sido inactivado!");
                        success();

                    } else {
                        const success = () => toast.success("¡El usuario sigue disponible!");
                        success();
                    }
                })
            }

        } else {

            if (window.confirm('¿Desea activar al usuario?')) {

                activarUser(uid, (data, flag) => {

                    if (flag) {

                        updateList(true);
                        setActive(!active);
                        const success = () => toast.success("¡El usuario ha sido activado!");
                        success();

                    } else {
                        const success = () => toast.success("¡El usuario sigue disponible!");
                        success();
                    }
                })

            }
        }

    }

    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 85% !important; }
                `}
            </style>
            <ToastContainer style={{ marginTop: 50, fontSize: 16 }} autoClose={10000} />
            <tr>
                <td>{data.estado}</td>
                <td>{data.nombre}</td>
                <td>{data.id}</td>
                <td>{FormatMoney(data.valor_inicial)}</td>
                <td>{FormatMoney(data.saldo)}</td>
                <td>{FormatMoney(data.saldo)}</td>
                <td>{data.vencidas}</td>
                <td>{data.cuotas_pagas.toFixed(2)}</td>
                <td>{(1-data.cuotas_pagas).toFixed(2)}</td>
                <td>{data.fecha}</td>
                <td>{data.fecha_vencimiento}</td>
                <td >
                    
                    <div>
                        <button className="show-btn"
                            size="sm"
                            variant="light"
                            style={{ borderRadius: 50, borderColor:'transparent' }}
                            onClick={handleShow}
                        >
                            <FontAwesomeIcon
                                style={{ color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                                icon={faUserEdit}
                            />
                        </button>
                        <button className="show-btn"
                            size="sm"
                            variant="light"
                            style={{ borderRadius: 50,borderColor:'transparent' }}
                            onClick={handleShowDetail}
                        >
                            <FontAwesomeIcon
                                style={{color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                                icon={faInfo}
                            />
                        </button>
                    </div>
                    <div>
                        <button className="show-btn"
                        size="sm"
                        variant="light"
                        style={{color:'#02dce8', height: '35px', width: '35px', borderColor:'transparent'}}
                        onClick={() => { activar(data.usuario_id, data.estado_usuario) }}
                        >
                        {active && <FontAwesomeIcon
                            style={{color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={data.estado_usuario ? faGrinBeam : faTired}
                        />}
                        {!active && <FontAwesomeIcon
                            style={{ height: '35px', width: '35px', marginTop: 2, color:'#02dce8'}}
                            icon={data.estado_usuario ? faGrinBeam : faTired}
                        />}
                    </button>
                    <button className="show-btn"
                        size="sm"
                        variant="light"
                        style={{color:'#02dce8', height: '35px', width: '35px', borderColor:'transparent'}}
                        onClick={showHistory}
                    >
                        <FontAwesomeIcon
                            style={{color:'#02dce8', height: '35px', width: '35px', marginTop: 2 }}
                            icon={faFileInvoice}
                        />
                    </button>

                    </div>
                </td>
            </tr>
            {showModalEditar && <UpdateCliente dataUser={data} setShowModalEditar={(flag) => setShowModalEditar(flag)} />}
            {showDetail && <ListaDetalleCartera show={showDetail} onHide={handleCloseDetail} nombreCliente={data.nombre} cuotas={data.detalle} pagos={data.pago} />}
            {show &&
                <Modal
                    dialogClassName="modal-90w" show={show} onHide={close} centered>
                    <Modal.Header style={{borderBottomWidth:3,borderBottomColor:'#02dce8'}} closeButton>
                        <Modal.Title className='text-total'>Historial</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListCreditsUserAdmin isAdmin={show} saldo={dataSaldo} idCredit={(id) => setId(id)} setShow={(flag) => setShowCredits(flag)} data={datas} />
                    </Modal.Body>
                </Modal>

            }
            

        </>
    );
}
