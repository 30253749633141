import React, {useEffect, useState} from 'react';
import { Row ,Col,Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import {FormattedMessage} from 'react-intl'
import '../about-us/about.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGetPocket } from '@fortawesome/free-brands-svg-icons';
export const FrequentQuestions = () => {
    let [queTanRapido,setQueTanRapido] = useState('');
    let [deboTener,setDeboTener] = useState('');
    let [comoProteger,setComoProteger] = useState('');
    let [queMicrocredito,setQueMicrocredito] = useState('');
    let [showOne,setShowOne]=useState(false)
    let [showTwo,setTwo]=useState(false)
    let [showThree,setShowThree]=useState(false)
    let [showFour,setShowFour]=useState(false)
    let [showFive,setShowFive]=useState(false)
    useEffect(() => {
        const server = localStorage.getItem('server');
        if(server==='"eeuu"'){
            
             }else{
                setQueTanRapido('Es posible que la aprobación del crédito pueda ser rápida, generalmente en el mismo día de la solicitud, es probable que el dinero solo pueda ser percibido tan pronto como el siguiente día.') 
                
                setDeboTener('VENGALEPRESTO SAS, si bien no se requiere un historial crediticio previo para calificar, se solicitarán y considerarán informes crediticios para todos los solicitantes')
                setComoProteger('Al transmitir sus datos personales a nuestra red de prestamistas, nos aseguraremos de que su información esté segura y protegida. Conservamos tu información confidencialmente y únicamente para los fines relacionados con tu crédito. Mayor información en nuestras políticas de privacidad.') 
                setQueMicrocredito('Las personas obtienen microcréditos para ayudar con las necesidades del hogar o emergencias económicas que puedan presentar. ¡Un crédito de este tipo puede ayudarte a obtener el efectivo que necesitas!, cuando completes los formularios en línea, el monto de dinero solicitado será depositado en tu cuenta bancaria máxima al día siguiente para que hagas uso de la misma.')
           
        }
      }, []);
    return (
        
            <div style={{color:'rgb(255, 255, 255)',fontWeight:'bold',maxWidth:'100%', marginTop:'5vh',alignSelf:'center',bottom:10}} class="col justify-content-center">
                    
                    <Col  xs={12} md={10}sm={10} lg={6} style={{top:'10%',left:0,bottom:0,right:0,margin:'auto'}}>
                        <div> 
                            <h1 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4 Titulo_preg_frec" style={{textDecoration:'underline', color:'rgb(2, 220, 232)', fontSize:'3.5ch'}}>Preguntas frecuentes</h1>
                        </div>
                    </Col>
                    <Col  xs={12} md={10}sm={10} lg={8} style={{top:'10%',left:0,bottom:0,right:0,margin:'auto'}}>
                            <div>
                            <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)',marginTop:30}}>
                                <h4 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4" 
                                    style={{color:'black',textAlign:'center', fontSize:'140%'}}>¿Qué tan rápido puedo obtener mi dinero?</h4>
                                <FontAwesomeIcon 
                                    onClick={()=>setShowOne(!showOne)} 
                                    style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showOne ? 'rotate(3.142rad)':'' }} 
                                    className="mr-2 align-self-center" 
                                    icon={faGetPocket}  />
                            </div>
                            <p style={{textAlign:'justify',color:'black'}} >
                                {showOne&&queTanRapido}
                            </p>
                        </div>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                  <h4 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4 negrita" 
                                      style={{color:'black',left:0, fontSize:'140%'}}>¿Que requisitos debo tener para mi crédito?</h4>  
                                  <FontAwesomeIcon 
                                        onClick={()=>setTwo(!showTwo)} 
                                        style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showTwo ? 'rotate(3.142rad)':'' }} 
                                        className="mr-2 align-self-center" 
                                        icon={faGetPocket}  />
                                </div>
                                {showTwo&&<div>
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        Para poder acceder a tu credito debes:
                                    </p>
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        1. Ser mayor de edad
                                    </p>
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        2. Tener linea telefonica propia
                                    </p> 
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        3. Tener un E-mail personal
                                    </p> 
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        4. Poseer un cuenta bancaria a tu nombre
                                    </p>
                                    <p style={{textAlign:'justify',color:'black'}}>
                                        5. Tener infromes y/o extractos bancarios
                                    </p>     
                                </div>}
                                
                            </div>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                   <h4 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4" 
                                       style={{color:'black', fontSize:'140%'}}>¿Debo tener historial crediticio?</h4> 
                                   <FontAwesomeIcon 
                                        onClick={()=>setShowThree(!showThree)} 
                                        style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showThree ? 'rotate(3.142rad)':'' }} 
                                        className="mr-2 align-self-center" 
                                        icon={faGetPocket}  />
                                </div>
                                <p style={{textAlign:'justify',color:'black'}}>
                                    {showThree&&deboTener}
                                </p>
                            </div>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                   <h4 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4" 
                                       style={{color:'black', fontSize:'140%'}}>¿Como proteger mi privacidad e información?</h4> 
                                   <FontAwesomeIcon 
                                        onClick={()=>setShowFour(!showFour)} 
                                        style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showFour ? 'rotate(3.142rad)':'' }} 
                                        className="mr-2 align-self-center" 
                                        icon={faGetPocket}  />
                                </div>
                                <p style={{textAlign:'justify',color:'black'}}>
                                    {showFour&&comoProteger}
                                </p>
                            </div>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                   <h4 className="font-weight-bold mx-auto text-uppercase mb-1 mt-4" 
                                       style={{color:'black', fontSize:'140%'}}>¿Que es un microcrédito y para qué puedo usarlo?</h4> 
                                   <FontAwesomeIcon 
                                        onClick={()=>setShowFive(!showFive)} 
                                        style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showFive ? 'rotate(3.142rad)':'' }} 
                                        className="mr-2 align-self-center" 
                                        icon={faGetPocket}  />
                                </div>
                                <p style={{textAlign:'justify',color:'black'}}>
                                    {showFive&&queMicrocredito}
                                </p>
                            </div>  
                    </Col>
        </div>
        
    )
}
