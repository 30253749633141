import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import { getHistorial } from '../../../services/datos.services';
import { ListCreditsUser } from './components/list';
import { ModalCuotas } from './components/modalCuotas';


export const CreditsHistory = (props) => {

    const { saldoTotal } = props;
    const [show, setShow] = useState(false);
    const [historyData, setHistoryData] = useState([]);

    const [id, setId] = useState('');
    const [cuota, setCuota] = useState([]);
    const [pago, setPago] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        getHistorial(null, (data) => {
            setHistoryData(data.sort((a,b) =>{
                return (a.id > b.id) ? -1 : 1
            }) );
        })

    }, []);

    const cuotas = (data) => {
        setCuota(data.detalles);
    }

    const pagos = (data) => {
        setPago(data.pagos)
    }

    const dataSaldo = (data) => {
        setData(data)
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Container>
                    <ListCreditsUser pagos={pagos} cuotas={cuotas} saldoTotal={saldoTotal} saldo={dataSaldo} idCredit={(id) => setId(id)} setShow={(flag) => setShow(flag)} data={historyData} />
                </Container>
            </div>
            <ModalCuotas cuota={cuota} pago={pago} saldo={data} id={id} show={show} close={() => setShow(false)} />
        </>
    )
}

