
import React from 'react';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { getHijos } from '../../../services/datos.services';
import { ListSons } from './components/list';
import { WalletClient } from './components/wallet-user-details';
import {  FormatMoney } from '../../../utilities/formats';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { PureComponent } from 'react';
import GridLoader from 'react-spinners/GridLoader';

export class Cartera extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            idAdmin: 0,
            adminName: '',
            listName: [],
            listSons: [],
            hijos: [],
            admins: [],
            list: [],
            loading:false,
            listAdmin: [],
            showDetalleCartera: false,
            showClientCartera: false,
            uid: '',
            nameSheller: '',
            saldoT: 0,
            tCreditos: 0,
        }
    }
    filterData = (hijos, buscar) => {
        let list = [];

        if (buscar && buscar !== '') {

            hijos.forEach(element => {
                if (element.nombre.toLowerCase().includes(buscar.toLowerCase())) {
                    list.push(element)
                }
                return this.setState({ list: list })
            })
        } else {

            hijos.forEach(element => {
                list.push(element)
            });
            return this.setState({ list: list })
        }
    }

    filterAdmin = (hijos, buscar) => {
        let list = [];

        if (buscar !== '') {
            hijos.forEach(element => {

                if (element.nombre.toLowerCase().includes(buscar.toLowerCase())) {
                    list.push(element)
                }
                return this.setState({ admins: list })
            })
        } else {
            return this.setState({ admins: this.state.listAdmin })
        }
    }

    traerHijos = (id, call) => {
        if (!id) {
            id = parseInt(localStorage.getItem("uid"));
        }
        getHijos(id, '', call);
    };
    showSeller = (id, name) => {
        let nameList = this.state.listName.concat(name);
        this.setState({ listName: nameList });
        getHijos(id, '', this.callBack);
    }

    callBack = (data, flag, id) => {
        this.setState({loading:false})
        if (flag) {
            if (this.state.listSons.length > 0) {
                let flag = false;
                this.state.listSons.forEach(element => {
       
       

                    if (element === id) {
                        flag = true;
                        return
                    }
                })
                if (!flag) {
                    let sonL = this.state.listSons.concat(id);
                    this.setState({ listSons: sonL });
                }

            } else {
                let sonL = this.state.listSons.concat(id);
                this.setState({ listSons: sonL });
            }

            let administradores = [];
            let sons = [];
            let saldo= 0;
            let creditos =0;
            data.caja_ids.forEach(element => {
                saldo += element.saldoVencidoCartera;
                creditos += element.debidoCobrarCantidad;

               
                if (element.permiso === "administrador") {
                    if (element.id === parseInt(localStorage.getItem("uid"))) {
                        this.setState({
                            adminName: `${element.nombre1 ? element.nombre1 : ''} ${element.nombre2 ? element.nombre2 : ''} ${element.apellido1 ? element.apellido1 : ''} ${element.apellido2 ? element.apellido2 : ''}`,
                            cantidadClientes: element.cantidadClientes
                        })
                    }
                    let admin = {
                        nombre: `${element.nombre1 ? element.nombre1 : ''} ${element.nombre2 ? element.nombre2 : ''} ${element.apellido1 ? element.apellido1 : ''} ${element.apellido2 ? element.apellido2 : ''}`,
                        id: element.id
                    }
                    administradores.push(admin)
                } else {
                    let hijo = {
                        nombre: `${element.nombre1} ${element.nombre2} ${element.apellido1} ${element.apellido2}`,
                        id: element.id,
                        debidoCobrarCantidad: element.debidoCobrarCantidad,
                        debidoCobrarValor: element.debidoCobrarValor,
                        saldoVencidoCartera: element.saldoVencidoCartera,
                        valorCartera: element.valorCartera,
                        saldoCartera: element.saldoCartera,
                    }
                    sons.push(hijo)
                    
                    
            
               
                    this.setState({
                        saldoT:saldo,
                        tCreditos:creditos,
                    });
                    
                    
                }
            });

            this.setState({
                hijos: sons,
                admins: administradores,
                listAdmin: administradores,
                
            });
            this.filterData(sons);
        }
    }

    goBack = () => {
        let children = this.state.listSons;
        let nameAdmin = this.state.listName;

        if (children.length > 1) {
            children.pop();
            nameAdmin.pop()
            this.setState({ adminName: nameAdmin[nameAdmin.length - 1] })
            getHijos(children[children.length], '', this.callBack);
        }
    }

    componentDidMount = () => {
        this.setState({loading:true})
        this.traerHijos(null, this.callBack);
    }

    render() {
        let nombreusuario = localStorage.getItem('nameUser');
        
        
        return (
            <>
                <style>
                    {`
                        .estilo-card
                        {
                            box-shadow: 0px 2px 4px 1px rgba(100,100,100,0.17); 
                            border-radius: 0.5rem;     
                        }                 
                    `}
                </style>
                <div style={{ borderBottomColor:'#02dce8', paddingTop: 70 }}>

                    <div className="container" style={{ textAlign: 'center' }}>

                        <label className='text-total' style={{ fontSize: 26 }}>
                            <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faWallet} />
                            Cartera
                        </label>
                    </div>
                </div>

                <Container style={{ marginTop: 15 }}>
                    <Row className="justify-content-md-center" style={{ marginBottom: 15 }}>
                        <Col md={2}><label className='text-title-black'>Total Creditos: {this.state.tCreditos}</label> </Col>
                        <Col md={2}><label className='text-title-black'>Saldo: {FormatMoney(this.state.saldoT)}</label> </Col>
                    </Row>
                    <Row >
                        <Col lg="2" className="estilo-card" style={{ marginBottom: 10, borderWidth:1,borderColor:'red' }} >
                            <div style={{ marginBottom: 10 }}>
                                <input
                                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                                    className="form-control text-title-black"
                                    type="text"
                                    placeholder="Buscar Admin"
                                    onChange={(e) => this.filterAdmin(this.state.admins, e.target.value)}
                                />
                            </div>
                            {this.state.listSons.length > 1 && <div>
                                <Button
                                    className="show-btn"
                                    variant="light"
                                    style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }}
                                    onClick={() => this.goBack()}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowAltCircleLeft}
                                    />
                                </Button>

                            </div>}
                            {this.state.admins.map((admin, i) => {
                                return (
                                    <Button
                                        active={nombreusuario === admin.nombre ? true : false}
                                        key={i}
                                        variant="outline-info"
                                        onClick={() => { this.showSeller(admin.id, admin.nombre); this.setState({ adminName: admin.nombre }) }}
                                        style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }}
                                    >
                                        <label className='text-title-black'>{admin.nombre}</label>
                                    </Button>
                                )
                            })}

                        </Col>
                        <Col >

                            <Row style={{ marginBottom: 10 }}>
                                <Col md={4}><label className='text-total'> Cartera de: {this.state.adminName} </label></Col>
                                <Col md={{ span: 4, offset: 4 }}>
                                    <input
                                        style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                                        className="form-control text-title-black"
                                        type="text"
                                        placeholder="Buscar Usuario"
                                        onChange={(e) => this.filterData(this.state.hijos, e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Table style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px gray' }} responsive striped bordered hover size="sm">

                                <thead>
                                    <tr>
                                        <th ><label className='text-total'>Vendedor</label></th>
                                        <th colSpan='2' className='text-total'>Debido cobrar</th>
                                        <th colSpan='3' className='text-total'>Cartera</th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th className='text-title-blue'>Nombre</th>
                                        <th className='text-title-blue'>Cantidad</th>
                                        <th className='text-title-blue'>Valor</th>
                                        <th className='text-title-blue'>Valor inicial</th>
                                        <th className='text-title-blue'>Valor Vencido</th>
                                        <th className='text-title-blue'>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody className='text-title-black'>
                                    {this.state.list.map((son, i) => (
                                        <ListSons item={son} key={i} showClient={(flag, uid, nameSheller) => this.setState({ showClient: flag, uid, nameSheller })} />
                                    )
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                {this.state.showClient && <WalletClient showClient={(flag) => this.setState({ showClient: flag })} uid={this.state.uid} nameSheller={this.state.nameSheller} />}
                <GridLoader  size={50} loading={this.state.loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} />
            </>
        )
    }
}