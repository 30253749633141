import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Movements } from '../../../../pages/client/movements/components/movements';
import { faPlus, faEdit, faImages, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from '../../../../utilities/formats';
import { ListRequest } from '../../../../pages/admin/box-detail/components/listRequest';
import { getHistorial, getRequest, getEvidence } from '../../../../services/datos.services'; 
import NumberFormat from 'react-number-format';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import '../../../../../src/App.css';
import { ListBill } from '../../../../pages/admin/box-detail/components/listBill';

export const ListGastoIngresoRetiro = (props) => {
    const { title, details, updateValue, option, boxId } = props;
    const [viewModalNewT, setViewModalNewT] = useState(false);
    const [editar, setEditar] = useState(false);
    const [value, setValue] = useState(0);
    const [item, setItem] = useState({});
    const [picture, setPicture] = useState(false);
    const [map, setMap] = useState(false);
    const [list, setList] = useState({});
    const [geolocation, setGeolocation] = useState([59.95, 30.33]);
    const [evidence, setEvidence] = useState('');
    const [descripcion, setDescripcion] = useState('');

    let valor = '';

    const handleClose = () => { setEditar(false); valor = '' };
    const closePicture = () => {
        setEvidence('');
        setPicture(false);
    };
    const closeMap = () => {
        setMap(false);
    };

    const save = () => {
        valor = parseInt((valor.split(' ')[1]).replace(',', '').replace(',', ''));
        updateValue(item, valor);
        setEditar(false);
    }

    const handleShow = (item) => {
        setItem(item);
        setValue(item.valor);
        setEditar(true);
    }

    const showPicture = (id) => {
        getEvidence(id, (data, flag) => {
            if (flag) {
                if (data.fotos_id.length > 0) {
                    setEvidence(data.fotos_id[0].foto)
                } else {
                    setEvidence('')
                }
            }
        })
        setPicture(true)
    }

    const handleChange = (value) => setValue(value);

    const inicialiceData = () => {
        const conceptos = JSON.parse(localStorage.getItem('conceptos'));
        const optionsConcept = [];
        let detailList = {};
        if (conceptos) {
            conceptos.forEach(element => {
                if (element.tipo === 'Gastos') {
                    if (detailList['Gastos']) {
                        detailList.Gastos.push(element)
                    } else {
                        detailList['Gastos'] = []
                        detailList.Gastos.push(element)
                    }
                } else if (element.tipo === 'Ingresos') {
                    if (detailList['Ingresos']) {
                        detailList.Ingresos.push(element)
                    } else {
                        detailList['Ingresos'] = []
                        detailList.Ingresos.push(element)
                    }
                } else if (element.tipo === 'Retiros') {
                    if (detailList['Retiros']) {
                        detailList.Retiros.push(element)
                    } else {
                        detailList['Retiros'] = []
                        detailList.Retiros.push(element)
                    }
                }
            });
        }
        setList(detailList)
    }

    useEffect(() => {
        inicialiceData();

    }, []);
    const prueba = (detail) => {
        fetch('http://45.56.122.125/nominatim/reverse?format=json&lat=' +
                detail.latitud + '&lon=' +
                detail.longitud + '&zoom=18&addressdetails')

                .then((response) => response.json()).then((responseJson) => {
                })
                .catch((error) => {
                    console.error(error);
                });
    }
    return (
        <>
            <style>
                {`
                    .show-btn{
                    height: 5px;
                    width: 5px;
                    margin: 1px 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    }
                    .show-btn:hover {
                    height: 35px;
                    width: 35px;
                    }
                `}
            </style>

            <Row>
                <Col className="text-md-center" >
                    <h4 className='text-total'>{title.toUpperCase()}</h4>
                </Col>
                <Col lg="1">
                    <Button
                        className="show-btn"
                        size="sm" variant="light"
                        style={{ borderRadius: 50, width: '40px', height: '40px' }}
                        onClick={() => setViewModalNewT(true)}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginTop: '3px', width: 20, height: 20 }}
                        />
                    </Button>
                </Col>
            </Row>
            <hr />
            <Table responsive striped bordered hover size="sm">
                <thead className='text-title-blue'>
                    <tr>
                        <th>Id</th>
                        <th>Descripcion</th>
                        <th>Valor</th>
                        <th>Fecha</th>
                        <th style={{ width: 80 }}>Evidencia</th>
                    </tr>
                </thead>
                <tbody className='text-title-black'>
                    {details ? details.map((detail, i) => (

                        <tr key={i}>
                            <td>{detail.id}</td>
                            <td>{detail.descripcion} </td>
                            <td>
                                <div className="row">
                                    <div className="col">
                                        {FormatMoney(detail.valor)}
                                    </div>
                                    <div className="col-tex-right" style={{ paddingRight: "10%" }}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{ height: 20, width: 20, marginLeft: 20 }}
                                            cursor="pointer"
                                            onClick={() => handleShow(detail)}
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>{detail.fecha}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div >
                                        <FontAwesomeIcon
                                            icon={faImages}
                                            style={{ height: 20, width: 20 }}
                                            cursor="pointer"
                                            onClick={() => showPicture(detail.id)}
                                        />
                                    </div>
                                    <div >
                                        <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                            style={{ height: 20, width: 20, marginLeft: 20 }}
                                            cursor="pointer"
                                            onClick={() => {prueba(detail); setGeolocation([detail.latitud, detail.longitud]); setMap(true); setDescripcion(detail.descripcion) }}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </Table>

            {viewModalNewT &&
                <Movements
                    boxId={boxId}
                    list={list}
                    option={option}
                    viewModalNewT={(flag) => setViewModalNewT(flag)}
                    tipoTransf={title}
                />
            }

            {editar &&
                <Modal show={editar} onHide={handleClose} style={{ width: "100%", textAlign: "center" }}>
                    <Modal.Header style={{borderBottomWidth:3,borderBottomColor:'#02dce8'}} closeButton>
                        <Modal.Title className='text-total'>
                            Editar el valor
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "20px 20% 0 20%" }}>
                        <div>
                            <h5 className='text-title-black'>Ingrese un valor</h5>
                            <NumberFormat
                                style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                                className="form-control text-title-black"
                                thousandSeparator={true}
                                prefix='$ '
                                maxLength={11}
                                value={valor}
                                placeholder={'Nuevo valor'}
                                onChange={(event) => valor = event.target.value}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={handleClose}>
                           <label className='text-title-black'> Cancelar</label> 
                        </Button>
                        <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={save}>
                        <label className='text-title-black'>  Guardar</label> 
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            { picture &&
                <Modal show={picture} onHide={closePicture} centered>
                    <Modal.Body >
                        {evidence !== '' && <img src={`data:image/jpeg;base64,${evidence}`} alt="Evidencia" className="w3-left w3-circle w3-margin-right" width="100%" height="100%" />}
                       
                    </Modal.Body>
                </Modal>
            }
            { map &&

                <Modal show={map} onHide={closeMap} style={{ width: "100%", textAlign: "center" }}>
                    <Modal.Header style={{borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                    <Modal.Title className='text-total'>
                            Evidencia
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MapContainer center={geolocation} zoom={17} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={geolocation}>
                                <Popup>
                                    {descripcion}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export const ListSolicitudesCreditosRenovaciones = (props) => {
    const { title, details, id } = props;

    const [viewModalNewT, setViewModalNewT] = useState(false);
    const [show, setShow] = useState(true);
    const [value, setValue] = useState(0);
    const [item, setItem] = useState({});
    const [data, setData] = useState([]);
    const [map, setMap] = useState(false);
    const [geolocation, setGeolocation] = useState([59.95, 30.33]);
    const [descripcion, setDescripcion] = useState('');

    const closeMap = () => setMap(false);
    const handleClose = () => setShow(false);

    const request = () => {
        getRequest(id, (data, flag) => {
            if (data) {
                setData(data.solictud_ids);
            }
        });
    }

    useEffect(() => {
        request();
    }, []);

    return (
        <>
            <style>
                {`
                    .show-btn{
                    height: 5px;
                    width: 5px;
                    margin: 1px 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    }
                    .show-btn:hover {
                    height: 35px;
                    width: 35px;
                    }
            
                `}
            </style>

            <Row>
                <Col className="text-md-center" >
                    <h4 className='text-total'>{title.toUpperCase()}</h4>
                </Col>
            </Row>
            <hr />
            <Table responsive striped bordered hover size="sm">
                <thead className='text-title-blue'>
                    <tr>
                        <th>Id</th>
                        <th>Cliente</th>
                        <th>Cuotas</th>
                        <th>Interes</th>
                        <th>Valor</th>
                        <th>Fecha</th>
                        <th style={{ width: 80 }}>Evidencia</th>
                    </tr>
                </thead>
                <tbody className='text-title-black'>
                    {details ? details.map((detail, i) => (
                        <tr key={i}>
                            <td>{detail.id}</td>
                            <td>{detail.nombre}</td>
                            <td>{detail.cuotas}</td>
                            <td>{detail.interes} </td>
                            <td>{detail.valor}</td>
                            <td>{detail.fecha}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <FontAwesomeIcon
                                        icon={faMapMarkedAlt}
                                        style={{ height: 20, width: 20 }}
                                        cursor="pointer"
                                        onClick={() => { setGeolocation([detail.latitud, detail.longitud]); setMap(true); setDescripcion(detail.descripcion) }}
                                    />
                                </div>
                            </td>

                        </tr>
                    )) : <></>}

                </tbody>
            </Table>
            {/* {viewModalNewT &&
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <ListRequest title={title} data={data} />
                    </Modal.Body>
                </Modal >
            } */}
            { map &&

                <Modal show={map} onHide={closeMap} style={{ width: "100%", textAlign: "center" }}>
                    <Modal.Header style={{borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                    <Modal.Title className='text-total'>
                            Evidencia
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MapContainer center={geolocation} zoom={17} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={geolocation}>
                                <Popup>
                                    {descripcion}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export const ListPagosAndNoPagos = (props) => {
    const { title, details, id, updateValue } = props;
    const [viewModalNewT, setViewModalNewT] = useState(false);
    const [show, setShow] = useState(true);
    // const [editar, setEditar] = useState(false);
    // const [value, setValue] = useState(0);
    // const [item, setItem] = useState({});
    const [data, setData] = useState([]);
    const [map, setMap] = useState(false);
    const [geolocation, setGeolocation] = useState([59.95, 30.33]);
    const [descripcion, setDescripcion] = useState('');

    let valor = '';


    const closeMap = () => setMap(false);
    // const closeEdit = () => { setEditar(false); valor = '' };
    const handleClose = () => setShow(false);


    const history = () => {
        getHistorial(id, (data, flag) => {
            if (data) {
                setData(data);
            }
        });
    }

    

    useEffect(() => {
        history();
    }, []);

    return (
        <>
            <style>
                {`
                    .show-btn{
                    height: 5px;
                    width: 5px;
                    margin: 1px 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                    }
                    .show-btn:hover {
                    height: 35px;
                    width: 35px;
                    }            
                `}
            </style>

            <Row>
                <Col className="text-md-center" >
                    <h4 className='text-total'>{title.toUpperCase()}</h4>
                </Col>
            </Row>
            <hr />
            <Table responsive striped bordered hover size="sm">
                <thead className='text-title-blue'>
                    <tr>
                        <th>Id</th>
                        <th>Descripcion</th>
                        <th>Valor</th>
                        <th>Fecha</th>
                        <th style={{ width: 80 }}>Evidencia</th>
                    </tr>
                </thead>
                <tbody className='text-title-black'>
                    {details ? details.map((detail, i) => (
                        <tr key={i}>
                            <td>{detail.id}</td>
                            <td>{detail.descripcion}</td>
                            <td>{FormatMoney(detail.valor)}
                                {/*<div className="row">
                                    <div className="col">    {FormatMoney(detail.valor)}    </div>
                                     <div className="col-tex-right" style={{ paddingRight: "10%" }}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            style={{ height: 20, width: 20, marginLeft: 20 }}
                                            cursor="pointer"
                                            onClick={() => handleShow(detail)}
                                        />
                                    </div> 
                                </div>*/}
                            </td>
                            <td>{detail.fecha}</td>
                            <td>
                                <div style={{ display: 'flex', justifyContent: 'center' }} >
                                    <FontAwesomeIcon
                                        icon={faMapMarkedAlt}
                                        style={{ height: 20, width: 20 }}
                                        cursor="pointer"
                                        onClick={() => { setGeolocation([detail.latitud, detail.longitud]); setMap(true); setDescripcion(detail.descripcion) }}
                                    />
                                </div>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </Table>
            {viewModalNewT &&
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <ListBill title={title} data={data} id={id} />
                    </Modal.Body>
                </Modal >
            }
            
            { map &&

                <Modal show={map} onHide={closeMap} style={{ width: "100%", textAlign: "center" }}>
                    <Modal.Header style={{borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                        <Modal.Title className='text-total'>
                            Evidencia
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MapContainer center={geolocation} zoom={17} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={geolocation}>
                                <Popup>
                                    {descripcion}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

