import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import NumberFormat from 'react-number-format';
import './../../pages/home/about-us/about.css'
import { Capitalize, FormatDateYYMMDD } from '../../utilities/formats';

// Campos de solo texto

export const InputForm = (props) => {
    const {  disabled, field = {}, type = "", handleChange, maxLength, onBlur = () => { } } = props
    return (
        <div className="form-group">
           
            <input
                style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                className="form-control text-title-black"
                value={field.value}
                type={type}
                onChange={(e) => handleChange(field.name, e.target.value)}
                onBlur={() => onBlur(field.name)}
                disabled={disabled}
                maxLength={maxLength}
            >
            </input>
            <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
        </div>
    )
}
// Campos de solo numeros
export const InputNumber = (props) => {
    const { disabled, field = {}, type = "", handleChange, onBlur = () => { } } = props
    if (type === "password") {
        return (
            <div className="form-group">

                <input
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
                    className="form-control text-title-black"
                    value={field.value}
                    type={type}
                    onChange={(e) => handleChange(field.name, e.target.value.replace(/\D/, ''))}
                    onBlur={() => onBlur(field.name)}
                    
                    
                >
                </input>
                <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
            </div>
        )

    } else {
        return (
            <div className="form-group">
                <input
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
                    className="form-control text-title-black"
                    value={field.value}
                    type={type}
                    onChange={(e) => handleChange(field.name, e.target.value.replace(/\D/, ''))}
                    onBlur={() => onBlur(field.name)}
                    disabled={disabled}
                >
                </input>
                <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
            </div>
        );
    }

}

export const InputMoney = (props) => {
    const { field = {}, type = "", handleChange, placeholder = "", onBlur = () => { } } = props

    return (
        <div className="form-group">
            <NumberFormat className="form-control mt-1 text-title-black"
                style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
                thousandSeparator={true}
                prefix='$ '
                maxLength={20}
                value={field.value}
                onBlur={() => onBlur(field.name)}
                placeholder={placeholder}
                onChange={(event) => handleChange(field.name,event.target.value.replace("$", "").replace(",", "").replace(",", ""))}
            />
            <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
        </div>
    );


}

export const RadioForm = (props) => {
    const {  field = {}, type = "vertical", handleChange, options = [] } = props
    if (type === 'horizontal') {
        return (
            <div className="form-group">
    
                <div className="form-check">
                    {options.map((option, i) => {
                        return (
                            <label className='text-body' key={i} style={{ marginLeft: 20, fontSize: '16px', paddingLeft: 20 }}>
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    value={option.value}
                                    checked={field.value === option.value}
                                    onChange={() => handleChange(field.name, option.value)}
                                />
                                <label className="form-check-label">
                                    {option.title}
                                </label>
                            </label>
                        )
                    })}
                </div>
                <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
            </div>
        )
    } else {
        return (
            <div className="form-group">
               
                <div>
                    {options.map((option, i) => {
                        return (
                            <div key={i} className="form-check" >
                                <label className="form-check-label text-body" style={{ marginLeft: 20, fontSize: '14px' }} >
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={option.value}
                                        checked={field.value === option.value}
                                        onChange={() => handleChange(field.name, option.value)}
                                    />
                                    {option.title}
                                </label>
                            </div>)
                    })}
                </div>
                <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
            </div>
        )
    }

}

export const ComboBoxForm = props => {
    const { field = {},disabled, handleChange, options = [], initial } = props
    return (
        <div className="form-group">
            <div className="form-group">
                <select
                    disabled={disabled}
                    placeholder={<FontAwesomeIcon icon={faFacebook}/>}
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                    className="custom-select text-title-black" 
                    value={initial} 
                    onChange={
                            (event) => handleChange(field.name, event.target.value)
                        }>
                    {options.map((option, i) => {

                        return (
                            <option key={i} value={option.value}>{option.title}</option>
                        )

                    })}
                </select>

            </div>
            <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label>
        </div>
    )
}


// tipo date
export const DateForm = props => {
    const {  field = {}, handleChange, type = "" } = props
    return (
        <div className="form-group">
          
            <div className="form-group">
                <input
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
                    data-provide="datepicker"
                    className="form-control text-title-black"
                    value={field.value}
                    type={type}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    max={FormatDateYYMMDD(new Date(Date.now()))}
                />
            </div>
            <h6>  <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label></h6>
        </div>
    )
}

export const DateFormMin = props => {
    const {  field = {}, handleChange, type = "" } = props
    return (
        <div className="form-group">
            
            <div className="form-group">
                <input
                
                    style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }}
                    data-provide="datepicker"
                    className="form-control text-title-black"
                    value={field.value}
                    type={type}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                    min={FormatDateYYMMDD(new Date(Date.now()))}
                    
                />
            </div>
            <h6>  <label style={{ color: "red", fontSize: '14px' }}>{field.error}</label></h6>
        </div>
    )
}