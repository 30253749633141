import React, {useEffect, useState} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../pages/home/about-us/about.css'
import { faDownload, faStream,faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { FormatMoney, FormatShortDate } from '../../../utilities/formats';
import { Button, Container, Col, Row, Card } from "react-bootstrap";
import consolidated from '../../../assets/btn-consolidado.png'
import generalExpenses from '../../../assets/btn-gastos.png'
import movements from '../../../assets/btn-movements.png'
import briefcase from '../../../assets/btn-cartera.png'
import GridLoader from 'react-spinners/GridLoader';
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf'
import { GetDetailBoxReport, getHijos } from '../../../services/datos.services';
import { ListReport } from './components/listReport';

export const Informs = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [sons,setSons] = useState([])
    let [data,setData] = useState([])
    let [base64, setBase64] = useState("")
    const [dataGasto,setDataGasto]=useState([])
    let [uid,setUid] = useState("")
    const [loading,setLoading] = useState(false)
    let [boxDetail,setBoxDetail] = useState()
    const [agrupadosFechaGeneral,setAgrupadosFechaGeneral]=useState()
    const generateMovements = async ()=>{
      setLoading(true)
      await updateList(async(flag)=>{
        if(flag){
          try {
            const htmlPdf = `
            <html>
            <head>
                <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.2/jspdf.min.js"></script>
            </head>
            <body>
            <div class="containerPdfMovements">
                    
                      
                        <h1 class="text-total">Reporte movimientos por ruta Desde ${FormatShortDate(startDate)} hasta  ${FormatShortDate(endDate)} </h3>
                                
                        <table class="table table-bordered table-lg" >
                            <tr class="text-title-blue">
                            <th>Ruta</th>
                            <th>Fecha servidor</th>
                            <th>Fecha celular</th>
                            <th>Tipo</th>
                            <th>Descripcion</th>
                            <th>Valor</th>
                            </tr>
                            ${data
                            .map(
                                line => `
                            <tr>
                                <td>${line.caja_user}</td>
                                <td>${line.fecha}</td>
                                <td>${line.hora_dispositivo}</td>
                                <td>${line.tipo}</td>
                                <td>${line.descripcion?line.descripcion:""}</td>
                                <td>${line.valor?FormatMoney(parseInt(line.valor)):"$ 0"}</td>
                            </tr>
                            `,
                            )
                            .join('')}
                        </table>
                    </div></body>
            </html>
            `;

            const doc = new jsPDF("p","mm","letter")
            doc.table(10,10)
            const margin=10;
            const scale=(doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth ;
            
            doc.html(htmlPdf,{
              x:margin,
              y:margin,
              html2canvas :{
                scale:scale
              },
              callback: function(doc){
                doc.save("ReporteMovimientos.pdf")
              }
            }) 
            
              /* */
            setLoading(false);
          } catch (error) {
            alert('Error', error);
          }
        }else{
          alert('se presento un error al actualizar datos')
        }
      }) 

    }
    const generateExpenses = async() =>{
      
        setLoading(true)
      await updateList(async(flag)=>{
        if(flag){
          try {
            const htmlPdf = `
            <html>
           
                   <div id="andresito">
                      <div>
                          <h3 class="text-total">Reporte movimientos por ruta Desde ${FormatShortDate(startDate)} hasta  ${FormatShortDate(endDate)} </h3>
                      </div> 
                         
                        <table class="table table-bordered table-sm" >
                            <tr class="text-title-blue">
                              <th>Ruta</th>
                              <th>Fecha servidor</th>
                              <th>Fecha celular</th>
                              <th>Tipo</th>
                              <th>Descripcion</th>
                              <th>Valor</th>
                            </tr>
                            ${dataGasto
                            .map(
                                line => `
                            <tr>
                              <td>${line.caja_user}</td>
                              <td>${line.fecha}</td>
                              <td>${line.hora_dispositivo}</td>
                              <td>${line.tipo}</td>
                              <td>${line.descripcion?line.descripcion:""}</td>
                              <td>${line.valor?FormatMoney(parseInt(line.valor)):"$ 0"}</td>
                            </tr>
                            `,
                            )
                            .join('')}
                        </table>
                      </div>
                    </html>
            `;

            const doc = new jsPDF("p","px","letter")
            const margin=10;
            //doc.table(10,10)
            const scale= (doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth;
            doc.html(htmlPdf,{
              x:margin,
              y:margin,
              html2canvas :{
                scale:scale
              },
  
              callback: function(doc){
                doc.save("ReporteGasto.pdf")
              }
            }) 
            
              /* */
            setLoading(false);
          } catch (error) {
            console.log(error)
            alert('Error', error);
          }
        }else{
          alert('se presento un error al actualizar datos')
        }
      })   
    }
    
    const updateList =async(call) =>{
        let Movimientos=[]
        let id = parseInt(localStorage.getItem("uid"));
        setDataGasto([])
        console.log("el id que se va",id)
        await GetDetailBoxReport(FormatShortDate(startDate),FormatShortDate(endDate),parseInt(id), async(flag,data ) => {
          console.log("qué está pasando con los items dotor garcia",data)
          setLoading(false)
          if (flag) {
            
              for(let i = 0; i < data.length; i++){
                
                if(data[i].tipo=='pago'){
                  
                    data[i].descripcion=data[i].descripcion!=null?"Pago "+data[i].descripcion :""
                }else if(data[i].tipo=='nopago'){
                  data[i].descripcion=data[i].descripcion!=null?"No pago "+data[i].descripcion :""
                }else if(data[i].tipo.toString()=='gasto'){
                  
                  dataGasto.push(data[i])
                }else if(data[i].tipo.toString()=='solicitud' || data[i].tipo.toString()=='venta' ){
                  
                  let obj2={
                    caja_user:data[i].caja_user,
                    fecha:data[i].fecha,
                    hora_dispositivo:data[i].hora_dispositivo,
                    tipo:data[i].tipo,
                    descripcion:data[i].tipo.toString()=='solicitud'?" solicitud de : "+data[i].nombre:" venta de : "+data[i].nombre,
                    valor:data[i].valor
                  }
                  data[i]=obj2
                }else if(data[i].tipo.toString()=='solicitudrenovacion' || data[i].tipo.toString()=='renovacion'){
                  let obj2={
                    caja_user:data[i].caja_user,
                    fecha:data[i].fecha,
                    hora_dispositivo:data[i].hora_dispositivo,
                    tipo:data[i].tipo,
                    descripcion:data[i].tipo.toString()=='solicitudrenovacion'?" solicitud de renovacion de  : "+data[i].nombre:"renovacion de : "+data[i].nombre,
                    valor:data[i].valor
                  }
                  data[i]=obj2
                }
                
                Movimientos.push(data[i])
                
              }
              setData(Movimientos)

              
              return call(true,Movimientos)
          }else{
              alert("Error en el servidor")
              return call(false)
          }
        })
    }
    const generalReport = async (call) =>{
      let id = parseInt(localStorage.getItem("uid"));
      await GetDetailBoxReport(FormatShortDate(startDate),FormatShortDate(endDate),parseInt(id), async(flag,data ) => {
        setLoading(false)
        if (flag) {
          
          let nuevoObjeto = {}
          let agrupadosFecha={}
          //Recorremos el arreglo 
          data.forEach( x => {
            //Si el vendedor no existe en nuevoObjeto entonces
            //lo creamos e inicializamos el arreglo de Movimientos. 
            if( !nuevoObjeto.hasOwnProperty(x.caja_user)){
              nuevoObjeto[x.caja_user] = {
                movimientos: []
              }
              agrupadosFecha[x.caja_user] = {}
            }
            
            //Agregamos los datos de movimientos. 
              nuevoObjeto[x.caja_user].movimientos.push(x)
            
          })

          

          let claves = Object.keys(nuevoObjeto); // claves = ["cris", "ferc", "macho", "edad"]
          

          claves.forEach(element => {
            nuevoObjeto[element].movimientos.forEach(element2 => {
              if( !agrupadosFecha[element].hasOwnProperty(element2.fecha)){
                agrupadosFecha[element][element2.fecha] = {
                  movimientos: [],
                  report:{
                    sumaGasto:0,
                    sumaIngreso:0,
                    sumaRetiro:0,
                    sumaVenta:0,
                    sumaRenovaciones:0,
                    sumaNoPago:0,
                    sumaRecaudo:0,
                    sumaSolicitud:0
                  }
                }
                
              }
              
              //Agregamos los datos de movimientos. 
              agrupadosFecha[element][element2.fecha].movimientos.push(element2)
              if(element2.tipo=='gasto'){
                agrupadosFecha[element][element2.fecha].report.sumaGasto+=parseFloat(element2.valor)
              }else if(element2.tipo=='ingreso'){
                agrupadosFecha[element][element2.fecha].report.sumaIngreso+=parseFloat(element2.valor)
              }else if(element2.tipo=='retiro'){
                agrupadosFecha[element][element2.fecha].report.sumaRetiro+=parseFloat(element2.valor)
              }else if(element2.tipo=='venta'){
                agrupadosFecha[element][element2.fecha].report.sumaVenta+=parseFloat(element2.valor)
              }else if(element2.tipo=='renovacion'){
                agrupadosFecha[element][element2.fecha].report.sumaRenovaciones+=parseFloat(element2.valor)
              }else if(element2.tipo=='nopago'){
                agrupadosFecha[element][element2.fecha].report.sumaNoPago+=parseFloat(element2.valor)
              }else if(element2.tipo=='pago'){
                agrupadosFecha[element][element2.fecha].report.sumaRecaudo+=parseFloat(element2.valor)
              }else if(element2.tipo=='solicitud'){
                agrupadosFecha[element][element2.fecha].report.sumaSolicitud+=parseFloat(element2.valor)
              }
            });
          });
          setAgrupadosFechaGeneral(agrupadosFecha)
          return call(true,agrupadosFecha)
        }else{
            alert("Error en el servidor")
            return call(false)
        }
      })
    }
    
    

    useEffect(()=>{
        let id = parseInt(localStorage.getItem("uid"));
        setUid(id)
        getHijos(id,"",(response,flag)=>{
            let son = []
            if(flag){
                response.caja_ids.forEach((element)=>{
                  if(element.permiso!=="administrador"){
                    son.push(element)
                  } 
                })
                setSons(son)
            }
        })
        const getBase64FromUrl = async (url) => {
          const data = await fetch(url);
          const blob = await data.blob();
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = () => {
              const base64data = reader.result;
              let image = base64data.split(',')[1]
              setBase64(image)  
              resolve(base64data);
            }
          });
        }
        
      getBase64FromUrl('https://i.imgur.com/j3YzEMx.png').then()
      
      

    },[])

    const generatedConsolidated = async() =>{
      setLoading(true);
      await generalReport(async(flag,data)=>{
      if(flag){
        let vendedores = Object.keys(data);
        
        try {
          let html = `
          <html>
            
          <div class="containerPdfConsolidated">
         
            <div>
                <h3 class="text-total">Reporte movimientos por ruta Desde ${FormatShortDate(startDate)} hasta  ${FormatShortDate(endDate)} </h3>
            </div> 
            <div>  
                <table class="table table-bordered table-sm" >
                  <tr class="text-title-blue">
                    <th>Ruta</th>
                    <th>Fecha</th>
                    <th>Gasto</th>
                    <th>Ingreso</th>
                    <th>Retiro</th>
                    <th>Ventas</th>
                    <th>Renovaciones</th>
                    <th># No Pago</th>
                    <th>Recaudo</th>
                    <th>Solicitud</th>
                    <th>debido Cobrar</th>
                    <th>Caja Inicial</th>
                    <th>Caja Final</th>
                  </tr> `
                      vendedores.forEach( x => {
                        console.log("x",x)
                        let Fechas=Object.keys(data[x]);
                        console.log("fechas",Fechas)
                        Fechas.forEach( y => {
                          html+=` <tr>
                            <td>${x.toString()}</td>
                            <td>${y.toString()}</td>
                            <td>${data[x][y].report.sumaGasto?FormatMoney(parseInt(data[x][y].report.sumaGasto)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaIngreso?FormatMoney(parseInt(data[x][y].report.sumaIngreso)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaRetiro?FormatMoney(parseInt(data[x][y].report.sumaRetiro)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaVenta?FormatMoney(parseInt(data[x][y].report.sumaVenta)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaRenovaciones?FormatMoney(parseInt(data[x][y].report.sumaRenovaciones)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaNoPago?FormatMoney(parseInt(data[x][y].report.sumaNoPago)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaRecaudo?FormatMoney(parseInt(data[x][y].report.sumaRecaudo)):"$ 0"}</td>
                            <td>${data[x][y].report.sumaSolicitud?FormatMoney(parseInt(data[x][y].report.sumaSolicitud)):"$ 0"}</td>
                            <td>${"0"}</td>
                            <td>${"0"}</td>
                            <td>${"0"}</td>
                          </tr> `
                        }) 
                      })
                      html+=   
                ` </table></div>
                </div>
          </html>
                    `;

                    const doc = new jsPDF("p","px","letter")
                    const margin=3;
                    const scale= (doc.internal.pageSize.width - margin * 2) / document.body.scrollWidth;
            
                  doc.html(html,{
              x:margin,
              y:margin,
              html2canvas :{
                scale:scale
              },
              callback: function(doc){
                doc.save("ReporteConsolidado.pdf")
              }
            }) 
            
              /* */
            setLoading(false);

                  
            
          
        } catch (error) {
          setLoading(false);
          alert("Error en el servidor")

        }

      }else{
        setLoading(false);
        alert('se presento un error al actualizar datos')
      }

      })
      

    }
    

    return (
        <>
            <div style={{ background: 'transparent', color: '##ffdc08', paddingTop: 70 }}>
                <div className="container" style={{ textAlign: 'center' }}>
                    <label className='text-total' style={{ fontSize: 26 }}><FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faFileArchive} />
                        Reportes
                    </label>
                    
                </div>
            </div>
            

            <Container className="mt-4">
                <Row style={{ marginBottom: 10 }}>
                    <Col md={2}> <img src={generalExpenses} style={{width:'120px', height:'120px'}} onClick={()=>{generateExpenses()}}/> </Col>
                    <Col md={2}><img src={consolidated} style={{width:'120px', height:'120px'}} onClick={()=>{generatedConsolidated()}}/></Col>
                    <Col md={2}><img src={movements} style={{width:'120px', height:'120px'}} onClick={()=>{generateMovements()}}/> </Col>
                    <Col md={2}><img src={briefcase} style={{width:'120px', height:'120px'}} onClick={()=>{console.log("Presionando cartera")}}/> </Col>
                    <Col md={2}>
                        <label className='text-title-blue'>Desde: </label>
                        <DatePicker
                            style={{ fontSize: '14px' ,backgroundColor:'#e3e3e3', borderRadius:50 }} 
                            className="form-control text-title-black"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            maxDate={new Date()}
                        />
                    </Col>
                    <Col md={2}>
                    <label className='text-title-blue'>Hasta: </label>
                        <DatePicker
                            style={{ fontSize: '14px' ,backgroundColor:'#e3e3e3', borderRadius:50 }} 
                            className="form-control text-title-black"
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            maxDate={new Date()}
                        /> 
                    </Col>
                    
                </Row>
                <Card style={{ width: '95%', borderRadius:50, boxShadow:'6px 10px 10px black' }}>
                    <Card.Body>
                        <Card.Title><h3 className='text-total'>Vendedores</h3></Card.Title>
                        <hr />
                        <ListReport startDate={FormatShortDate(startDate)} endDate={FormatShortDate(endDate)} sons={sons}/>

                    </Card.Body>
                </Card>
                <GridLoader  size={50} loading={loading} style={{position:'relative', marginLeft:'20%'}} color={"#02dce8"} /> 
                
            </Container>
            
        </>
    )
}
