import React from 'react';
import req1 from '../../../assets/req1.png'
import req2 from '../../../assets/req2.png'
import req3 from '../../../assets/req3.png'
import req4 from '../../../assets/req4.png'
import '../about-us/about.css'

export const Requirements = () => {
    return (
        <div className='containerAbout' style={{height:'100%'}}   >
            <div className='como-f' >
                <div className='titulo'  >
                    <text className='text-title' style={{fontWeight:'bold', fontSize:'3.5ch'}}>REQUISITOS</text>
                </div>
                <div className='secciones'>
                    <img src={req1}  style={{marginLeft:'6%'}}/>
                    <img src={req2} />
                    <img src={req3} />
                    <img src={req4} />
                </div>
            </div>
        </div>
    )
}