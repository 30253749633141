import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { country } from '../utilities/odoo-config';
//const country='colombia';

export const Login = async (data, call) => {
    const config = await OdooConfig(country);
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    console.log("este es el odoo conect",odoo)
    odoo.connect((err, response) => {
        console.log("EL ERROR",err)
        if (err) {
            call(response, false)
        } else {
            localStorage.setItem('uid', response)
            setData(odoo, call, data)
        }
    })
}

export const setData = async (odoo, call, data) => {
    odoo.connect((err, response) => {
        if (err) {
            return call(response, false)
        } else {
            const args = [[
                0,
                { departamento_write_date: null, municipio_write_date: null }
            ]]
            const paramsOdoo = {
                model: "nova.webservice",
                method: "obtenerDatos",
            };
            return odoo.execute_kw(paramsOdoo.model, paramsOdoo.method, args, async (err, response) => {
                if (err) {
                    call(2, false, err);
                    return false;
                }
               
                if (response.permiso !== 'vendedor') {
                    localStorage.setItem("Permission", "" + response.permiso);
                    response['user'] = data;
                    call(response, true,);                  
                }else{
                    call('vendedor', true); 
                    
                }
                
               
            })

        }
    })
}


