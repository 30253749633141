import React from 'react'
import { InputForm } from '../../../shared/components/inputs';
import Register from '../../../shared/components/register';
import { Button, Col, Card, Row } from 'react-bootstrap';
import { Desencrypt } from '../../../utilities/cipher';
import '../../../pages/home/about-us/about.css'
import { subirClientes } from '../../../services/datos.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { ToastContainer,toast } from 'react-toastify';

export class UpdatePassword extends Register {

    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            valid: false,
            flagColombia: false,
            flagusa: false,
            currentPassword: '',
            validatePassword: '',
            succPass: '',
            tconfirm:'',
            errorCampos: '',
            tPass: "",
            typePass: true,
            typeRepeatPass: true,
            password: {
                name: 'password',
                value: '',
                error: '',
                type: 'password',
                required: true
            },
            passwordRepeat: {
                name: 'passwordRepeat',
                value: '',
                error: '',
                type: 'passwordRepeat',
                required: true
            }
        };
        this.allOK = true;
        this.inputsToVerify = [
            'password',
            'passwordRepeat',
        ]
    }
    handleChangeValidate = (value) => {

        this.setState({
            validatePassword: value,
        })
        if (value !== '') {
            if (this.state.currentPassword === value) {
                this.setState({ valid: false, disabled: false })
            } else {
                this.setState({ valid: true, disabled: true })
            }
        } else {
            this.setState({ valid: false, disabled: true })
        }
    }

    inicialiceData = async () => {
        const session = Desencrypt(await localStorage.getItem("Session"));
        this.setState({
            currentPassword: session.password,
        })
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            this.setState({
                flagusa: true,
                succPass: <FormattedMessage id='passUpdate' defaultMessage={"Contraseña actualizada"} />,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                tconfirm:<FormattedMessage id='confirm' defaultMessage={"Confirmar"} />,
                tPass: <FormattedMessage id='updatePassword' defaultMessage={"Actualización de Contraseña"} />
            })
        }else{
            this.setState({
                succPass: "Contraseña actualizada correctamente. Es necesario cerrar Sesión",
                flagColombia: true,
                tconfirm: "Confirmar",
                tPass: "Actualización de contraseña",
                errorCampos: "Atención, aún faltan campos por llenar"
            })
        }
    }

    componentDidMount = () => {
        this.inicialiceData();
    }
    registrar = () => {

        if (this.verifyForm()) {
            const uid = localStorage.getItem('uid')
            const personal = {
                id: parseInt(uid),
                cliente: {
                    clave: this.state.password.value,
                }
            }
            subirClientes(personal, (data, flag) => {
                if (flag) {
                    const success = () => toast.success(this.state.succPass);
                    success();
                    setTimeout(() => {
                        localStorage.clear();
                        window.location = "*";
                        }, 3000);
                    
                }
            })
        } else {
            const error = () => toast.error(this.state.errorCampos);
            error();
        }
    }

    render() {
        return (
            <> 
             <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={3500}/>
           
            <div style={{ margin: '5% 0 0 5%', padding: "1% 0 2% 0" }}>
                <Card style={{ width: '75%' , borderRadius:50, boxShadow:'6px 10px 10px black'}}>
                    <Card.Body>
                        <Card.Title><h3 className='text-total'>{this.state.tPass}</h3></Card.Title>
                        <hr />
                        <div className="form-group">
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='currentPassword' defaultMessage={"Contraseña actual"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Contraseña actual</label>}
                            <input
                                style={{ fontSize: '14px' }}
                                className="form-control text-title-black"
                                value={this.state.validatePassword}
                                onChange={(e) => this.handleChangeValidate(e.target.value)}
                                maxLength={8}
                            >
                            </input>
                            {this.state.valid && this.state.flagColombia &&<label className='text-title-red' style={{ color: "red", fontSize: '14px' }}>La contraseña actual no es la correcta</label>}
                            {this.state.valid && this.state.flagusa &&<label style={{ color: "red", fontSize: '14px' }}><FormattedMessage id='invalidCurrentPassword' defaultMessage={"Contraseña actual incorrecta"}/></label>}
                        </div>
                        <Row>
                            <Col className="col-10">
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='newPassword' defaultMessage={"Nueva contraseña"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Contraseña nueva</label>}
                                <InputForm
                                    field={this.state.password}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    maxLength={8}
                                    minLength={6}
                                    disabled={this.state.disabled}
                                    type={this.state.typePass ? 'password' : 'text'}
                                />
                            </Col>
                            <Col className="col-sm-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    style={{ backgroundColor: "white", borderColor: "white", height: '30px', width: '30px', marginLeft: -30, marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={() => this.setState({ typePass: !this.state.typePass })}
                                >
                                    <FontAwesomeIcon style={{ height: '25px', width: '25px', color: "black" }} icon={this.state.typePass?faEye:faEyeSlash} />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-10">
{this.state.flagusa &&<label style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='confirmPassword' defaultMessage={"Confirmar contraseña"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Confirmar contraseña</label>}
                                <InputForm
                                    field={this.state.passwordRepeat}
                                    handleChange={this.handleChange}
                                    onBlur={this.handleRelease}
                                    maxLength={8}
                                    minLength={6}
                                    disabled={this.state.disabled}
                                    type={this.state.typeRepeatPass ? 'password' : 'text'}
                                />
                            </Col>
                            <Col className="col-sm-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    style={{ backgroundColor: "white", borderColor: "white", height: '30px', width: '30px', marginLeft: -30, marginTop: -10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={() => this.setState({ typeRepeatPass: !this.state.typeRepeatPass })}
                                >
                                    <FontAwesomeIcon style={{ height: '25px', width: '25px', color: "black" }} icon={this.state.typeRepeatPass?faEye:faEyeSlash} />
                                </Button>
                            </Col>
                        </Row>
                        <Col className="container">
                        <Button style={{ backgroundColor: 'transparent', borderColor: '#02dce8', borderRadius:100 }} onClick={this.registrar} className="btn btn-warning"  > <label className='text-title-black' style={{marginTop:6}}>{this.state.tconfirm}</label></Button>
                        </Col>

                    </Card.Body>
                </Card>
            </div>
            </>
        );
    }

}