import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import DetallesCarteraM from './detallesCarteraM';
import DetallesCarterasPagos from './detallesCarterasPagos';

const ListaDetalleCartera = ({ show, onHide, nombreCliente, cuotas, pagos }) => {
    
    return (
        <>
            <style>
                {`
                    .modal-90w { max-width: 90% !important; }
                `}
            </style>

            <Modal
                dialogClassName="modal-90w"
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >
                <Modal.Header style={{ background: 'transparent', color: '#02dce8', borderBottomWidth:3, borderBottomColor:'#02dce8'}} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <FontAwesomeIcon style={{ marginRight: '10px', width: 40, height: 40 }} icon={faFileInvoiceDollar} />
                        Detalles de Cartera
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg='4'><label className='text-title-black'>Usuario:</label> <label className='text-body'>{nombreCliente}</label> </Col>
                    </Row>
                    <Row>
                        <Col >
                            <DetallesCarteraM cuotas={cuotas}/>
                        </Col>
                        <Col >
                            <DetallesCarterasPagos pagos={pagos}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between pl-5 pr-5">

                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ListaDetalleCartera;