import React, { useState } from 'react';
import { Card, Button, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUserCircle, faArrowAltCircleRight, faUserEdit, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


export const ItemDetail = (props) => {

    const { dataUser, showDetalleCartera, setShowModalEditar } = props


    const [showOptions, setShowOptions] = useState(false)
    const [open, setOpen] = useState(false)

    const handleOpen = (flag) => {
        setShowOptions(flag);
        setOpen(flag);
    }

    return (
        <>
            <Card style={{ marginBottom: 2 }}>
                <Card.Header>
                    <Row  onClick={() => handleOpen(!showOptions)}>
                        <Col xs lg="3">
                            <Button style={{ minWidth: 110 }} variant="outline-dark">
                                {dataUser.nomCliente}
                                <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={dataUser.perfil === 'admin' ? faTrophy : faUserCircle} />
                            </Button>
                        </Col>

                        <Col>
                            <Row>
                                <Col><label style={{ fontSize: 16 }}>id de venta: {dataUser.id}</label></Col>
                                <Col><label style={{ fontSize: 16 }}>Saldo: $ {dataUser.debCobrar}</label></Col>
                                <Col className="text-md-right" lg="1">
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px' }} icon={!open ? faChevronDown : faChevronUp} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>

                {showOptions && <Card.Body>
                    <Row>
                        <Col >
                            <label style={{ fontSize: 16 }}>Valor Inicial: {dataUser.valInicial}</label>
                        </Col>
                        <Col>
                            <label style={{ fontSize: 16 }}>Valor Final: {dataUser.valFinal}</label>
                        </Col>
                        <Col style={{ justifyContent: 'center' }}>
                            <label>
                                <Button variant="light" onClick={() => showDetalleCartera(true)} >
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px', marginRight: 8 }} icon={faArrowAltCircleRight} />
                                    Ver detalle
                                </Button>
                            </label>
                        </Col>

                        <Col style={{ justifyContent: 'center' }}>
                            <label>
                                <Button variant="light" onClick={() =>  setShowModalEditar(true)} >
                                    <FontAwesomeIcon style={{ height: '20px', width: '20px', marginRight: 8 }} icon={faUserEdit} />
                                    Editar
                                </Button>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Table style={{ fontSize: 16 }} responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th><label >Cuotas</label></th>
                                        <th><label>Vencidos</label></th>
                                        <th><label >Creada</label></th>
                                        <th><label >Finaliza</label></th>
                                        <th><label >Ultimo pago</label></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{dataUser.noPagos}{' '}/{' '}{dataUser.totalCuotas}</td>
                                        <td>{dataUser.noPagos}</td>
                                        <td>{dataUser.creado}</td>
                                        <td>{dataUser.finPagos}</td>
                                        <td>{dataUser.ultimoPago}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>}

            </Card>


        </>
    )
}

export const ItemDetailCuotaCartera = (props) => {

    const { cuotaCartera } = props

    return (
        <>
            <tr>
                <td>{cuotaCartera.id}</td>
                <td>{cuotaCartera.valCuota}</td>
                <td>{cuotaCartera.finPagos}</td>
                <td>{cuotaCartera.valInicial}</td>
                <td>{cuotaCartera.estado}</td>
            </tr>
        </>
    )
}

export const ItemDetailPagoCartera = (props) => {

    const { pagoCartera } = props

    return (
        <>
            <tr>
                <td>{pagoCartera.id}</td>
                <td>{pagoCartera.ultimoPago}</td>
                <td>{pagoCartera.valCuota}</td>
            </tr>
        </>
    )
}