import React from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Button } from 'react-bootstrap';
export const PagoPse = (props) => {

    const { showPse } = props;
    let valor = '';

    const handleClose = () => {
        showPse(false)
    }

    return (
        <>
            <Modal centered show={true} onHide={handleClose} >

                <Modal.Header closeButton>
                    <Modal.Title> Abonar por PSE </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <h5>Ingrese un valor</h5>
                        <NumberFormat
                            className="form-control mt-3 mb-3"
                            thousandSeparator={true}
                            prefix='$ '
                            maxLength={11}
                            value={valor}
                            placeholder={'Nuevo valor'}
                            onChange={(event) => valor = event.target.value}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="secondary" >
                        Cancelar
                        </Button>
                    <Button variant="primary" >
                        Abonar
                        </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

