import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { firmarContrato } from '../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const PassedData = () => {
    const location = useLocation();
    const [valor, setValor] = useState('');
    let [ip,setIp] = useState('')
    let [successT,setSuccessT] = useState('')
    let [errorT,setErrorT] = useState('')
    const [alerta, setAlerta] = useState(false);
    const [flagColombia, setFlagColombia] = useState(false);
    const [flagUsa, setFlagusa] = useState(false);

    //localStorage.clear();
    const getIp = async () =>{
        const res = await axios.get('https://geolocation-db.com/json/')
        setIp(res.data.IPv4)

    }

    const firmar = () => {

        let llave = location.search.split('b=b%27');
        llave = llave[1].split('%27');
        let cadena = {
            ip_firmacontrato:ip,
            llave: llave[0],
            valor: valor
        }
        if (valor.length > 0) {
            setAlerta(false);
            console.log("Firmando",cadena)
            firmarContrato(cadena, (data, flag) => {
                console.log("esto está respondiendo",data,flag)
                if (flag) { 
                    const success = () => toast.success(successT);
                    success();
                    setTimeout(() => {
                        localStorage.clear()
                        window.location = "/";     
                    }, 10000);      
                } else {
                    const error= () => toast.error(errorT);
                    error();
                }
            })
        }else{
            setAlerta(true);
        }
    }
    useEffect(() => {
        let server = localStorage.getItem("server")
        if(server==='"eeuu"'){
            setErrorT("Verify that you entered the confirmation code correctly.")
            setSuccessT("CONGRATULATIONS!. Your loan has been approved and will be disbursed in the next twenty-four (24) hours according to the method chosen by you.")
            setFlagusa(true)
        }else{
            setErrorT("¡ Ups!!!, Verifica que ingresaras bien el código de confirmación !")
            setFlagColombia(true)
            setSuccessT("¡FELICITACIONES!, Su crédito ha sido aprobado y será desembolsado en las próximas veinticuatro (24) horas de acuerdo al método elegido por usted.")
        }
        getIp()
        firmar()
    }, [])
    return (
        <>
        <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
        <div style={{ paddingTop: 140 }} className='App d-flex flex-column align-items-center'>
            <div className="justify-content-lg-center">
                {flagColombia &&<p className='text-title-black'>
                    Felicitaciones, tu solicitud de crédito fue aprobada. Estás a un clic de recibir y poder disfrutar tu dinero. Gracias por confiar en nosotros.
                        <br />Atentamente:
                        <br />Equipo VENGA LE PRESTO.
                    </p>}
                    {flagUsa &&<p>
                        Congratulations, your credit application was approved. You are one click away from receiving and enjoying your money. Keep in mind the means you chose to make the disbursement. Thanks for trusting us.
                        <br />Sincerely:
                        <br />VENGALEPRESTO team.
                    </p>}
            </div>
            <Row>

                <Col>
                    <Form >
                        <Form.Group>
                            <Row>
                                <Col sm>
                                    <input
                                        style={{ fontSize: '14px', background:'#e3e3e3', borderRadius:50 }} 
                                        type="text"
                                        className="form-control text-title-black"
                                        autoComplete="off"
                                        placeholder=""
                                        value={valor}
                                        onChange={(e) => setValor(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            {alerta && <Row>
                                <Col sm>
                                    {flagColombia &&<label className='text-title-red'>Debe ingresar el código de confirmación.</label>}
                                    {flagUsa &&<label className='text-title-red'>You must enter the confirmation code.</label>}
                                </Col>
                            </Row>}
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col className="col-12">
                                    <Button onClick={firmar} style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}>
                                        {flagColombia &&<p className='text-title-black'>Firmar</p>}
                                        {flagUsa &&<p className='text-title-black'>Sign</p>}
                                    </Button>
 
                                </Col>

                                <Col className="col-12">
                                    <Button variant="secondary" style={{marginTop:10 ,borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}>
                                        {flagColombia &&<p className='text-title-black'>No Firmar</p>}
                                        {flagUsa &&<p className='text-title-black'>Do not sign</p>}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
           
        </div>
        </>
    );
}

export default PassedData;
