import React, {useEffect, useState} from 'react';
import { Row ,Col,Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../home/about-us/about.css'
import {FormattedMessage} from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGetPocket } from '@fortawesome/free-brands-svg-icons';
export const MisionAndVision = () => {
    let [quienes, setQuienes] = useState('');
    let [mision, setMision] = useState('');
    let [vision, setVision] = useState('');
    let [quienest, setQuienest] = useState('');
    let [misiont, setMisiont] = useState('');
    let [visiont, setVisiont] = useState('');
    let [showHow,setShowHow]=useState(false)
    let [showMision,setShowMision]=useState(false)
    let [showVision,setShowVision]=useState(false)

    useEffect(() => {
        const server = localStorage.getItem('server');
        if(server==='"eeuu"'){
            setQuienest(<FormattedMessage id='whoesT' defaultMessage={"¿Quiénes somos?"} />)
            setMisiont(<FormattedMessage id='misionT' defaultMessage={"Misión"} />)
            setVisiont(<FormattedMessage id='visionT' defaultMessage={"Visión"} />)
            setQuienes(<FormattedMessage id='whoes' defaultMessage={"VENGALEPRESTO, es una plataforma de préstamos 100% en línea a corto plazo, operada por VENGALEPRESTO INC, que busca integrar soluciones de adelantos en efectivo rápidos, sencillos y fáciles a través del uso inteligente de la tecnología. Somos una línea de prestamos de consumo que apoya la inclusión financiera a los clientes que no tienen crédito o con problemas económicos de manera inmediata que se acomoda a sus necesidades, brindándoles una atención personalizada. Nuestros clientes tienen la oportunidad de elegir el monto de dinero que necesitan y cuánto tiempo requiere para realizar el pago del servicio, el opera con opera con flexibilidad y rapidez."} />)
            setVision(<FormattedMessage id='vision' defaultMessage={"Ser en el año 2024, la empresa líder en Estados Unidos en gestión y concesión de préstamos personales a través de nuestras plataformas."} />)
            setMision(<FormattedMessage id='mission' defaultMessage={"Brindar con eficiencia, flexibilidad y rapidez, préstamos que ayuden a nuestros clientes a encontrar soluciones inmediatas y a mejorar su calidad de vida, ayudándolos a resolver problemas."} />)
        }else{
            setQuienest('¿Quiénes somos?')
            setMisiont('Misión')
            setVisiont('Visión')
            setQuienes('VENGALEPRESTO SAS, es una plataforma de crédito 100% en línea, operada por VENGALEPRESTO SAS, que busca integrar soluciones de microcrédito rápidos, sencillos y fáciles a través del uso inteligente de la tecnología. Somos una línea de crédito de consumo financiero que apoya a los clientes en el crecimiento personal o con problemas económicos de manera inmediata que se acomoda a tus necesidades brindándoles una atención personalizada. Nuestros clientes tienen la oportunidad de elegir el monto de dinero que necesitan y cuánto tiempo requiere para realizar el pago del servicio, el cual opera con flexibilidad y rapidez.')
            setVision('Ser en el año 2024, la empresa líder en Colombia en gestión y concesión de microcréditos a personas naturales y/o comerciantes a través de plataformas 100% en línea. ')
            setMision(' Brindar con eficiencia, flexibilidad y rapidez, créditos que ayuden a nuestros clientes a encontrar soluciones inmediatas y a mejorar su calidad de vida, ayudándolos a resolver problemas.')
        }
      }, []);
    return (
        
            <div style={{color:'rgb(255, 255, 255)',fontWeight:'bold',maxWidth:'100%', marginTop:'5vh',alignSelf:'center'}} class="col justify-content-center">
                    <Col  xs={12} md={10}sm={10} lg={6} style={{top:'10%',left:0,bottom:0,right:0,margin:'auto'}}>
                        <div> 
                            <h1 className='text-title_nosotros'  style={{textDecoration:'underline', color:'rgb(2, 220, 232)', fontSize:'3.5ch'}}><strong>NOSOTROS</strong></h1>
                        </div>
                    </Col>
                    
                    
                    <Col  xs={12} md={10}sm={10} lg={6} style={{top:'10%',left:0,bottom:0,right:0,margin:'auto'}}>
                        <div>
                            <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                <h2 className='font-weight-bold mx-auto text-uppercase mb-1 mt-4' style={{color:'rgb(2, 220, 232)',left:0, fontSize:'160%'}}>{quienest}</h2>
                                <FontAwesomeIcon onClick={()=>setShowHow(!showHow)} style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showHow ? 'rotate(3.142rad)':'' }} className="mr-2 align-self-center" icon={faGetPocket}  />
                            </div>
                            <p className='text-contenido_nosotros' style={{textAlign:'justify',color:'black',borderBottomWidth:10, borderBottomColor:'red'}} >
                                {showHow&&quienes}
                            </p>
                        </div>
                    </Col>
                    <Col  xs={12} md={10}sm={10} lg={6} style={{top:'10%',left:0,bottom:0,right:0,margin:'auto'}}>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                  <h2 className='font-weight-bold mx-auto text-uppercase mb-1 mt-4' style={{color:'rgb(2, 220, 232)',left:0, fontSize:'160%'}}>{misiont}</h2>  
                                  <FontAwesomeIcon onClick={()=>setShowMision(!showMision)} style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showMision ? 'rotate(3.142rad)':'' }} className="mr-2 align-self-center" icon={faGetPocket}  />
                                </div>
                                <p className='text-contenido_nosotros' style={{textAlign:'justify',color:'black'}}>
                                    {showMision&&mision}
                                </p>
                            </div>
                            <div >
                                <div className='row' style={{borderBottom: '3px solid rgb(2, 220, 232)'}}>
                                   <h2 className='font-weight-bold mx-auto text-uppercase mb-1 mt-4' style={{color:'rgb(2, 220, 232)', fontSize:'160%'}}>{visiont}</h2> 
                                   <FontAwesomeIcon onClick={()=>setShowVision(!showVision)} style={{ height: '35px', width: '35px', color: 'rgb(2, 220, 232)',transform:showVision ? 'rotate(3.142rad)':'' }} className="mr-2 align-self-center" icon={faGetPocket}  />
                                </div>
                                <p className='text-contenido_nosotros' style={{textAlign:'justify',color:'black'}}>
                                    {showVision&&vision}
                                </p>
                            </div>  
                    </Col>
        </div>
        
    )
}
