import React from 'react'

export const TermsAndConditions = (props) => {
    const {selectPdf}=props;

    return (
        <div style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center'}}>
            <div style={{  width: '100%', height: '100%', padding:15 }}>
                <object
                    data={selectPdf === "terminos"?"TÉRMINOS_Y_CONDICIONES.pdf":'POLITICAS_DE_PRIVACIDAD_VENGALEPRESTO.pdf'}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                >
                </object>
            </div>
        </div>
    )
}