import Odoo from 'odoo-xmlrpc';

import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';
import { clientSession } from '../utilities/odoo-config';
import { country } from '../utilities/odoo-config';
//const country='colombia';

export const getDataFormulario = async (id, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0,
        id
    ]]

    let data = await Desencrypt();
    
    const params = {
        model: "nova.webservice",
        method: "obtenerDatosFormulario"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password:data.password
    });

    client.connect((err, response) => {
        
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const getDetaillBox = async (id, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0,
        id
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerDetalleCaja"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}
export const GetDetailBoxReport = async (fecha_inicio,fecha_fin,res_users_id, callback) => {
     const config = await OdooConfig(country);
     const args = [[0,null,fecha_inicio,fecha_fin,res_users_id]]
     console.log("losar gumentos",args)
     const data = await Desencrypt();
     const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    const params = {
      model: "nova.webservice",
      method: "obtenerDetalleCaja",
      args: args,
      kwargs: {}
    }
   console.log("cliente conectándose",client)
    client.connect((err, response) => {
      if (err) {
        console.log("conect error", err)
        return;
      }
      client.execute_kw(params.model, params.method, args, async (err, response) => {
        if (err) {
            callback(false, err);
            return false;
        }
        callback(true, response.caja_detalles);
        });
    });
  }

export const getParametros = async (callback) => {
    const config = await OdooConfig(country);
    localStorage.setItem('server', JSON.stringify(country))
    

    let finish = false;
    const args = [[
        0,
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerParametros"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedido");
        callback(false, "");
        return;
    }, 30000);

    if (!data) {
        data = {
            user: 'cristian',
            password: '1111'
        }
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        console.log("error del obtener para")
        if (err) {
        
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
            
                finish = true
                if (err) {
                
                    callback(err, false);
                    return false;
                }
                localStorage.setItem('valorMinimo', JSON.stringify(response.MinValorPrestamo))
                localStorage.setItem('valorMaximo', JSON.stringify(response.MaxValorPrestamo))
                callback(response, true);
            });

        }
    });
}

export const getData = async (callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { departamento_write_date: null, municipio_write_date: null }
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerDatos",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}


export const subirClientes = async (dataClient, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, [dataClient]
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "subirClientes",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    let client;
    if (data) {
        client = new Odoo({
            url: config.host,
            port: config.port,
            db: config.database,
            username: data.user,
            password: data.password
        });
    } else {
        client = new Odoo({
            url: config.host,
            port: config.port,
            db: config.database,
            username: clientSession.user,
            password: clientSession.password
        });
    }

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)

        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    console.log(err)
                    callback(err, false);
                    return false;
                }
                console.log(response)
                callback(response, true);
            });
        }
    });
}


export const getHijos = async (id, date, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, id, date
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerHijoCajas",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true, id);
            });
        }
    });
}


export const openBoxAll = async (id, callback) => {
    const config = await OdooConfig(country);

    const args = [[
        0, { user_id: id }
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "abrirCajasMasivas",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {

                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const openBox = async (id, callback) => {
    const config = await OdooConfig(country);

    const args = [[
        0, { id }
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "abrirCaja",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {

                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const closeBox = async (id, callback) => {
    const config = await OdooConfig(country);

    const args = [[
        0, { id }
    ]]

    const data = await Desencrypt(country);
    const params = {
        model: "nova.webservice",
        method: "cerrarCaja",
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {

                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}


export const sendTransaction = async (transaction, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, transaction
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "subirTransaccion",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    })
    client.connect((err, response) => {
        if (err) {
            console.log("error de conexion");
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    console.log("error de ejecucion")
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const uploadEvidence = async (transaction, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, transaction
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "subirPagoFactura",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    })

    console.log("el cliente",client)
    client.connect((err, response) => {
        if (err) {
            console.log("error de conexion");
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    console.log("error de ejecucion")
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const getHistorial = async (uid, callback, inactivos = false) => {

    let args = [];
    const config = await OdooConfig(country);
    let finish = false;
    if (!inactivos) {
        args = [[
            0, { res_user_id: uid }
        ]]
    } else {
        args = [[
            0, { res_user_id: uid, inactivos: false }
        ]]
    }

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerCreditosVendedor"
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                console.log("response del credito",response)
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}

export const getCreditsAdmin = async (uid, callback, inactivos = false, cliente = false, estado) => {
    
    let args = [];
    const config = await OdooConfig(country);

    if (cliente) {
       
        if (!estado) {
            args = [[
                0, { user_id_parent_id: uid, inactivos : false }
            ]]
        } else {
            args = [[
                0, { user_id_parent_id: uid }
            ]]
        }
    } else if (!inactivos) {
        
        args = [[
            0, { res_user_id: uid }
        ]]
    } else {
        
        args = [[
            0, { res_user_id: uid, inactivos: false }
        ]]
    }

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerCreditosVendedor"
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}




export const getRequest = async (uid, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { solicitud_write_date: null }
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerSolicitudCredito"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}

export const getEvidence = async (id, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { id }
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "traerEvidencia"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}

export const getDefaulter = async (id, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, {}
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "obtenerMorosos"
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}

export const requestPay = async (pay, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, pay
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "solicitarPago",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const activarUsuario = async (cadena, callback) => {

    const config = await OdooConfig(country);
    let finish = false;
    const args = [[0, cadena]]

    const params = {
        model: "nova.webservice",
        method: "activarUsuario",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: clientSession.user,
        password: clientSession.password  
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const recuperarContrasena = async (email, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { email: email }
    ]]

    const params = {
        model: "nova.webservice",
        method: "recuperarCuenta",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: clientSession.user,
        password: clientSession.password   
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const cambiarContrasena = async (cadena, callback) => {

    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, cadena
    ]]

    const params = {
        model: "nova.webservice",
        method: "cambiarContrasena",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: clientSession.user,
        password: clientSession.password    
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}


export const firmarContrato = async (cadena, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, cadena
    ]]

    const params = {
        model: "nova.webservice",
        method: "firmarContrato",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: clientSession.user,
        password: clientSession.password    
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}
export const cambiaCorreo = async (cadena, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, cadena
    ]]

    const params = {
        model: "nova.webservice",
        method: "cambiarCorreo",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: clientSession.user,
        password: clientSession.password    
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                console.log("Aqui la respuesta",response)
                callback(response, true);
            });
        }
    });
}
export const pazySalvo = async (id, callback) => {
    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { factura_id: id }
    ]]

    const data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "correoPazySalvo",
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

    export const generarFactura = async (id, callback) => {
        const config = await OdooConfig(country);
        let finish = false;
        const args = [[
            0, { factura_id: id }
        ]]
    
        const data = await Desencrypt();
        const params = {
            model: "nova.webservice",
            method: "correoGenerarFactura",
        }
    
        setTimeout(() => {
            if (finish) {
                return;
            }
            alert("Tiempo limite excedidoo");
            callback(false, "");
            return;
        }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    })
    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    });
}

export const inactivarUser = async (uid, callback) => {

    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { usuario_id: uid }
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "inactivarUsuarioCartera"
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}

export const activarUser = async (uid, callback) => {

    const config = await OdooConfig(country);
    let finish = false;
    const args = [[
        0, { usuario_id: uid }
    ]]

    let data = await Desencrypt();
    const params = {
        model: "nova.webservice",
        method: "activarUsuarioCartera"
    }

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, async (err, response) => {
                finish = true
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });

        }
    });
}
