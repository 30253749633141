import React, { useEffect, useState } from 'react'
import pdfC from '../../assets/documents/politicas.pdf'

export const Politicas = () => {
    const [flagCol, setFlagCol] = useState(false)
    const [flagUs, setFlagUs] = useState(false)
    
    useEffect(()=>{
        let server = localStorage.getItem("server");
        if(server === '"eeuu"'){
            setFlagUs(true)
        }else{
            setFlagCol(true)
        }

    }, [])

    return (
        <div style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center',marginTop:'3%'}}>
         
            <div style={{  width: '100%', height: 800, padding:15 }}>
                {flagCol &&<object
                    data={pdfC}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                >
                </object>}
                
            </div>
        </div>
    )
}