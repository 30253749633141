import React from 'react'
import Register from '../../../shared/components/register';
import { Form, Row, Button, Col, Card } from 'react-bootstrap';
import { InputForm, InputNumber, ComboBoxForm } from '../../../shared/components/inputs';
import { subirClientes } from '../../../services/datos.services';
import { ToastContainer, toast } from 'react-toastify';
import './../../../pages/home/about-us/about.css'
import { FormattedMessage } from 'react-intl';

export class RegisterReference extends Register {
    constructor(props) {
        super();
        this.data = props.data;
        this.state = {
            showBoton: true,
            selectedRef: false,

            nombre1Personal: {
                name: 'nombre1Personal',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            nombre2Personal: {
                name: 'nombre2Personal',
                value: '',
                error: '',
                type: "off",
                required: false
            },
            apellido1Personal: {
                name: 'apellido1Personal',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            apellido2Personal: {
                name: 'apellido2Personal',
                value: '',
                error: '',
                type: "off",
                required: false
            },
            departamentoPersonal: {
                name: 'departamentoPersonal',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            ciudadPersonal: {
                name: 'ciudadPersonal',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            direccionPersonal: {
                name: 'direccionPersonal',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            celularPersonal: {
                name: 'celularPersonal',
                value: '',
                error: '',
                type: "mobile",
                required: true
            },
            emailPersonal: {
                name: 'emailPersonal',
                value: '',
                error: '',
                type: "email",
                required: true
            },


            parentesco: {
                name: 'parentesco',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            nombre1Familiar: {
                name: 'nombre1Familiar',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            nombre2Familiar: {
                name: 'nombre2Familiar',
                value: '',
                error: '',
                type: "off",
                required: false
            },
            apellido1Familiar: {
                name: 'apellido1Familiar',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            apellido2Familiar: {
                name: 'apellido2Familiar',
                value: '',
                error: '',
                type: "off",
                required: false
            },

            ciudadFamiliar: {
                name: 'ciudadFamiliar',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            direccionFamiliar: {
                name: 'direccionFamiliar',
                value: '',
                error: '',
                type: "off",
                required: true
            },
            celularFamiliar: {
                name: 'celularFamiliar',
                value: '',
                error: '',
                type: "mobile",
                required: true
            },
            emailFamiliar: {
                name: 'emailFamiliar',
                value: '',
                error: '',
                type: "email",
                required: true
            },
            departamentoFamiliar: {
                name: 'departamentoFamiliar',
                value: "",
                error: '',
                type: "off",
                required: true
            },
            confirmT:"",
            backT:"",
            errorCampos:"",
            nextT:"",
            succRef:"",
            optionsDepartamentosFamiliar: [],
            optionsMunicipiosFamiliar: [],
            optionsDepartamentosPersonal: [],
            optionsMunicipiosPersonal: [],
            optionsTipoReferencia: [],
            optionParentesco: [],
            flagColombia:false,
            flagusa:false
        }
        this.allOK = true;
        if(localStorage.getItem('server') === '"colombia"' || localStorage.getItem('server') === '"colombiapruebas"' ){
        this.inputsToVerify = [
            'nombre1Personal',
            'nombre2Personal',
            'apellido1Personal',
            'apellido2Personal',
            'departamentoPersonal',
            'ciudadPersonal',
            'direccionPersonal',
            'celularPersonal',
            'emailPersonal',

            'parentesco',
            'nombre1Familiar',
            'nombre2Familiar',
            'apellido1Familiar',
            'apellido2Familiar',
            'departamentoFamiliar',
            'ciudadFamiliar',
            'direccionFamiliar',
            'celularFamiliar',
            'emailFamiliar',
            ]
        }
        if(localStorage.getItem('server') === '"eeuu"' || localStorage.getItem('server') === '"eeuupruebas"' ){
            this.inputsToVerify = [
                'nombre1Personal',
                'nombre2Personal',
                'apellido1Personal',
                'apellido2Personal',
                'celularPersonal',
                'emailPersonal', 
                ]

        }
    }

    inicialiceData = () => {
        const pais_server =  localStorage.getItem('server');
        if(pais_server==='"eeuu"'){
            this.setState({
                flagusa:true,
                succRef:<FormattedMessage id='referencesOK' defaultMessage={"form Ref"}/>,
                nextT:<FormattedMessage id='next' defaultMessage={"Siguiente"}/>,
                backT:<FormattedMessage id='back' defaultMessage={"Atras"}/>,
                errorCampos:<FormattedMessage id='thereare' defaultMessage={"Aun faltan campos por llenar"} />,
                confirmT:<FormattedMessage id='confirm' defaultMessage={"Confirmar"}/>,
            });
        }else{
            this.setState({
                flagColombia:true,
                succRef: "Formulario de referencias cargado OK!",
                nextT:"Siguiente",
                confirmT: "Confirmar",
                backT:"Atrás",
                errorCampos: "Atención, aún faltan campos por llenar"
            });
        }
        if (this.props.dataForm['referencias_ids']) {
            if (this.props.dataForm['referencias_ids'].length > 0) {
                this.setState({
                    nombre1Personal: {
                        name: 'nombre1Personal',
                        value: this.props.dataForm['referencias_ids'][0]['nombre1'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    nombre2Personal: {
                        name: 'nombre2Personal',
                        value: this.props.dataForm['referencias_ids'][0]['nombre2'],
                        error: '',
                        type: "off",
                        required: false
                    },
                    apellido1Personal: {
                        name: 'apellido1Personal',
                        value: this.props.dataForm['referencias_ids'][0]['apellido1'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    apellido2Personal: {
                        name: 'apellido2Personal',
                        value: this.props.dataForm['referencias_ids'][0]['apellido2'],
                        error: '',
                        type: "off",
                        required: false
                    },
                    departamentoPersonal: {
                        name: 'departamentoPersonal',
                        value: this.props.dataForm['referencias_ids'][0]['municipio_id'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    ciudadPersonal: {
                        name: 'ciudadPersonal',
                        value: this.props.dataForm['referencias_ids'][0]['municipio_id'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    direccionPersonal: {
                        name: 'direccionPersonal',
                        value: this.props.dataForm['referencias_ids'][0]['street'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    celularPersonal: {
                        name: 'celularPersonal',
                        value: this.props.dataForm['referencias_ids'][0]['mobile'],
                        error: '',
                        type: "mobile",
                        required: true
                    },
                    emailPersonal: {
                        name: 'emailPersonal',
                        value: this.props.dataForm['referencias_ids'][0]['email'],
                        error: '',
                        type: "email",
                        required: true
                    },
                })
            }
                if (this.props.dataForm['referencias_ids'].length > 1) {
                    this.setState({


                    parentesco: {
                        name: 'parentesco',
                        value: this.props.dataForm['referencias_ids'][1]['parentesco_id'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    nombre1Familiar: {
                        name: 'nombre1Familiar',
                        value: this.props.dataForm['referencias_ids'][1]['nombre1'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    nombre2Familiar: {
                        name: 'nombre2Familiar',
                        value: this.props.dataForm['referencias_ids'][1]['nombre2'],
                        error: '',
                        type: "off",
                        required: false
                    },
                    apellido1Familiar: {
                        name: 'apellido1Familiar',
                        value: this.props.dataForm['referencias_ids'][1]['apellido1'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    apellido2Familiar: {
                        name: 'apellido2Familiar',
                        value: this.props.dataForm['referencias_ids'][1]['apellido2'],
                        error: '',
                        type: "off",
                        required: false
                    },

                    ciudadFamiliar: {
                        name: 'ciudadFamiliar',
                        value: this.props.dataForm['referencias_ids'][1]['municipio_id'] ? this.props.dataForm['referencias_ids'][1]['municipio_id'] : '',
                        error: '',
                        type: "off",
                        required: true
                    },
                    departamentoFamiliar: {
                        name: 'departamentoFamiliar',
                        value: this.props.dataForm['referencias_ids'][1]['municipio_id'] ? this.props.dataForm['referencias_ids'][1]['municipio_id'] : '',
                        error: '',
                        type: "off",
                        required: true
                    },
                    direccionFamiliar: {
                        name: 'direccionFamiliar',
                        value: this.props.dataForm['referencias_ids'][1]['street'],
                        error: '',
                        type: "off",
                        required: true
                    },
                    celularFamiliar: {
                        name: 'celularFamiliar',
                        value: this.props.dataForm['referencias_ids'][1]['mobile'],
                        error: '',
                        type: "mobile",
                        required: true
                    },
                    emailFamiliar: {
                        name: 'emailFamiliar',
                        value: this.props.dataForm['referencias_ids'][1]['email'],
                        error: '',
                        type: "email",
                        required: true
                    },
                    optionsDepartamentosFamiliar: [],
                    optionsMunicipiosFamiliar: [],
                    optionsDepartamentosPersonal: [],
                    optionsMunicipiosPersonal: [],
                    optionsTipoReferencia: [],
                    optionParentesco: []
                })
            }
        }
        const departamentoFamiliar = [{ title: "seleccione una opción", value: "seleccione" }];
        const municipioFamiliar = [{ title: "seleccione una opción", value: "seleccione" }];
        const departamentoPersonal = [{ title: "seleccione una opción", value: "seleccione" }];
        const municipioPersonal = [{ title: "seleccione una opción", value: "seleccione" }];
        const listParentesco = [{ title: " ", value: "seleccione" }];

        if (!this.data) {
            return;
        }
        if (this.data.parentesco_ids) {
            if (this.props.dataForm['referencias_ids'].length === 0) {
                this.setState({
                    parentesco: {
                        name: 'parentesco',
                        value: listParentesco[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
            this.data.parentesco_ids.forEach(element => {
                listParentesco.push({
                    title: element.nombre, value: element.id
                });
                listParentesco.sort((a,b) =>{
                    return (a.title < b.title) ? -1 : 1
                })
            });
        }
        if (this.data.departamento_ids) {
            this.data.departamento_ids.forEach(element => {
                departamentoPersonal.push({
                    title: element.nombre, value: element.id
                });
                departamentoPersonal.sort((a,b) =>{
                    return (a.title < b.title) ? -1 : 1
                })
            });
            if (this.props.dataForm['referencias_ids'].length > 0) {
                this.setState({
                    departamentoPersonal: {
                        name: 'departamentoPersonal',
                        value: this.props.dataForm['referencias_ids'][0].departamento_id
                            ? this.props.dataForm['referencias_ids'][0].departamento_id
                            : departamentoPersonal[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        }
        if (this.data.departamento_ids) {
            this.data.departamento_ids.forEach(element => {
                departamentoFamiliar.push({
                    title: element.nombre, value: element.id
                });
                departamentoFamiliar.sort((a,b) =>{
                    return (a.title < b.title) ? -1 : 1
            })
            });
            if (this.props.dataForm['referencias_ids'].length > 1) {
                this.setState({
                    departamentoFamiliar: {
                        name: 'departamentoFamiliar',
                        value: this.props.dataForm['referencias_ids'][1].departamento_id
                            ? this.props.dataForm['referencias_ids'][1].departamento_id
                            : departamentoFamiliar[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        }

        if (this.data.municipio_ids) {
            if (this.props.dataForm['referencias_ids'].length > 0) {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.props.dataForm['referencias_ids'][0].departamento_id) === element.departamento_id) {
                        municipioPersonal.push({ title: element.nombre, value: element.id, });
                    }  
                    municipioPersonal.sort((a,b) =>{
                     return (a.title < b.title) ? -1 : 1
                    })
                });
                this.setState({
                    ciudadPersonal: {
                        name: 'ciudadPersonal',
                        value: this.props.dataForm['referencias_ids'][0].municipio_id
                            ? this.props.dataForm['referencias_ids'][0].municipio_id
                            : municipioPersonal[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })

            } else {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.data.departamento_ids[0].id) === element.departamento_id) {
                        municipioPersonal.push({ title: element.nombre, value: element.id, });
                    }
                });
                this.setState({
                    ciudadPersonal: {
                        name: 'ciudadPersonal',
                        value: municipioPersonal[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                    departamentoPersonal: {
                        name: 'departamentoPersonal',
                        value: departamentoPersonal[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })

            }
        }
        if (this.data.municipio_ids) {
            if (this.props.dataForm['referencias_ids'].length > 1) {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.props.dataForm['referencias_ids'][1].departamento_id) === element.departamento_id) {
                        municipioFamiliar.push({ title: element.nombre, value: element.id, });
                    }
                    municipioFamiliar.sort((a,b) =>{
                        return (a.title < b.title) ? -1 : 1
                })
                });
                this.setState({
                    ciudadFamiliar: {
                        name: 'ciudadFamiliar',
                        value: this.props.dataForm['referencias_ids'][1].municipio_id
                            ? this.props.dataForm['referencias_ids'][1].municipio_id
                            : municipioFamiliar[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            } else {
                this.data.municipio_ids.forEach(element => {
                    if (parseInt(this.data.departamento_ids[0].id) === element.departamento_id) {
                        municipioFamiliar.push({ title: element.nombre, value: element.id, });
                    }
                });
                this.setState({
                    ciudadFamiliar: {
                        name: 'ciudadFamiliar',
                        value: municipioFamiliar[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                    departamentoFamiliar: {
                        name: 'departamentoFamiliar',
                        value: departamentoFamiliar[0].value,
                        error: '',
                        type: "off",
                        required: true
                    },
                })
            }
        }


        this.setState({
            optionsDepartamentosFamiliar: departamentoFamiliar,
            optionsMunicipiosFamiliar: municipioFamiliar,
            optionsDepartamentosPersonal: departamentoPersonal,
            optionsMunicipiosPersonal: municipioPersonal,
            optionParentesco: listParentesco,
        })
    }

    selectDepartamentoFamiliar = (index) => {
        const optionsMunicipiosFamiliar = [{ title: "seleccione una opción", value: "seleccione" }];
        this.data.municipio_ids.forEach(element => {
            if (parseInt(index) === parseInt(element.departamento_id)) {
                optionsMunicipiosFamiliar.push({ title: element.nombre, value: element.id, });
            }
            
        });
        this.setState({
            optionsMunicipiosFamiliar,
            ciudadFamiliar: {
                name: 'ciudadFamiliar',
                value: optionsMunicipiosFamiliar[0].value,
                error: '',
                type: "off",
                required: true
            },
        })
    }
    selectDepartamentoPersonal = (index) => {
        const optionsMunicipiosPersonal = [{ title: "seleccione una opción", value: "seleccione" }];
        this.data.municipio_ids.forEach(element => {
            if (parseInt(index) === parseInt(element.departamento_id)) {
                optionsMunicipiosPersonal.push({ title: element.nombre, value: element.id, });
            }
            
            
        });
        this.setState({
            optionsMunicipiosPersonal,
            ciudadPersonal: {
                name: 'ciudadPersonal',
                value: optionsMunicipiosPersonal[0].value,
                error: '',
                type: "off",
                required: true
            },
        })
    }

    componentDidMount = () => {
        this.inicialiceData();
    }

    registrar = () => {
        let list = this.props.dataForm;
        let referencias = [];
        let refPersonal = [];
        let refFamiliar = [];
        let primeraPosicion = 0;
        let posicionPersonal = 0;
        let posicionFamiliar = 0;

        if (this.verifyForm()) {
            if(this.state.flagColombia){
                const uid = localStorage.getItem('uid');

            if (this.props.dataForm['referencias_ids'].length > 0) {
                primeraPosicion = 1;
                posicionPersonal = this.props.dataForm['referencias_ids'][0].id;
                posicionFamiliar = this.props.dataForm['referencias_ids'][1].id
            }

            const personal = {
                id: parseInt(uid),
                cliente: {
                    estadoreferencias: true,
                    referencias_ids: [
                        [primeraPosicion, posicionPersonal,
                            {
                                tipo: "personal",
                                nombre1: this.state.nombre1Personal.value,
                                nombre2: this.state.nombre2Personal.value,
                                apellido1: this.state.apellido1Personal.value,
                                apellido2: this.state.apellido2Personal.value,
                                mobile: this.state.celularPersonal.value,
                                departamento_id: this.state.departamentoPersonal.value,
                                municipio_id: this.state.ciudadPersonal.value,
                                street: this.state.direccionPersonal.value,
                                email: this.state.emailPersonal.value,
                            }
                        ],
                        [primeraPosicion, posicionFamiliar,
                            {
                                tipo: "familiar",
                                parentesco_id: this.state.parentesco.value,
                                nombre1: this.state.nombre1Familiar.value,
                                nombre2: this.state.nombre2Familiar.value,
                                apellido1: this.state.apellido1Familiar.value,
                                apellido2: this.state.apellido2Familiar.value,
                                mobile: this.state.celularFamiliar.value,
                                departamento_id: this.state.departamentoFamiliar.value,
                                municipio_id: this.state.ciudadFamiliar.value,
                                street: this.state.direccionFamiliar.value,
                                email: this.state.emailFamiliar.value,
                            }
                        ]
                    ]
                }
            }

            refPersonal = personal.cliente.referencias_ids[0][2];
            refFamiliar = personal.cliente.referencias_ids[1][2];
            referencias.push(refPersonal);
            referencias.push(refFamiliar);
            list.referencias_ids = referencias;

            subirClientes(personal, (data, flag) => {
                if (flag) {
                    if (this.props.next) {
                        this.props.next();
                    } else {
                        const success = () => toast.success(this.state.succRef);
                                    success(); 
                    }
                }
            });
        }
        if(this.state.flagusa){
            const uid = localStorage.getItem('uid');

            if (this.props.dataForm['referencias_ids'].length > 0) {
                primeraPosicion = 1;
                posicionPersonal = this.props.dataForm['referencias_ids'][0].id;
            }

            const personal = {
                id: parseInt(uid),
                cliente: {
                    estadoreferencias: true,
                    referencias_ids: [
                        [primeraPosicion, posicionPersonal,
                            {
                                tipo: "personal",
                                nombre1: this.state.nombre1Personal.value,
                                nombre2: this.state.nombre2Personal.value,
                                apellido1: this.state.apellido1Personal.value,
                                apellido2: this.state.apellido2Personal.value,
                                mobile: this.state.celularPersonal.value,
                             //   departamento_id: this.state.departamentoPersonal.value,
                             //   municipio_id: this.state.ciudadPersonal.value,
                                street: this.state.direccionPersonal.value,
                                email: this.state.emailPersonal.value,
                                parentesco_id: this.state.parentesco.value,
                            }
                        ]
                    ]
                }
            }

            refPersonal = personal.cliente.referencias_ids[0][2];
            referencias.push(refPersonal);
            list.referencias_ids = referencias;

            subirClientes(personal, (data, flag) => {
                if (flag) {
                    if (this.props.next) {
                        this.props.next();
                    } else {
                        const success = () => toast.success(this.state.succRef);
                        success(); 
                    }
                }
            });

        }


            
        } else {
            const error = () => toast.error(this.state.errorCampos);
            error();
        }
    }

    render() {
        return (
            <>
                <ToastContainer style={{marginTop:50, fontSize:16}} autoClose={10000}/>
                <div style={{ margin: '2% 0 0 8%', paddingBottom: "2%" }}>
                    <Card style={{ maxWidth: '70rem' }}>
                        <Card.Body>
                            <Form>
{this.state.flagColombia && <h1 className='text-title-black'> Referencia Personal</h1>}
{this.state.flagusa && <h3 className='text-title-black'> <FormattedMessage id='referenceP' defaultMessage={"Referencia personal"}/></h3>}
                                <hr />
                                <Row>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='firstName' defaultMessage={"Primer nombre"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer nombre</label>}
                                        <InputForm
                                            field={this.state.nombre1Personal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='secondName' defaultMessage={"Segundo nombre"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}></span>Segundo nombre</label>}
                                        <InputForm
                                            field={this.state.nombre2Personal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>

<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer apellido</label>
                                        <InputForm
                                            field={this.state.apellido1Personal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='secondSurName' defaultMessage={"Segundo apellido"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}></span>Segundo apellido</label>}
                                        <InputForm       
                                            field={this.state.apellido2Personal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>

{this.state.flagColombia &&         <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Departamento</label>
                                        <ComboBoxForm
                                            field={this.state.departamentoPersonal}
                                            options={this.state.optionsDepartamentosPersonal !== '' ? this.state.optionsDepartamentosPersonal : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.selectDepartamentoPersonal(value) }}
                                            type='horizontal'
                                            onBlur={this.handleRelease}
                                            initial={this.state.departamentoPersonal.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />

{this.state.flagColombia &&         <Col sm>
    <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Municipio</label>
                                        <ComboBoxForm
                                            field={this.state.ciudadPersonal}
                                            options={this.state.optionsMunicipiosPersonal !== '' ? this.state.optionsMunicipiosPersonal : []}
                                            handleChange={this.handleChange}
                                            type='horizontal'
                                            onBlur={this.handleRelease}
                                            initial={this.state.ciudadPersonal.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />

                                </Row>
                                <Row>
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Dirección</label>
                                        <InputForm
                                            field={this.state.direccionPersonal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='phone' defaultMessage={"Teléfono"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Número celular</label>}
                                        <InputNumber
                                            field={this.state.celularPersonal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <Col sm>
{this.state.flagusa &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='email' defaultMessage={"Correo Electrónico"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo Electrónico</label>}
                                        <InputForm
                                            field={this.state.emailPersonal}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <hr />
        {this.state.flagColombia &&   <h4 className='text-title-black'> Referencia Familiar</h4>}
                                <hr />
                                <Row>
                                     <Col sm>
{this.state.flagusa &&<label className='text-title-black'  style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span><FormattedMessage id='relationship' defaultMessage={"Parentesco"}/></label>}
{this.state.flagColombia &&<label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Parentesco</label>}
                                        <ComboBoxForm
                                            field={this.state.parentesco}
                                            options={this.state.optionParentesco !== '' ? this.state.optionParentesco : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.parentesco.value}
                                        />
                                    </Col>
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer nombre</label>
                                        <InputForm
                                            field={this.state.nombre1Familiar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Segundo nombre</label>
                                        <InputForm
                                            field={this.state.nombre2Familiar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>

                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Primer Apellido</label>
                                        <InputForm
                                            field={this.state.apellido1Familiar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Segundo Apellido</label>
                                        <InputForm
                                            field={this.state.apellido2Familiar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                <Row>

        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Departamento</label>
                                        <ComboBoxForm
                                            field={this.state.departamentoFamiliar}
                                            options={this.state.optionsDepartamentosFamiliar !== '' ? this.state.optionsDepartamentosFamiliar : []}
                                            handleChange={(name, value) => { this.handleChange(name, value); this.selectDepartamentoFamiliar(value) }}
                                            type='horizontal'
                                            onBlur={this.handleRelease}
                                            initial={this.state.departamentoFamiliar.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />

        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Municipio</label>
                                        <ComboBoxForm
                                            field={this.state.ciudadFamiliar}
                                            options={this.state.optionsMunicipiosFamiliar !== '' ? this.state.optionsMunicipiosFamiliar : []}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                            initial={this.state.ciudadFamiliar.value}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />

                                </Row>
                                <Row>
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Dirección</label>
                                        <InputForm
                                            field={this.state.direccionFamiliar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Número celular</label>
                                        <InputNumber
                                            field={this.state.celularFamiliar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
        {this.state.flagColombia && <Col sm>
            <label className='text-title-black' style={{ fontSize: '16px' }}><span style={{ color: "red" }}>*</span>Correo electrónico</label>
                                        <InputForm
                                            field={this.state.emailFamiliar}
                                            handleChange={this.handleChange}
                                            onBlur={this.handleRelease}
                                        />
                                    </Col>}
                                    <hr className="clearfix w-100 d-md-none" />
                                </Row>
                                {!this.props.showBoton &&
                                    <div className="container mt-4">
                                        <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                            onClick={this.registrar}>
                                            <label className='text-title-black' style={{marginTop:6}}>{this.state.confirmT}</label>         
                                        </Button>
                                    </div>
                                }
                            </Form>
                            {this.props.showBoton &&
                                <div className="container mt-4">
                                    <hr />
                                    <Row className="justify-content-md-center">
                                        <Col xs sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={() => this.props.back ? this.props.back() : <></>}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.backT}</label>   
                                            </Button>
                                        </Col>
                                        <Col xs sm>
                                            <Button style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }} onClick={this.registrar}>
                                                <label className='text-title-black' style={{marginTop:6}}>{this.state.nextT}</label>   
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
            </>
        )
    }
}