import React, { useState, useEffect } from 'react';
import { Col, Tooltip, OverlayTrigger, Button, Table, Popover } from 'react-bootstrap';
import { FormatMoney, FormatDateYYMMDD } from '../../../utilities/formats'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { sendTransaction } from '../../../services/datos.services';
import { RadioForm } from '../../../shared/components/inputs';
import { useToasts } from 'react-toast-notifications';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router';
import '../../home/about-us/about.css'
import logo from '../../../assets/venga.png'
import { ItemDetailCalc } from './itemDetailCalc';
import './../../../shared/components/Popover/popover.css';
import {  FormattedMessage } from 'react-intl';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';



export const Resume = props => {

    const { close, total, tax = 0, administration, tecnology,sellos, investigacion, totalDays,cuotas,
        iva = 0, porcentajes, value = 0, days = 0, subTotal = 0,
        sendRequest, show, nameUser } = props;
        let [radioMovilGo,setRadioMovilGo] = useState("")
    let [optionsPunto,setOptionsPunto] = useState([]); 
    const [_days, setDays] = useState(days);
    const [datos, setDatos] = useState({});
    const [ids, setIds] = useState({});
    const [flagColombia,setFlagColombia] = useState(false);
    const [flagUsa,setFlagUsa] = useState(false);
    const [redirect, setRedirect] = useState(<></>);
    let [textSend, setTextSend] = useState('');
    let [valueCuot, setValueCuot]= useState(0)
    let [textAccept, setTextSAccept] = useState('')
    let [textCancel, setTextSCancel] = useState('')
    let [textloan, setTextLoan] = useState('')
    const [swalProps, setSwalProps] = useState({});

    const { addToast } = useToasts();

    const initServidor = () =>{
        let servidor= localStorage.getItem('server');
            if(servidor==='"eeuu"'){
                setFlagUsa(true);
                setTextLoan(<FormattedMessage id='successloan' defaultMessage={"crédito ok"}/>)
                setTextSCancel(<FormattedMessage id='cancel' defaultMessage={"Cancelar"}/>)
                setTextSend(<FormattedMessage id='send' defaultMessage={"Enviar Solicitud"}/>)
                setTextSAccept(<FormattedMessage id='acceptloan' defaultMessage={"Aceptar préstamo"}/>)
            }else{
                setTextLoan("¡Se ha soliciado tu crédito. En un plazo de 24 horas tendrás tu respuesta!")
                setFlagColombia(true)
                setTextSend("Enviar Solicitud")
                setTextSAccept("Aceptar crédito")
                setTextSCancel("Cancelar")
            }
    }

    const location = () => {
        setRedirect(<Redirect to="/simulador" />)
        localStorage.removeItem('valueSummary');
    }
    useEffect(() => {
        setDays(days);
        setValueCuot((props.total / Math.ceil(props.cuotas)).toFixed(0))
         let options = localStorage.getItem("desembolso_ids")
         console.log("lasop ciones",options)
          if(options==!null){
            JSON.parse(options)
            setOptionsPunto(options) 
         } 
        setOptionsPunto(options) 
        initServidor();
    }, [days]);

    const infoValor = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             <p className='text-title' style={{ fontSize: 16 }}>
                Corresponde al cupo designado y aprobado al usuario, previa su solicitud.
            </p>
        </Tooltip>
    );
    const infoDays = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             <p className='text-title' style={{ fontSize: 16 }}>
                Corresponde a los días en los que pagarás tu crédito.
            </p>
        </Tooltip>
    );
    const infoCuotas = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             <p className='text-title' style={{ fontSize: 16 }}>
                Corresponde a las cuotas en las que pagarás tu crédito.
            </p>
        </Tooltip>
    );
    const infoVCuotas = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             <p className='text-title' style={{ fontSize: 16 }}>
                Corresponde a las cuotas en las que pagarás tu crédito.
            </p>
        </Tooltip>
    );
    const infoSubtotal = (props) => (
        <Tooltip id="button-tooltip" {...props}>
             <p className='text-title' style={{ fontSize: 16 }}>
                Subtotal de tu crédito.
            </p>
        </Tooltip>
    );

    const infoInteres = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <p className='text-title' style={{ fontSize: 16 }}>
            Corresponde al interés corriente, equivalente a la tasa fija aplicada al valor del crédito (tu capital), el cual con VENGALEPRESTO equivale al 25% E.A (Efectivo Anual). Este interés se calcula diariamente según el plazo que escojas para pagar tu crédito.
            </p>
        </Tooltip>
    );
    const infoAdmin = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <p className='text-title' style={{ fontSize: 16 }}>
                Gestión activa asociada a la disponibilidad del cupo.
            </p>
        </Tooltip>
    );
    const infoPlataforma = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <p className='text-title' style={{ fontSize: 16 }}>
            Corresponde a la posibilidad de acceso a la plataforma tecnológica la cual permite instrumentar la firma electrónica del crédito cien por ciento (100%) en línea, además, brindar los beneficios relacionados al crédito y consulta de tercero para la implantación de tecnologías que permiten la realización y gestión de mecanismos transparentes para brindar mayor confianza en la prestación del servicio. *El cobro del CARGO TECNOLÓGICO no se causará en caso de que NO desee usar nuestra plataforma tecnológica VENGALEPRESTO. Para más información contactanos al 320 330 6827
            </p>
        </Tooltip>
    );
    const infoIva = (props) => {
        return (
        <Tooltip id="button-tooltip" {...props}>
            <p className='text-title' style={{ fontSize: 16 }}>
                Impuesto de valor agregado, correspondiente al 19%,
                el cual se aplica a la tarifa final del producto o servicio.
            </p>
        </Tooltip>
    )};
    const calCredit = (props) => {
        const data = [];
        let cuotas = props.cuotas.split(" ")[2];
        let valor= props.valor / cuotas ;
        let interes = props.interes / cuotas ;
        let administracion = props.administracion / cuotas ;
        let sello = sellos / cuotas;
        let cInvestigacion= investigacion / cuotas;

       
        for (let k = 0; k < cuotas; k++) {
            data.push({
                    id:k,
                    valor: valor,
                    interes: interes,
                    administracion: administracion,
                    vsello: sello,
                    vInvestigacion: cInvestigacion    
            })        
        }
        return (
        

           
           <Popover id='id-popover' style={{maxWidth:'600px'}} >
                
                    <p><h5> Desgloce de crédito por valor de {props.valor}</h5> </p>
                    <Table striped bordered hover responsive="sm">
                        <thead>
                            <tr>
                                <th>Cuota </th>
                                <th>Valor </th>
                                <th>Cargos por interés </th>
                                <th> Cargos por financiamiento </th>
                                {/* <th>Investigacion de Crédito </th> */}
                            </tr>
                        </thead>
                        <tbody >
                            {data.map((data, i) => (
                                <ItemDetailCalc   key={i} data={data} />
                            ))}

                        </tbody>
                    </Table>
                
            </Popover>
            
    )};
    

    useEffect(() => {

        const datos = JSON.parse(localStorage.getItem('valuesSimulator'));
        setDatos(datos);
        const ids = JSON.parse(localStorage.getItem('idsSolicitud'));
        setIds(ids);
    }, []);


    const applyCredit = () => {

        const partner_id = parseInt(localStorage.getItem('partner_id'));
        const transaction =
            [
                {
                    id: -1,
                    detalle: {
                        solicitud_partner_id: { id: parseInt(partner_id), cliente: {} },
                        solicitud_payment_term_id: datos.payment_term_id,
                        solicitud_venta_interes_id: ids.venta_interes_id,
                        'tipo': 'solicitud',
                        'hora_dispositivo': FormatDateYYMMDD(new Date(Date.now())),
                        'valor': value,
                        'valortotal' : total,
                        'valorinteres':tax,
                        'valoradministracion':administration,
                        'valortecnologia':tecnology
                    }
                }
            ]
            console.log("Aqui se va la solicitud",transaction)
        sendTransaction(transaction, (data, flag) => {

            if (flag){
                if (data.errores) {
                    let messagge = data.errores.mensaje.split(',')[0] + ')';
                    //addToast(messagge, { appearance: 'error' });
                    Swal.fire({
                        title: 'VengaLePresto',
                        html: '<span style="color: black;">' + messagge + '</span>',
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonColor: '#4B4B4B',
                        confirmButtonText: '<strong>Aceptar</strong>'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            window.location = "/simulador";
                        }
                      });
                   
                } if(data[0].error){
                    let messagge = data[0].error.split(',')[0] + ')';
                    //addToast(messagge, { appearance: 'error' });
                    Swal.fire({
                        title: 'VengaLePresto',
                        html: '<span style="color: black;">' + messagge + '</span>',
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonColor: '#4B4B4B',
                        confirmButtonText: '<strong>Aceptar</strong>'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            window.location = "/simulador";
                        }
                      });

                } else{
                    const success = () => toast.success(textloan);
                    //alert("",textloan)
                    //window.confirm(textloan);
                    Swal.fire({
                        title: 'VengaLePresto',
                        html: '<span style="color: black;">' + textloan + '</span>',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#4ccfd4',
                        confirmButtonText: '<strong>Aceptar</strong>'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('valueSummary');
                            window.location = "/simulador";
                        }
                      });

                }

            }

        });
    };
    

    return (
        <>
            <ToastContainer style={{ marginTop: 50, fontSize:16}} autoClose={10000} />
            <SweetAlert2 {...swalProps} />
            {redirect}
            <style>
                {`
                    .estilo-card{
                        box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                        border-radius: 1em;
                    }
                `}
            </style>
            <div>
                <div style={{ fontSize: '16px' }} >
                    {show && <Col>
                        <div className="d-flex justify-content-between">
                            <h4 className='text-title-black'>
                                Solicitud de credito de: <strong>{nameUser}</strong>
                            </h4>
                            
                        </div>
                    </Col>}
                    <Col>
                        <div className="d-flex justify-content-between">
                            
                                <label className='text-title-black'>Valor:</label>
                                <label className='text-title-black'>{FormatMoney(parseInt(value))}
                                <label style={{marginLeft:10}}>
                            
                            
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 150, hide: 200 }}
                                    overlay={infoValor}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </OverlayTrigger></label></label>
                            
                        </div>
                    </Col>
                     <Col>
                        <div className="d-flex justify-content-between">
                            
                                <label className='text-title-black'>Plazo del crédito: </label>

                            
                           
                                <label  className='text-title-black '>{_days} 
                                <label style={{marginLeft:10}}>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 150, hide: 200 }}
                                        overlay={infoDays}

                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>

                                </label>

                           
                           
                                </label>
                                

 
                            
                            
                            
                        </div>
                    </Col>
{flagColombia &&     <Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                            Interés ( {porcentajes.monthlyInterest}% ) <strong>EA</strong> Fija :
                            </label >
                            <label className='text-title-black'>{FormatMoney(parseFloat(tax))}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 150, hide: 400 }}
                                overlay={infoInteres}
                            >  
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>

                            </label>
                                
                            
                            </label>
                            
                        </div>
                    </Col>}
                    {flagColombia && <Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                            Administración : 
                            </label>
                            <label className='text-title-black'>{FormatMoney(parseFloat(administration))}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 150, hide: 400 }}
                                    overlay={infoAdmin}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </OverlayTrigger>

                            </label>
                            </label>
                        </div>
                    </Col>}

                    <Col>
                        <div className="d-flex justify-content-between">
            {flagColombia &&     <label className='text-title-black'>
                           Plataforma : 
                            </label>}
                            <label className='text-title-black'>
                                {FormatMoney(parseFloat(tecnology))}
                                <label style={{marginLeft:10}}>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 150, hide: 400 }}
                                    overlay={infoPlataforma}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </OverlayTrigger>

                                </label>
                               

                                
                            </label>
                            

                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                                IVA ( {porcentajes.iva}% ): 
                            </label>
                            <label className='text-title-black'>{FormatMoney(iva)}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 150, hide: 400 }}
                                overlay={infoIva}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>

                            </label>
                            
                            
                            </label>
                            
                            
                            
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                                Número de Cuotas: 
                            </label>
                            
                            <label className='text-title-black'>{Math.ceil(cuotas) ? Math.ceil(cuotas) : 0}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 150, hide: 200 }}
                                overlay={infoCuotas}

                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>

                            </label>
                            
                            
                            </label>
                            
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                                Valor de la cuota: 
                            </label>
                            
                            <label className='text-title-black'>{valueCuot ? FormatMoney(parseInt(valueCuot)) : 0}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 150, hide: 200 }}
                                overlay={infoVCuotas}

                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>

                            </label>
                            </label>
                            
                        </div>
                    </Col>
                    {flagColombia &&<Col>
                        <div className="d-flex justify-content-between">
                            <label className='text-title-black'>
                                Subtotal: 
                            </label >
                            <label  className='text-title-black'>{FormatMoney(parseFloat(subTotal))}
                            <label style={{marginLeft:10}}>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 150, hide: 200 }}
                                overlay={infoSubtotal}

                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </OverlayTrigger>
                            </label>
                            </label>
                           
                        </div>
                    </Col>}
                    <Col>
                        <h5><strong className='text-total'>Total {FormatMoney(parseFloat(total))}</strong></h5>
                    </Col>
                    <hr />
                    <Col style={{ paddingBottom: 20 }} className="container">

                        {!show && <Button  style={{ backgroundColor: '#02dce8', borderColor: '#02dce8', borderRadius:100 }}
                            type="submit"
                            onClick={() => { sendRequest(); {
                                let json={porcentajes,sellos,investigacion, value, _days, nameUser, tax, administration,cuotas, tecnology, iva, subTotal, total}
                                localStorage.setItem("valueSummary", JSON.stringify(json))} }}>
                                <label className='text-title-black' style={{marginTop:6,fontSize:25}}>{textSend}</label>
                    </Button>}
                    
                        {show &&
                            <div className="container">
                                <Button
                                    style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                    onClick={close ? close : location}
                                >
                                     <label className='text-title-black'>{textCancel}</label>
                            </Button>
                                <Button  style={{ borderWidth:'3px solid',color:'black',borderRadius:50, backgroundColor:'transparent', minWidth: '20vw' }}
                                    type="submit"
                                    onClick={() => applyCredit()}
                                >
                                    <label className='text-title-black' style={{marginTop:6}}>{textAccept}</label>
                            </Button>

                            </div>
                        }
                    </Col>
                </div>
            </div>
        </>
    )
}